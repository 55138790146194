import React from 'react';
import {faTrashAlt, faCopy, faEye, faLock} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";;
import {FaPencil} from "react-icons/lib/fa";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const TemplateInfoblockActions = (props) => {
    const {user, assingedUsers} = props;
    const coAuthors = assingedUsers && Array.isArray(assingedUsers) ? assingedUsers : [];
    const isCoAuthor = coAuthors.includes(user.id);

    return(
        <div className={`infoblock-actions-container`}>
            <div className={`infoblock-actions`}>
                {
                    user && (user.role === 'Admin' || isCoAuthor) &&
                        <>
                            <FontAwesomeIcon icon={faTrashAlt} className={'trash-icon'} onClick={() => props.onDelete(props.infoblock)}/>
                            <FontAwesomeIcon icon={faCopy} className={'copy-icon'} onClick={props.onDuplicate(props.infoblock)}/>
                            <FontAwesomeIcon icon={faEye} className={'eye-icon'} onClick={() => props.onHide(props.infoblock)}/>
                        </>
                }

                {props.infoblock && !props.infoblock.locked ? <>
                    {props.user && props.user.role === 'Admin' && <FontAwesomeIcon icon={faLock} className={'lock-alt-icon'} onClick={() => props.onLockUnlock(props.infoblock)}/>}
                    {user && (user.role === 'Admin' || isCoAuthor) ?
                        <FaPencil className='edit-icon' onClick={() => props.onEdit(props.infoblock)}/>
                        :
                        <img src={`${static_url}assets/ic_translate_48px.svg`}
                             height={30} width={30}
                             style={{paddingLeft: '12px', cursor: 'pointer'}}
                             onClick={() => props.onEdit(props.infoblock)}
                        />
                    }
                </> : <FontAwesomeIcon icon={faLock} className={'locked lock-alt-icon'} onClick={() => {
                    if (props.user && props.user.role === 'Admin') {
                        props.onLockUnlock(props.infoblock)
                    }
                }}/>}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        assingedUsers: state.managementConsole.singleTerm.assigned_users,
    }
}

export default connect(mapStateToProps)(TemplateInfoblockActions);