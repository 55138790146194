import React, { useState } from "react";

const ImageUpload = (props) => {

  const [form, setValues] = useState({
    file: '',
    imagePreviewUrl: ''
  });

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setValues({
        ...form,
        file: file,
        imagePreviewUrl: reader.result
      });

      props.setAvatar(file)
    }


    reader.readAsDataURL(file)
  }

  const handleImageRemove = (e) => {
    e.preventDefault();

    setValues({
      ...form,
      imagePreviewUrl: '',
    });
    props.setAvatar('')
  }

  const { imagePreviewUrl } = form;
  let imagePreview = null;
  if (imagePreviewUrl !== '') {
    imagePreview = (<img className="add-image" src={imagePreviewUrl} />);
  }

  return (
    <div className="change-photo-wrapper">
      <div className="image-preview">
        { props.avatarImg && !imagePreview &&
            <img className="add-image" src={props.avatarImg} />}
        {imagePreview}
      </div>
      <div className="controls-image">
        <div className="control-buttons">
          <div className="change-photo-btn">
            Change Photo
            <input className="fileInput"
              type="file"
              onChange={ handleImageChange }
            />
          </div>
          <span>|</span>
          <button className="remove-img-btn" onClick={ handleImageRemove }>Remove</button>
        </div>
        <p className="description-file">JPG, GIF or PNG. Max size 2MB</p>
      </div>
    </div>
  )
}

export default ImageUpload
