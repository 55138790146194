import React, {Component, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Select from 'react-select';
import PageTemplate from "../Terms/templates/PageTemplate";
import ListTemplateHelper from "./helpers/ListTemplateHelper";
import {
    createAdminTemplate, editAdminSingleTemplate, getAdminListTemplateItems, getAdminSingleTemplate,
    getAvailableTermTemplates
} from "../../../store/console/actions";
import DocumentTemplate from "./DocumentTemplate";

class Template extends Component {

    state = {
        id: false,
        type: false,
        template: {},
        isEdit: false,
    };

    async componentDidMount() {
        const {match: {params}} = this.props;

        await this.props.getAvailableTermTemplates();

        if (params.templateId !== undefined) {
            try {
                const response = await this.props.getAdminSingleTemplate(params.templateId);
                const type = this.props.availableTemplates.find((item) => item.value === response.payload.data.data.type);
                this.setState({isEdit: true, template: {...response.payload.data.data.template}, type: type !== undefined ? type : false});

                if (type.value === 0) {
                    const listItems = await this.props.getAdminListTemplateItems(response.payload.data.data.template.id)
                    this.setState({template: {listTemplateTerms: listItems.payload.data.data}})
                }

            } catch (e) {
                this.props.history.push('/management-console/templates')
            }
        }
    }

    changeType = (selected) => this.setState({type: selected, template: {}});
    removeTemplate = () => this.setState({type: false, template: {}});
    changeData = (key, value) => this.setState({template: {...this.state.template, [key]: value}});

    saveTemplate = async (status) => {
        const {type, template} = this.state;
        const {match: {params}, createAdminTemplate, editAdminSingleTemplate} = this.props;
        const data = {
            type: type !== false ? type.value : false,
            status, ...template,
        };
        await (params.templateId === undefined ? createAdminTemplate(data) : editAdminSingleTemplate(params.templateId, data))
        this.props.history.push('/management-console/templates')
    };

    parentCallback = (name, value) => {
        this.setState({
            ...this.state,
            [name]: value
        })
    };

    render() {
        const {type, isEdit, template} = this.state;
        const {availableTemplates} = this.props;

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row console-title">
                        <div className="col-xl-6 offset-xl-3 console-title-cell">
                            <div className="col-xl-12">
                                <Link to={'/management-console/templates'} className="back-to-console">
                                    Back to Console
                                </Link>
                                <div>{isEdit ? 'Edit' : 'Add'} Template</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container container-add-set">
                    <div className="row">
                        <div className="col-xl-12">
                            <form className="add-set-form">
                                <div className="row">

                                    <div className="col-xl-12 col-set-form-lp">

                                        <div className="form-group">
                                            <label>Type</label>
                                            <Select
                                                className="custom-param-data-select"
                                                onChange={this.changeType}
                                                value={type !== false ? type : false}
                                                options={availableTemplates}
                                                name={'type'}
                                                classNamePrefix="react-select"
                                            />
                                        </div>

                                        {type !== false && type.value === 0 &&
                                            <ListTemplateHelper
                                                removeTemplate={this.removeTemplate}
                                                changeData={this.changeData}
                                                template={template}
                                            />
                                        }

                                        {type !== false && type.value === 1 &&
                                            <PageTemplate
                                                removeTemplate={this.removeTemplate}
                                                onChange={this.changeData}
                                                isEdit={true}
                                                data={{}}
                                                dataEdit={template}
                                            />
                                        }

                                        {type && type.value === 2 &&
                                            <DocumentTemplate
                                                template={template}
                                                parentCallback={this.parentCallback}
                                            />
                                        }
                                    </div>

                                    <button type="button"
                                            onClick={() => this.saveTemplate('publish')}
                                            className="console-button publish-set-button"
                                            disabled={!type}>
                                        Publish
                                    </button>

                                    <button type="button"
                                            onClick={() => this.saveTemplate('draft')}
                                            className="console-button publish-set-button"
                                            disabled={!type}>
                                        Draft
                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    availableTemplates: state.managementConsole.availableTemplates
});

const mapDispatchToProps = (dispatch) => ({
    getAvailableTermTemplates: () => dispatch(getAvailableTermTemplates()),
    createAdminTemplate: (data) => dispatch(createAdminTemplate(data)),
    getAdminSingleTemplate: (id) => dispatch(getAdminSingleTemplate(id)),
    getAdminListTemplateItems: (id) => dispatch(getAdminListTemplateItems(id, 'en')),
    editAdminSingleTemplate: (id, data) => dispatch(editAdminSingleTemplate(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Template));