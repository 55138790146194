import React from "react";
import InfoblockRow from "./InfoblockRow";

function PrintInfoblocksRows(props) {
    return (
        <>
            {props.infoblocks && props.infoblocks.length > 0 && props.infoblocks.map((item, index) => {
                if (item.checked) {
                    return <InfoblockRow {...props} item={item} index={index} key={index}/>
                }
            })}
        </>
    )
}

export default PrintInfoblocksRows