import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import reactStringReplace from 'react-string-replace';
import {clearEmbeddedTerm, fetchEmbeddedTerm, toggleFavourite} from "../../store/terms/actions";
import StarredRow from "./StarredRow"
import StarredTermNote from "./StarredTermNote";
import EmbeddedTerm from "../../helpers/EmbeddedTerm";
import RelatedInfo from "../Term/partials/RelatedInfo";
import ModalConfirmation from "../../helpers/ModalConfirmation";


class StarredTerm extends Component {
    state = {
        toggleNotExpanded: true,
        notesIsCreate: false,
        clickedEmbeddedTerm: false,
        showUnstarModal: false
    };

    _handleToggle = (id) => {
        const {toggleNotExpanded} = this.state;

        if (id === this.props.id) {

            if (toggleNotExpanded) {
                document.addEventListener('click', this.handleClick, false);
            } else {
                document.removeEventListener('click', this.handleClick, false);
            }
            this.setState({toggleNotExpanded: !toggleNotExpanded},
                () => this.props.onToggleExpand(this.props.id, !this.state.toggleNotExpanded))
        }

    };

    createMarkup = (data) => {
        return reactStringReplace(data, /(.*)/, (match, i) => (
            <span className='inline-topic_inner' key={`${i + Math.random()}_inner`}
                  dangerouslySetInnerHTML={{__html: match}}></span>));
    };

    onClickEmbedded(title, i) {
        const {term, fetchEmbeddedTerm, embeddedTerm} = this.props;
        const {clickedEmbeddedTerm} = this.state;
        title = title.replace(/(<span class='found-word'>)|(<\/span>)/gi, '');

        if (embeddedTerm.title !== undefined && embeddedTerm.title.toLowerCase() === title.toLowerCase()) {
            if (!clickedEmbeddedTerm) {
                this.setState({clickedEmbeddedTerm: true});
            }
            return;
        }

        fetchEmbeddedTerm(title, term.content.language, i)
            .then((value) => {
                this.setState({clickedEmbeddedTerm: true});
            });
    }

    closeEmbeded = () => {
        this.setState({
            clickedEmbeddedTerm: false
        })
    };

    convertEmbeddedTerms() {
        const {embeddedTerm, term} = this.props;
        if (term && term.content && term.content.description === undefined)
            return term.content.description;
        if (term && term.content && term.content.description) {
            return reactStringReplace(term.content.description, new RegExp(/\[\[(.*?)\]\]/, "gim"), (match, i) => (
                <span className='inline-topic_embedded' key={`${i}_embedded`}
                      onClick={(e) => {
                          e.stopPropagation();
                          this.onClickEmbedded(match, i);
                      }}>
                    <span dangerouslySetInnerHTML={{__html: match}}></span>
                    {i === embeddedTerm.id && this.state.clickedEmbeddedTerm && embeddedTerm.title &&
                    <EmbeddedTerm regExp={new RegExp(/\[\[(.*?)\]\]/, "gim")} term={embeddedTerm}
                                  title={embeddedTerm.title} description={embeddedTerm.description}
                                  closeEmbeded={this.closeEmbeded}/>
                    }
              </span>
            ))

        }
    }

    componentWillUnmount() {
        this.props.clearEmbeddedTerm();
        document.removeEventListener('click', this.handleClick, false);
    }

    handleClick = (e) => {
        if (this.shouldCloseTerm(e) && !this.state.toggleNotExpanded) {
            this._handleToggle(this.props.term.id);
        }
    };

    shouldCloseTerm = (e) => {
        if (e.which !== 1 || this.state.clickedEmbeddedTerm) {
            return false;
        }
        if (e.target.className === 'starred-title') {
            return true;
        }
        return !this.isClickInsideWrapper(e);
    };

    isClickInsideWrapper = (event) => {
        let copy = event.target;
        while (copy.parentNode) {
            if (copy.classList &&
                (copy.classList.contains('wrapper-starred-terms') || copy.classList.contains('ck'))
                || copy.classList.contains('wrapper-pop-up')) {
                return true;
            }
            copy = copy.parentNode;
        }
        return copy !== document;
    };

    handleClickedStar = () => {
        this.setState({
            showUnstarModal: true
        })
    };

    removeFromStarred = () => {
        const {checked, handleChange, toggleFavourite, term} = this.props;
        if (checked) {
            handleChange(term.id);
        }
        toggleFavourite(term);
        this.hideUnstarModal();
    };

    hideUnstarModal = () => {
        this.setState({
            showUnstarModal: false
        })
    };

    render() {
        const {term, id, checked, handleChange, isShowNotes} = this.props;
        const {toggleNotExpanded, clickedEmbeddedTerm, showUnstarModal} = this.state;

        return (
            <>
                {
                    toggleNotExpanded
                        ? <StarredRow
                            className="row-starred-term"
                            term={term}
                            isLink={false}
                            handleToggle={this._handleToggle}
                            handleChange={handleChange}
                            checked={checked}
                            handleClickedStar={this.handleClickedStar}
                            id={id}
                        />
                        : <div
                            className={`card-starred-term ${clickedEmbeddedTerm ? 'term-card-shadow_background' : ''}`}>
                            <StarredRow
                                className="title-starred-term"
                                term={term}
                                isLink={true}
                                handleToggle={this._handleToggle}
                                handleChange={handleChange}
                                checked={checked}
                                handleClickedStar={this.handleClickedStar}
                                id={id}
                            />
                            <div className="wrapper-description-note">
                                <div className="description-starred-term">
                                    <div className="starred_description-body">
                                        <div
                                            className={`starred_description ${isShowNotes ? "starred_description-position" : ''}`}>
                                            <div>
                                                <p className="starred_subtitle">Description</p>
                                                {this.createMarkup(this.convertEmbeddedTerms())}
                                            </div>
                                            <RelatedInfo term={term} alsoReferTo/>
                                        </div>
                                        {isShowNotes && <StarredTermNote term={term}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                <ModalConfirmation
                    show={showUnstarModal}
                    onHide={this.hideUnstarModal}
                    onSubmit={this.removeFromStarred}
                    title={'Confirm Remove from Starred Terms'}
                    body={'Warning: You are about to Remove term from Starred Terms. Please Confirm.'}
                    cancelText={'Cancel'}
                    submitText={'Yes, Remove'}
                    />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    embeddedTerm: state.terms.embeddedTerm,
});

const mapActionsToProps = (dispatch) => ({
    fetchEmbeddedTerm: (title, lang, id) => dispatch(fetchEmbeddedTerm(title, lang, id)),
    clearEmbeddedTerm: () => dispatch(clearEmbeddedTerm()),
    toggleFavourite: (term) => dispatch(toggleFavourite(term))
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(StarredTerm));
