import {FaShareAlt} from "react-icons/lib/fa";
import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import ToggleFavourite from "./ToggleFavourite";
import CopyTermIcon from "../../../helpers/CopyTermIcon";
import moment from "moment";
import EarthLanguage from "./EarthLanguage";
import TermNotifications from "./TermNotifications";
import PrintTermTemplateIcon from "../../../helpers/PrintTermTemplateIcon";

const TermBar = ({term, showNotifications, onChangeTranslation}) => {

    const [isClickedLink, setClickedLink] = useState(false);
    const [isClickedStar, setClickedStar] = useState(false);
    const [starError, setStarError] = useState(null);

    const termAvailableLanguages = term.available_languages || [];

    return (
        <>
            <div className="wrapper-term-bar">
                <ul className="term-icons-bar">
                    <li>
                        {termAvailableLanguages.length > 0 && (
                            <EarthLanguage
                                onChangeTranslation={onChangeTranslation}
                                term={term}
                                showLangUnderIcon
                            />
                        )}
                    </li>

                    {term.extended_description_type === 2 && (
                        <li>
                            <PrintTermTemplateIcon/>
                        </li>
                    )}

                    <li className='term-link-wrapper'>
                        <CopyTermIcon
                            term={term}
                            handleClickedLink={() => {
                                setClickedLink(true)
                            }}
                        />
                    </li>

                    <li>
                        <ToggleFavourite
                            term={term}
                            handleClickedStar={() => {
                                setStarError(null);
                                setClickedStar(true)
                            }}
                            onError={(e) => {
                                setStarError(e)
                            }}
                        />
                    </li>
                </ul>

                <div className="wrapper-updated">
                    <span className="last-updated">
                        {term.version && term.version.number ? `v${term.version.number} | ` : ''}
                        {term.content && moment(term.content.modified).format('LL')}
                    </span>
                </div>
            </div>
            {showNotifications &&
            <div
                className={`invisible-second-layer ${isClickedLink || isClickedStar || starError ? '' : 'invisible-layer-move-back'}`}>
                <TermNotifications
                    term={term}
                    isClickedLink={isClickedLink}
                    isClickedStar={isClickedStar}
                    starError={starError}
                    onLinkClose={() => setClickedLink(false)}
                    onStarClose={() => {
                        setClickedStar(false);
                        setStarError(null);
                    }}
                />

            </div>
            }
        </>
    )
};

export default TermBar;