import React, {Component, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import _get from 'lodash/fp/get';
import {Col, Container, Row} from 'react-bootstrap';
import {connect} from "react-redux";
import {
    adminPostMessage, bulkEditTemplates, editTerm, getAdminListTemplateItems, getAdminSingleTemplate,
    getAvailableTermTemplates,
    getSingleTerm, restoreTermVersion, updateSingleTerm
} from "../../../store/console/actions";
import {fetchListItems, fetchTerm} from "../../../store/terms/actions";

import PageTemplate from "./templates/PageTemplate";
import DocumentTemplate from "./templates/document/DocumentTemplate";
import MoalRemoveTemplate from "./templates/ModalRemoveTemplate";
import Discussion from '../../../helpers/Discussion';
import TermHeaderTemplate from "./templates/TermHeaderTemplate";
import Versions from "./templates/Versions";
import ListTemplateHelper from "../Templates/helpers/ListTemplateHelper";

import ExtendedDescriptionRow from './partials/ExtentedDescriptionRow';
import DocumentLegend from './templates/document/rightSidebar/DocumentLegend';
import DocumentInfoblocks from './templates/document/leftSidebar/DocumentInfoblocks';
import {fetchDocumentInfoblocks, syncInfoblocks} from "../../../store/infoblocks/actions";
import AttachedTemplateModal from "./templates/modals/AttachedTemplateModal";

const initialState = {
    editView: false,
    editTemplate: false,
    isEditTitle: false,
    editingTitle: '',
    isEditDescription: false,
    editingDescription: '',
    descriptionType: false,
    selectedDescriptionType: false,
    extendedDescriptionAdding: false,
    modalShow: false,
    modalRemoveTemplateShow: false,
    modalAttachTemplateShow: false,
    discussionItemsVisible: false,
    commentValue: '',
    commentLength: 0,
    showVersions: false,
    isEditHeader: false,
    isNewVersion: false,
    version: 1,
    template: {},
    prevPath: ''
};

class EditTerm extends Component {
    state = {
        ...initialState,
        termId: 0,
        termTitle: '',
        termDescription: '',
        clickedEmbeddedTerm: false,
        is_blank: false,
        _tmpData: {},
    };

    changeTranslation(language) {
        const {fetchTerm, match: {params: {termId}}} = this.props;
        fetchTerm(termId, language.code);
    }

    setDocumentTemplate = async () => {
        const {_tmpData: {key, selected, templateResponse}} = this.state;
        const templateValue = this.props.availableTemplates.find((item) => item.label === key).value;

        this.setState({
            selectedDescriptionType: templateValue,
            extendedDescriptionAdding: null,
            editListTemplate: selected.value === 0
        });

        if (selected.value > 0) {
            if (templateValue === 0) {
                const listItems = await this.props.getAdminListTemplateItems(templateResponse.payload.data.data.template.id);
                this.setState({template: {...templateResponse.payload.data.data.template, listTemplateTerms: listItems.payload.data.data}, is_blank: false})
            } else if (templateValue === 1) {
                this.setState({template: {...templateResponse.payload.data.data.template}, is_blank: false});
            } else if (templateValue === 2) {
                this.setState({is_blank: true});
                this.props.syncInfoblocks(templateResponse.payload.data.data.template.infoblocks);
            }
        }

        if (selected.value === 0) {
            this.setState({is_blank: true});
            this.props.syncInfoblocks([]);
        }
    };

    applyDescriptionType = async (key, selected) => {
        if (selected.value > 0) {
            const response = await this.props.getAdminSingleTemplate(selected.value);
            this.setState({_tmpData: {key, selected, templateResponse: response}});

            if (response.payload.data.data.is_attached === true) {
                this.setState({modalAttachTemplateShow: true})
            } else {
                this.setDocumentTemplate();
            }
        } else {
            this.setState({_tmpData: {key, selected}},
                () => {
                    this.setDocumentTemplate();
                }
            );
        }
    };

    changeView = () => this.setState({editView: false, isEditTitle: false, isEditDescription: false});
    changeEdit = () => this.setState({editView: true});
    cancelDescriptionType = () => this.setState({descriptionType: false, selectedDescriptionType: false});
    changeTemplateData = (key, value) => this.setState({template: {...this.state.template, [key]: value}});
    removeExtendedDescription = () => this.setState({modalRemoveTemplateShow: true});
    modalRemoveTemplateClose = () => this.setState({modalRemoveTemplateShow: false});
    modalRemoveTemplate = () => this.setState({...initialState, editView: true});
    modalAttachTemplateClose = () => this.setState({modalAttachTemplateShow: false, _tmpData: {}});
    modalAttachTemplate = () => {
        this.setState({...initialState, editView: true});
        this.setDocumentTemplate();
    };

    modalDuplicateTemplate = async () => {
        const {_tmpData: {templateResponse, key, selected}} = this.state;
        const templateId = templateResponse.payload.data.data.id;
        const data = {
            templates: [templateId],
            duplicate: true
        };
        const response = await this.props.duplicateTemplate(data);
        const template = await this.props.getAdminSingleTemplate(response.payload.data.duplicated[0]);
        this.setState({...initialState, editView: true, _tmpData: {key, selected, templateResponse: template}});
        this.setDocumentTemplate();
    };

    postMessage = (data) => this.props.adminPostMessage({term_id: this.state.termId, content: data});

    async updateLSingleTerm(term_id) {
        try {
            const fetchedTerm = await this.props.getSingleTerm(term_id);
            const fetchedAvailableTemplates = await this.props.getAvailableTermTemplates();

            let template = {};
            let selectedDescriptionType = false;

            const fetchedTermData = fetchedTerm.payload.data;
            const fetchedAvailableTemplatesData = fetchedAvailableTemplates.payload.data;

            if (fetchedTermData.extended_description_type !== null) {
                selectedDescriptionType = fetchedTermData.extended_description_type;
                template = {...fetchedTermData.extended_description};

                if (fetchedTermData.extended_description_type === fetchedAvailableTemplatesData.data[0].value) {
                    const listItems = await this.props.fetchListItems(term_id);
                    template.listTemplateTerms = [...listItems.payload.data.data]
                }
            }

            this.setState({
                termTitle: fetchedTermData.content.title,
                termDescription: fetchedTermData.content.description,
                termId: fetchedTermData.id,
                selectedDescriptionType,
                template
            })
        } catch (e) {
            this.props.history.push(this.state.prevPath)
        }
    };

    saveTerm = async (isDraft = false) => {
        const {singleTerm} = this.props;
        const data = {
            type: this.state.selectedDescriptionType,
            title: this.state.termTitle,
            description: this.state.termDescription,
            template: {...this.state.template},
        };
        if (Object.keys(data.template).length > 0) {
            data.template = Object.assign(data.template, {isDraft: isDraft})
        }

        if (this.state.isNewVersion) {
            data.new_version = true
        }

        if (this.state.selectedDescriptionType === 2) {
            data.infoblocks = this.props.infoblocks;
        }

        if (singleTerm.extended_description.new_title) {
            data.template.title = singleTerm.extended_description.new_title;
        }

        await this.props.editTerm(this.state.termId, data);
        this.updateLSingleTerm(singleTerm.id);
        this.changeView();
        if (this.state.selectedDescriptionType === 2) {
            await this.props.fetchDocumentInfoblocks(singleTerm.id);
        }
    };

    async componentDidMount() {
        const {match: {params}} = this.props;
        this.updateLSingleTerm(params.termId);
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     // console.log('this.props.location.state', this.props.location.state);
    //     if (nextProps.location !== this.props.location) {
    //      this.setState({ prevPath: this.props.location.state.prevPath })
    //     }
    // }

    componentWillMount() {
        const prevPath = this.props.location.state ? this.props.location.state.prevPath : '/';
        this.setState({ prevPath: prevPath });
    }

    handleShowEditTitle() {
        this.setState({
            isEditTitle: true,
            editingTitle: this.state.termTitle
        })
    }

    handleTitleChanged(data) {
        this.setState({
            editingTitle: data
        })
    }

    handleTitleSave() {
        this.setState({
            termTitle: this.state.editingTitle,
            isEditTitle: false
        })
    }

    handleTitleCancel() {
        this.setState({
            isEditTitle: false
        })
    }

    handleShowEditDescription() {
        this.setState({
            isEditDescription: true,
            editingDescription: this.state.termDescription
        })
    }

    handleDescriptionChanged(data) {
        this.setState({
            editingDescription: data
        })
    }

    handleDescriptionSave() {
        this.setState({
            termDescription: this.state.editingDescription,
            isEditDescription: false
        })
    }

    handleDescriptionCancel() {
        this.setState({
            isEditDescription: false
        })
    }

    updateEmbeddedTermClick = (data) => this.setState({clickedEmbeddedTerm: data});
    toggleVersions = () => this.setState(({showVersions}) => ({showVersions: !showVersions}));
    onVersionChange = (version) => this.setState({isEditHeader: false, version});
    changeDocumentTemplate = (infoblocks) => this.setState({template: {infoblocks}});

    onVersionRestore = async (version, deleteNewerVersions) => {
        const {singleTerm} = this.props;

        await this.props.restoreTermVersion(singleTerm.id, {version, deleteNewerVersions});
        this.props.history.push(this.state.prevPath);
    };

    onCreateNewVersion = (notes) => {
        const {pageTemplateItems} = this.state;
        
        this.setState({
            isEditHeader: true,
            isNewVersion: true,
            editView: true,
            showVersions: false,
            pageTemplateItemsEdit: {...pageTemplateItems}
        });
    };

    render() {
        const {singleTerm, user} = this.props;
        const {editView,
            selectedDescriptionType,
            modalRemoveTemplateShow,
            modalAttachTemplateShow,
            termTitle,
            termDescription,
            clickedEmbeddedTerm,
            showVersions,
            isEditHeader,
            template,
            is_blank
        } = this.state;

        const isDraft = _get('extended_description.isDraft', singleTerm);
        const user_language_code = _get('language.code', user);
        let modified_dt = _get('extended_description.modified_date', singleTerm);
        const locale = user_language_code ? user_language_code : 'en';
        if (modified_dt) {
            let options = {
                year: 'numeric', month: 'numeric', day: 'numeric',
                hour: 'numeric', minute: 'numeric'
            };
            modified_dt = new Intl.DateTimeFormat(locale, options).format(new Date(modified_dt));
        }

        return (
            <React.Fragment>
                <div className={isDraft ? "wrapper-term-details-draft": "wrapper-term-details"}>
                    <div className="header-term-details">
                        <Link className="link-back" to={this.state.prevPath}>Back To Terms List</Link>
                        <span className="title-details">Term Details</span>
                        {isDraft && (
                            <div className="term-varsion">
                                <span>Draft ( {modified_dt} )</span>
                            </div>
                        )}
                        {singleTerm.version && singleTerm.version.number && !isDraft && (
                            <div className="term-varsion">
                                Current Term Version:&nbsp;
                                <span onClick={this.toggleVersions}>Version {singleTerm.version.number} ↓</span>
                                {showVersions &&
                                        <Versions
                                            versions={singleTerm.versions}
                                            current={this.state.version}
                                            onVersionClick={this.onVersionChange}
                                            onRestoreClick={this.onVersionRestore}
                                            onNewVersion={this.onCreateNewVersion}/>}
                            </div>
                        )}
                        {!singleTerm.version && (
                            <div className="term-varsion">
                                Current Term Version:&nbsp;
                                <span onClick={() => this.toggleVersions()}>Version 1 ↓</span>
                                {showVersions && <Versions
                                    versions={singleTerm.versions}
                                    current={this.state.version}
                                    onVersionClick={this.onVersionChange}
                                    onRestoreClick={this.onVersionRestore}
                                    onNewVersion={this.onCreateNewVersion}/>}
                            </div>
                        )}
                    </div>
                </div>

                <div className='term-switch-btn-wrapper main-term-page'>
                    <div className='switch-btn-wrapper'>
                        <Link to={{}} onClick={this.changeView} className={`term-view ${editView === false ? "switch-btn-active" : ""}`}>
                            View
                        </Link>
                        <Link to={{}} onClick={this.changeEdit} className={`term-edit ${editView === true ? "switch-btn-active" : ""}`}>
                            Edit
                        </Link>
                    </div>
                </div>

                {singleTerm.id !== undefined && (
                    <Container className={`switch-term-container max-width ${(selectedDescriptionType === 2) ? "document-container" : ""}`}>
                        {selectedDescriptionType === 2 &&
                            <DocumentInfoblocks
                                isEdit={editView}
                            />
                        }

                        <div className={'switch-term-subcontainer'}>
                            <Row
                                className={`current-term-row max-width ${clickedEmbeddedTerm ? 'term-card-shadow_background' : ''}`}
                            >
                                <div className={'current-term-collection-wrapper'}>
                                    <Col
                                        className={'current-term-col static'}
                                        xl={12}
                                    >
                                        <TermHeaderTemplate
                                            title={termTitle}
                                            description={termDescription}
                                            term={singleTerm}
                                            isTermBar={false}
                                            isEdit={editView}
                                            isEditHeader={isEditHeader}
                                            editingTitle={this.state.editingTitle}
                                            isEditTitle={this.state.isEditTitle}
                                            handleShowEditTitle={() => this.handleShowEditTitle()}
                                            handleTitleChanged={(data) => this.handleTitleChanged(data)}
                                            handleTitleSave={() => this.handleTitleSave()}
                                            handleTitleCancel={() => this.handleTitleCancel()}
                                            editingDescription={this.state.editingDescription}
                                            isEditDescription={this.state.isEditDescription}
                                            handleShowEditDescription={() => this.handleShowEditDescription()}
                                            handleDescriptionChanged={(data) => this.handleDescriptionChanged(data)}
                                            handleDescriptionSave={() => this.handleDescriptionSave()}
                                            handleDescriptionCancel={() => this.handleDescriptionCancel()}
                                            onChangeTranslation={(language) => this.changeTranslation(language)}
                                            updateEmbeddedTermClick={this.updateEmbeddedTermClick}
                                        />

                                        {selectedDescriptionType !== false && selectedDescriptionType === 0 && <ListTemplateHelper
                                            isEdit={editView}
                                            removeTemplate={this.removeExtendedDescription}
                                            changeData={this.changeTemplateData}
                                            template={template}
                                        />}

                                        {selectedDescriptionType !== false && selectedDescriptionType === 1 && <PageTemplate
                                            removeTemplate={this.removeExtendedDescription}
                                            onChange={this.changeTemplateData}
                                            isEdit={editView}
                                            data={template}
                                            dataEdit={template}
                                        />}

                                        {selectedDescriptionType !== false && selectedDescriptionType === 2 && <DocumentTemplate
                                            removeTemplate={this.removeExtendedDescription}
                                            isEdit={editView}
                                            template={template}
                                            is_blank={is_blank}
                                            singleTerm={singleTerm !== undefined ? singleTerm : {}}
                                            fetchAction={this.props.fetchDocumentInfoblocks}
                                            updateEmbeddedTermClick={this.updateEmbeddedTermClick}
                                        />}

                                        {editView && selectedDescriptionType !== 2 && (
                                            <div className="save-button-wrapper">
                                                <button type={"button"} className={"console-button"} onClick={() => this.saveTerm()}>
                                                    Save Changes
                                                </button>
                                            </div>
                                        )}

                                        <MoalRemoveTemplate
                                            show={modalRemoveTemplateShow}
                                            onHide={this.modalRemoveTemplateClose}
                                            onSave={this.modalRemoveTemplate}
                                            singleTerm={singleTerm}
                                            documentInfoblocks
                                        />

                                        <AttachedTemplateModal
                                            show={modalAttachTemplateShow}
                                            onHide={this.modalAttachTemplateClose}
                                            onSave={this.modalAttachTemplate}
                                            onDuplicate={this.modalDuplicateTemplate}
                                        />
                                    </Col>
                                </div>
                            </Row>

                            {editView && selectedDescriptionType === 2 && <Row className={'actions-edit-term'}>
                                <Col xl={6}>
                                    <button type={"button"} className={"remove-template-button"} onClick={this.removeExtendedDescription}>
                                        Detach Document
                                    </button>
                                </Col>
                                <Col xl={6}>
                                    <button type={"button"} className={"draft document-button"} onClick={() => this.saveTerm(true)}>
                                        Save as draft
                                    </button>
                                    <button type={"button"} className={"save-button"} onClick={() => this.saveTerm()}>
                                        Save Changes
                                    </button>
                            </Col></Row>}

                            {(selectedDescriptionType === undefined || selectedDescriptionType === false) && <ExtendedDescriptionRow
                                editView={editView}
                                applyDescriptionType={this.applyDescriptionType}
                                cancelDescriptionType={this.cancelDescriptionType}
                            />}

                        </div>

                        <div className={`right-sidebar`}>
                            {singleTerm.messages && <Discussion
                                messages={singleTerm.messages}
                                sendComment={this.postMessage}
                                user={user}
                                termId={singleTerm.id}
                            />}

                            {selectedDescriptionType !== false && selectedDescriptionType === 2 && <DocumentLegend />}
                        </div>
                    </Container>)}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    terms: state.managementConsole.items,
    singleTerm: state.managementConsole.singleTerm,
    availableTemplates: state.managementConsole.availableTemplates,
    infoblocks: state.infoblocks.infoblocks,
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTerm: (termId, lang) => dispatch(fetchTerm(termId, lang)),
    getSingleTerm: (id) => dispatch(getSingleTerm(id)),
    getAvailableTermTemplates: () => dispatch(getAvailableTermTemplates()),
    editTerm: (id, data) => dispatch(editTerm(id, data)),
    restoreTermVersion: (id, data) => dispatch(restoreTermVersion(id, data)),
    adminPostMessage: (data) => dispatch(adminPostMessage(data)),
    fetchListItems: (id) => dispatch(fetchListItems(id, 'en')),
    getAdminSingleTemplate: (id) => dispatch(getAdminSingleTemplate(id)),
    getAdminListTemplateItems: (id) => dispatch(getAdminListTemplateItems(id)),

    fetchDocumentInfoblocks: (id) => dispatch(fetchDocumentInfoblocks(id)),
    syncInfoblocks: (data) => dispatch(syncInfoblocks(data)),
    duplicateTemplate: (data) => dispatch(bulkEditTemplates(data)),
    updateSingleTerm: (data) => dispatch(updateSingleTerm(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditTerm));