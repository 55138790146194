import * as types from "./types";
import { baseURL } from "../../configs/config";

export const signIn = (data) => ({
    type: types.SIGN_IN,
    payload: {
        request: {
            url: `${baseURL}login`,
            method: 'POST',
            data
        }
    }
});

export const googleSignIn = (data) => ({
    type: types.GOOGLE_SIGN_IN,
    payload: {
        request: {
            url: `${baseURL}google`,
            method: 'POST',
            data
        }
    }
});
export const facebookSignIn = (data) => ({
    type: types.FACEBOOK_SIGN_IN,
    payload: {
        request: {
            url: `${baseURL}facebook`,
            method: 'POST',
            data
        }
    }
});
export const linkedinSignIn = (data) => ({
    type: types.LINKEDIN_SIGN_IN,
    payload: {
        request: {
            url: `${baseURL}linkedin`,
            method: 'POST',
            data
        }
    }
});

export const signUp = (data) => ({
    type: types.SIGN_UP,
    payload: {
        request: {
            url: `${baseURL}register`,
            method: 'POST',
            data
        }
    }
});

export const signOut = () => ({
    type: types.SIGN_OUT,
    payload: {
        request: {
            url: `${baseURL}logout`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const forgotPassword = (data) => ({
    type: types.FORGOT_PASSWORD,
    payload: {
        request: {
            url: `${baseURL}forgot`,
            method: 'POST',
            data
        }
    },
    meta: {
        asPromise: true
    }
});

export const resetPassword = (token, data) => ({
    type: types.RESET_PASSWORD,
    payload: {
        request: {
            url: `${baseURL}reset_password?token=${token}`,
            method: 'POST',
            data
        }
    },
    meta: {
        asPromise: true
    }
});

export const signOutOtherTab = () => ({
    type: types.SIGN_OUT_OTHER_TAB
});

export const refreshToken = (data) => ({
    type: types.REFRESH_TOKEN,
    payload: {
    },

        request: {
            url: `${baseURL}token/refresh`,
            method: 'POST',
            data: {
                'refresh': data
            }
        }
});

export const fetchCurrentUserInfo = () => ({
    type: types.FETCH_CURRENT_USER_INFO,
    payload: {
        request: {
            url: `${baseURL}current_user`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        takeLatest: true
    }
});

export const editUserProfile = (data) => ({
    type: types.EDIT_USER_PROFILE,
    payload: {
        request: {
            url: `${baseURL}user`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
        },
    },
    meta: {asPromise: true},
});

export const deleteUserAccount = () => ({
    type: types.DELETE_USER_ACCOUNT,
    payload: {
        request: {
            url: `${baseURL}user`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
        },
    },
    meta: {asPromise: true},
});

export const clearNotificationsCount = () => ({
    type: types.CLEAR_NOTIFICATION_COUNT,
});

export const clearErrors = () => ({
    type: types.CLEAR_ERRORS
});