import React, {useEffect, useRef} from "react";
import moment from "moment";

function PrintFooter(props) {
    const {footerData, user} = props;
    const footerImg = `${location.origin}/api/static/assets/footer-logo.9f6d9729.svg`;
    const textStyle = {
        marginLeft: "0px",
        width: "100%",
        minWidth: "225px",
        maxWidth: "225px",
        // minWidth: "280px",
        // maxWidth: "280px",
        fontSize: "8px",
        fontWeight: "normal",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "12px",
        letterSpacing: "0.25px",
        textAlign: "left",
        color: "#707070",
    };

    const backStile = {
        backgroundColor: '#f6f6f6',
        display: "flex",
        padding: "32px 0px 32px 36px",
        maxHeight: "120px",
    };

    const currentDate = moment(new Date()).format('MMM DD, YYYY HH:mm');

    const createdByUser = user ? user.fullname ? user.fullname : user.email ? user.email : "Anonymous" : "Anonymous";

    return (
        <div style={{backgroundColor: '#f6f6f6'}}>
            <div style={backStile}>
                {<img src={`${footerImg}`}
                      height={63} width={226}/>}&nbsp;
                <>
                    {footerData &&
                    <div style={textStyle} dangerouslySetInnerHTML={createMarkup(footerData)}/>
                    }
                    {!footerData &&
                    <div style={textStyle}>
                        This is a static snapshot of online {' '}
                        <a href={location.origin}>BIMdictionary.com</a> {' '}
                        materials and is intended
                        as a reference only. Printout was created by {createdByUser} on
                        {` ${currentDate}`} and is provided under a {' '}
                        <a href="https://creativecommons.org/licenses/by-sa/4.0/legalcode">
                            Creative Commons Attribution-ShareAlike 4.0
                        </a> {' '}
                        International License and applicable {' '}
                        <a href={`${location.origin}/terms-of-use`}>
                            Terms of Use
                        </a>
                    </div>
                    }
                </>
            </div>
        </div>
    )
}

function createMarkup(data) {
    return {__html: data};
};

export default PrintFooter;
