import React, {Component} from "react";
import {connect} from "react-redux";
import Filter from "../../helpers/Filter";
import StarredTerm from "./StarredTerm";
import EmbedTerms from "./EmbedTerms";
import {FaPrint, FaTrash} from "react-icons/lib/fa";
import Paginator from "../../helpers/Paginator";
import {fetchStarredTerms, setStarredTermsFilters, fetchLanguageList, fetchLabelList, removeStarredTerms} from "../../store/terms/actions";

class StarredTerms extends Component {
    state = {
        per_page: 20,
        page: 0,
        checked: [],
        isShowPopUp: false,
        chosenParams: [],
        searchParams: {
            lang: '',
            label: '',
        },
        isShowNotes: false,
        expandedTerms: []
    };

    _filterParams = [{
        name: 'Language:',
        queryName: 'lang',
        _getData: () => this.props.langs,
        _onClick: (param) => this._chooseFilterParams(param)
    }, {
        name: 'Labels:',
        queryName: 'label',
        _getData: () => this.props.labels,
        _onClick: (param) => this._chooseFilterParams(param)
    }];

    _chooseFilterParams = (param) => {
        const { chosenParams } = this.state;

        if (!chosenParams.includes(param)) {
            this.setState({
                chosenParams: [...chosenParams, param],
            });
        }
    };

    _removeAllParams = () => {
        const { fetchStarredTerms } = this.props;

        this.setState({
            chosenParams: [],
            searchParams: {
                lang: '',
                label: ''
            }
        }, () => {
            fetchStarredTerms('en')
                .then(() => this.setFiltersFromSearchParams())
        });
    };

    _removeParam = (paramIndex, param) => {
        const { chosenParams } = this.state;
        const { fetchStarredTerms } = this.props;

        this.setState({
            chosenParams: chosenParams.filter((param, index) => index !== paramIndex),
            searchParams: {
                ...this.state.searchParams,
                [param.queryName]: ''
            }
        }, () => {
            if (param.queryName === 'lang') {
                fetchStarredTerms('en')
                    .then(() => this.setFiltersFromSearchParams())
            } else {
                this.setFiltersFromSearchParams()
            }
        });
    };

    chooseParamData = (selected, name) => {
        const { fetchStarredTerms } = this.props;

        this.setState( state => ({
            ...state,
            searchParams: {
                ...state.searchParams,
                [name]: selected.value,
            }
        }), () => {
            if (name === 'lang') {
                fetchStarredTerms(selected.value)
                    .then(() => this.setFiltersFromSearchParams())
            } else {
                this.setFiltersFromSearchParams()
            }
        })
    };

    setFiltersFromSearchParams = () => {

        this.props.setStarredTermsFilters({
            ...this.state.searchParams
        })
    };

    _paginatorChangedPage(data) {
        const page = data.selected;
        this.setState({
            page
        });
    };

    _handleChange = (id) => {
        const {checked} = this.state

        const index = checked.findIndex(item => item === id);
        index === -1 ? checked.push(id) : checked.splice(index, 1);
        this.setState({
            checked
        })
    };

    _selectAll = () => {
        const {checked} = this.state

        this.props.favouriteTerms.map(item => {
            if (!checked.includes(item.id)) {
                checked.push(item.id)
            }
        });

        this.setState({checked})
    };

    _unselectAll = () => {
        this.setState({checked: []})
    };

    _removeStarred = () => {
        const {checked} = this.state

        this.props.removeStarredTerms({terms: checked})

        this.setState({checked: []})
    };

    _showPopUp = () => {
        this.setState({isShowPopUp: true});
        document.body.style.overflow = "hidden";
    };

    _hidePopUp = () => {
        this.setState({isShowPopUp: false});
        document.body.style.overflow = "auto";
    };

    showNotes = () => {
        this.setState(state => ({
            ...state,
            isShowNotes: !state.isShowNotes
        }))
    };

    componentDidMount() {
        const { fetchStarredTerms, fetchLanguageList, fetchLabelList, langs, labels } = this.props;
        fetchStarredTerms('en');
        if (langs.length === 0) {
            fetchLanguageList()
        }
        if (labels.length === 0) {
            fetchLabelList()
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.favouriteTerms.length !== this.props.favouriteTerms.length) {
            const newChecked = this.state.checked.filter(check => {
                return !!this.props.favouriteTerms.find(term => check === term.id)
            });
            this.setState({
                checked: newChecked
            });
        }
    }

    toggleChooseAllFilterParams = () => {
        this.setState(state => ({chosenParams: state.chosenParams.length === 0 ?  this._filterParams : []}))
    };

    getShowNotesText = () => {
        const areThereNotes = this.props.favouriteTerms.some(term => term.my_note);
        if (areThereNotes) {
            if (this.state.expandedTerms.length) {
                if (this.state.isShowNotes) {
                    return "hide notes"
                } else {
                    return "show notes"
                }
            } else {
                return ""
            }
        } else {
            return ""
        }
    };

    onTermToggleExpand = (id, isExpanded) => {
        const {expandedTerms} = this.state;
        let newExpandedTerms;
        if (isExpanded) {
            newExpandedTerms = [...expandedTerms, id];
        } else {
            newExpandedTerms = expandedTerms.filter(item => item !== id);
        }
        this.setState({
            expandedTerms: newExpandedTerms
        })

    };

    render() {
        const {favouriteTerms, filtrationResultNull} = this.props;
        const {page, per_page, checked, isShowPopUp, chosenParams, isShowNotes, searchParams} = this.state;
        const limit = ((page * per_page) + per_page < favouriteTerms.length) ? (page * per_page) + per_page : favouriteTerms.length;
        const favouriteTermsToRender = favouriteTerms.slice(page * per_page, limit);

        return (
            <div>
                <div className="legend-page-wrapper starred-wrapper">
                    <div className="starred-terms-title_wrapper">
                        <div className="rate"></div>
                        <div className="starred-terms-page_title">Starred Terms List</div>
                        <div className="rate">
                            <span className="current">{page * per_page + favouriteTermsToRender.length}</span>
                            <span className="total">/100</span>
                        </div>

                    </div>
                </div>

                <div className="wrapper-starred-terms container w-sm">
                <div className="filter-row starred_term-filters">
                    <div className="filter-col starred-terms-filters">
                        <Filter
                            langs={this.props.labels}
                            filterParams={this._filterParams}
                            chooseFilterParams={this._chooseFilterParams}
                            removeAllParams={this._removeAllParams}
                            removeParam={this._removeParam}
                            chosenParams={chosenParams}
                            chooseParamData={this.chooseParamData}
                            toggleChooseAllFilterParams={this.toggleChooseAllFilterParams}
                        />
                        <span className='starred-show-notes' onClick={this.showNotes}>{this.getShowNotesText()}</span>
                    </div>
                </div>
                <div className="control-panel-starred">
                    <div className="left-wrapper">
                        <div className="terms-select"><span>{checked.length}</span> terms selected</div>
                        {/*<div className="control-button">
                            <FaPrint/>
                            <p>print</p>
                        </div>*/}
                        {checked.length > 0 &&
                            <>
                                <div onClick={this._showPopUp} className="control-button">
                                    <span className="arrow"> &#8249; &#8250; </span>
                                    <p>embed</p>
                                </div>
                                <div onClick={this._removeStarred} className="control-button">
                                    <FaTrash/>
                                    <p>remove</p>
                                </div>
                            </>
                        }
                    </div>
                    <div className="right-wrapper">
                        <button onClick={this._selectAll}>select all terms</button>
                        <button onClick={this._unselectAll}>unselect all</button>
                    </div>
                </div>

                {favouriteTerms.length ?
                    <div className='starred-terms-wrapper'>
                        {favouriteTermsToRender.map(term => (
                            <StarredTerm
                            key={term.id}
                            id={term.id}
                            term={term}
                            isShowNotes={isShowNotes}
                            checked={checked.findIndex(item => item === term.id) !== -1 ? true : false}
                            handleChange={this._handleChange}
                            onToggleExpand={this.onTermToggleExpand}
                            />
                        )) }
                    </div>
                    :
                    <div className="no-terms">No Starred Terms</div>
                }

                <Paginator
                    className={"console-paginator starred"}
                    pageCount={favouriteTerms.length / per_page}
                    pageChanged={(data) => this._paginatorChangedPage(data)}
                    forcePage={page}
                />
                </div>
                {isShowPopUp &&
                    <EmbedTerms
                        hidePopUp={this._hidePopUp}
                        checked={checked}
                        language={searchParams.lang}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    favouriteTerms: state.terms.favouriteTermsFiltred,
    langs: state.terms.langs,
    labels: state.terms.labels,
});

const mapActionsToProps = (dispatch) => ({
    fetchStarredTerms: (language) => dispatch(fetchStarredTerms(language)),
    removeStarredTerms: (data) => dispatch(removeStarredTerms(data)),
    setStarredTermsFilters: (data) => dispatch(setStarredTermsFilters(data)),
    fetchLanguageList: () => dispatch(fetchLanguageList()),
    fetchLabelList: () => dispatch(fetchLabelList()),
});

export default connect(mapStateToProps, mapActionsToProps)(StarredTerms);
