import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Row} from "reactstrap";
import {
    changeSearchTermLang,
    clearEmbeddedTerm,
    fetchEmbeddedTerm,
    fetchTerm,
    setSearchLabel
} from "../../store/terms/actions";
import NotesBox from './partials/NotesBox'
import reactStringReplace from 'react-string-replace';
import EmbeddedTerm from "../../helpers/EmbeddedTerm";
import TermCardView from "./TermCardView";
import Notification from "../../helpers/Notification";
import LocalNotification from "../../helpers/LocalNotification";

class TermFound extends Component {

    state = {
        clickedEmbeddedTerm: false,
        notAuthorizedNotification: false
    };

    onChangeTranslation = (language) => {
        this.props.fetchTerm(this.props.term.id, language.code)
            .then(() => {
                this.props.changeSearchTermLang(this.props.term.id)
            })
    };

    acceptSearchLabel = (data) => {
        this.props.setSearchLabel(data.item);
    };

    onClickEmbedded(title, i) {
        const {term, fetchEmbeddedTerm} = this.props;
        const {embeddedTerm} = this.props;
        title = title.replace(/(<span class='found-word'>)|(<\/span>)/gi, '');

        if (embeddedTerm.title !== undefined && embeddedTerm.title.toLowerCase() === title.toLowerCase()) {
            this.setState({clickedEmbeddedTerm: !this.state.clickedEmbeddedTerm});
            return
        }

        fetchEmbeddedTerm(title, term.content.language, i)
            .then((value) => {
                this.setState({clickedEmbeddedTerm: true});
            });
    }

    closeEmbeded = () => {
        this.setState({
            clickedEmbeddedTerm: false
        })
    };

    convertEmbeddedTerms() {
        const {embeddedTerm} = this.props;
        let textDescription = this.props.term.content.description;
        const searchWord = this.props.searchWord;

        if (textDescription === undefined)
            return textDescription;

        const isChecked = this.props.searchCheckbox.search_descriptions;
        if (isChecked && searchWord !== '') {
            textDescription = textDescription.replace(new RegExp("(" + searchWord + ")", "gim"), `<span class='found-word'>${searchWord}</span>`)
        }

        return reactStringReplace(textDescription, new RegExp(/\[\[(.*?)\]\]/, "gim"), (match, i) => (
            <span className='inline-topic_embedded' key={`${i + Math.random()}_embedded`}
                  onClick={() => this.onClickEmbedded(match, i)}>

                {isChecked ?
                    reactStringReplace(match, new RegExp("<span class='found-word'>(" + searchWord + ")<\/span>", "gim"), (matchJ, j) => (
                        <span className='found-word' key={`${j + Math.random()}_embedded`} dangerouslySetInnerHTML={{__html: matchJ}}></span>
                    )) : <span dangerouslySetInnerHTML={{__html: match}}></span>
                }

                {embeddedTerm.title &&
                match.replace(/(<span class='found-word'>)|(<\/span>)/gi, '').toLowerCase() === embeddedTerm.title.toLowerCase() &&
                this.state.clickedEmbeddedTerm &&
                <EmbeddedTerm regExp={new RegExp(/\[\[(.*?)\]\]/, "gim")} term={embeddedTerm} title={embeddedTerm.title}
                              description={embeddedTerm.description} closeEmbeded={this.closeEmbeded}/>
                }
            </span>
        ));
    }

    highlightMatches = (text) => {
        const searchWord = this.props.searchWord;
        const isChecked = this.props.searchCheckbox.search_descriptions;
        if (isChecked && searchWord.length > 0 && text) {
            let regex = new RegExp(searchWord, "gim");
            return text.replace(regex, `<span class='found-word'>${text.match(regex)}</span>`)
        } else {
            return text
        }
    };

    setNotAuthorizedNotification(notAuthorizedNotification) {
        this.setState({
            notAuthorizedNotification
        })
    }

    componentWillUnmount() {
        this.props.clearEmbeddedTerm();
    }

    render() {
        const {term, langs} = this.props;
        const {clickedEmbeddedTerm, notAuthorizedNotification} = this.state;
        
        return (
            <Row className="bim-card-row term-found">
                <Col
                    className={'term-found-col'}
                    xl={{size: 8, offset: 2}}
                >
                    <TermCardView
                        title={this.highlightMatches(term.content.title)}
                        similarTerms={this.highlightMatches(term.similar_terms)}
                        convertEmbeddedTerms={() => this.convertEmbeddedTerms()}
                        onChangeTranslation={this.onChangeTranslation}
                        term={term}
                        langs={langs}
                        clickedEmbeddedTerm={clickedEmbeddedTerm}
                        acceptSearchLabel={({item, term}) => this.acceptSearchLabel({item, term})}
                    />
                    <NotesBox
                        term={term}
                        onNotAuthorizedClick={() => this.setNotAuthorizedNotification(true)}/>
                    <LocalNotification
                        top={"20px"}
                        text={'Please login to use this feature'}
                        show={notAuthorizedNotification}
                        onClose={() => this.setNotAuthorizedNotification(false)} />
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    langs: state.terms.langs,
    isAuthorized: state.auth.isAuthorized,
    searchCheckbox: state.terms.searchCheckbox,
    searchWord: state.terms.searchWord,
    embeddedTerm: state.terms.embeddedTerm,
});

const mapActionsToProps = (dispatch) => ({
    changeSearchTermLang: (data) => dispatch(changeSearchTermLang(data)),
    fetchTerm: (id, language) => dispatch(fetchTerm(id, language)),
    setSearchLabel: (data) => dispatch(setSearchLabel(data)),
    fetchEmbeddedTerm: (title, lang, id) => dispatch(fetchEmbeddedTerm(title, lang, id)),
    clearEmbeddedTerm: () => dispatch(clearEmbeddedTerm()),
});

export default connect(mapStateToProps, mapActionsToProps)(TermFound);
