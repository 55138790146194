import * as types from "./types";
import {baseURL} from "../../configs/config";

const fetchReviewerTerms = (type, params = {}) => ({
    type,
    payload: {
        request: {
            url: `${baseURL}v1/reviewer/terms`,
            params,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const fetchToBeReviewedTerms = () => (
    fetchReviewerTerms(types.FETCH_TO_BE_REVIEWED_TERMS)
);

export const fetchRejectedTerms = () => (
    fetchReviewerTerms(types.FETCH_REJECTED_TERMS, { status: 'rejected'})
);

export const fetchApprovedTerms = () => (
    fetchReviewerTerms(types.FETCH_APPROVED_TERMS, { status: 'approved'})
);

export const clearReviewerTerms = () => ({
    type: types.CLEAR_REVIEWER_TERMS
});


export const fetchReviewerTerm = (termId, lang = 'en') => ({
    type: types.FETCH_REVIEWER_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/reviewer/terms/${termId}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                'lang': lang,
        }
    }
}});

export const approve = (termId) => ({
    type: types.APPROVE,
    payload: {
        request: {
            url: `${baseURL}v1/reviewer/terms/${termId}`,
            method: 'PATCH',
            data: {
                approve: true
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        asPromise: true
    }
});

export const reject = (termId, rejectReason) => ({
    type: types.REJECT,
    payload: {
        request: {
            url: `${baseURL}v1/reviewer/terms/${termId}`,
            method: 'PATCH',
            data: {
                reject: true,
                content: rejectReason
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        asPromise: true
    }
});

export const sendReviewerMessage = (termId, data) => ({
    type: types.SEND_REVIEWER_MESSAGE,
    payload: {
        request: {
            url: `${baseURL}v1/reviewer/terms/${termId}`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});
