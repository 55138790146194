import React, {Component} from "react";
import {fetchEmbeddedTerm, clearEmbeddedTerm } from "../../../store/terms/actions";
import Paginator from "../../../helpers/Paginator";
import EmbeddedTerm from "../../../helpers/EmbeddedTerm";
import {connect} from "react-redux";
import {fetchListItems} from "../../../store/terms/actions";
import reactStringReplace from 'react-string-replace';
import ToggleFavourite from "./ToggleFavourite";
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import {froala3Key} from "../../../configs/config";
import {Link} from "react-router-dom";

class ListTemplate extends Component {
    state = {
        per_page: 3,
        page: 0,
        clickedEmbeddedTerm: false,
        embeddedTermParentId: -1,
        title: {
            text: '',
            isSaved: false
        },
        description: {
            text: '',
            isSaved: false
        },
        translationLoaded: false
    };

    _paginatorChangedPage(data) {
        const page = data.selected;
        this.setState({
            page
        });
    }

    handleChangeEditField = (data, name) => {
        this.setState( state => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    text: data,
                    isSaved: false
                }
            }
        })
    };

    saveEditData = (name) => {
        this.setState( state => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isSaved: true
                }
            }
        }, () => {
            this.props.handleSaveChildData({ title: this.state.title.text, content: this.state.description.text })
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!this.state.translationLoaded && this.props.term.my_translation) || this.props.clearChildrenState) {
            const { term } = this.props;
            const extendedDescription = term.my_translation && term.my_translation.extended_description ? term.my_translation.extended_description : {};
            const translatedTitle = extendedDescription.title ? extendedDescription.title : '';
            const translatedDescription = extendedDescription.content ? extendedDescription.content : '';

            this.setState({
                title: {
                    text: translatedTitle,
                    isSaved: true
                },
                description: {
                    text: translatedDescription,
                    isSaved: true
                },
                translationLoaded: true
            })
        }
        if (prevProps.language !== this.props.language) {
            this.props.fetchListItems(this.props.term.id, this.props.language);
        }
    }

    createMarkup = (data) => {
        return reactStringReplace(data, /(.*)/, (match, i) => (<span className='inline-topic_inner' key={`${i+Math.random()}_inner`} dangerouslySetInnerHTML={{__html: match}}></span>));
    };

    onClickEmbedded(title, i, term) {
        const { fetchEmbeddedTerm } = this.props;
        const {embeddedTerm} = this.props;
        title = title.replace(/(<span class='found-word'>)|(<\/span>)/gi, '');

        if (embeddedTerm.title !== undefined && embeddedTerm.title.toLowerCase() === title.toLowerCase()) {
            this.setState({clickedEmbeddedTerm: !this.state.clickedEmbeddedTerm, embeddedTermParentId: term.id});
            this.props.updateEmbeddedTermClick(!this.state.clickedEmbeddedTerm);
            return
        }

        fetchEmbeddedTerm(title, this.props.language || 'en', term.id)
            .then((value) => {
                this.setState({clickedEmbeddedTerm: true, embeddedTermParentId: term.id});
                this.props.updateEmbeddedTermClick(true);
            });
    }

    convertEmbeddedTerms(term) {
        const {embeddedTerm} = this.props;
        if (term && term.description === undefined)
            return term.description;
        if (term && term.description) {
            return reactStringReplace(term.description, new RegExp(/\[\[(.*?)\]\]/, "gim"), (match, i) => (
                <span className='inline-topic_embedded' key={`${i}_embedded`} onClick={() => this.onClickEmbedded(match, i, term)}><span dangerouslySetInnerHTML={{__html: match}}></span>
                {match === embeddedTerm.title && match === embeddedTerm.title && this.state.clickedEmbeddedTerm && embeddedTerm.title && this.state.embeddedTermParentId === term.id &&
                    <EmbeddedTerm regExp={new RegExp(/\[\[(.*?)\]\]/, "gim")} term={embeddedTerm} title={embeddedTerm.title} description={embeddedTerm.description} closeEmbeded={this.closeEmbeded}/>
                }
                </span>
            ))
        }
    }

    closeEmbeded = () => {
        this.props.updateEmbeddedTermClick(false);
        this.setState({
            clickedEmbeddedTerm: false
        });        
    };

    componentDidMount() {
        this.props.fetchListItems(this.props.term.id, this.props.language);
        this.props.clearEmbeddedTerm();
    }

    render() {
        const {term, listItems, isEdit, showTranslation} = this.props;
        const {page, per_page, title, description} = this.state;
        const limit = ((page * per_page) + per_page < listItems.length) ? (page * per_page) + per_page : listItems.length;
        const content = showTranslation ? term.my_translation : term.content;
        const mainTerm = term;

        const placeholderTitle = content && content.extended_description ? content.extended_description.title : '';

        const placeholderDescription = content && content.extended_description ? content.extended_description.content : '';

        return (
            <>
            <div className="wrapper-term-list-body">
                {term.content.extended_description && placeholderTitle && (<div className="term-list-title">
                    <span dangerouslySetInnerHTML={{__html: term.content.extended_description && placeholderTitle || ''}}/>
                    {/*<span className="list-items-count"> (showing {limit} of {listItems.length} terms)</span>*/}
                    <span className="list-items-count"> ({listItems.length} terms)</span>
                    {isEdit &&
                        <div className="editor-list-template-title">
                            <input type={"text"}
                                   value={title.text}
                                   placeholder="Enter your translation"
                                   onChange={(e) => this.handleChangeEditField(e.target.value, 'title')}/>
                            <button disabled= {!title.text || title.isSaved}
                                    type={"button"} className={"console-button"} onClick={() => this.saveEditData('title')}>
                                save
                            </button>
                        </div>}
                </div>)}
                {term.content.extended_description && <div className="term-list-description">{this.createMarkup(placeholderDescription)}</div>}
                {isEdit &&
                    <div className="editor-list-template-description">
                        <FroalaEditor
                            tag='div'
                            config={{
                                htmlUntouched: true,
                                fullPage: true,
                                htmlRemoveTags: [],
                                key: froala3Key,
                                toolbarInline: true,
                                imageUploadParam: 'image_param',
                                imageUploadURL: '/api/froala_upload_image',
                                imageUploadMethod: 'POST',
                                charCounterCount: false,
                                direction: 'ltr',
                                toolbarVisibleWithoutSelection: true,
                                attribution: false,
                                indentMargin: 0,
                                editorClass: 'editor-header-title',
                                placeholderText: 'Enter Your Translation',
                                toolbarButtons: {
                                    'moreText': {
                                        'buttons': ['bold', 'italic']
                                    },
                                    'moreMisc': {
                                        'buttons': ['undo', 'redo']
                                    }
                                }
                            }}
                            model={description.text}
                            onModelChange={( ...data ) => {
                                this.handleChangeEditField(data[0], 'description');
                            }}
                        />
                        <button disabled= {!description.text || description.isSaved} className="console-button"
                                onClick={() => this.saveEditData('description')}>save</button>

                    </div>}
            </div>
            <div>
                {listItems && listItems.slice(page * per_page, limit).map((term, index) => (
                    <div className="wrapper-term-list mobile-description" key={index}>
                        {term.title && <div className={`list-title ${mainTerm.content && (mainTerm.content.language === 'ar' || mainTerm.content.langauge === 'fa') ? 'arabic' : ''} ${term.direction !== undefined ? term.direction : ''}`}>
                            <Link to={`/${mainTerm.content && mainTerm.content.language ? mainTerm.content.language : 'en'}/${term.slug}/${term.version}`} dangerouslySetInnerHTML={{__html: term.title}} />
                        </div>}
                        <div className="list-description">
                            {<div className={`text-description ${mainTerm.content && (mainTerm.content.language === 'ar' || mainTerm.content.langauge === 'fa') ? 'arabic' : ''} ${term.direction !== undefined ? term.direction : ''}`}>{this.createMarkup(this.convertEmbeddedTerms(term))}</div>}
                            <div className="icons-box">
                                <ToggleFavourite term={term}/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="paginator-wrapper">
                {/*<div className="paginator-info">(<span>{limit}</span> of <span>{listItems.length}</span> terms)</div>*/}
                <Paginator
                    pageCount={listItems.length / per_page}
                    pageChanged={(data) => this._paginatorChangedPage(data)}
                    forcePage={page}
                    className={"console-paginator"}
                />
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    listItems: state.terms.listItems,
    embeddedTerm: state.terms.embeddedTerm,
});

const mapActionsToProps = (dispatch) => ({
    fetchListItems: (termId, lang) => dispatch(fetchListItems(termId, lang)),
    fetchEmbeddedTerm: (title, lang, id) => dispatch(fetchEmbeddedTerm(title, lang, id)),
    clearEmbeddedTerm: () => dispatch(clearEmbeddedTerm()),
});

export default connect(mapStateToProps, mapActionsToProps)(ListTemplate);
