import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    clearAdminTerms,
    clearSingleSet,
    createSet,
    editSingleSet,
    getAdminTerms,
    getSingleSet
} from "../../../store/console/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import ShareSetModal from "./ShareSetModal";
import AddTerms from "../../../helpers/AddTerms";

class AddEditSet extends Component {
    state = {
        modalShow: false,
        singleSet: {},
        id: false,
        name: '',
        description: '',
        sharedUsers: [],
        isEdit: true
    };

    includedTerms = [];

    _changeValue(e, field) {
        this.setState({
            [field]: e.target.value
        });
    }

    _deleteUser(currentUser) {
        const {sharedUsers} = this.state;

        this.setState({
            sharedUsers: sharedUsers.filter(user => user.id !== currentUser.id)
        })
    }

    _modalShow() {
        this.setState({
            modalShow: true
        })
    }

    _modalClose() {
        this.setState({
            modalShow: false
        })
    }

    _shareSet(data) {
        const {sharedUsers} = this.state;

        this.setState({
            modalShow: false,
            sharedUsers: [
                ...sharedUsers,
                ...data.itemsToShare.filter(item => sharedUsers.findIndex(item2 => item.id === item2.id && item.type === item2.type) === -1)
            ]
        })
    }

    publishSet() {
        const {name, description, sharedUsers} = this.state;
        const {singleSet} = this.props;

        if (singleSet.id !== undefined) {
            this.props.editSet(singleSet.id, {
                name,
                description,
                terms: this.includedTerms,
                shared_with: sharedUsers
            }).then(() => this.props.history.push('/management-console/sets'));
        } else {
            this.props.createSet({
                name,
                description,
                terms: this.includedTerms,
                shared_with: sharedUsers
            }).then(() => this.props.history.push('/management-console/sets'));
        }
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        this.props.clearSet();
        this.props.clearAdminTerms();

        const array = this.props.location.pathname.split('/');
        const isEdit = array[array.length-1] === "add-set" ? false : true
        this.props.getAdminTerms({all: true}, !isEdit)
            .then((data) => {

                const excludedTerms = data.payload.data.data.map(term => term.id);
                this.setState({
                    excludedTerms
                });

                if (params.setId !== undefined) {
                    this.props.getSingleSet(params.setId)
                }
            });
    }

    componentWillReceiveProps(nextProps) {
        const {singleSet} = nextProps;
        const array = this.props.location.pathname.split('/');
        if (array[array.length-1] === "add-set"){
            this.setState({
                isEdit: false
            })
        }
        if (singleSet.id !== undefined) {
            this.includedTerms = singleSet.shared_terms.map(term => term.id);
            this.setState({
                id: singleSet.id,
                name: singleSet.name,
                description: singleSet.description,
                sharedUsers: singleSet.shared_with
            })
        }
    }

    render() {
        const {terms} = this.props;
        const {
            name,
            description,
            sharedUsers,
            modalShow,
            isEdit
        } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row console-title">
                        <div className="col-xl-6 offset-xl-3 console-title-cell">
                            <div className="col-xl-12">
                                <Link to={'/management-console/sets'} className="back-to-console">
                                    Back to Sets List
                                </Link>
                                <div>{isEdit ? 'Edit' : 'Add'} Set</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container container-add-set">
                    <div className="row">
                        <div className="col-xl-12">
                            <form className="add-set-form">
                                <div className="row">

                                    <div className="col-xl-12 col-set-form-lp">
                                        {/*<div className="sub-title">*/}
                                        {/*    Set Properties*/}
                                        {/*</div>*/}

                                        <div className="form-group form-input col-xl-6">
                                            <label>Set Name<span className="input-required">*</span></label>
                                            <input type={"text"}
                                                   value={name}
                                                   onChange={(e) => this._changeValue(e, 'name')}
                                                   name={"name"}/>
                                        </div>
                                        <div className="form-group form-input col-xl-6">
                                            <label>Description</label>
                                            <textarea name={"description"}
                                                      value={description}
                                                      onChange={(e) => this._changeValue(e, 'description')}
                                            />
                                        </div>

                                        <AddTerms
                                            isEdit={isEdit}
                                            terms={terms}
                                            includedTerms={this.props.singleSet.shared_terms ? this.props.singleSet.shared_terms.map(term => term.id) : []}
                                            onIncludedTermsUpdate={includedTerms => {
                                                this.includedTerms = includedTerms;
                                            }}/>

                                        <button type="button"
                                                onClick={() => this.publishSet()}
                                                className="console-button publish-set-button"
                                                disabled={!name}>
                                            Save
                                        </button>
                                    </div>

                                    <div className="col-xl-4 col-set-form-rp">
                                        <div className="sub-title share-set">
                                            Shared with
                                            <button
                                                type="button"
                                                className="console-button"
                                                onClick={() => this._modalShow()}>
                                                Share Set
                                            </button>
                                        </div>

                                        {sharedUsers && sharedUsers.map((item, index) => (
                                            <div className="team-person" key={index}>
                                                {item.name}

                                                <FontAwesomeIcon icon={faTrashAlt} className={'delete-user'}
                                                                 onClick={() => this._deleteUser(item)}/>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <ShareSetModal
                    show={modalShow}
                    setName={name}
                    onHide={() => this._modalClose()}
                    onSave={(data) => this._shareSet(data)}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    terms: state.managementConsole.items,
    singleSet: state.managementConsole.singleSet
});

const mapDispatchToProps = (dispatch) => ({
    getAdminTerms: (data) => dispatch(getAdminTerms(data)),
    createSet: (data) => dispatch(createSet(data)),
    editSet: (id, data) => dispatch(editSingleSet(id, data)),
    getSingleSet: (id) => dispatch(getSingleSet(id)),
    clearSet: () => dispatch(clearSingleSet()),
    clearAdminTerms: () => dispatch(clearAdminTerms())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddEditSet));