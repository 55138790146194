import React, { Component } from "react";
import Select from 'react-select';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from 'react-reveal/Fade';

class Filter extends Component {

    state = {
        openDropdown: false,
        activeViewButton: false,
        innerWidth: window.innerWidth,
    };

    componentDidMount() {
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        this.setState({ innerWidth:  window.innerWidth })
    }

    _toggleActiveButton = () => {
        const { activeViewButton } = this.state;

        this.setState({
            activeViewButton: !activeViewButton
        });
    };


    render() {
        const { openDropdown, activeViewButton, innerWidth } = this.state;
        const { filterParams, chosenParams, chooseParamData, removeAllParams, removeParam } = this.props;
        const style = {
            control: (base, state) => ({
              ...base,
              border: state.isFocused ? 0 : 0,
              boxShadow: state.isFocused ? 0 : 0,
              "&:hover": {
                border: state.isFocused ? 0 : 0
              }
            }),
        };

        return (
            <React.Fragment>

                {innerWidth > 500
                    ? (
                        <Dropdown isOpen={openDropdown} toggle={this.props.toggleChooseAllFilterParams}>
                            <DropdownToggle className={'dropdown-toggle-button'}>
                                <div className={'filter-icon'} />
                            </DropdownToggle>
                        </Dropdown>
                    ) : (
                        <>
                            {chosenParams.length === 0 && (
                                <Dropdown isOpen={openDropdown} toggle={this.props.toggleChooseAllFilterParams}>
                                    <DropdownToggle className={'dropdown-toggle-button'}>
                                        <div className={'filter-icon'} />
                                    </DropdownToggle>
                                </Dropdown>
                            )}
                        </>
                    )
                }

                <div className="chosen-params">
                    {chosenParams.map((param, index) => (
                        <Fade left={index === 0} right={index !== 0} key={index}><div  className={'chosen-param'}>
                            <span className="param-name">
                                {param.name}
                            </span>

                            <span className="param-values">
                                {param.name === 'Language:'
                                ?
                                    <Select
                                        className="custom-param-data-select"
                                        onChange={(selected, name) => chooseParamData(selected, param.queryName)}
                                        value={param.title}
                                        defaultValue={
                                            param.defaultValue !== undefined
                                                ? param.defaultValue
                                                : {value: param._getData()[0].code, label: param._getData()[0].name}
                                        }
                                        name={param.queryName}
                                        options={
                                            param._getData()
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((item) => ({
                                            value: item.code,
                                            label: `${item.name} (${item.code})`
                                        }))}
                                        styles={style}
                                        classNamePrefix="react-select"
                                    />

                                :

                                <Select
                                        className="custom-param-data-select"
                                        onChange={(selected, name) => chooseParamData(selected, param.queryName)}
                                        value={param.defaultValue !== undefined ? param.defaultValue : param.title}
                                        name={param.queryName}
                                        options={param._getData().map((item) => ({
                                            value: item.id,
                                            label: item.title
                                        }))}
                                        styles={style}
                                        classNamePrefix="react-select"
                                    />

                                }
                            </span>
                            <FontAwesomeIcon
                                className={'unchoose-param'}
                                icon={'times-circle'}
                                onClick={() => removeParam(index, param)}
                            />
                        </div>
                        </Fade>
                    ))}

                    {chosenParams.length !== 0 && this.props.dontShowClear === undefined
                    &&
                    <Fade right>
                        <div className={'hide-fade'}>
                            <Button type={'button'} color={'link'} className={'clear-all-btn'} onClick={() => removeAllParams()}>
                                clear all
                            </Button>
                        </div>
                    </Fade>}
                </div>
                {/*<div className="change-view-wrapper">
                    <img
                    src={`${static_url}assets/menu-6.svg`}
                    onClick={this._toggleActiveButton}
                    className={`btn-lft ${activeViewButton ? 'active-view-wrapper' : ''}`}
                    />

                    <img
                    src={`${static_url}assets/menu-8.svg`}
                    onClick={this._toggleActiveButton}
                    className={`btn-rht ${activeViewButton ? '' : 'active-view-wrapper'}`}
                    />

                </div>*/}
            </React.Fragment>
        );
    }
}

export default Filter;
