import React, {Component} from "react";
import TermTitle from "./TermTitle";
import {connect} from "react-redux";
import {fetchAllTerms, clearAllTerms} from "../../store/terms/actions";
import * as PropTypes from "prop-types";


class AllTerms extends Component {

    static propTypes = {
        terms: PropTypes.array,
        fetchAllTerms: PropTypes.func
    };

    isLetter = (char) => {
        return /[a-zA-Z]/.test(char);
    };

    componentDidMount() {
        setTimeout(() => {
            this.props.fetchAllTerms();
        }, 10);
    }

    componentWillUnmount() {
        this.props.clearAllTerms();
    }

    removeTags = (string) => {
        return string.replace(/<\s*[^>]*>/g, '')
    };

    render() {
        const { terms, user } = this.props;
        return (
            <div className="all-terms-container">
                <div className="all-terms-title-wrapper">
                    <span className="all-terms-title">All {terms.length > 0 && terms.length} terms!</span>
                </div>
                <div className="all-terms container w-sm">
                    <div className="all-terms-list">
                        {terms.map((term, i) => {
                            const currentTitle = this.removeTags(terms[i].content.title);
                            const previousTitle = (i > 0) ? this.removeTags(terms[i - 1].content.title) : '';

                            let letterSubheader = null;
                            if (!this.isLetter(currentTitle.charAt(0))) {
                                if (i === 0) {
                                    letterSubheader = <div className="letter-subheader">{"0-9"}</div>;
                                }
                            } else {
                                if (i === 0 || previousTitle.charAt(0).toUpperCase() !== currentTitle.charAt(0).toUpperCase()) {
                                    letterSubheader = <div className="letter-subheader">{currentTitle.charAt(0).toUpperCase()}</div>
                                }
                            }
                            return (
                                <React.Fragment key={term.id}>
                                    {letterSubheader}
                                    <TermTitle 
                                        className={`all-terms-row all-terms-row-${i % 2 === 0 ? `even` : `odd`}${term.is_assigned !== undefined && term.is_assigned === true ? ` all-terms-row-highlighted`: ``}`}
                                        term={term}
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    terms: state.terms.items,
    user: state.auth.user,
});

const mapDispatchToProps = { fetchAllTerms, clearAllTerms };

export default connect(mapStateToProps, mapDispatchToProps)(AllTerms);