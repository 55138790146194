import * as types from "./types";
import {baseURL} from "../../configs/config";

export const fetchTopTerms = () => ({
    type: types.FETCH_TOP_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/terms?most_searched=`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const fetchAllTerms = () => ({
    type: types.FETCH_ALL_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/terms?all=`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        asPromise: true
    }
});

export const clearAllTerms = () => ({
    type: types.CLEAR_ALL_TERMS
});

export const searchTerms = (data) => ({
    type: types.SEARCH_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/terms`,
            method: 'POST',
            data,
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        takeLatest: true
    }
});

export const searchMoreTerms = (data) => ({
    type: types.FETCH_MORE_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/terms`,
            method: 'POST',
            data,
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        asPromise: true
    }
});

export const clearSearchedTerms = () => ({
    type: types.CLEAR_SEARCHED_TERMS
});

export const fetchTerm = (termId, lang = 'en') => ({
    type: types.FETCH_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/terms/${termId}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                lang
            }
        }
    },
    meta: {asPromise: true},
});


export const fetchEmbeddedTerm = (title, lang = 'en', id) => ({
    type: types.FETCH_EMBEDDED_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/terms/by_title`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                title,
                lang
            }
        }
    },
    meta: {asPromise: true, id},
});

export const clearEmbeddedTerm = () => ({
    type: types.CLEAR_EMBEDDED_TERM,
    meta: {asPromise: true}
});

export const fetchTermBySlug = (slug, lang = 'en', version = 1) => ({
    type: types.FETCH_TERM_BY_SLUG,
    payload: {
        request: {
            url: `${baseURL}v1/terms/${lang}/${slug}/${version}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {asPromise: true},
});

export const fetchVersionBySlug = (slug) => ({
    type: types.FETCH_VERSION_BY_SLUG,
    payload: {
        request: {
            url: `${baseURL}v1/terms/slug/${slug}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {asPromise: true}
});

export const fetchSearchTerm = (termId, lang = 'en') => ({
    type: types.FETCH_SEARCH_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/terms/${termId}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                lang
            }
        }
    }
});

export const searchChangeTermLanguage = (termId, lang = 'en') => ({
    type: types.CHANGE_LANGUAGE_IN_SEARCH,
    payload: {
        request: {
            url: `${baseURL}v1/terms/${termId}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                lang
            }
        }
    }
});

export const fetchListItems = (termId, lang = 'en') => ({
    type: types.FETCH_LIST_ITEMS,
    payload: {
        request: {
            url: `${baseURL}v1/list/${termId}?lang=${lang}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const fetchTerms = () => ({
    type: types.FETCH_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms`,
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const fetchHomeInfo = () => ({
    type: types.FETCH_HOME_INFO,
    payload: {
        request: {
            url: `${baseURL}home_info`,
            method: 'GET'
        }
    }
});

export const getRandomTerm = (termId = null, lang = 'en') => ({
    type: types.GET_RANDOM_TERM,
    payload: {
        request: {
            url: termId === null ? `${baseURL}v1/terms?random=` : `${baseURL}v1/terms/${termId}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                term_id: termId,
                lang
            }
        }
    }
});

export const toggleFavourite = (data) => ({
    type: types.TOGGLE_FAVOURITE,
    payload: {
        request: {
            url: `${baseURL}v1/favourite/${data.id}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        data,
        asPromise: true,
    }
});

export const fetchLanguageList = () => ({
    type: types.FETCH_LANGUAGE_LIST,
    payload: {
        request: {
            url: `${baseURL}v1/languages`,
            method: 'GET'
        }
    }
});

export const fetchLabelList = () => ({
    type: types.FETCH_LABEL_LIST,
    payload: {
        request: {
            url: `${baseURL}v1/labels?all=`,
            method: 'GET'
        }
    }
});

export const setSearchWord = (data) => ({
    type: types.SEARCH_WORD,
    payload: {
        data
    }
});

export const setSearchLabel = (data) => ({
    type: types.SEARCH_LABEL,
    payload: {
        data
    }
});

export const changeSearchTermLang = (data) => ({
    type: types.CHANGE_FOUND_TERM_LANG,
    payload: {
        data
    }
});

export const setSearchCheckbox = (data) => ({
    type: types.SEARCH_CHECKBOX,
    payload: {
        data
    }
});

export const cleanTermsFoundList = () => ({
    type: types.CLEAN_TERMS_FOUND_LIST,
    data: {
        itemsFound: []
    }
});

export const clearSearchForm = (status) => ({
    type: types.CLEAR_SEARCH_FORM,
    payload: {
        data: status !== undefined ? status : false
    }
});

export const createNote = (data) => ({
    type: types.CREATE_NOTE,
    payload: {
        request: {
            url: `${baseURL}v1/notes`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        data
    }
});

export const removeNote = (id) => ({
    type: types.REMOVE_NOTE,
    payload: {
        request: {
            url: `${baseURL}v1/notes/${id}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        id
    }
});

export const editNote = (data) => ({
    type: types.EDIT_NOTE,
    payload: {
        request: {
            url: `${baseURL}v1/notes/${data.id}`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        data
    }
});

export const fetchStarredTerms = (language = 'en') => ({
    type: types.FETCH_STARRED_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/favourite?lang=${language}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const setStarredTermsFilters = (data) => ({
    type: types.STARRED_TERMS_FILTERS,
    payload: {
        data
    }
});

// Todo - chech id of term
export const removeStarredTerms = (data) => ({
    type: types.REMOVE_STARRED_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/favourite`,
            method: 'DELETE',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        data
    }
});

export const createEmbeddedList = (data) => ({
    type: types.CREATE_EMBED_LIST,
    payload: {
        request: {
            url: `${baseURL}v1/embedded`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
})

export const updateTermDialogShowStatus = (name) => ({
    type: types.UPDATE_TERM_DIALOG_SHOW_STATUS,
    payload: name
});