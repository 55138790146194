import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fetchLanguageList, fetchTermBySlug, searchTerms} from "../../store/terms/actions";
import {
    fetchEditorTerm, fetchEditorTerms, sendEditorMessage, updateEditorTerm, draftEditorTerm
} from "../../store/dashboard/actions";
import TermTemplate from "./TermTemplate";
import Discussion from '../../helpers/Discussion';
import ModalConfirmation from "../../helpers/ModalConfirmation";
import {fetchDocumentInfoblocks, fetchEditorInfoblocks} from "../../store/infoblocks/actions";
import DocumentLegend from "../ManagementConsole/Terms/templates/document/rightSidebar/DocumentLegend";

class TermPageForEditors extends Component {
    state = {
        isEditor: true,
        moveToEdit: false,
        isEditing: false,
        isAvailableToPublish: true,
        clearChildrenState: false,
        handleSaved: false,
        fieldsData: {},
        template: {},
        sent: false,
        submitErrorShow: false,
        submitErrorText: "",
        direction: 'ltr',
    };

    _hendleMoveToEdit = () => {
        const {moveToEdit} = this.state;
        this.setState({
            ...this.state,
            moveToEdit: !moveToEdit,
            isEditing: false
        })
    };
    _hendleEditing = () => {
        const {isEditing} = this.state;
        this.setState({
            ...this.state,
            isEditing: !isEditing,
            direction: 'ltr'
        });
        if (!isEditing) {
            this.props.fetchEditorTerm(this.props.term.id, this.props.user.translation_language.code)
                .then(res => {
                    this.setState({
                        isAvailableToPublish: res.payload.data.data.available_publish,
                        direction: res.payload.data.data.my_translation.direction
                    });
                });
        }
    };

    _handleAddCommentReply = (data) => {
        this.props.sendEditorMessage(this.props.term.id, {content: data})
    };

    _handleClearChildrenData = () => {

        const {clearChildrenState} = this.state;
        this.setState({
            ...this.state,
            clearChildrenState: !clearChildrenState
        })
    };

    _handleSaveChildData = (data) => {
        this.setState(state => {
            return {
                fieldsData: {
                    ...state.fieldsData,
                    ...data
                }
            }
        })
    };

    changeTemplateData = (data) => this.setState(state => ({template: {...state.template, ...data}}));

    _hasErrorContentTitleInfoblocks(ifbsList) {
        let res = null;

        let validateIfbs = (ifbsList) => {
            for (let i = 0; i !== ifbsList.length; i++) {
                let ifb = ifbsList[i];
                let processedContent = ifb.content.replace(/&nbsp;/gi, '').replace(/ /g, '');
                if (!ifb.title || !processedContent || processedContent === "<p></p>") {
                    return false
                } else {
                    if (ifb['children']) {
                        let lIsValid = validateIfbs(ifb['children']);
                        if (!lIsValid) {
                            return false
                        }
                    }
                }
            }
            return true
        };

        let isValid = validateIfbs(ifbsList);
        if (!isValid) {
            res = `'Content' or 'Title' was not filled in to one of the infoblocks`;
        }
        return res
    }

    _handlePublishTranslation = () => {
        const {term, updateEditorTerm, translatedInfoblocks, stateInfoblocks} = this.props;
        const {fieldsData, template} = this.state;
        let error_msg = null;

        if (this.state.isAvailableToPublish === false) {
            fieldsData['review'] = true;
        }
        fieldsData['template'] = template;
        fieldsData['infoblocks'] = stateInfoblocks.infoblocks ? stateInfoblocks.infoblocks : undefined;
        fieldsData['extended_description'] = fieldsData['template'] ? fieldsData['template'] : {};
        fieldsData['isAvailableToPublish'] = this.state.isAvailableToPublish;

        let lInfoblocks = stateInfoblocks.infoblocks ? stateInfoblocks.infoblocks : undefined;
        lInfoblocks ? error_msg = this._hasErrorContentTitleInfoblocks(lInfoblocks) : '';

        if (typeof fieldsData.description === 'undefined' || fieldsData.description === '' || fieldsData.description === null) {
            NotificationManager.error('Summary Description is a mandatory field');
            return false;
        }

        if (error_msg) {
            alert(error_msg);
        } else {
            updateEditorTerm(term.id, fieldsData)
                .then(res => {
                    this.setState({
                        sent: true
                    })
                }).catch(err => {
                    let response = err.payload.response;
                    this.setState({
                        submitErrorShow: true,
                        submitErrorText: response.data.message ? response.data.message : "Something went wrong"
                    });
                    if (err.payload.response.data && err.payload.response.data.already_submitted) {
                        this.setState({
                            submitErrorShow: true,
                            submitErrorText: "This term has been already submitted to review by another editor"
                        });
                }
            });
        }
    };

    _handleSaveAsDraft = () => {
        const {term, draftEditorTerm, translatedInfoblocks, stateInfoblocks} = this.props;
        const {fieldsData, template} = this.state;

        fieldsData['template'] = template;
        fieldsData['infoblocks'] = stateInfoblocks.infoblocks ? stateInfoblocks.infoblocks : undefined;

        if (typeof fieldsData.description === 'undefined' || fieldsData.description === '' || fieldsData.description === null) {
            NotificationManager.error('Summary Description is a mandatory field');
            return false;
        }

        draftEditorTerm(term.id, {...fieldsData, ...{draft: true}});
        this.setState({
            ...this.state,
            moveToEdit: false,
            isEditing: false
        })
    };

    _handleRevise = () => {
        this.props.fetchEditorInfoblocks(this.props.term.id);
        this.setState({
            isAvailableToPublish: false,
            isEditing: true
        });
    };

    changeTranslation(language) {
        const {fetchTermBySlug, match: {params: {slug, version}}} = this.props;
        fetchTermBySlug(slug, version, language.code);
    }

    async componentDidMount() {
        const {fetchEditorTerm, match: {params: {slug, version}}} = this.props;
        fetchEditorTerms();
        await this.props.fetchTermBySlug(slug, 'en', version)
        const response = await fetchEditorTerm(
            this.props.term.id,
            this.props.user.translation_language.code
        );
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                ...this.mapTranslationToFields(response.payload.data.data.my_translation)
            }
        });
    }

    mapTranslationToFields = (translation) => {
        return {
            ...translation,
            ...translation.extended_description
        }
    };

    goToDashboard = () => {
        this.props.history.push('/editors-dashboard');
    };

    onSubmitHide = () => {
        this.setState({
            submitErrorShow: false,
            submitErrorText: ""
        })
    };

    changeDocumentTemplate = (infoblocks) => this.setState({template: {infoblocks}});

    render() {
        const {term, termForEditind, user, termToEdit, langs} = this.props;
        const {
            moveToEdit,
            isEditing,
            isEditor,
            handleSaved,
            sent,
            isAvailableToPublish,
            submitErrorShow,
            submitErrorText
        } = this.state;
        let currentTerm = isEditing ? termToEdit : term;

        currentTerm = {
            ...currentTerm,
            direction: this.state.direction
        }

        const updTerm = {
            ...termToEdit,
            direction: this.state.direction
        }
        return (
            <>
                {moveToEdit && <>

                    <div className="wrapper-term-details">
                        <div className="header-term-details">
                            <Link className="link-back" to='/editors-dashboard'><FontAwesomeIcon
                                icon={'chevron-left'}/> Back To Terms List</Link>
                            <span className="title-details">Term Details</span>
                            {term.version && term.version.number && (
                                <div className="term-varsion">Current Term
                                    Version: <span>Version {term.version.number} ↓</span></div>
                            )}
                            {Object.keys(term.version).length == 0 && (
                                <div className="term-varsion">Current Term Version: <span>Version 1 ↓</span></div>
                            )}
                        </div>
                    </div>
                    <div className="translate-term-content-wrapper">
                        {sent && <div className="dimmed" onClick={() => this.goToDashboard()}>
                            <span>{isAvailableToPublish || termToEdit.publish_without_translation === true ? 'Published' : 'Sent for review'}</span>
                        </div>}
                        <div className="edit_term_list-btn-wrapper ">
                            <div className='term-switch-btn-wrapper edit_term_list-edit_languege-wrapper'>
                                <div className="term_edir-btn-wrapper">
                                    <div className='language-block'>
                                        <span className={`term-view ${isEditing ? '' : 'switch-btn-active'}`}
                                              onClick={() => this._hendleEditing()}>
                                            English
                                        </span>
                                        {user.translation_language &&
                                        <span className={`term-edit ${isEditing ? 'switch-btn-active' : ''}`}
                                              title={(langs.length && user.translation_language) ? langs.find(({code}) => code === user.translation_language.code).english : ''}
                                              onClick={() => this._hendleEditing()}>
                                            {user.translation_language.name}
                                        </span>}
                                    </div>

                                    {isEditing && <div className='term_edit-saved_data-btn'>
                                        {user.role === 'Editor' && termToEdit.status === 'Published' &&
                                        <span style={{textAlign: 'center', paddingLeft: '50px'}}>Unpublish this term to create or update its translation</span>}
                                        {user.role !== 'Editor' && termToEdit.status === 'Published' &&
                                        <span style={{textAlign: 'center', paddingLeft: '50px'}}>Translation has already published</span>}

                                        {termToEdit.status !== 'Published' && <>

                                            {user.role === 'Co-Editor' && <>{termToEdit.status !== 'Suggested' && termToEdit.status !== 'Reviewed' ? <>
                                                <span className='cancel-changes-btn'
                                                      onClick={() => this._handleClearChildrenData()}>Cancel Changes</span>
                                                <span className='publish-translation-btn'
                                                      onClick={() => this._handleSaveAsDraft()}>Save as draft</span>
                                                <span className='publish-translation-btn'
                                                      onClick={() => this._handlePublishTranslation()}>Submit for review</span>
                                            </> : <span style={{
                                                textAlign: 'center',
                                                paddingLeft: '50px'
                                            }}>{termToEdit.status === 'Suggested' ? 'Translation is under review' : 'Translation is waiting for publishing'}</span>
                                            }</>}

                                            {user.role === 'Editor' && <> {termToEdit.status !== 'Suggested' ? <>
                                                    {
                                                        !isAvailableToPublish ? <>
                                                            <span className='cancel-changes-btn'
                                                                  onClick={() => this._handleClearChildrenData()}>Cancel Changes</span>
                                                                <span className='publish-translation-btn'
                                                                      onClick={() => this._handleSaveAsDraft()}>Save as draft</span>
                                                            </>
                                                            : <span className='publish-translation-btn'
                                                                    onClick={() => this._handleRevise()}>Revise Translation</span>
                                                    }

                                                    <span className='publish-translation-btn'
                                                          onClick={() => this._handlePublishTranslation()}>
                                                {isAvailableToPublish || termToEdit.publish_without_translation === true ? 'Publish Translation' : 'Submit for review'}
                                            </span>
                                                </> :
                                                <>
                                                <span style={{textAlign: 'center', paddingLeft: '50px'}}>
                                                    Translation is under review
                                                </span>
                                                    <span className='publish-translation-btn'
                                                          onClick={() => this._handlePublishTranslation()}>
                                                    Update translation
                                                </span>
                                                </>
                                            }
                                            </>}

                                        </>}
                                    </div>}

                                </div>
                                <div className='switch-btn-wrapper'>
                                    <span className="term-view" onClick={() => this._hendleMoveToEdit()}>
                                        View
                                    </span>
                                    <span className="term-edit switch-btn-active"
                                          onClick={() => this._hendleMoveToEdit()}>
                                        Edit
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={`term-edit-container max-width
                            ${isEditing ? 'editing-mode' : ''}
                            ${(isEditing && termToEdit.extended_description_type === 2) ? "document-container" : ""}
                            ${(currentTerm.extended_description_type === 2) ? "current-document-container" : ""}`
                        }>
                            {isEditing && <TermTemplate
                                isEditor={isEditor}
                                isEditing={isEditing && !isAvailableToPublish}
                                moveToEdit={moveToEdit}
                                term={updTerm}
                                hideNavbar={true}
                                showTranslation={isEditing && isAvailableToPublish}
                                clearChildrenState={this.state.clearChildrenState}
                                handleClearChildrenData={this._handleClearChildrenData}
                                handleSaved={handleSaved}
                                handleSaveChildData={this._handleSaveChildData}
                                handleSaveTemplateData={this.changeTemplateData}
                                is_newest={true}
                                fetchInfoblocks={this.props.fetchEditorInfoblocks}
                                forTranslation={true}
                            />}
                            {!isEditing && <TermTemplate
                                isEditor={isEditor}
                                isEditing={isEditing}
                                moveToEdit={moveToEdit}
                                infoblocks={this.props.infoblocks}
                                termForEditind={termForEditind}
                                term={currentTerm}
                                is_newest={true}
                                fetchInfoblocks={this.props.fetchDocumentInfoblocks}
                            />}

                            <div className={`right-sidebar`}>
                                {currentTerm.messages &&
                                <Discussion
                                    messages={currentTerm.messages}
                                    sendComment={(data) => this._handleAddCommentReply(data)}
                                    user={user}
                                    termId={currentTerm.id}
                                />
                                }
                                {(isEditing && termToEdit.extended_description_type === 2) && <DocumentLegend/>}

                            </div>
                        </div>
                    </div>
                </>
                }
                {!moveToEdit &&

                <div className='term-switch-btn-wrapper main-term-page_editing'>
                    <div className='switch-btn-wrapper'>
                        {currentTerm.content && <Link
                            to={`/${currentTerm.content.language}/${currentTerm.slug}/${currentTerm.id}/edit-view-page`}
                            className="term-view switch-btn-active">
                            View
                        </Link>}
                        <span className="term-edit" onClick={() => this._hendleMoveToEdit()}>
                                Edit
                            </span>
                    </div>
                </div>

                }
                {!moveToEdit &&
                <TermTemplate
                    isEditor={isEditor}
                    isEditing={isEditing}
                    moveToEdit={moveToEdit}
                    infoblocks={this.props.infoblocks}
                    termForEditind={termForEditind}
                    term={currentTerm}
                    is_newest={true}
                    fetchInfoblocks={this.props.fetchDocumentInfoblocks}
                />
                }
                <ModalConfirmation
                    show={submitErrorShow}
                    title={'Error occurred'}
                    body={
                        <div>
                            <strong>Error:</strong> {submitErrorText}
                        </div>
                    }
                    cancelText=""
                    submitText="Go to dashboard"
                    onSubmit={() => {
                        this.props.history.push('/editors-dashboard')
                    }}
                    onHide={() => this.onSubmitHide()}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    termToEdit: state.dashboard.term,
    term: state.terms.item,
    terms: state.dashboard.terms,
    langs: state.terms.langs,
    isAuthorized: state.auth.isAuthorized,
    user: state.auth.user,

    infoblocks: state.terms.infoblocks,
    stateInfoblocks: state.infoblocks,
    translatedInfoblocks: state.dashboard.documentInfoblocks
});

const mapActionsToProps = (dispatch) => ({
    fetchEditorTerms: () => dispatch(fetchEditorTerms()),
    fetchTermBySlug: (termId, lang, version) => dispatch(fetchTermBySlug(termId, lang, version)),
    fetchEditorTerm: (termId, lang) => dispatch(fetchEditorTerm(termId, lang)),
    fetchLanguageList: () => dispatch(fetchLanguageList()),
    searchTerms: (data) => dispatch(searchTerms(data)),
    sendEditorMessage: (termId, data) => dispatch(sendEditorMessage(termId, data)),
    updateEditorTerm: (termId, data) => dispatch(updateEditorTerm(termId, data)),
    draftEditorTerm: (termId, data) => dispatch(draftEditorTerm(termId, data)),

    // fetchDocumentInfoblocks: (termId, lang='en') => console.log('sdfdsfdsfds'),
    fetchDocumentInfoblocks: (termId, lang = 'en') => dispatch(fetchDocumentInfoblocks(termId, lang)),
    fetchEditorInfoblocks: (termId, for_view = false) => dispatch(fetchEditorInfoblocks(termId, for_view))
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TermPageForEditors));
