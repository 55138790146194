import React, {Component} from 'react';
import {connect} from 'react-redux';
import UserRow from './UserRow';
import {
    blockUser,
    bulkBlockUser,
    bulkPlainTextUser,
    bulkRichTextUser,
    bulkUnblockUser,
    changeRole,
    deleteUser,
    getAdminTerms,
    getAvailableUserRoles,
    getUser,
    getUsers,
    updateUser,
    richTextUser,
    verifyUser,
    makeCoAuthor,
    impersonateUser,
} from '../../../store/console/actions';
import Paginator from '../../../helpers/Paginator';
import Select from 'react-select';
import {managementConsolePaging} from '../../../configs/config';
import CustomFilterTable from '../../../helpers/CustomFilterTable';
import ModalChangeRole from './ModalChangeRole';
import ActionsWrapper from '../../../helpers/ActionsWrapper';
import ModalEditUser from './ModalEditUser';

class Users extends Component {
    state = {
        searchParams: {
            q: '',
            page: 1,
            per_page: managementConsolePaging,
            order_by: '',
            order_asc: 'asc',
            q_type: ''
        },
        chosenRole: {},
        showRoleFilter: false,
        showMultipleActions: false,
        showModalChangeRole: false,
        showModalEditUser: false,
        selected: [],
        changeRoleUser: null,
        userById: null
    };

    _tableColumns = [
        {
            style: {
                width: '3%'
            },
            className: 'console-select',
            checkboxesTitle: true
        },
        {
            style: {
                width: '20%'
            },
            title: 'User Name',
            titleSlug: 'name',
            className: 'console-terms-title',
            input: {
                type: 'text',
                name: 'name'
            }
        },
        {
            style: {
                width: '10%'
            },
            title: 'Role',
            titleSlug: 'role',
            className: 'console-terms-languages',
            input: {
                type: 'text',
                name: 'role'
            }
        },
        {
            style: {
                width: '10%'
            },
            title: 'Interface',
            titleSlug: 'interface',
            className: 'console-terms-interface',
            input: {
                type: 'text',
                name: 'interface'
            }
        },
        {
            style: {
                width: '15%'
            },
            title: 'Language',
            titleSlug: 'language',
            className: 'console-terms-sets',
            input: {
                type: 'text',
                name: 'language'
            }
        },
        {
            style: {
                width: '9.5%'
            },
            title: 'Sets',
            titleSlug: 'sets',
            className: 'console-terms-created',
            input: {
                type: 'text',
                name: 'set'
            }
        },
        {
            style: {
                width: '14.5%'
            },
            title: 'Registered Date',
            titleSlug: 'registered',
            className: 'console-terms-modificated',
            input: {
                type: 'date',
                name: 'registered'
            }
        },
        {
            style: {
                width: '10%'
            },
            title: 'Latest Activity',
            titleSlug: 'activity',
            className: 'console-terms-translated',
            input: {
                type: 'date',
                name: 'activity'
            }
        },
        {
            style: {},
            title: '',
            className: 'console-teres-actions'
        }
    ];

    _paginatorChangedPage(data) {
        const {searchParams} = this.state;

        searchParams.page = data.selected + 1;
        this.setState({searchParams});

        this.props.getAdminUsers(searchParams);
    }

    _searchByName(e) {
        const {searchParams} = this.state;
        searchParams.q = e.target.value;
        searchParams.page = 1;
        searchParams.q_type = 'name';

        this.props.getAdminUsers(searchParams);
    }

    componentDidMount() {
        this.props.getModalAvailableUserRoles();
    }

    async openEditUserModal(id) {
        const {payload: {data: {data}}} = await this.props.getAdminUser(id)
        this.setState({userById: data})
        this.setShowModalEditUser(true)
    }

    blockUserAction(user) {
        const {searchParams} = this.state;
        this.props
            .blockUser(user.id)
            .then(() => this.props.getAdminUsers(searchParams));
    }

    deleteUserAction(user) {
        const {searchParams} = this.state;
        this.props
            .deleteUser(user.id)
            .then(() => this.props.getAdminUsers(searchParams));
    }

    richTextEditorAction(user) {
        const {searchParams} = this.state;
        this.props
            .richTextUser(user.id)
            .then(() => this.props.getAdminUsers(searchParams));
    }

    verifyUserAction(user) {
        const {searchParams} = this.state;
        this.props
            .verifyUser(user.id)
            .then(() => this.props.getAdminUsers(searchParams));
    }

    makeCoAuthorAction(user) {
        const {searchParams} = this.state;
        this.props
            .makeCoAuthor(user.id)
            .then(() => this.props.getAdminUsers(searchParams));
    }

    impersonateUserAction(user) {
        this.props
            .impersonateUser(user.email)
            .then(() => {
                window.location.reload()
            })
    }

    setShowModalChangeRole = showModalChangeRole => {
        this.setState({
            showModalChangeRole
        });
    };

    setShowModalEditUser = showModalEditUser => {
        this.setState({
            showModalEditUser
        });
    };

    _selectRole(selected) {
        const {searchParams} = this.state;

        if (selected.value !== 'all') {
            searchParams.role = selected.value;
            this.setState({
                chosenRole: selected,
                showRoleFilter: false,
                searchParams
            });
        } else {
            searchParams.role = '';
            this.setState({
                chosenRole: {},
                showRoleFilter: false,
                searchParams
            });
        }

        this.props.getAdminUsers(searchParams);
    }

    _showRoleFilter() {
        const {showRoleFilter} = this.state;

        this.setState({
            showRoleFilter: !showRoleFilter
        });
    }

    blockSelectedUsers() {
        const {selected, searchParams} = this.state;

        this.props
            .bulkBlockUser({users: selected})
            .then(() => this.props.getAdminUsers(searchParams));
    }

    unblockSelectedUsers() {
        const {selected, searchParams} = this.state;

        this.props
            .bulkUnblockUser({users: selected})
            .then(() => this.props.getAdminUsers(searchParams));
    }

    richTextSelectedUsers() {
        const {selected, searchParams} = this.state;

        this.props
            .bulkRichTextUser({users: selected})
            .then(() => this.props.getAdminUsers(searchParams));
    }

    plainTextSelectedUsers() {
        const {selected, searchParams} = this.state;

        this.props
            .bulkPlainTextUser({users: selected})
            .then(() => this.props.getAdminUsers(searchParams));
    }

    onArrowClick = (order_by, isAsc) => {
        this.setState(
            ({searchParams}) => ({
                searchParams: {
                    ...searchParams,
                    order_by,
                    order_asc: isAsc ? 'asc' : 'desc'
                }
            }),
            () => this.props.getAdminUsers(this.state.searchParams)
        );
    };

    toggleMultipleActions = () => {
        this.setState(({showMultipleActions}) => ({
            showMultipleActions: !showMultipleActions
        }));
    };

    onColumnFiltersChanges = filters => {
        this.setState(
            ({searchParams}) => ({
                searchParams: {
                    ...searchParams,
                    q_type: filters.type,
                    q: filters.value
                }
            }),
            () => this.props.getAdminUsers(this.state.searchParams)
        );
    };

    onChangeRoleClick = item => {
        this.setState({
            changeRoleUser: item
        });
        this.setShowModalChangeRole(true);
    };

    changeRole = role => {
        this.setShowModalChangeRole(false);
        this.setState({
            changeRoleUser: null
        });
        this.props
            .changeRole(this.state.changeRoleUser.id, role)
            .then(() => this.props.getAdminUsers(this.state.searchParams));
    };

    onChangeSelect = selected => {
        this.setState({
            selected
        });
    };

    onSubmitEditUserForm = async (user) => {
        await this.props.updateAdminUser(user)
        this.setShowModalEditUser(false)
        this.props.getAdminUsers(this.state.searchParams)
    };

    render() {
        const {
            chosenRole,
            showRoleFilter,
            showMultipleActions,
            showModalChangeRole,
            changeRoleUser,
            showModalEditUser
        } = this.state;
        const {users, allRoles, totalUsers} = this.props;

        const _tableFooterColumns = [
            {
                colspan: this._tableColumns.length,
                component: (
                    <Paginator
                        pageCount={
                            totalUsers / this.state.searchParams.per_page
                        }
                        pageChanged={this._paginatorChangedPage.bind(this)}
                        forcePage={this.state.searchParams.page - 1}
                        className={'console-paginator'}
                    />
                )
            }
        ];

        return (
            <div className='console-container-full console-terms-container console-users-container'>
                <div className='console-header'>
                    <div className='console-header-lp'>
                        <input
                            type='text'
                            className='console-search'
                            placeholder='Search by name'
                            onChange={e => this._searchByName(e)}
                        />

                        <div className='console-filter console-sets-select'>
                            <Select
                                className={'terms-filter-set'}
                                onChange={selected => this._selectRole(selected)}
                                value={
                                    chosenRole.value !== undefined
                                        ? chosenRole
                                        : {
                                            value: 'all',
                                            label: 'All Roles'
                                        }
                                }
                                options={[
                                    {
                                        value: 'all',
                                        label: 'All Roles'
                                    },
                                    ...allRoles.map((item, index) => ({
                                        value: item.id,
                                        label: item.name
                                    }))
                                ]}
                            />
                        </div>
                        <ActionsWrapper
                            mode='vertical'
                            className={'console-multiple-actions'}
                            show={showMultipleActions}
                            onClickOutside={() => this.toggleMultipleActions()}
                            onClosedClick={() => this.toggleMultipleActions()}
                        >
                            <div className='delete-item-action'>
                                <div
                                    onClick={() => this.richTextSelectedUsers()}
                                >
                                    Enable rich text
                                </div>
                                <div
                                    onClick={() =>
                                        this.plainTextSelectedUsers()
                                    }
                                >
                                    Disable rich text
                                </div>
                                <div onClick={() => this.blockSelectedUsers()}>
                                    Block
                                </div>
                                <div
                                    onClick={() => this.unblockSelectedUsers()}
                                >
                                    Unblock
                                </div>
                            </div>
                        </ActionsWrapper>
                    </div>
                    <div className='console-header-actions-line'>
                        <div className='console-header-selected'>
							<span className='selected-rows'>
								{this.state.selected.length} Selected
							</span>
                            <span className='selected-rows selected-rows--inline'>
								Total users: {totalUsers}
							</span>
                        </div>
                    </div>
                </div>

                <div className='console-body'>
                    <CustomFilterTable
                        className={'console-table'}
                        columns={this._tableColumns}
                        footer={_tableFooterColumns}
                        items={users}
                        editUserAction={id => this.openEditUserModal(id)}
                        blockAction={item => this.blockUserAction(item)}
                        deleteAction={item => this.deleteUserAction(item)}
                        richTextEditorAction={item =>
                            this.richTextEditorAction(item)
                        }
                        verifyUserAction={item => this.verifyUserAction(item)}
                        makeCoAuthorAction={item => this.makeCoAuthorAction(item)}
                        impersonateUserAction={item => this.impersonateUserAction(item)}
                        onArrowClick={this.onArrowClick}
                        onFiltersChange={this.onColumnFiltersChanges}
                        changeRole={this.onChangeRoleClick}
                        onChangeSelect={this.onChangeSelect}
                    >
                        <UserRow/>
                    </CustomFilterTable>
                </div>
                <ModalChangeRole
                    show={showModalChangeRole}
                    currentRole={changeRoleUser && changeRoleUser.role}
                    onCancelClick={() => this.setShowModalChangeRole(false)}
                    onSendClick={role => this.changeRole(role)}
                />

                <ModalEditUser
                    show={showModalEditUser}
                    user={this.state.userById}
                    onCancelClick={() => this.setShowModalEditUser(false)}
                    onSubmit={(user) => this.onSubmitEditUserForm(user)}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    users: state.managementConsole.users,
    totalUsers: state.managementConsole.totalUsers,
    hasMoreUsers: state.managementConsole.hasMoreUsers,
    allRoles: state.managementConsole.availableUserRoles
});

const mapDispatchToProps = dispatch => ({
    getAdminUsers: data => dispatch(getUsers(data)),
    getAdminUser: user => dispatch(getUser(user)),
    updateAdminUser: user => dispatch(updateUser(user)),
    blockUser: id => dispatch(blockUser(id)),
    deleteUser: id => dispatch(deleteUser(id)),
    richTextUser: id => dispatch(richTextUser(id)),
    verifyUser: id => dispatch(verifyUser(id)),
    makeCoAuthor: id => dispatch(makeCoAuthor(id)),
    impersonateUser: email => dispatch(impersonateUser(email)),
    changeRole: (id, role) => dispatch(changeRole(id, role)),
    getModalAvailableUserRoles: () => dispatch(getAvailableUserRoles()),
    getAdminTerms: data => dispatch(getAdminTerms(data)),
    bulkBlockUser: data => dispatch(bulkBlockUser(data)),
    bulkUnblockUser: data => dispatch(bulkUnblockUser(data)),
    bulkRichTextUser: data => dispatch(bulkRichTextUser(data)),
    bulkPlainTextUser: data => dispatch(bulkPlainTextUser(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);
