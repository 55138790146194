import React, {Component} from 'react';
import {Modal, Container, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import SelectedLabelRow from "../../../../../../helpers/SelectedLabelRow";
import HtmlOption from "../../../../../../helpers/HtmlOption";
import {
    clearAdminTerms,
    createTerm,
    editDraftTerm,
    getAdminSets, getAdminTerms, getAvailableLabels, getSingleTerm,
    minorEditTerm,
    updateSingleTerm
} from "../../../../../../store/console/actions";
import {connect} from "react-redux";

class AssignToUserModal extends Component {
    state = {
        items: []
    };

    onSave = () => {
        const {items, singleTerm} = this.state;
        this.props.onSave(items);
        let assined_users = [];
        items.forEach(el => {
           assined_users.push(el.value)
        });

        this.setState({title: '', content: '', singleTerm : {...singleTerm, assigned_users: assined_users}},
            () => {
            this.props.updateSingleTerm(this.state.singleTerm);
        });

        // this.props.updateSingleTerm()
    };
    clearData = () => {
        // this.setState({items: []});
        this.props.onHide()
    };

    _selectItems = (selected) => {
        const {items} = this.state;
        this.setState({
            items: [...items, {id: selected.value, title: selected.label, value: selected.value}],
        });
    };

    _deleteItem = (currentItem) => {
        const {items} = this.state;
        this.setState({
            items: items.filter(item => item.value !== currentItem.value)
        })
    };

    componentWillUnmount() {
        // this.setState({items: []})
    }

    componentWillMount() {
        const items = [];
        let {users, singleTerm} = this.props;
        let assignedUsers = singleTerm.assigned_users;
        assignedUsers.forEach(id => {
            users.forEach(user => {
                if (user.id === id) {
                    items.push({
                        value: user.id,
                        label: user.fullname,
                        title: user.fullname
                    });
                    users = users.filter(u => u.id !== user.id)
                }
            })
        });
        this.setState({items, users, singleTerm});
    }

    render() {
        const {users, items} = this.state;

        return (
            <Modal
                className="wrapper-base-modal insert-infoblock-modal"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.clearData}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Assign Users to Term
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <label>Select co-Authors</label>
                                <Select
                                    className="similar-terms-select"
                                    onChange={this._selectItems}
                                    isSearchable={true}
                                    value={""}
                                    components={{
                                        Option: HtmlOption
                                    }}
                                    options={users.map(item => {
                                        return {
                                            value: item.id,
                                            label: item.fullname
                                        }
                                    })}
                                />
                                <SelectedLabelRow
                                    selectedRows={items}
                                    dangerouslySetInnerHTML
                                    deleteRow={row => this._deleteItem(row)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer className="delete-extended">
                    <span onClick={this.clearData}>Cancel</span>
                    <button className="console-button" onClick={this.onSave}>
                        Add
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateSingleTerm: (data) => dispatch(updateSingleTerm(data)),
});

export default connect(null, mapDispatchToProps)(AssignToUserModal);