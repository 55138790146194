import React from "react";
import {Modal, Container} from 'react-bootstrap';
import * as PropTypes from "prop-types";

const ModalConfirmation = (props) => (
    <Modal
        className="delete-popup-width"
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>

        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {props.title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container className="padding-none">
                {props.body}
            </Container>
        </Modal.Body>
        <Modal.Footer className="delete-extended">
            <span onClick={props.onHide}>{props.cancelText}</span>
            <button className="console-button" onClick={() => props.onSubmit()}>{props.submitText}</button>
        </Modal.Footer>
    </Modal>
);

ModalConfirmation.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    body: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    cancelText: PropTypes.string.isRequired,
    submitText: PropTypes.string.isRequired
};

export default ModalConfirmation;