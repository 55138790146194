import { Modal } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';

const initUserValues = {
	firstName: '',
	lastName: ''
};

class ModalEditUser extends Component {
	submitForm = async () => {
		await this.form.submitForm();

		const errors = Object.keys(this.form.state.errors).length;
		if (!errors) {
			const { firstName, lastName } = this.form.state.values;
			const { id } = this.props.user;
			this.props.user.first_name = firstName
			this.props.user.last_name = lastName
			this.props.onSubmit({ id, firstName, lastName });
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.form) {
			const { user } = this.props;
			const { values } = this.form.state;
			values.firstName = user.first_name;
			values.lastName = user.last_name;
		}
	}

	render() {
		const { user, show, onCancelClick } = this.props;
		return (
			<Modal
				show={show}
				className='modal-user-change-role'
				size='md'
				centered
				aria-labelledby='contained-modal-title-vcenter'
				onHide={onCancelClick}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<span>Edit user</span>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Formik
						ref={node => (this.form = node)}
						enableReinitialize={true}
						initialValues={initUserValues}
						validationSchema={UserSchema}
						onSubmit={() => {}}
						render={({
							values,
							errors,
							touched,
							setFieldTouched,
							setTouched,
							setFieldValue
						}) => (
							<Form>
								<div className='form-group'>
									<label>User Email</label>
									<p>{user.email}</p>
								</div>
								<div className='form-group'>
									<label>
										First Name
										<span className='input-required'>
											*
										</span>
									</label>
									<Field
										type='text'
										name='firstName'
										className={`form-control ${
											touched.firstName &&
											errors.firstName
												? 'is-invalid'
												: ''
										}`}
									/>
									<ErrorMessage
										name='firstName'
										component='div'
										className='error-text'
									/>
								</div>
								<div className='form-group'>
									<label>
										Last Name
										<span className='input-required'>
											*
										</span>
									</label>
									<Field
										type='text'
										name='lastName'
										className={`form-control ${
											touched.lastName && errors.lastName
												? 'is-invalid'
												: ''
										}`}
									/>
									<ErrorMessage
										name='lastName'
										component='div'
										className='error-text'
									/>
								</div>
							</Form>
						)}
					/>
				</Modal.Body>
				<Modal.Footer>
					<div onClick={onCancelClick} className='cancel-button'>
						Cancel
					</div>
					<div onClick={this.submitForm} className='console-button'>
						Save
					</div>
				</Modal.Footer>
			</Modal>
		);
	}
}

const UserSchema = Yup.object().shape({
	firstName: Yup.string().required().label('First name'),
	lastName: Yup.string().required().label('Last name')
});

export default connect(state => ({
	allRoles: state.managementConsole.availableUserRoles.allRoles
}))(ModalEditUser);
