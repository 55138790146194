import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faPlusCircle} from "@fortawesome/free-solid-svg-icons";


const TermInsertLine = (props) => {
    return(
        <div className="inserline-container">
            <div className="plus-btn-container positionary">
                <span>add block</span>
                <span onClick={() => props.onAdd(props.item)}><FontAwesomeIcon icon={faPlus} className={'plus-icon'}/></span>
            </div>
            <div className="line-container"><hr/></div>
            <div className="circle-plus-btn-container positionary">
                <span>insert block</span>
                <span onClick={() => props.onInsert(props.item)}><FontAwesomeIcon icon={faPlusCircle} className={'plus-circle-icon'}/></span>
            </div>
        </div>
    )
};

export default TermInsertLine
