import React from "react";
import {Link} from "react-router-dom";

function TermAdditionalInfo(props) {
    const {term} = props;

    return (
        <>
            <div className="additional-term-info">
                {term.similar_terms &&
                <div>
                    <span className="additional-term-info-title">{`Similar: `}</span>
                    <span className="additional-term-info-item">{term.similar_terms && term.similar_terms}</span>
                </div>
                }
                {term.linked_terms && term.linked_terms.length !== 0 && (
                    <>
                        <span className="additional-term-info-title">{'Refer to: '}</span>
                        {term.linked_terms.map((item, index) => (
                            <span key={index} className="additional-term-info-item">
                            <Link to={`/en/${item.slug}/${item.version ? item.version : 1}`}
                                  dangerouslySetInnerHTML={{__html: item.title}}>
                            </Link>
                                {index < term.linked_terms.length - 1 && (<span>&nbsp;|&nbsp;</span>)}
                        </span>
                        ))}
                    </>)
                }
                {term.labels && term.labels.length !== 0 &&
                <div className='additional-term-info-title'>
                    <span className="additional-term-info-title">{`Labels: `}</span>
                    {term.labels.map((item, index) => (
                        <span className='additional-term-info-item black' key={item.id}>
                        {item.title}
                        {index <  term.labels.length - 1 && (<span>&nbsp;|&nbsp;</span>)}
                    </span>
                    ))}
                </div>
                }
            </div>
        </>
    )
}

export default TermAdditionalInfo