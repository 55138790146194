import React from "react";
import moment from "moment";
import {clearEmbeddedTerm, fetchEmbeddedTerm} from "../../../../store/terms/actions";
import {connect} from "react-redux";
import ScrollableAnchor from "react-scrollable-anchor";

function InfoblockContent(props) {
    const {item, term, prefix, level, index, user} = props;
    const prefixLevel = prefix ? prefix.split('.').length - 1 : 1;
    const titleFontSize = prefixLevel === 1 ? 18 : prefixLevel === 2 ? 16 : 14;
    return (
        <>
            <div className='infoblock-title-row'>
                <div className='infoblock-title' style={{fontSize: `${titleFontSize}px`}}>
                    {`${prefix} ${item.title}`}
                </div>

                <div className='infoblock-date'>
                    {/*{term.version && term.version.number ? `v${term.version.number} | ` : ''}*/}
                    {item && moment(item.modified).format('LL')}
                </div>
            </div>
            <div>
                {createMarkup(processContent())}
            </div>
        </>
    );

    function processContent() {
        let content = props.item.content;
        if (content === undefined)
            return content;
        content = removeIframes(content);
        content = convertEmbeddedTerms(content);
        content = removeEmptyPtags(content);
        content = changeFont(content);
        return content
    }

    function removeEmptyPtags(content) {
        let res = content
            .replace(/<p><br><\/p>/g, '')
            .replace(/<br style="box-sizing: border-box;"><\/p>/g, '');
        return res
    }

    function removeIframes(content) {
        let nonPrinableImgUrl = `${location.origin}/api/static/assets/content_cannot_be_shown_in_printout.png`;
        let nonPrinableImg = `<img src=${nonPrinableImgUrl} alt="Can not be printable">`;
        let res = content
            .replace(/(?:<span class="fr-[^>]*)(?:(?:\/>)|(?:>.*?<\/span>))/g, nonPrinableImg)
            .replace(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g, nonPrinableImg)
            .replace(/<li/g,'<div>- <span class="replaced-li"').replace(/<\/li>/g,'</span></div>')
            .replace(/<ol/g,'<div class="replaced-ul"').replace(/<\/ol>/g,'</div>')
            .replace(/<ul/g,'<div class="replaced-ul"').replace(/<\/ul>/g,'</div>');
        return res
    }

    function changeFont(content) {
        let res = content.replace(/Raleway/g, 'DejaVu Sans');
        return res
    }

    function convertEmbeddedTerms(content) {
        let res = content.replace(/\[\[(.*?)\]\]/gim, '<span class="replace-topic_embedded">$1</span>')
        return res
    };

    function createMarkup(data) {
        return <span dangerouslySetInnerHTML={{__html: data}}></span>
    }


}

const mapStateToProps = (state) => ({
    embeddedTerm: state.terms.embeddedTerm,
});

const mapActionsToProps = (dispatch) => ({
    fetchEmbeddedTerm: (title, lang, id) => dispatch(fetchEmbeddedTerm(title, lang, id)),
    clearEmbeddedTerm: () => dispatch(clearEmbeddedTerm()),
});

export default connect(mapStateToProps, mapActionsToProps)(InfoblockContent);
