import {froala3Key} from "./config";

const config = {
    key: froala3Key,
    toolbarInline: true,
    charCounterCount: false,
    direction: 'ltr',
    attribution: false,
    indentMargin: 0
}

export const defaultTextareaConfig = {
    ...config,
    editorClass: "details-textarea",
    height: 50,
    toolbarVisibleWithoutSelection: true
}

export const fullTextareaConfig = {
    ...config,
    toolbarButtons: {
        moreText: {
            buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting'
            ],
                align: 'left',
                buttonsVisible: 0
        },
        moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                align: 'left',
                buttonsVisible: 0
        },
        moreRich: {
            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                align: 'left',
                buttonsVisible: 0
        },
        moreMisc: {
            buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                align: 'right',
                buttonsVisible: 2
        }
    }
};