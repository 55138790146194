import React, {Component} from "react";
import EmbeddedTerm from "./EmbeddedTerm";

class EmbeddedTermWrapper extends Component {
    state = {
        embeddedTerm: {},
        clickedEmbeddedTerm: false
    };

    onClickEmbedded(title, i) {
        const {fetchEmbeddedTerm, updateEmbeddedTermClick} = this.props;
        const {embeddedTerm} = this.props;
        title = title.replace(/(<span class='found-word'>)|(<\/span>)/gi, '');
        if (embeddedTerm.title !== undefined && embeddedTerm.title.toLowerCase() === title.toLowerCase()) {
            if (!this.state.clickedEmbeddedTerm) {
                this.setState({clickedEmbeddedTerm: true});
                updateEmbeddedTermClick(true);
            } else {
                this.setState({clickedEmbeddedTerm: false});
                updateEmbeddedTermClick(false);
            }
            return;
        }
        fetchEmbeddedTerm(title, 'en', i)
            .then((value) => {
                this.setState({clickedEmbeddedTerm: true});
                this.props.updateEmbeddedTermClick(true);
                this.forceUpdate();
                this.setState({
                    embeddedTerm: value.payload.data.data
                })
            });
    };

    closeEmbedded = () => {
        this.props.updateEmbeddedTermClick(false);
        this.setState({
            clickedEmbeddedTerm: false
        });
    };

    render() {
        const { id, title } = this.props;
        const { embeddedTerm } = this.state;
         return <span className='inline-topic_embedded' key={`${id}_embedded`}
                  onClick={() => this.onClickEmbedded(title, id)}><span dangerouslySetInnerHTML={{__html: title}}></span>
            {this.state.clickedEmbeddedTerm && embeddedTerm.title &&
            <EmbeddedTerm regExp={new RegExp(/\[\[(.*?)\]\]/, "gim")} term={embeddedTerm} title={embeddedTerm.title}
                          description={embeddedTerm.description} closeEmbeded={this.closeEmbedded} withoutRouter={true}/>
            }
        </span>
    }
}

export default EmbeddedTermWrapper
