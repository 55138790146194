import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import Select from 'react-select';
import SelectedLabelRow from '../../../helpers/SelectedLabelRow';
import SpinnerDownload from "../../../helpers/SpinnerDownload";

const customStyleSelect = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused
            ? '2px solid #4bacc6 !important'
            : '1px solid #707070',
        borderRadius: '5px',
        height: '50px',
        boxShadow: state.isFocused ? 0 : 0
    })
};

const ModalSelectSets = props => {
    const {isModalSelectSets, sets, selectedSets} = props;
    const {onClose, addSelectedSet, removeSelectedSet, dwnSets} = props;
    const [includeTranlsations, setIncludeTranlsations] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const downloadSet = () => {
        setIsLoading(true)
        dwnSets(selectedSets, includeTranlsations)
    }

    return (
        <Modal show={isModalSelectSets} onHide={() => onClose()}>

            {isLoading && <div className='spinner-main-wrapper'>
                <SpinnerDownload/>
            </div>}

            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                    Select specific set or sets
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className='form-group field-Item-0 select-labels similar-terms'>
                    <Select
                        styles={customStyleSelect}
                        onChange={selected => addSelectedSet(selected)}
                        isSearchable
                        value={''}
                        options={sets.map(set => ({
                            value: set.id,
                            label: set.name
                        }))}
                    />
                    <SelectedLabelRow
                        selectedRows={selectedSets}
                        deleteRow={row => removeSelectedSet(row)}
                    />
                </div>

                <label
                    className="check-box"
                    style={{
                        display: 'flex',
                        marginTop: '-0.4rem',
                        marginBottom: '-0.4rem',
                    }}
                >
                    <input
                        type="checkbox"
                        checked={includeTranlsations}
                        onChange={() => setIncludeTranlsations(!includeTranlsations)}
                    /><span
                    style={{left: '0.5rem'}}
                    className="checkmark"
                />
                    <span style={{
                        paddingLeft: '2.6rem',
                        fontSize: '1.0rem',
                        color: '#4bacc6'
                    }}>
                            Include published translations
                        </span>
                </label>

            </Modal.Body>

            <Modal.Footer>
                <div onClick={() => onClose()} className='cancel-button'>
                    Cancel
                </div>
                <button
                    type='button'
                    disabled={selectedSets.length === 0}
                    onClick={downloadSet}
                    className='console-button'
                >
                    Download
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalSelectSets;
