import React, {Component} from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import StyledCheckbox from "../../../helpers/StyledCheckbox";
import ActionsWrapper from "../../../helpers/ActionsWrapper";

class LabelRow extends Component {

    state = {
        showDeleteAction: false
    };

    setDeleteAction(showDeleteAction) {
        this.setState({
            showDeleteAction
        })
    }

    deleteSet(e, item) {
        e.preventDefault();
        e.stopPropagation();

        this.setDeleteAction(false);

        this.props.props.deleteAction(item);
    }

    render() {
        const {showDeleteAction} = this.state;
        const props = this.props;

        return (
            <tr>
                <td className={props.props.columns[0].className + '-td'}>
                    <StyledCheckbox
                        checked={props.item.checked}
                        changeSelect={() => props.props.toggleSelect(props.item.id)}/>
                </td>
                <td className={props.props.columns[1].className + '-td'}>
                    <Link to={'/management-console/labels/' + props.item.id}>{props.item.title}</Link>
                </td>
                <td className={props.props.columns[2].className + '-td'}>{props.item.description}</td>
                <td className={props.props.columns[3].className + '-td'}>{props.item.terms_count}</td>
                <td className={props.props.columns[4].className + '-td'}>
                    {props.item.created && moment(props.item.created).format('LL')}
                </td>
                <td className={props.props.columns[5].className + '-td console-delete-action-td'}>
                    <ActionsWrapper show={showDeleteAction}
                        onClickOutside={() => this.setDeleteAction(false)}
                        onClosedClick={() => this.setDeleteAction(true)}
                    >
                        <span onClick={(e) => this.deleteSet(e, props.item)} className={"delete-item-action"}>
                            Delete
                        </span>
                    </ActionsWrapper>
                </td>
            </tr>
        )
    }
}

export default LabelRow;