import React, {Component} from "react";
import {connect} from "react-redux";
import {createEmbeddedList} from "../../store/terms/actions"
import {NotificationManager} from "react-notifications";
import {Link} from "react-router-dom";

class EmbedTerms extends Component {
    state = {
        checkboxes: [
            {id: 1, label: "Include Personal Notes", name: "include_personal_notes", isChecked: false},
            {id: 2, label: "Include Similar Terms", name: "include_similar_terms", isChecked: false},
            {id: 3, label: "Include Referred Terms", name: "include_referred_terms", isChecked: false},
        ],
        webpageAddress: '',
        iframe: '',
        size: 'responsive',
        width: 100,
        height: 100
    };

    digitsRegexp = /^[0-9]+$/;
    webpageAddressRegexp = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;


    componentDidUpdate(prevProps) {
        const {code} = this.props.embeddedList;

        if (this.props.embeddedList !== prevProps.embeddedList) {
            this.setState({iframe: code})
        }
    }

    _handleChangeCheckbox = (event) => {
        const {checkboxes} = this.state;
        checkboxes.forEach(checkbox => {
            if (checkbox.name === event.target.name) {
                checkbox.isChecked = event.target.checked
            }
        });
        this.setState({checkboxes})
    };

    _handleChangeText = (e) => {
        this.setState({webpageAddress: e.target.value});
    };

    _handleSubmit = () => {
        const {webpageAddress, checkboxes, width, height, size} = this.state;

        if (!this.webpageAddressRegexp.test(this.state.webpageAddress)) {
            NotificationManager.error('Webpage address should be in form http://example.com');
            return;
        }
        if (webpageAddress) {
            const data = {
                include_personal_notes: checkboxes[0].isChecked,
                include_similar_terms: checkboxes[1].isChecked,
                include_referred_terms: checkboxes[2].isChecked,
                webpage_address: webpageAddress,
                terms: this.props.checked,
                lang: this.props.language || 'en'
            };
            if (size === 'fixed') {
                data.width = parseInt(width);
                data.height = parseInt(height);
            }
            this.props.createEmbeddedList(data)
        }
    };

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
    };

    onSizeChange = (e) => {
        this.setState({
            size: e.currentTarget.value
        });
    };

    onFixedSizeChange = (e) => {
        const {value, name} = e.target;
        let newValue = value;
        if (!newValue.length) {
            newValue = 0;
        }
        if (!this.digitsRegexp.test(newValue)) {
            return;
        }
        if (name === 'width') {
            this.setState({
                width: newValue
            })
        } else if (name === 'height') {
            this.setState({
                height: newValue
            })
        }
    };


    render() {
        const {checkboxes, webpageAddress, iframe, size, width, height} = this.state;
        const {embeddedList} = this.props;
        const iframeUrl = iframe.split(`'`)[1];

        return (
            <div className="wrapper-pop-up">
                <div className="box-pop-up">
                    <div className="title-box">
                        <p className="title">Embed Terms List</p>
                        <div onClick={this.props.hidePopUp} className="close-box">
                            <span className="top"></span>
                            <span className="bottom"></span>
                        </div>
                    </div>
                    <div className="wrapper-options">
                        <div className="left-box">


                            <p className="title-select">Select Options:</p>

                            {
                                checkboxes.map(item => {
                                    return (
                                        <label key={item.id} id={item.id} className="check-box">
                                            <input
                                                type="checkbox"
                                                name={item.name}
                                                onChange={this._handleChangeCheckbox}
                                                checked={item.isChecked}
                                            />
                                            <span className="checkmark"></span>
                                            <p>{item.label}</p>
                                        </label>
                                    )
                                })
                            }
                            <p className="description-include">Note: Extended Descriptions cannot be printed or
                                embedded</p>
                        </div>
                        <div className="right-box">
                            <label className="identify-webpage">
                                <span>Identify webpage address:</span>
                                <input
                                    type="text"
                                    value={webpageAddress}
                                    placeholder="Address in format: http://example.com"
                                    onChange={this._handleChangeText}
                                />
                            </label>
                            <div className="token-box">
                                <p className="title">License Token:</p>
                                <div className="token-container">
                                    <span>{embeddedList.license_token}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="size-options">
                        <span className="title-select">Size:</span>
                        <label className="check-box-round">
                            <input type="radio" name="size" value="responsive" onChange={this.onSizeChange} checked={size === 'responsive'}/>
                            <span className="checkmark-round"></span>
                            <p>Responsive</p>
                        </label>
                        <label className="check-box-round">
                            <input type="radio" name="size" value="fixed" onChange={this.onSizeChange} checked={size === 'fixed'}/>
                            <span className="checkmark-round"></span>
                            <input type="text"/>
                            <div>
                                Fixed
                                <input type="text" name="width" maxLength={4} value={width} onChange={this.onFixedSizeChange} disabled={size !== 'fixed'}/>
                                x
                                <input type="text" name="height" maxLength={4} value={height} onChange={this.onFixedSizeChange} disabled={size !== 'fixed'}/>
                                pixels
                            </div>
                        </label>
                    </div>
                    <div className="code-to-embed">
                        <div className="title">Code to Embed:</div>
                        <textarea
                            className="iframe-term"
                            readOnly={true}
                            value={iframe}
                            ref={(textarea) => this.textArea = textarea}
                        />
                        <span
                            className="hint-embed">Embedding terms is subject to specific Terms of Use; <Link to={'/terms-of-use'} target="_blank">click here to review</Link></span>
                    </div>
                    <div className="wrapper-embed-btn">
                        <button onClick={this._handleSubmit} className="generate-btn">Generate Embed Code</button>

                        <div className="actions-btn">
                            <a disabled={iframeUrl} href={iframeUrl} target="_blank"
                               className={`preview-btn ${iframeUrl ? '' : 'disabled-link'}`}>Preview</a>
                            <button onClick={this.copyToClipboard}
                                    className={`copy-btn ${embeddedList.length !== 0 ? 'is-copy' : ''}`}>Copy
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    embeddedList: state.terms.embeddedList,
});

const mapActionsToProps = (dispatch) => ({
    createEmbeddedList: (data) => dispatch(createEmbeddedList(data))
});

export default connect(mapStateToProps, mapActionsToProps)(EmbedTerms);
