import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {fetchVersionBySlug} from "../../store/terms/actions";
import NotFoundPage from "../../helpers/NotFoundPage";

const RedirectTerm = ({match: {params: {slug}}, history, fetchVersionBySlug}) => {

    const [showNotFound, setShowNotFound] = useState(false);

    useEffect(() => {
        fetchVersionBySlug(slug)
            .then(res => {
                const {status, number} = res.payload.data;
                if (status) {
                    history.push(`/en/${slug}/${number}`)
                } else {
                    setShowNotFound(true);
                }
            })
            .catch(err => {
                setShowNotFound(true)
            })
    }, []);

    return <>
        {showNotFound && <NotFoundPage />}
    </>
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {fetchVersionBySlug};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RedirectTerm));