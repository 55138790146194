import React, {useEffect, useState} from "react";
import SpinnerDownload from "./SpinnerDownload";
import moment from "moment";
import {sendContactUsEmail} from "../store/common/actions";

const AddTermsDnD = (props) => {
    // params
    const {
        showExcludedFilter,
        excludedFilter,
        isFetchingTerms,
        isEdit,
        excludedTermIds,
        includedTermIds,
        termsToIncludeIds,
        termsToExcludeIds,
        terms,
        selectAllToExclude,
        selectAllToInclude,
        showIncludedFilter,
        includedFilter
    } = props;

    // methods
    const {
        toggleExcludedFilter,
        onFilterChange,
        onDropToExcluded,
        onDropToIncluded,
        onDragOver,
        onExcludedTermClick,
        onDragStart,
        onDragEnd,
        selectAllToExcludeAction,
        selectAllToIncludeAction,
        onIncludedTermClick,
        toggleIncludedFilter,
        excludeSelectedAction,
        includeSelectedAction
    } = props;

    const [sortedIncludedTerms, setSortedIncludedTerms] = useState([]);
    const [sortedExcludedTerms, setSortedExcludedTerms] = useState([]);
    const [includedTermsSort, setIncludedTermsSort] = useState({});
    const [excludedTermsSort, setExcludedTermsSort] = useState({});

    const ASC = 'ascending';
    const DESC = 'descending';

    useEffect(() => {
        let result = [];
        includedTermIds && includedTermIds.map((id, index) => {
            const term = findTerm(id);
            term && result.push(term)
        });
        sortTermsFilter(result, 'included')

    }, [includedTermIds]);

    useEffect(() => {
        sortTermsFilter([...sortedIncludedTerms], 'included')
    }, [includedTermsSort]);

    useEffect(() => {
        let result = [];
        excludedTermIds && excludedTermIds.map((id, index) => {
            const term = findTerm(id);
            term && result.push(term)
        });
        sortTermsFilter(result, 'excluded');
    }, [excludedTermIds]);

    useEffect(() => {
        sortTermsFilter([...sortedExcludedTerms], 'excluded')
    }, [excludedTermsSort]);

    const _columns = [
        {
            'title': 'Term Title',
            'colWidth': 7,
            'dataName': 'title'
        },
        {
            'title': 'Ver',
            'colWidth': 1,
            'dataName': 'version'
        },
        {
            'title': 'Last updated',
            'colWidth': 4,
            'dataName': 'modified_dt'
        },
    ];

    const findTerm = (id) => {
        let _term = terms.find(term => term.id === id);
        if (_term === -1) _term = null;
        return _term;
    };

    const _updateTermsSort = async (termsListType, key) => {
        let _termsSort, _setTermsSort;
        if (termsListType === 'included') {
            _termsSort = {...includedTermsSort};
            _setTermsSort = setIncludedTermsSort;
        } else {
            _termsSort = {...excludedTermsSort};
            _setTermsSort = setExcludedTermsSort;
        }

        if (_termsSort.sortKey !== key ) {
            _termsSort.sortKey = key;
            _termsSort.orderKey = ASC;
        } else {
            _termsSort.orderKey = _termsSort.orderKey === ASC ? DESC : ASC;
        }
        await _setTermsSort(_termsSort);
    };

    const sortTermsFilter = async (termsList, listType) => {
        let _terms = [...termsList];
        let listSortSetter = listType === "included"
            ? setSortedIncludedTerms
            : setSortedExcludedTerms;

        let sortDict = listType === "included"
            ? includedTermsSort
            : excludedTermsSort;

        let keyName = sortDict.sortKey;
        let sortWay = sortDict.orderKey;

        _terms.sort((first, second) => {
            let firstValue = typeof first[keyName] !== 'string'
                ? first[keyName]
                : first[keyName].toLowerCase();

            let secondValue = typeof second[keyName] !== 'string'
                ? second[keyName]
                : second[keyName].toLowerCase();

            // filter by key
            if (firstValue < secondValue) return sortWay === ASC ? -1 : 1;
            if (firstValue > secondValue) return sortWay === ASC ? 1 : -1;

            // filter by id
            if (firstValue < secondValue) return -1;
            if (firstValue > secondValue) return 1;

            // dont move
            return 0;
        });
        await listSortSetter(_terms);
        return _terms;
    };

    const sortTermsAction = async (event) => {
        let listType = event.target.dataset.list_type;
        let keyName = event.target.dataset.name;
        await _updateTermsSort(listType, keyName);
    };

    return (
        <>
            <div className="col-xl-6 add-terms">
                <div className="sub-title terms-to-list">
                    <div className="title-select-set">
                        Terms to include
                        <div
                            className={'filter-icon'}
                            onClick={() => toggleExcludedFilter()}
                        />
                    </div>

                    {showExcludedFilter && (
                        <input type="text" className="title-select-set-filter"
                               placeholder="Filter" name="excludedFilter"
                               onChange={onFilterChange}
                               value={excludedFilter}
                        />
                    )}

                    <div className="console-multiselect-list"
                         onDrop={onDropToExcluded}
                         onDragOver={onDragOver}
                    >
                        {isFetchingTerms && !isEdit && false && (
                            <div className='spinner-main-wrapper'>
                                <SpinnerDownload/>
                            </div>
                        )}

                        <div className="d-flex dnd-title-wrapper">
                            {_columns.map(_column => {
                                return (
                                    <div
                                        className={`col-sm-${_column.colWidth} dnd-title`}
                                        data-name={_column.dataName}
                                        data-list_type="excluded"
                                        onClick={sortTermsAction}
                                        key={_column.dataName}
                                    >
                                        {_column.title}
                                    </div>
                                )
                            })}
                        </div>

                        {sortedExcludedTerms.length > 0 && sortedExcludedTerms.map((term, index) => {
                            const isSelected = termsToIncludeIds.find(id => term.id === id);
                            const termLastUpdated = term.modified_dt && moment(term.modified_dt).format('LL');
                            return (
                                <div draggable
                                     key={index}
                                     className={`d-flex ${isSelected && 'active'}`}
                                     onDragStart={() => onDragStart(isSelected, term.id, 'excluded')}
                                     onDragEnd={() => onDragEnd()}
                                     onClick={(event) => onExcludedTermClick(event, term.id, index)}
                                >

                                    <div className={'col-sm-7'}>
                                        {term.title}
                                    </div>
                                    <div className={'col-sm-1'}>
                                        {term.version}
                                    </div>
                                    <div className={'col-sm-4'}>
                                        {termLastUpdated || '---'}
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                    <div className="select-set-controls">
                        <label className="check-box">
                            <input
                                type="checkbox"
                                checked={selectAllToInclude}
                                onChange={() => selectAllToIncludeAction()}
                                value={"Select All"}
                            />
                            <span className="checkmark"/>
                            <p className="select-set-all">
                                Select All
                            </p>
                        </label>
                        <span
                            className="select-change"
                            onClick={() => includeSelectedAction()}
                        >
                                {`Add selected >`}
                            </span>
                    </div>
                </div>
            </div>


            <div className="col-xl-6">
                <div className="sub-title terms-to-list">
                    <div className="title-select-set">
                        Included terms
                        <div
                            className={'filter-icon'}
                            onClick={() => toggleIncludedFilter()}
                        />
                    </div>

                    {showIncludedFilter && (
                        <input type="text" className="title-select-set-filter"
                               placeholder="Filter" name="includedFilter"
                               onChange={onFilterChange}
                               value={includedFilter}
                        />
                    )}

                    <div className="console-multiselect-list"
                         onDrop={onDropToIncluded}
                         onDragOver={onDragOver}
                    >
                        <div className="d-flex dnd-title-wrapper">
                            {_columns.map(_column => {
                                return (
                                    <div
                                        className={`col-sm-${_column.colWidth} dnd-title`}
                                        data-name={_column.dataName}
                                        data-list_type="included"
                                        onClick={sortTermsAction}
                                        key={_column.dataName}
                                    >
                                        {_column.title}
                                    </div>
                                )
                            })}
                        </div>

                        {sortedIncludedTerms.length > 0 && sortedIncludedTerms.map((term, index) => {
                            const isSelected = termsToExcludeIds.find(id => term.id === id);
                            const termLastUpdated = term.modified_dt && moment(term.modified_dt).format('LL');
                            return (
                                <div draggable
                                     key={index}
                                     className={`d-flex ${isSelected && 'active'}`}
                                     onDragStart={() => onDragStart(isSelected, term.id, 'included')}
                                     onDragEnd={() => onDragEnd()}
                                     onClick={(event) => (onIncludedTermClick(event, term.id, index))}
                                >
                                    <div className={'col-sm-7'}>
                                        {term.title}
                                    </div>
                                    <div className={'col-sm-1'}>
                                        {term.version}
                                    </div>
                                    <div className={'col-sm-4'}>
                                        {termLastUpdated || '---'}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="select-set-controls">
                        <label className="check-box">
                            <input
                                type="checkbox"
                                checked={selectAllToExclude}
                                onChange={() => selectAllToExcludeAction()}
                                value={"Select All"}
                            />
                            <span className="checkmark"/>
                            <p className="select-set-all">
                                Select All
                            </p>
                        </label>

                        <span
                            className="select-change"
                            onClick={() => excludeSelectedAction()}
                        >
                                {`< Remove selected`}
                            </span>
                    </div>
                </div>
            </div>
        </>
    )
};


export default AddTermsDnD;