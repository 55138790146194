import React, {Component, useState} from "react";
import {connect} from "react-redux";
import {Button} from "reactstrap";
import {
    bulkTranslationUpload,
    downloadTermsForTranslate,
    fetchEditorTerms,
    fetchEditorSets,
    dwnSelectedTerms,
    dwnSets,
    fetchEditorTermStatuses,
    publishTermEditorTranslation,
    sendSelectedDraftToReviewTranslations
} from "../../store/dashboard/actions";
import Paginator from "../../helpers/Paginator";
import GeneralListRow from "./GeneralListRow";
import FileDownload from 'js-file-download';
import NotificationsTable from "./NotificationsTable";
import {clearNotificationsCount} from "../../store/auth/actions";
import CustomFilterTable from "../../helpers/CustomFilterTable";
import SpinnerDownload from "../../helpers/SpinnerDownload";
import ModalSelectSets from "./templates/ModalSelectSets"
import ModalTermsUploadResult from "../ManagementConsole/Terms/templates/ModalTermsUploadResult";
import Select from "react-select";

class EditorsDashboard extends Component {
    state = {
        page: 1,
        searchParams: {
            lang: 'en',
            q: '',
            q_type: '',
            titles_only: false,
            exclude: false,
            order_by: '',
            order_asc: 'asc',
            page: 1,
            per_page: 20,
            status: 'all',
        },
        isOpen: false,
        sets: [],
        selectedTerms: [],
        selectedSets: [],
        isFetchingEditorSets: true,
        isFetchingEditorTerms: true,
        isModalSelectSets: false,
        showModalTermsUploadResult: false,
        uploaded: [],
        rejected: [],
        showStatusFilter: false,
        chosenStatus: {},
        dimmedModal: {
            show: false,
            'text': ''
        },
        isSendingMultipleRequest: false,
    };

    hiddenInputRef = React.createRef();

    _generalListTableColumns = [
        {
            style: {
                width: '3%'
            },
            checkboxesTitle: true,
            className: 'console-select',
            isSortable: false,
        },
        {
            style: {
                width: '28%'
            },
            title: 'Term Title',
            titleSlug: 'content.title',
            className: 'general-table-title',
            isSortable: true,
            input: {
                type: 'text',
                name: 'title'
            },
        },
        {
            style: {
                width: '24%'
            },
            title: 'Translated title',
            titleSlug: 'translated_title',
            className: 'general-table-translated',
            isSortable: true,
            input: {
                type: 'text',
                name: 'translated_title'
            },
        },
        {
            style: {
                width: '16%'
            },
            title: 'Term Set',
            titleSlug: 'termSet',
            className: 'general-table-set',
            isSortable: true,
            input: {
                type: 'text',
                name: 'sets'
            },
        },
        {
            style: {
                width: '6%'
            },
            title: 'Version',
            titleSlug: 'version.number',
            className: 'general-table-revision',
            isSortable: true,
            input: {
                type: 'text',
                name: 'version'
            },
        },
        {
            style: {
                width: '11%'
            },
            title: 'Translation Status',
            titleSlug: 'status',
            className: 'general-table-status',
            isSortable: false,
        },
        // {
        //     style: {
        //         width: '10%'
        //     },
        //     title: 'Assigned',
        //     titleSlug: 'assigned',
        //     className: 'general-table-assigned'
        // },
        {
            style: {
                width: '8%'
            },
            title: 'Action',
            className: 'general-table-action',
            isSortable: false,
        },
    ];

    _paginatorChangedPage(data) {
        this.setState({isFetchingEditorTerms: true})
        const {searchParams} = this.state;
        searchParams.page = data.selected + 1;
        this.setState({searchParams});

        this._fetchEditorTerms();
    }

    _updateDimmedModal(show, text) {
        let newDimmed = {
            show: show,
            text: text
        };
        this.setState({
            dimmedModal: newDimmed
        });
    }

    updateDimmedModal = (show, text) => {
        let newDimmed = {
            show: show,
            text: text
        };
        this.setState({
            dimmedModal: newDimmed
        });
    }

    _fetchEditorTerms = async () => {
        const {searchParams} = this.state;
        await this.props.fetchEditorTerms(searchParams)
            .finally(() => {
                this.setState({
                    isFetchingEditorTerms: false,
                    selectedTerms: []
                })
            });
    };

    setIsSendingMultipleRequest(status) {
        this.setState({
            ...this.state,
            isSendingMultipleRequest: status ? status : !this.state.isSendingMultipleRequest
        })
    }

    searchTerms = () => {
        const {searchParams} = this.state;
        this.setState({isFetchingEditorTerms: true}, () => {
            this._fetchEditorTerms();
        });
    };

    _searchByName(e) {
        const {searchParams} = this.state;

        if (e.target.value === searchParams.q) {
            return false;
        }

        searchParams.q = e.target.value;
        searchParams.q_type = '';
        searchParams.page = 1;
        this.setState({searchParams}, () => {
            this.searchTerms();
        });
    }

    onColumnFiltersChanges = (filters) => {
        this.setState(({searchParams}) => ({
            searchParams: {
                ...searchParams,
                q_type: filters.type,
                q: filters.value
            }
        }), () => this.searchTerms())
    };

    _downloadTermsXls() {
        this.setState({isFetchingEditorTerms: true})
        this.props.downloadTermsForTranslate()
            .then((response) => {
                FileDownload(response.payload.data, 'Assigned terms.xlsx');
            }).finally(() => {
            this.setState({isFetchingEditorTerms: false})
        })
    }

    _bulkTranslationUplaodClick() {
        this.hiddenInputRef.current.click()
    }

    _uploadEditedTermsXls(e) {
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            const formData = new FormData();
            formData.append('file', file);

            this.props.bulkTranslationUpload(formData)
                .then((res) => {
                    const {uploaded, rejected} = res.payload.data;
                    this.setState({
                        showModalTermsUploadResult: true,
                        uploaded,
                        rejected
                    });

                    this.searchTerms();
                });
            // .catch((values) => {
            //     this.setState({
            //         errors: values.payload.response.data.errors
            //     })
            // });
            this.hiddenInputRef.current.value = "";
        };
        reader.readAsDataURL(file)
    }

    _showStatusFilter() {
        const {showStatusFilter} = this.state;

        this.setState({
            showStatusFilter: !showStatusFilter
        });
    }

    _selectStatus(selected) {
        const {searchParams} = this.state;

        if (selected.value !== 'all') {
            searchParams.status = selected.value;
            this.setState({
                chosenStatus: selected,
                showStatusFilter: false,
                searchParams
            });

        } else {
            searchParams.status = '';
            this.setState({
                chosenStatus: {},
                showStatusFilter: false,
                searchParams
            });
        }

        this.searchTerms();
    }

    dwnSelectedTerms() {
        const {selectedTerms} = this.state;
        if (selectedTerms.length === 0) return false
        this.setState({isFetchingEditorTerms: true})
        this.props.dwnSelectedTerms(selectedTerms)
            .then((response) => {
                FileDownload(response.payload.data, 'Selected terms.xlsx');
            }).finally(() => {
            this.setState({isFetchingEditorTerms: false})
        })
    }

    dwnSets(sets, includeTranlsations) {
        if (sets.length === 0) return false
        const setsId = sets.map(item => item.value)
        this.props.dwnSets(setsId, includeTranlsations)
            .then((response) => {
                FileDownload(response.payload.data, 'Selected sets.xlsx');
                this.toggleModalSelectedSets(false)
                this.setState({selectedSets: []})
            })
    }

    toggleModalSelectedSets = (show) => {
        this.setState({
            isModalSelectSets: show,
            isFetchingEditorSets: true,
            isFetchingEditorTerms: true,
        })
        this.props.fetchEditorSets()
            .then(({payload}) => this.setState({
                sets: payload.data.data
            }))
            .finally(() => {
                this.setState({
                    isFetchingEditorSets: false,
                    isFetchingEditorTerms: false,
                })
            })
    }

    addSelectedSet = (set) => {
        const {selectedSets, sets} = this.state
        this.setState({
            selectedSets: [...selectedSets, set],
            sets: sets.filter((label) => label.id !== set.value)
        })
    }

    removeSelectedSet = (set) => {
        const {selectedSets, sets} = this.state
        this.setState({
            selectedSets: selectedSets.filter(item => item.value !== set.value),
            sets: [...sets, {id: set.value, name: set.label}]
        })
    }

    componentDidMount() {
        this.props.fetchStatusList()
            .then(res => {
                this.setState({statuses: res.payload.data})
            });
        this.searchTerms();
        this.props.clearNotificationsCount();
    }

    onArrowClick = (order_by, isAsc) => {
        this.setState(({searchParams}) => ({
            searchParams: {
                ...searchParams,
                order_by,
                order_asc: isAsc ? 'asc' : 'desc'
            }
        }), () => {
            this.setState({isFetchingEditorTerms: true});
            this._fetchEditorTerms();
        })
    }

    resolve(path, obj = self, separator = '.') {
        const properties = Array.isArray(path) ? path : path.split(separator)
        const value = properties.reduce((prev, curr) => prev && prev[curr], obj)
        return typeof value === 'string' ? value.replace(/<\/?[^>]+(>|$)/g, "") : value;
    }

    publishSelectedTranslations = async (event) => {
        this.setIsSendingMultipleRequest(true);

        let req = await this.props.publishTermEditorTranslation(this.state.selectedTerms);
        this._updateDimmedModal(true, req.payload.data.message ? req.payload.data.message : 'Something went wrong');
        await this._fetchEditorTerms();

        this.setIsSendingMultipleRequest(false);
    };

    sendToReviewSelectedDrafts = async (event) => {
        this.setIsSendingMultipleRequest(true);

        let req = await this.props.sendSelectedDraftToReviewTranslations(this.state.selectedTerms);
        this._updateDimmedModal(true, req.payload.data.message ? req.payload.data.message : 'Something went wrong');
        await this._fetchEditorTerms();

        this.setIsSendingMultipleRequest(false);
    };

    render() {
        let {sets, selectedTerms} = this.state;
        const {terms, user, totalTerms} = this.props;
        const {
            searchParams, isFetchingEditorTerms, isModalSelectSets, selectedSets,
            chosenStatus, showStatusFilter, statuses, isSendingMultipleRequest
        } = this.state;

        // if (searchParams.order_by !== '') {
        //     terms.sort((a, b) => {
        //         if (searchParams.order_asc === 'asc') {
        //             return (
        //                 this.resolve(searchParams.order_by, a) > this.resolve(searchParams.order_by, b)
        //             ) ? 1 : ((
        //                 this.resolve(searchParams.order_by, b) > this.resolve(searchParams.order_by, a)
        //             ) ? -1 : 0)
        //         } else {
        //             return (
        //                 this.resolve(searchParams.order_by, a) > this.resolve(searchParams.order_by, b)
        //             ) ? -1 : ((
        //                 this.resolve(searchParams.order_by, b) > this.resolve(searchParams.order_by, a)
        //             ) ? 1 : 0)
        //         }
        //     })
        // }

        selectedSets.length && selectedSets.map(item => {
            sets = sets.filter(set => set.id !== item.value)
        });

        const _tableFooterColumns = [{
            colspan: 7,
            component: <Paginator
                className={"console-paginator"}
                pageCount={this.props.totalTerms / this.state.searchParams.per_page}
                pageChanged={(data) => this._paginatorChangedPage(data)}
                forcePage={this.state.searchParams.page - 1}
            />
        }];

        const userIsEditor = user.role === "Editor";
        const userIsCoEditor = user.role === "Co-Editor";

        let statusFilterOptions = [{
            value: 'all',
            label: 'All Statuses'
        }];

        statuses && statuses.map(item => statusFilterOptions.push({
            value: item.value,
            label: item.name
        }));

        return (
            <>
                <div className="editors-dashboard-page">
                    <div className="editors-dashboard-title">
                        <h1>Language {user.role} Dashboard</h1>
                    </div>
                    <div className="editors-dashboard-body">
                        <NotificationsTable/>

                        {this.state.dimmedModal.show &&
                        <div
                            className="center-status-dimmed"
                            onClick={() => this._updateDimmedModal(false, '')}
                        >
                            <span>{this.state.dimmedModal.text}</span>
                        </div>}

                        <div className={`general-list-container ${isFetchingEditorTerms && 'is-loading'}`}>
                            <div className="general-list-header">

                                <div className="general-list-title-panel">
                                    <div className="general-list-search_block">
                                        <span className="general-list-brand">
                                            General List
                                        </span>
                                        {/*<div className="search-by-titles-form">*/}
                                        {/*    <input type="text" className="form-control search-titles-input"*/}
                                        {/*           placeholder="Search by term name"*/}
                                        {/*           onChange={(e) => this._searchByName(e)}/>*/}
                                        {/*</div>*/}

                                        <div className="editor-language-filter-singular">
                                            <Select
                                                className={"custom-param-data-select terms-filter-set"}
                                                classNamePrefix="react-select"
                                                onChange={(selected) => this._selectStatus(selected)}
                                                value={chosenStatus.value !== undefined ? chosenStatus : {
                                                    value: 'all',
                                                    label: 'All Statuses'
                                                }}
                                                options={statusFilterOptions}
                                            />
                                        </div>
                                    </div>

                                    <div className="general-list-download_block">
                                        {['Draft', 'Reviewed'].indexOf(this.state.searchParams.status) < 0 ?
                                            <Button type={'button'} className={'upload-terms-btn'} color={'link'}
                                                    onClick={() => this._bulkTranslationUplaodClick()}>
                                                <div className="download-image-block">
                                                    <img src={`${static_url}assets/bracket.7373cac5.png`}
                                                         className="image-bracket"/>
                                                    <img src={`${static_url}assets/arrow.de566b9f.png`}
                                                         className="image-arrow up"/>
                                                </div>
                                                <span>Upload Multiple Translations</span>
                                                <input type={"file"}
                                                       className={"hidden-input-file"}
                                                       ref={this.hiddenInputRef}
                                                       onChange={(e) => this._uploadEditedTermsXls(e)}/>
                                            </Button>
                                            : this.state.searchParams.status === 'Reviewed' ?
                                                <>
                                                    {selectedTerms.length > 0 && userIsEditor && (
                                                        <div className='term-multiple_action_button-area'>
                                                            <Button
                                                                type='button'
                                                                className="flex-button"
                                                                color='link'
                                                                onClick={this.publishSelectedTranslations}
                                                            >
                                                                Publish ({selectedTerms.length})
                                                            </Button>
                                                        </div>
                                                    )}
                                                </> : <>
                                                    {selectedTerms.length > 0 && (
                                                        <div className='term-multiple_action_button-area'>
                                                            <Button
                                                                type='button'
                                                                className="flex-button"
                                                                color='link'
                                                                onClick={this.sendToReviewSelectedDrafts}
                                                            >
                                                                Send for Review ({selectedTerms.length})
                                                            </Button>
                                                        </div>
                                                    )}
                                                </>
                                        }
                                    </div>
                                </div>

                                <div className="general-list-title-panel">
                                    <div className="general-list-download_block">
                                        <Button type={'button'} className={'download-terms-btn'} color={'link'}
                                                onClick={() => this._downloadTermsXls()}>
                                            <div className="download-image-block">
                                                <img src={`${static_url}assets/bracket.7373cac5.png`}
                                                     className="image-bracket"/>
                                                <img src={`${static_url}assets/arrow.de566b9f.png`}
                                                     className="image-arrow"/>
                                            </div>
                                            <span>Download All Assigned Terms</span>
                                        </Button>

                                        <Button type={'button'} className={'download-terms-btn'} color={'link'}
                                                onClick={() => this.toggleModalSelectedSets(true)}>
                                            <div className="download-image-block">
                                                <img src={`${static_url}assets/bracket.7373cac5.png`}
                                                     className="image-bracket"/>
                                                <img src={`${static_url}assets/arrow.de566b9f.png`}
                                                     className="image-arrow"/>
                                            </div>
                                            <span>Download a specific set or sets</span>
                                        </Button>

                                        <Button type={'button'} className={'download-terms-btn'} color={'link'}
                                                onClick={() => this.dwnSelectedTerms()}>
                                            <div className="download-image-block">
                                                <img src={`${static_url}assets/bracket.7373cac5.png`}
                                                     className="image-bracket"/>
                                                <img src={`${static_url}assets/arrow.de566b9f.png`}
                                                     className="image-arrow"/>
                                            </div>
                                            <span>Download selected items</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="general-list-table_block">
                                <CustomFilterTable
                                    drawFilters={true}
                                    className={"dashboard-genetalList-table"}
                                    columns={this._generalListTableColumns}
                                    items={terms}
                                    footer={_tableFooterColumns}
                                    onArrowClick={this.onArrowClick}
                                    onFiltersChange={this.onColumnFiltersChanges}
                                    selectedItemIds={selectedTerms}
                                    onChangeSelect={(selected) => this.setState({selectedTerms: selected})}
                                >
                                    <GeneralListRow
                                        searchTerms={this.searchTerms}
                                        updateDimmedModal={this.updateDimmedModal}
                                    />
                                </CustomFilterTable>
                            </div>

                            {(isFetchingEditorTerms || isSendingMultipleRequest) && (
                                <div className='spinner-main-wrapper'>
                                    <SpinnerDownload/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {isModalSelectSets && (
                    <ModalSelectSets
                        sets={sets}
                        selectedSets={selectedSets}
                        isModalSelectSets={isModalSelectSets}
                        onClose={() => this.toggleModalSelectedSets(false)}
                        addSelectedSet={(row) => this.addSelectedSet(row)}
                        removeSelectedSet={(row) => this.removeSelectedSet(row)}
                        dwnSets={(sets, includeTranlsations) => this.dwnSets(sets, includeTranlsations)}
                    />
                )}

                <ModalTermsUploadResult
                    show={this.state.showModalTermsUploadResult}
                    onExit={() => {
                        this.setState({
                            showModalTermsUploadResult: false
                        })
                    }}
                    uploaded={this.state.uploaded}
                    rejected={this.state.rejected}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    terms: state.dashboard.terms,
    totalTerms: state.dashboard.totalTerms,
    hasMoreTerms: state.dashboard.hasMoreTerms,
    user: state.auth.user
});

const mapActionsToProps = (dispatch) => ({
    fetchEditorTerms: (data) => dispatch(fetchEditorTerms(data, true)),
    fetchEditorSets: () => dispatch(fetchEditorSets(true)),
    fetchStatusList: () => dispatch(fetchEditorTermStatuses()),
    downloadTermsForTranslate: () => dispatch(downloadTermsForTranslate()),
    bulkTranslationUpload: (data) => dispatch(bulkTranslationUpload(data)),
    clearNotificationsCount: () => dispatch(clearNotificationsCount()),
    dwnSelectedTerms: (termsId) => dispatch(dwnSelectedTerms(termsId)),
    dwnSets: (setsId, includeTranlsations) => dispatch(dwnSets(setsId, includeTranlsations)),
    publishTermEditorTranslation: (ids) => dispatch(publishTermEditorTranslation(ids)),
    sendSelectedDraftToReviewTranslations: (ids) => dispatch(sendSelectedDraftToReviewTranslations(ids)),
});

export default connect(mapStateToProps, mapActionsToProps)(EditorsDashboard);
