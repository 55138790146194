import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FaPencil} from "react-icons/lib/fa";
import {clearEmbeddedTerm, fetchEmbeddedTerm} from "../../../../store/terms/actions";
import EmbeddedTerm from "../../../../helpers/EmbeddedTerm";
import reactStringReplace from 'react-string-replace';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import RelatedInfo from "../../../Term/partials/RelatedInfo";
import TermBar from "../../../Term/partials/TermBar";
import {froala3Key} from "../../../../configs/config";

class TermHeaderTemplate extends Component {

    state = {
        newData: {},
        clickedEmbeddedTerm: false
    };

    createMarkup = (data) => {
        return reactStringReplace(data, /(.*)/, (match, i) => (
            <span className='inline-topic_inner' key={`${i + Math.random()}_inner`}
                  dangerouslySetInnerHTML={{__html: match}}></span>));
    };

    onClickEmbedded(title, i) {
        const {term, fetchEmbeddedTerm, updateEmbeddedTermClick} = this.props;
        const {embeddedTerm} = this.props;
        title = title.replace(/(<span class='found-word'>)|(<\/span>)/gi, '');
        // if (embeddedTerm.title !== undefined && embeddedTerm.title.toLowerCase() === title.toLowerCase()) {
        //     if (!this.state.clickedEmbeddedTerm) {
        //         this.setState({
        //             clickedEmbeddedTerm: true,
        //             embeddedTermMousePos: {x:ev.clientX, y:ev.clientY},
        //         });
        //         updateEmbeddedTermClick(true);
        //     }
        //     return;
        // }
        fetchEmbeddedTerm(title, term.content.language, i)
            .then((value) => {
                this.setState({clickedEmbeddedTerm: true});
                this.props.updateEmbeddedTermClick(true);
            });
    };

    closeEmbeded = () => {
        this.props.updateEmbeddedTermClick(false);
        this.setState({
            clickedEmbeddedTerm: false
        });
    };

    convertEmbeddedTerms() {
        const {embeddedTerm, description} = this.props;
        if (description === undefined)
            return description;

        return reactStringReplace(description, new RegExp(/\[\[(.*?)\]\]/, "gim"), (match, i) => (
            <span className='inline-topic_embedded' key={`${i}_embedded`}
                  onClick={() => this.onClickEmbedded(match, i)}><span dangerouslySetInnerHTML={{__html: match}}></span>
                {i === embeddedTerm.id && this.state.clickedEmbeddedTerm && embeddedTerm.title &&
                <EmbeddedTerm regExp={new RegExp(/\[\[(.*?)\]\]/, "gim")} term={embeddedTerm} title={embeddedTerm.title}
                              description={embeddedTerm.description} closeEmbeded={this.closeEmbeded}/>
                }
            </span>
        ))
    };

    render() {
        const {term, isEdit, title, isEditTitle, isEditDescription, isEditHeader} = this.props;

        return (
            <div className="wrapper-term-header">
                <div className="current-term-header">
                    <div className="current-term-title">
                        {!isEditTitle
                            ? <div className={isEdit ? `console-edit-term-title` : (term.direction !== undefined ? term.direction : '')}>
                                <p dangerouslySetInnerHTML={{__html: title}}/>
                                {term.content && term.content.acronym && <span>&nbsp;({term.content.acronym})</span>}
                                {isEditHeader && isEdit && <FaPencil onClick={() => this.props.handleShowEditTitle()}/>}
                            </div>
                            : <div>
                                <FroalaEditor
                                    tag='div'
                                    config={{
                                        htmlUntouched: true,
                                        fullPage: true,
                                        htmlRemoveTags: [],
                                        key: froala3Key,
                                        imageUploadParam: 'image_param',
                                        imageUploadURL: '/api/froala_upload_image',
                                        imageUploadMethod: 'POST',
                                        toolbarInline: true,
                                        charCounterCount: false,
                                        direction: 'ltr',
                                        toolbarVisibleWithoutSelection: true,
                                        attribution: false,
                                        indentMargin: 0,
                                        toolbarButtons: {
                                            'moreText': {
                                                'buttons': ['bold', 'italic']
                                            },
                                            'moreMisc': {
                                                'buttons': ['undo', 'redo'],
                                                'align': 'right',
                                                'buttonsVisible': 2
                                            }
                                        }

                                    }}
                                    model={this.props.editingTitle ? this.props.editingTitle : ''}
                                    onModelChange={(...data) => {
                                        this.props.handleTitleChanged(data[0]);
                                    }}
                                />
                                <div className="console_edit-header-editing">
                                    <button className='save-edit-block'
                                            onClick={() => this.props.handleTitleSave()}>save
                                    </button>
                                    <span onClick={() => this.props.handleTitleCancel()}>Cancel</span>
                                </div>

                            </div>
                        }
                    </div>

                    {!this.props.isTermBar &&
                        <TermBar term={term} onChangeTranslation={this.props.onChangeTranslation} />
                    }
                </div>
                <div className="current-term-description edit_term_list-description">
                    {!isEditDescription
                        ? <div className={isEdit ? "console-edit-term-description" : (term.direction !== undefined ? term.direction : '')}>
                            {this.createMarkup(this.convertEmbeddedTerms())}
                            {isEditHeader && isEdit && <FaPencil onClick={() => this.props.handleShowEditDescription()}/>}
                        </div>
                        : <div>
                            <FroalaEditor
                                tag='div'
                                config={{
                                    htmlUntouched: true,
                                    fullPage: true,
                                    htmlRemoveTags: [],
                                    key: froala3Key,
                                    toolbarInline: true,
                                    imageUploadParam: 'image_param',
                                    imageUploadURL: '/api/froala_upload_image',
                                    imageUploadMethod: 'POST',
                                    charCounterCount: false,
                                    direction: 'ltr',
                                    toolbarVisibleWithoutSelection: true,
                                    attribution: false,
                                    indentMargin: 0,
                                    toolbarButtons: {
                                        'moreText': {
                                            'buttons': ['bold', 'italic']
                                        },
                                        'moreMisc': {
                                            'buttons': ['undo', 'redo'],
                                            'align': 'right',
                                            'buttonsVisible': 2
                                        }
                                    }
                                }}
                                model={this.props.editingDescription ? this.props.editingDescription : ''}
                                onModelChange={(...data) => {
                                    this.props.handleDescriptionChanged(data[0]);
                                }}
                            />
                            <div className="console_edit-header-editing">
                                <button className='save-edit-block'
                                        onClick={() => this.props.handleDescriptionSave()}>save
                                </button>
                                <span onClick={() => this.props.handleDescriptionCancel()}>Cancel</span>
                            </div>
                        </div>
                    }
                </div>
                <RelatedInfo term={term} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    embeddedTerm: state.terms.embeddedTerm
});

const mapActionsToProps = (dispatch) => ({
    fetchEmbeddedTerm: (title, lang, id) => dispatch(fetchEmbeddedTerm(title, lang, id)),
    clearEmbeddedTerm: () => dispatch(clearEmbeddedTerm()),
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TermHeaderTemplate));
