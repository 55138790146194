import React from "react";
import moment from "moment";
import InfoblocksRows from "./InfoblocksRows";
import InfoblockContent from "./InfoblockContent";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

function InfoblockRow(props) {
    const {term, item, index, user} = props;
    const level = props.level ? props.level : 1;

    const prefix = `${(props.prefix ? props.prefix : '') + (index + 1)}.`;
    return (
        <>
            <InfoblockContent
                term={term}
                prefix={prefix}
                level={level}
                item={item}
                index={index}
                user={user}
            />

            {item.children && item.children.length !== 0 &&
            <InfoblocksRows
                term={term}
                prefix={prefix}
                infoblocks={item.children}
                level={level < 2 ? level + 1 : level}
            />
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InfoblockRow));