import React, { PureComponent } from 'react';
import {PieChart, Pie, Sector, Cell, Tooltip} from 'recharts';



const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, mainFill, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
      <g>
          <text x={cx} y={cy} dy={10} textAnchor="middle" fill="#707070">{payload.language}</text>
          <text className='english-translation' x={cx} y={cy} dy={35} textAnchor="middle" fill="#707070" fontSize="13">{payload.language_english}</text>
          <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
          />
          <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
          <circle cx={ex} cy={ey} r={2} fill="#f37f72" stroke="none"/>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#707070">{`${Math.round(percent)}% Translated `}</text>
      </g>
  );
};



export default class Example extends PureComponent {

  state = {
    activeIndex: 0,
  };

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };




    render() {
      const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#29d5e2', "#bdb76b", "#556b2f", "#8b0000", "#ff00ff", "#4b0082", "#add8e6", "#ff00ff", "#808000", "#ffc0cb", "#800080" ];
    return (
        <PieChart className='shape-chart' width={500} height={400}>
            <Pie
                activeIndex={this.state.activeIndex}
                activeShape={renderActiveShape}
                isAnimationActive={true}
                animationEasing='ease-in-out'
                data={this.props.translations}
                cx={300}
                cy={200}
                innerRadius={110}
                outerRadius={150}
                mainFill="#29d5e2"
                onMouseEnter={this.onPieEnter}
                paddingAngle={2}
                dataKey="percent"
            >
                {
                    this.props.translations && this.props.translations.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                }
            </Pie>
        </PieChart>
    );
  }
}
