import React from 'react'
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Markers,
    Marker,
} from "react-simple-maps";
import countryCoords from '../../helpers/CountryCoords';
// import Cursor from '../../../assets/pin-user.svg';

const wrapperStyles = {
    width: "100%",
    maxWidth: 1920,
}

class ReactSimpleMap extends React.Component {

    state = {
        currentCountry: {},
        countryData: [
            {
                country: "Zimbabwe",
                translate: 20,
                // coordinates: [29.154857, -19.015438]
            },
        ],
        clickedCountryMarker: {},
        isMarkerClicked: false,
        markerIndex: null,
        mrCountry: null,
    };

    handleMapCountryClick = (geography, evt) => {
        this.setState({
            ...this.state,
            currentCountry: {
                country: geography.properties.geounit,
                percent: (this.props.countries.find((item) => item.code === geography.properties.iso_a2) || {}).percent || 0,
            }
        })
    };

    handleMarkerClick = (country, index) => {
        const clickedCountry = this.props.countries.filter((item) => item.code === country)[0];
        const markerState = this.state.isMarkerClicked;
        const isDifferent = this.state.markerIndex !== index;

        this.setState(state => {

            return {
                ...state,
                clickedCountryMarker: clickedCountry,
                isMarkerClicked: state.markerIndex === null ? true : (!isDifferent ? false : true),
                markerIndex: state.markerIndex === null ? index : (isDifferent ? index : null),
                mrCountry: country
            }
        });
    };

    handleMarkerEnter = (country, index) => {
        const clickedCountry = this.props.countries.filter((item) => item.code === country)[0];
        const isDifferent = this.state.markerIndex !== index;

        this.setState(state => {

            return {
                ...state,
                clickedCountryMarker: clickedCountry,
                isMarkerClicked: state.markerIndex === null ? true : (!isDifferent ? false : true),
                markerIndex: state.markerIndex === null ? index : (isDifferent ? index : null),
                mrCountry: country
            }
        });
    };

    handleMarkerLeave = (country, index) => {
        this.setState(state => {
            return {
                ...state,
                clickedCountryMarker: {},
                isMarkerClicked: false,
                markerIndex: null,
                mrCountry: null
            }
        });
    }

    calculateSvgHeight = (index) => {
        const {active_users} = this.props.countries[index];
        let fullHeight = 30;
        for (let i = 0; i < active_users.length; i++) {
            const itemHeight = 20 + active_users[i].teams.length * 20;
            fullHeight += itemHeight;
        }
        return fullHeight;
    };

    render() {
        const {clickedCountryMarker, isMarkerClicked, markerIndex, mrCountry} = this.state;
        const {countries} = this.props;
        const active_users = [
            "Language editors",
            "Language co-editors",
            "Country editors",
            "Reviewers",
            "Topic curators"
        ];
        const sortedCountries = countries ? countries.sort((a, b) => a.code > b.code ? 1 : a.code < b.code ? -1 : 0) : null;
        
        let mapTextPreviousHeight = 0;

        return (
            <div className='map-img' style={wrapperStyles}>

                <ComposableMap
                    projectionConfig={{
                        scale: 211,
                        rotation: [-11, 0, 0],
                    }}
                    width={1400}
                    height={551}

                >
                    <ZoomableGroup center={[0, 20]} disablePanning>
                        {countries && <Geographies geography={`https://bim-new-s3.s3.amazonaws.com/static/world-50m-with-population.json`}>
                            {(geographies, projection) => geographies.map((geography, i) => {
                                let res = countries.filter(item => item.code === geography.properties.iso_a2);
                                const index = countries.findIndex(item => item.code === geography.properties.iso_a2);
                                const percent = res.length > 0 ? (res[0].percent + 0.5) : false;
                                return geography.id !== "ATA" && (
                                    <React.Fragment key={i}>
                                        {percent &&
                                        <svg aria-hidden="true" focusable="false">
                                            <linearGradient id={`gradient-horizontal${i}`} x1="0%" y1="0%" x2="0%"
                                                            y2="100%">
                                                <stop offset="0%" stopColor="#4bacc6"/>
                                                <stop offset={`${100 - percent}%`} stopColor="#4bacc6"/>
                                                <stop offset={`${100 - percent}%`} stopColor="#4bacc6"/>
                                                <stop offset={`100%`} stopColor="#4bacc6"/>
                                            </linearGradient>
                                        </svg>
                                        }

                                        {percent &&
                                        <svg aria-hidden="true" focusable="false">
                                            <linearGradient id={`gradient-hover${i}`} x1="0%" y1="0%" x2="0%" y2="100%">
                                                <stop offset="0%" stopColor="#1b9fc3"/>
                                                <stop offset={`${100 - percent}%`} stopColor="#1b9fc3"/>
                                                <stop offset={`${100 - percent}%`} stopColor="#1b9fc3"/>
                                                <stop offset={`100%`} stopColor="#1b9fc3"/>
                                            </linearGradient>
                                        </svg>
                                        }
                                        <Geography
                                            key={i}
                                            data-tip={geography.properties.name}
                                            geography={geography}
                                            projection={projection}
                                            onMouseEnter={(geo, e) => {
                                                if (percent && sortedCountries) {
                                                    this.handleMarkerEnter(res[0].code, index)
                                                }
                                            }}
                                            onMouseLeave={(geo, e) => {
                                                if (percent && sortedCountries) {
                                                    this.handleMarkerLeave(res[0].code, index)
                                                }
                                            }}
                                            onClick={() =>  {
                                                if (percent && sortedCountries) {
                                                    this.handleMarkerClick(res[0].code, index)
                                                }}
                                            }
                                            style={{
                                                default: {
                                                    fill: percent ? `url(#gradient-horizontal${i})` : "#eaeaea",
                                                    stroke: "#5A5A5A",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                },
                                                hover: {
                                                    fill: percent ? `url(#gradient-hover${i})` : "rgba(181, 181, 181, 0.7)",
                                                    stroke: "#5A5A5A",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                },
                                                pressed: {
                                                    fill: percent ? `url(#gradient-hover${i})` : "rgba(181, 181, 181, 0.7)",
                                                    stroke: "#5A5A5A",
                                                    strokeWidth: 0.75,
                                                    outline: "none",
                                                }
                                            }}
                                        />
                                    </React.Fragment>
                                )
                            })}
                        </Geographies>}

                        {sortedCountries &&
                        <Markers>
                            {countryCoords.filter((item) => -1 !== sortedCountries.findIndex(i => i.code === item.country)).map((marker, i) =>
                                <Marker
                                    key={i}
                                    marker={{coordinates: [marker.longitude, marker.latitude]}}
                                    style={{
                                        default: {stroke: "#212529"},
                                        hover: {stroke: "#5AB6E7"},
                                        pressed: {stroke: "#5AB6E7"},
                                        outline: 'none'
                                    }}
                                    >
                                    {clickedCountryMarker.code === marker.country && markerIndex===i && isMarkerClicked &&
                                    <svg className={'dd'} width="300" height={this.calculateSvgHeight(i)} y="10" >

                                        <rect width={'100%'} height={'100%'} fill={'#ffffff'} />

                                        <text
                                            textAnchor="middle"
                                            y={23}
                                            x={'50%'}
                                            style={{
                                                fontSize: '17px',
                                                fontWeight: '600',
                                                fontFamily: "Roboto, sans-serif",
                                                fill: "#707070",
                                                stroke: "none",

                                            }}
                                        >
                                            {clickedCountryMarker.country}
                                        </text>
                                        {sortedCountries[i].active_users.map((item, index) => {
                                            if (index > 0) {
                                                const prevItem = sortedCountries[i].active_users[index - 1];
                                                mapTextPreviousHeight += prevItem.teams.length * 20 + 20;
                                            }
                                            return (
                                                <React.Fragment key={index}>
                                                    <text
                                                        textAnchor="middle"
                                                        viewBox="0 0 100 100"
                                                        className="map-country-text"
                                                        y={43 + mapTextPreviousHeight}
                                                        x={10}
                                                        style={{
                                                            fontSize: '15px',
                                                            fontFamily: "Roboto, sans-serif",
                                                            fill: "#707070",
                                                            stroke: "none",
                                                            textAnchor: 'start'
                                                        }}
                                                    >
                                                        <tspan dy={0} className="map-country-text-name">{`${item.fullname}`}</tspan>
                                                        {item.teams.map((team, index) => {
                                                            const text = `${team.role} of ${team.name}`;
                                                            return <tspan key={index} x={10} dy={20}>{text}</tspan>
                                                        })}
                                                    </text>
                                                </React.Fragment>
                                            );
                                        })}
                                        <use id="use" xlinkHref="#shape2" />

                                    </svg>
                                    }

                                </Marker>
                            )}
                        </Markers>}
                    </ZoomableGroup>
                </ComposableMap>
            </div>
        )
    }
}

export default ReactSimpleMap