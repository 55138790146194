import React from 'react';
import PrintHeader from "./Header";
import PrintFooter from "./Footer";

export default class PageTemplate extends React.Component {
    render() {
        return (
            <>
                <div style={{position: "absolute", top: "0", width: '100%'}}>
                    <PrintHeader {...this.props}/>
                </div>
                <div style={{position: "absolute", bottom: "24px", right: "34px", zIndex: 2}}>
                    <span style={{
                        fontSize: '10px',
                        fontWeight: "normal",
                        fontStyle: "normal",
                        fontStretch: "normal",
                        lineHeight: "14px",
                        letterSpacing: "0.55px",
                        color: "#353333",
                    }}
                    >Page {this.props.pageNum} of {this.props.totalPages}</span>
                </div>
                <div style={{position: "absolute", bottom: "0", width: '100%'}}>
                    <PrintFooter user={this.props.user}/>
                </div>
            </>
        );
    }
}