import React, {Component} from 'react';
import {Modal, Container, Row, Col} from 'react-bootstrap';
import FroalaEditor from 'react-froala-wysiwyg';
import {fullTextareaConfig} from "../../../../../../configs/froalaConfigs";

class AddInfoblockModal extends Component {
    state = {
        title: '',
        content: '',
    };

    changeValue = (value, field) => {
        this.setState({
            [field]: value
        });
    };
    onSave = () => {
        const {title, content} = this.state;
        this.props.onSave(title, content);
        this.setState({title: '', content: ''});
    };

    clearData = () => {
        this.setState({title: '', content: ''});
        this.props.onHide()
    };

    componentWillUnmount() {
        this.setState({title: '', content: ''})
    }

    render() {
        const {title, content} = this.state;

        return (
            <Modal
                className="wrapper-base-modal insert-infoblock-modal"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.clearData}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Information Block
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="form-group">
                                    <label>Title<span className="input-required">*</span></label>
                                    <input type={"title"}
                                           value={title}
                                           onChange={(e) => this.changeValue(e.target.value, 'title')}
                                           className={"inline-edit-infoblock-title"}
                                           name={"name"}/>
                                </div>
                            </Col>

                            <Col xs={12} md={12}>
                                <div className="form-group">
                                    <label>Content</label>
                                    <FroalaEditor
                                        className={"inline-infoblock-content-editor infoblock-editor-modal"}
                                        tag='div'
                                        config={{
                                            htmlUntouched: true,
                                            fullPage: true,
                                            htmlRemoveTags: [],
                                            ...fullTextareaConfig,
                                            editorClass: 'infoblock-content-editor',
                                            toolbarInline: false,
                                            imageUploadParam: 'image_param',
                                            imageUploadURL: '/api/froala_upload_image',
                                            imageUploadMethod: 'POST',
                                            "min-height": 250
                                        }}
                                        model={content ? content : ''}
                                        onModelChange={(...data) => this.changeValue(data[0], 'content')}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer className="delete-extended">
                    <span onClick={this.clearData}>Cancel</span>
                    <button className="console-button" onClick={this.onSave}>
                        Add
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddInfoblockModal