import {Link} from "react-router-dom";
import React from "react";
import moment from "moment";

const Log = ({log, prevLog, isFirst, isLast}) => {
    let date = null;
    if (isFirst || !moment(log.created).isSame(prevLog.created, 'day')) {
        date = moment(log.created).format("LL");
    }

    return (
        <div className="logs-list-item">
            {!isFirst && <div className={`vertical-line1 ${date ? 'expanded' : ''}`}/>}
            {date &&
            <div className="date">
                {date}
            </div>
            }
            {date && <div className={`vertical-line1 ${date ? 'expanded' : ''}`}/>}
            <div className="logs-list-item-content">
                <div className="vertical-line2">
                    <div className="circle"/>
                </div>
                <div className="item-action">
                    {moment(log.created).format('HH:mm A')}&nbsp;&nbsp;&nbsp;
                    <Link to={'/'}>
                        {log.user}
                    </Link>&nbsp;
                    {log.action}&nbsp;
                    {Object.keys(log.item).length !== 0 && (<Link to={`/en/${log.item.slug}/${log.item.version.number}`} className="log-term-link"
                                                                  dangerouslySetInnerHTML={{__html: log.item.content.title}}>
                    </Link>)}
                </div>
            </div>
            {isLast && <div className={`vertical-line1 ${date ? 'expanded' : ''}`}/>}
        </div>
    )

};

export default Log;