import React, {Component} from "react";
import StyledCheckbox from "./StyledCheckbox";
import * as PropTypes from "prop-types";

class CustomTableWithSelecting extends Component {

    static propTypes = {
        items: PropTypes.array,
        children: PropTypes.object,
        columns: PropTypes.array,
        footer: PropTypes.array,
        className: PropTypes.string,
        onChangeSelect: PropTypes.func,
        selectedItemIds: PropTypes.array
    };

    state = {
        selected: this.props.selectedItemIds || [],
        selectAll: false
    };

    componentDidUpdate() {
        const {selectedItemIds} = this.props;
        if (selectedItemIds && selectedItemIds !== this.state.selected) {
            this.setState({selected: selectedItemIds})
        }
    }

    toggleSelectAll = () => {
        this.setState(({selectAll}) => ({
            selected: (!selectAll) ? this.props.items.map(obj => obj.id) : [],
            selectAll: !selectAll
        }), () => {
            if (this.props.onChangeSelect) {
                this.props.onChangeSelect(this.state.selected)
            }
        });
    };

    toggleSelect = (id) => {
        const { selected } = this.state;
        const itemIndex = selected.findIndex(item => item === id);

        let newSelected = [];
        if (itemIndex === -1) {
            newSelected = [...selected, id];
        } else {
            newSelected = selected.filter((item, index) => index !== itemIndex);
        }
        this.setState({
            selected: newSelected
        }, () => {
            if (this.props.onChangeSelect) {
                this.props.onChangeSelect(this.state.selected)
            }
        });
    };

    isItemChecked = ({ id }) => {
        return this.state.selected.find((item) => item === id) !== undefined;
    };

    render() {
        const { items, children, columns, footer, className, drawFilters } = this.props;

        const childrenWithProps = items && items.map((item) => React.Children.map(children, child =>
            React.cloneElement(child,
                {
                    item: {...item, checked: this.isItemChecked(item)},
                    props: {...this.props, toggleSelect: this.toggleSelect}
                })
        ));

        return (
            <table className={className}>

                <thead>
                    <tr>
                        {columns && columns.map((field, index) => (
                            <th
                                style={field.style}
                                className={field.className + '-th'}
                                key={index}
                            >
                                {field.checkboxesTitle && !field.component
                                    ? <StyledCheckbox changeSelect={() => this.toggleSelectAll()} />
                                    : field.component ? field.component : field.title
                                }
                            </th>
                        ))}
                    </tr>
                    {drawFilters && (
                        <tr>
                            {columns && columns.map((field, index) => (
                                <th key={index} style={field.style}>
                                    {field.input && field.input.component}
                                </th>
                            ))}
                        </tr>
                    )}
                </thead>

                <tbody>
                    {childrenWithProps}
                </tbody>

                <tfoot>
                <tr>
                    {footer && footer.map((field, index) => (
                        <td
                            colSpan={field.colspan ? field.colspan : 1}
                            key={index}
                        >
                            {field.component}
                        </td>
                    ))}
                </tr>
                </tfoot>
            </table>
        )
    }

}

export default CustomTableWithSelecting;