import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import store from "./store";
import MainApp from "./components/MainApp";

import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faIgloo,
    faTimesCircle,
    faGlobeAmericas,
    faLink,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faBookOpen,
    faStar,
    faInfoCircle,
    faGlobe
} from '@fortawesome/free-solid-svg-icons';
import {faComment, faStickyNote as farStickyNote} from '@fortawesome/free-regular-svg-icons';

library.add(
    faIgloo,
    faTimesCircle,
    faGlobeAmericas,
    faLink,
    farStickyNote,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faBookOpen,
    faStar,
    faInfoCircle,
    faGlobe,
    faComment
);

Array.prototype.flatRecursive = function () {
    const copy = [...this];

    for (let i = 0; i < copy.length; i++) {
        if (copy[i].children !== undefined && copy[i].children.length !== 0) {
            copy.splice(i + 1, 0, ...copy[i].children);
        }
    }
    return copy;
};

class App extends React.Component {
    render() {
        document.getElementById('pre-app-loading').remove();
        return (
            <Provider store={store}>
                <Router>
                    <MainApp/>
                </Router>
            </Provider>
        )
    }
}

ReactDOM.render(<App/>, document.getElementById('app'));
