import * as types from "./types";
import {success, error} from "redux-saga-requests";

const initialState = {
    terms: [],
    term: {},
    isFetching: false
};

const reviewersDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_TO_BE_REVIEWED_TERMS:
        case types.FETCH_REJECTED_TERMS:
        case types.FETCH_APPROVED_TERMS:
            return {
                ...state,
                isFetching: true
            };
        case success(types.FETCH_TO_BE_REVIEWED_TERMS):
        case success(types.FETCH_REJECTED_TERMS):
        case success(types.FETCH_APPROVED_TERMS):
            return {
                ...state,
                isFetching: false,
                terms: action.payload.data.data
            };
        case success(types.FETCH_REVIEWER_TERM):
            return {
                ...state,
                isFetching: false,
                term: action.payload.data.data
            };
        case error(types.FETCH_TO_BE_REVIEWED_TERMS):
        case error(types.FETCH_REJECTED_TERMS):
        case error(types.FETCH_APPROVED_TERMS):
        case error(types.FETCH_REVIEWER_TERM):
            return {
                ...state,
                isFetching: false
            };
        case types.CLEAR_REVIEWER_TERMS:
            return {
                ...state,
                terms: []
            };

        default:
            return state;
    }
};

export default reviewersDashboardReducer;
