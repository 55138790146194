import React, {useEffect, useState} from 'react';
import {
    DropdownItem, DropdownMenu, DropdownToggle, Button, Dropdown
} from "reactstrap";

const MenuViewableByUsers = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [checkedUsers, setCheckedUsers] = useState([]);
    const [unCheckedUsers, setUnCheckedUsers] = useState([]);
    const [filter, setFilter] = useState('');
    const [fetchedUsers, setFetchedUsers] = useState([]);

    const {users, makeLogsViewerAction} = props;

    // update local state of users
    useEffect(() => {
        setFetchedUsers([...users])
    }, [users]);

    // separate users by <is_logs_viewer> and filter of necessary
    useEffect(() => {
        prepareUsers()
    }, [fetchedUsers, filter]);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    const prepareUsers = () => {
        let _unCheckedUsers = [];
        let _checkedUsers = [];
        let _users = [...fetchedUsers];

        // filter users
        if (filter) {
            let _filter = filter.toLowerCase();
            _users = _users.filter(_user => (
                _user.fullname.toLowerCase().includes(_filter)
                || _user.email.toLowerCase().includes(_filter)
            ))
        }

        // sort users
        _users.sort(function (a, b) {
            if (a.fullname.toLowerCase() > b.fullname.toLowerCase()) {
                return 1;
            }
            if (a.fullname.toLowerCase() < b.fullname.toLowerCase()) {
                return -1;
            }
            return 0;
        });

        // separate users to arrays depend on < is_logs_viewer > param
        _users && _users.forEach(_user => {
            _user.is_logs_viewer ? _checkedUsers.push(_user) : _unCheckedUsers.push(_user);
        });

        setCheckedUsers(_checkedUsers);
        setUnCheckedUsers(_unCheckedUsers);
    };

    const updateFilter = event => {
        setFilter(event.target.value);
    };

    const handleUserCheck = id => event => {
        let _fetchedUsers = fetchedUsers.map(_fetchedUser => {
            if (_fetchedUser.id === id) {
                _fetchedUser.is_logs_viewer = !_fetchedUser.is_logs_viewer;

                // update db instance
                makeLogsViewerAction(_fetchedUser.id, _fetchedUser.is_logs_viewer)
            }
            return _fetchedUser
        });
        setFetchedUsers(_fetchedUsers);
    };

    return (
        <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className="viewable-user__dropdown"
        >
            <DropdownToggle
                caret
                className="viewable-user__toggle-btn">
                Log viewable by
            </DropdownToggle>

            <DropdownMenu className="viewable-user__menu">

                <input
                    type="text"
                    placeholder="Search by fullname"
                    className="viewable-user__search-input"
                    value={filter}
                    onChange={updateFilter}
                />

                {checkedUsers.length > 0 && (
                    <>
                        {checkedUsers.map(checkedUser => (
                            <div
                                className="viewable-user__item check-box c-flex"
                                key={checkedUser.id}
                                onClick={handleUserCheck(checkedUser.id)}
                            >
                                <input
                                    type={"checkbox"}
                                    checked={checkedUser.is_logs_viewer}
                                    readOnly
                                />
                                <span className="checkmark"></span>
                                <p>{checkedUser.fullname}</p>
                            </div>
                        ))}
                        <DropdownItem divider/>
                    </>
                )}

                {unCheckedUsers.length > 0 && unCheckedUsers.map(unCheckedUser => (
                    <div
                        className="viewable-user__item check-box c-flex"
                        key={unCheckedUser.id}
                        onClick={handleUserCheck(unCheckedUser.id)}
                    >
                        <input
                            type={"checkbox"}
                            checked={unCheckedUser.is_logs_viewer}
                            readOnly
                        />
                        <span className="checkmark"></span>
                        <p>{unCheckedUser.fullname}</p>
                    </div>
                ))}

            </DropdownMenu>
        </Dropdown>
    )
};

export default MenuViewableByUsers;