import { baseURL } from "../../configs/config";
import * as types from "./types";


export const fetchContributors = () => ({
    type: types.FETCH_CONTRIBUTORS,
    payload: {
        request: {
            url: `${baseURL}contributors`,
            method: 'GET',
        }
    }
});

export const fetchAllTeams = () => ({
    type: types.FETCH_ALL_TEAMS,
    payload: {
        request: {
            url: `${baseURL}contributors`,
            method: 'GET',
            params: {
                all_teams: 'all_teams'
            }
        },

    }
});

export const fetchEditors = () => ({
    type: types.FETCH_EDITORS,
    payload: {
        request: {
            url: `${baseURL}contributors/editors`,
            method: 'GET',
        }
    }
});

export const getContributorsMeetSection = () => ({
    type: types.FETCH_CONTRIBUTORS_MEET_SECTION,
    payload: {
        request: {
            url: `${baseURL}static/meetSection`,
            method: 'GET',
        }
    }
});

export const getContributorsSponsorSection = () => ({
    type: types.FETCH_CONTRIBUTORS_SPONSOR_SECTION,
    payload: {
        request: {
            url: `${baseURL}static/sponsorSection`,
            method: 'GET',
        }
    }
});

export const getContributorsTopicCuratorsSection = () => ({
    type: types.FETCH_TOPIC_CURATORS,
    payload: {
        request: {
            url: `${baseURL}static/topicCuratorsSection`,
            method: 'GET',
        }
    }
});

export const getContributorsAcknowledgementsSection = () => ({
    type: types.FETCH_CONTRIBUTORS_ACKNOWLEDGEMENTS,
    payload: {
        request: {
            url: `${baseURL}static/acknowledgementsSection`,
            method: 'GET',
        }
    }
});

export const clearContributorsData = () => ({
    type: types.CLEAR_CONTRIBUTORS_DATA
})

export const setContributorsParagraphs = (path, data) => ({
    type: types.SET_CONTRIBUTORS_PARAGRAPHS,
    payload: {
        path,
        data
    }
});

export const updateContributorsParagraphs = (path,  data) => ({
    type: types.UPDATE_CONTRIBUTORS_PARAGRAPHS,
    payload: {
        request: {
            url: `${baseURL}static/${path}`,
            method: 'PATCH',
            headers: localStorage.getItem('access') &&  {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            data
        },
        meta: {
            path
        }
    }
});
