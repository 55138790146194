import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {Container, Row, Col} from "reactstrap";
import { getCommonData } from "../../store/common/actions";

import PrivateRoute from "../PrivateRoute";

class MorePages extends Component {

    componentDidMount() {
        this.getCommonData()
    }

    createMarkup = (data) => {
        return {__html: data};
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match.path.substring(1) !== this.props.match.path.substring(1)) {
            this.getCommonData()
        }
    }

    getCommonData = () => {
        const pageName = this.props.match.path.substring(1);
        this.props.getCommonData(pageName);
    }


    render() {
        const {user, commonData} = this.props;
        const pagePath = this.props.match.path.substring(1);
        return (
            <div className={'container w-sm'}>
                {user.role === 'Admin' && <div className='term-switch-btn-wrapper main-term-page more-pages-wrapper'>
                    <div className='switch-btn-wrapper'>
                        <Link to={`/${pagePath}`} className="term-view switch-btn-active">
                            View
                        </Link>
                        <Link to={`/${pagePath}/edit`} className="term-edit">
                            Edit
                        </Link>
                    </div>
                </div>}
                <div className={'switch-term-container more-page-container'}>
                    <div className={'page-content-wrapper'} >
                        {!commonData && 'No data to display'}
                        {commonData && <div dangerouslySetInnerHTML={this.createMarkup(commonData)}></div>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    commonData: state.common.commonData
});

const mapActionsToProps = (dispatch) => ({
    getCommonData: (pageName) => dispatch(getCommonData(pageName)),
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(MorePages));
