import {success, error, abort} from "redux-saga-requests";
import * as types from "./types";

const initialState = {
    isFetching: false,
    isError: false,
    errMsg: '',
    errorStatus: null,
    items: [],
    sets: [],
    users: [],
    logUsers: [],
    teams: [],
    labels: [],
    languages: [],
    infoblocks: [],
    infoblocksTitles: [],
    templates: [],
    logs: [],
    tokens: [],
    listTemplateItems: [],
    documentEditors: [],

    availableUserRoles: [],
    availableCountries: [],
    availableTemplates: [],
    availableLabels: [],
    availableInfoBlockTypes: [],

    allSets: [],
    totalTerms: 0,
    totalSets: 0,
    totalUsers: 0,
    totalTeams: 0,
    totalLabels: 0,
    totalLanguages: 0,
    totalTokens: 0,
    totalInfoblocks: 0,
    totalTemplates: 0,

    singleTerm: {},
    singleSet: {},
    singleTeam: {},
    singleLabel: {},
    singleLanguage: {},
    singleInfoblock: {},
    singleTemplate: {},
    singleInfoBlockVersions: [],
    singleInfoBlockActiveVersion: null,

    hasMoreTerms: false,
    hasMoreSets: false,
    hasMoreUsers: false,
    hasMoreTeams: false,
    hasMoreLabels: false,
    hasMoreLanguages: false,
    hasMoreTokens: false,
    hasMoreLogs: false,
    hasMoreInfoblocks: false,
    hasMoreTemplates: false,
};

let actionsArray = [];
const removeFromActions = (action) => {
    actionsArray.splice(actionsArray.findIndex((item) => item === action.meta.requestAction.type), 1);
    return actionsArray.length !== 0;
}

const consoleReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.CREATE_TERM:
        case types.BULK_CREATE_TERMS:
        case types.EDIT_DRAFT_TERM:
        case types.GET_SINGLE_TERM:
        case types.EDIT_TERM:
        case types.MINOR_EDIT_TERM:
        case types.DELETE_TERM:
        case types.POST_TERM_MESSAGE:
        case types.BULK_DELETE_TERMS:
        case types.RESTORE_TERM_VERSION:

        case types.GET_AVAILABLE_USER_ROLES:
        case types.GET_AVAILABLE_TERM_TEMPLATES:
        case types.GET_AVAILABLE_COUNTRIES:
        case types.GET_AVAILABEL_LABELS:

        case types.GET_USERS:
        case types.BLOCK_USER:
        case types.RICH_TEXT_USER:
        case types.VERIFY_USER:
        case types.MAKE_CO_AUTHOR:
        case types.MAKE_LOGS_VIEWER:
        case types.DELETE_USER:
        case types.CHANGE_ROLE_USER:
        case types.BULK_BLOCK_USERS:
        case types.BULK_RICH_TEXT_USERS:

        case types.EDIT_SET:
        case types.CREATE_SET:
        case types.DELETE_SET:
        case types.BULK_DELETE_SET:
        case types.GET_SINGLE_SET:

        case types.GET_TEAMS:
        case types.DELETE_TEAM:
        case types.GET_SINGLE_TEAM:
        case types.EDIT_TEAM:
        case types.CREATE_TEAM:
        case types.BULK_DELETE_TEAM:

        case types.GET_LABELS:
        case types.CREATE_LABEL:
        case types.GET_SINGLE_LABEL:
        case types.EDIT_LABEL:
        case types.DELETE_LABEL:
        case types.BULK_DELETE_LABEL:

        case types.GET_LANGUAGES:
        case types.CREATE_LANGUAGE:
        case types.GET_SINGLE_LANGUAGE:
        case types.EDIT_LANGUAGE:
        case types.DELETE_LANGUAGE:
        case types.BULK_DELETE_LANGUAGE:

        case types.GET_INFOBLOCKS_TITLES:
        case types.GET_INFOBLOCKS_TYPES:
        case types.GET_INFOBLOCKS:
        case types.CREATE_INFOBLOCK:
        case types.GET_SINGLE_INFOBLOCK:
        case types.EDIT_INFOBLOCK:
        case types.DELETE_INFOBLOCK:
        case types.BULK_DELETE_INFOBLOCK:
        case types.BULK_EDIT_INFOBLOCK:

        case types.GET_TEMPLATES:
        case types.CREATE_TEMPLATE:
        case types.GET_SINGLE_TEMPLATE:
        case types.EDIT_TEMPLATE:
        case types.DELETE_TEMPLATE:
        case types.BULK_DELETE_TEMPLATE:
        case types.BULK_EDIT_TEMPLATE:
        case types.GET_LIST_TEMPLATE_ITEMS:
        case types.GET_AVAILABLE_TEMPLATES:

        case types.FETCH_LOGS:
        case types.FETCH_LOG_USERS:
        case types.FETCH_TOKENS:

        case types.FETCH_DOCUMENT_EDITORS:

        case types.GET_ALL_SETS:
        case types.GET_ADMIN_TERMS:
        case types.GET_ADMIN_SETS: {
            const loadInBackground = action.meta && action.meta.loadInBackground;
            if (!loadInBackground) {
                actionsArray.push(action.type);
            }
            return {
                ...state,
                isFetching: actionsArray.length !== 0,
                isError: false
            };
        }

        case types.UPDATE_SINGLE_TERM: {
            return {
                ...state,
                singleTerm: action.payload
            }
        }

        case types.CLEAR_ADMIN_SETS: {
            return {
                ...state,
                isFetching: false,
                sets: []
            }
        }

        case types.CLEAR_SET: {
            return {
                ...state,
                isFetching: false,
                singleSet: {}
            }
        }

        case types.CLEAR_TEAM: {
            return {
                ...state,
                isFetching: false,
                singleTeam: {}
            }
        }

        case types.CLEAR_ADMIN_TERMS: {
            return {
                ...state,
                items: []
            }
        }

        case success(types.GET_ADMIN_TERMS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                items: action.payload.data.data,
                totalTerms: action.payload.data.total,
                hasMoreTerms: action.payload.data.has_next_page
            };
        }

        case success(types.GET_ALL_SETS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                allSets: action.payload.data.data
            };
        }

        case success(types.GET_ADMIN_SETS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                sets: action.payload.data.data,
                totalSets: action.payload.data.total,
                hasMoreSets: action.payload.data.has_next_page
            };
        }

        case success(types.DELETE_SET):
        case success(types.BLOCK_USER):
        case success(types.DELETE_USER):
        case success(types.CHANGE_ROLE_USER):
        case success(types.RICH_TEXT_USER):
        case success(types.VERIFY_USER):
        case success(types.MAKE_CO_AUTHOR):
        case success(types.MAKE_LOGS_VIEWER):
        case success(types.DELETE_TEAM):
        case success(types.DELETE_LABEL):
        case success(types.DELETE_LANGUAGE):
        case success(types.DELETE_INFOBLOCK):
        case success(types.DELETE_TEMPLATE):
        case success(types.BULK_DELETE_SET):
        case success(types.BULK_DELETE_TERMS):
        case success(types.BULK_BLOCK_USERS):
        case success(types.BULK_RICH_TEXT_USERS):
        case success(types.BULK_DELETE_TEAM):
        case success(types.BULK_DELETE_LABEL):
        case success(types.BULK_DELETE_LANGUAGE):
        case success(types.BULK_DELETE_INFOBLOCK):
        case success(types.BULK_EDIT_INFOBLOCK):
        case success(types.BULK_DELETE_TEMPLATE):
        case success(types.BULK_EDIT_TEMPLATE):
        case success(types.DELETE_TERM): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
            }
        }

        case success(types.EDIT_TERM):
        case success(types.CREATE_SET):
        case success(types.EDIT_TEAM):
        case success(types.EDIT_SET):
        case success(types.POST_TERM_MESSAGE):
        case success(types.CREATE_TEAM):
        case success(types.RESTORE_TERM_VERSION):
        case success(types.CREATE_LABEL):
        case success(types.EDIT_LABEL):
        case success(types.CREATE_LANGUAGE):
        case success(types.EDIT_LANGUAGE):
        case success(types.CREATE_INFOBLOCK):
        case success(types.EDIT_INFOBLOCK):
        case success(types.CREATE_TEMPLATE):
        case success(types.EDIT_TEMPLATE):
        case success(types.EDIT_DRAFT_TERM):
        case success(types.MINOR_EDIT_TERM):
        case success(types.BULK_CREATE_TERMS):
        case success(types.CREATE_TERM): {
            return {
                ...state,
                // singleTerm: {},
                singleSet: {},
                singleTeam: {},
                singleLabel: {},
                singleLanguage: {},
                singleInfoblock: {},
                singleTemplate: {},
                isFetching: removeFromActions(action),
                isAuthorized: true,
            }
        }

        case success(types.GET_SINGLE_TERM): {
            return {
                ...state,
                singleTerm: action.payload.data,
                isFetching: removeFromActions(action),
                isAuthorized: true,
            }
        }

        case success(types.GET_SINGLE_SET): {
            return {
                ...state,
                singleSet: action.payload.data,
                isFetching: removeFromActions(action),
                isAuthorized: true,
            }
        }

        case success(types.GET_USERS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                users: action.payload.data.data,
                totalUsers: action.payload.data.total,
                hasMoreUsers: action.payload.data.has_next_page
            }
        }

        case success(types.FETCH_LOG_USERS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                logUsers: action.payload.data.data,
            }
        }

        case success(types.GET_TEAMS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                teams: action.payload.data.data,
                totalTeams: action.payload.data.total,
                hasMoreTeams: action.payload.data.has_next_page
            }
        }

        case success(types.GET_AVAILABLE_USER_ROLES): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                availableUserRoles: action.payload.data.data
            }
        }

        case success(types.GET_AVAILABLE_COUNTRIES): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                availableCountries: action.payload.data.data
            }
        }

        case success(types.GET_AVAILABLE_TERM_TEMPLATES): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                availableTemplates: action.payload.data.data
            }
        }

        case success(types.GET_AVAILABLE_TEMPLATES): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true
            }
        }

        case success(types.GET_SINGLE_TEAM): {
            return {
                ...state,
                singleTeam: action.payload.data,
                isFetching: removeFromActions(action),
                isAuthorized: true,
            }
        }

        case success(types.GET_LABELS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                labels: action.payload.data.data,
                totalLabels: action.payload.data.total,
                hasMoreLabels: action.payload.data.has_next_page
            }
        }

        case success(types.GET_SINGLE_LABEL): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                singleLabel: action.payload.data.data
            }
        }

        case success(types.GET_AVAILABEL_LABELS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                availableLabels: action.payload.data.data
            }
        }

        case success(types.GET_LANGUAGES): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                languages: action.payload.data.data,
                totalLanguages: action.payload.data.total,
                hasMoreLanguages: action.payload.data.has_next_page
            }
        }

        case success(types.GET_SINGLE_LANGUAGE): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                singleLanguage: action.payload.data.data
            }
        }

        case success(types.GET_INFOBLOCKS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                infoblocks: action.payload.data.data,
                totalInfoblocks: action.payload.data.total,
                hasMoreInfoblocks: action.payload.data.has_next_page
            }
        }

        case success(types.GET_INFOBLOCKS_TITLES): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                infoblocksTitles: action.payload.data.data
            }
        }

        case success(types.GET_SINGLE_INFOBLOCK): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                singleInfoblock: action.payload.data.data
            }
        }

        case success(types.GET_INFOBLOCKS_TYPES): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                availableInfoBlockTypes: action.payload.data.data
            }
        }

        case success(types.GET_TEMPLATES): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                templates: action.payload.data.data,
                totalTemplates: action.payload.data.total,
                hasMoreTemplates: action.payload.data.has_next_page
            }
        }

        case success(types.GET_LIST_TEMPLATE_ITEMS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                listTemplateItems: action.payload.data.data
            }
        }

        case success(types.GET_SINGLE_TEMPLATE): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                singleTemplate: action.payload.data.data
            }
        }

        case success(types.FETCH_LOGS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                logs: action.meta.requestAction.clearLogs ? action.payload.data.data : [...state.logs, ...action.payload.data.data],
                hasMoreLogs: action.payload.data.has_next_page
            }
        }

        case success(types.FETCH_TOKENS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                tokens: action.payload.data.data,
                totalTokens: action.payload.data.total,
                hasMoreTokens: action.payload.data.has_next_page
            }
        }

        case success(types.FETCH_DOCUMENT_EDITORS): {
            return {
                ...state,
                isFetching: removeFromActions(action),
                isAuthorized: true,
                documentEditors: action.payload.data.data
            }
        }

        case success(types.IMPERSONATE_USER): {
            let adminAccess = localStorage.getItem('access');
            let adminRefresh = localStorage.getItem('access');
            localStorage.setItem('adminAccess', adminAccess);
            localStorage.setItem('adminRefresh', adminRefresh);
            localStorage.setItem('access', action.payload.data.access);
            localStorage.setItem('refresh', action.payload.data.refresh);
            return state
        }

        case success(types.FETCH_INFOBLOCK_VERSIONS): {
            let activeVersion = null;
            action.payload.data.forEach(el => {
                activeVersion = el.is_current ? el : activeVersion;
            });
            return {
                ...state,
                singleInfoBlockVersions: action.payload.data,
                singleInfoBlockActiveVersion: activeVersion,
            }
        }

        case types.REMOVE_INFOBLOCK_VERSIONS: {
            return {
                ...state,
                singleInfoBlockVersions: [],
                singleInfoBlockActiveVersion: null
            }
        }

        case success(types.RESTORE_INFOBLOCK_VERSION): {
            return {
                ...state,
                singleInfoblock: action.payload.data
            }
        }

        case abort(types.GET_ADMIN_TERMS):
        case abort(types.CREATE_TERM):
        case abort(types.GET_SINGLE_TERM):
        case abort(types.EDIT_TERM):
        case abort(types.MINOR_EDIT_TERM):
        case abort(types.RESTORE_TERM_VERSION):
        case abort(types.GET_USERS):
        case abort(types.CREATE_SET):
        case abort(types.GET_AVAILABLE_COUNTRIES):
        case abort(types.GET_AVAILABLE_USER_ROLES):
        case abort(types.GET_SINGLE_TEAM):
        case abort(types.DELETE_SET):
        case abort(types.BULK_DELETE_SET):
        case abort(types.BULK_DELETE_TERMS):
        case abort(types.BULK_BLOCK_USERS):
        case abort(types.BULK_RICH_TEXT_USERS):
        case abort(types.BULK_DELETE_TEAM):
        case abort(types.BULK_DELETE_LABEL):
        case abort(types.BULK_DELETE_LANGUAGE):
        case abort(types.BLOCK_USER):
        case abort(types.DELETE_USER):
        case abort(types.CHANGE_ROLE_USER):
        case abort(types.RICH_TEXT_USER):
        case abort(types.EDIT_TEAM):
        case abort(types.DELETE_TEAM):
        case abort(types.POST_TERM_MESSAGE):
        case abort(types.EDIT_SET):
        case abort(types.GET_TEAMS):
        case abort(types.DELETE_TERM):
        case abort(types.GET_ALL_SETS):
        case abort(types.GET_SINGLE_SET):
        case abort(types.GET_AVAILABLE_TERM_TEMPLATES):
        case abort(types.GET_LABELS):
        case abort(types.CREATE_LABEL):
        case abort(types.GET_SINGLE_LABEL):
        case abort(types.EDIT_LABEL):
        case abort(types.DELETE_LABEL):
        case abort(types.GET_AVAILABEL_LABELS):
        case abort(types.CREATE_TEAM):
        case abort(types.FETCH_LOGS):
        case abort(types.FETCH_LOG_USERS):
        case abort(types.GET_TEMPLATES):
        case abort(types.GET_INFOBLOCKS):
        case abort(types.FETCH_TOKENS):
        case abort(types.GET_INFOBLOCKS_TITLES):
        case abort(types.GET_AVAILABLE_TEMPLATES):
        case abort(types.FETCH_DOCUMENT_EDITORS):
        case abort(types.GET_ADMIN_SETS): {
            removeFromActions(action);
            return {
                ...state,
                isFetching: false
            };
        }

        case error(types.GET_ADMIN_TERMS):
        case error(types.CREATE_TERM):
        case error(types.EDIT_DRAFT_TERM):
        case error(types.GET_SINGLE_TERM):
        case error(types.EDIT_TERM):
        case error(types.MINOR_EDIT_TERM):
        case error(types.DELETE_TERM):
        case error(types.POST_TERM_MESSAGE):
        case error(types.BULK_CREATE_TERMS):
        case error(types.BULK_DELETE_TERMS):
        case error(types.RESTORE_TERM_VERSION):

        case error(types.GET_USERS):
        case error(types.BLOCK_USER):
        case error(types.DELETE_USER):
        case error(types.CHANGE_ROLE_USER):
        case error(types.RICH_TEXT_USER):
        case error(types.VERIFY_USER):
        case error(types.MAKE_CO_AUTHOR):
        case error(types.MAKE_LOGS_VIEWER):
        case error(types.BULK_BLOCK_USERS):
        case error(types.BULK_RICH_TEXT_USERS):

        case error(types.CREATE_SET):
        case error(types.DELETE_SET):
        case error(types.BULK_DELETE_SET):
        case error(types.EDIT_SET):
        case error(types.GET_SINGLE_SET):

        case error(types.GET_SINGLE_TEAM):
        case error(types.EDIT_TEAM):
        case error(types.DELETE_TEAM):
        case error(types.GET_TEAMS):
        case error(types.CREATE_TEAM):
        case error(types.BULK_DELETE_TEAM):

        case error(types.GET_ADMIN_SETS):
        case error(types.GET_ALL_SETS):

        case error(types.GET_LABELS):
        case error(types.CREATE_LABEL):
        case error(types.GET_SINGLE_LABEL):
        case error(types.EDIT_LABEL):
        case error(types.DELETE_LABEL):
        case error(types.BULK_DELETE_LABEL):

        case error(types.GET_LANGUAGES):
        case error(types.CREATE_LANGUAGE):
        case error(types.GET_SINGLE_LANGUAGE):
        case error(types.EDIT_LANGUAGE):
        case error(types.DELETE_LANGUAGE):
        case error(types.BULK_DELETE_LANGUAGE):

        case error(types.GET_INFOBLOCKS_TITLES):
        case error(types.GET_INFOBLOCKS):
        case error(types.CREATE_INFOBLOCK):
        case error(types.GET_SINGLE_INFOBLOCK):
        case error(types.EDIT_INFOBLOCK):
        case error(types.DELETE_INFOBLOCK):
        case error(types.BULK_DELETE_INFOBLOCK):
        case error(types.BULK_EDIT_INFOBLOCK):
        case error(types.GET_INFOBLOCKS_TYPES):

        case error(types.GET_TEMPLATES):
        case error(types.CREATE_TEMPLATE):
        case error(types.GET_SINGLE_TEMPLATE):
        case error(types.EDIT_TEMPLATE):
        case error(types.DELETE_TEMPLATE):
        case error(types.BULK_DELETE_TEMPLATE):
        case error(types.BULK_EDIT_TEMPLATE):
        case error(types.GET_LIST_TEMPLATE_ITEMS):
        case error(types.GET_AVAILABLE_TEMPLATES):

        case error(types.FETCH_LOGS):
        case error(types.FETCH_LOG_USERS):
        case error(types.FETCH_TOKENS):

        case error(types.FETCH_DOCUMENT_EDITORS):

        case error(types.GET_AVAILABLE_TERM_TEMPLATES):
        case error(types.GET_AVAILABLE_COUNTRIES):
        case error(types.FETCH_INFOBLOCK_VERSIONS):
        case error(types.RESTORE_INFOBLOCK_VERSION):
        case error(types.GET_AVAILABEL_LABELS):{
            actionsArray = [];
            return {
                ...state,
                isFetching: false,
                isError: true,
                errMsg: action.payload.response.data,
                errorStatus: action.payload.response.status
            };
        }

        default:
            return state;
    }
};

export default consoleReducer;
