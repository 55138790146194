import * as types from "./types";
import {baseURL} from "../../configs/config";

export const getAdminTerms = (data, loadInBackground = false) => ({
    type: types.GET_ADMIN_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                ...data
            }
        }
    },
    meta: {
        asPromise: true,
        loadInBackground
    }
});

export const getAllSets = () => ({
    type: types.GET_ALL_SETS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/sets`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },

});

export const createTerm = (data) => ({
    type: types.CREATE_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkCreateTerms = (data) => ({
    type: types.BULK_CREATE_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms/multiple`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkDeleteTerms = (data) => ({
    type: types.BULK_DELETE_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms`,
            method: 'DELETE',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const editDraftTerm = (id, data) => ({
    type: types.EDIT_DRAFT_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms/${id}`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const minorEditTerm = (id, data) => ({
    type: types.MINOR_EDIT_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms/${id}`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const deleteTerm = (id, data) => ({
    type: types.DELETE_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms/${id}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getSingleTerm = (id) => ({
    type: types.GET_SINGLE_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms/${id}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const updateSingleTerm = (data) => ({
    type: types.UPDATE_SINGLE_TERM,
    payload: data
});

export const editTerm = (id, data) => {
    return {
        type: types.EDIT_TERM,
        payload: {
            request: {
                url: `${baseURL}v1/admin/terms/${id}`,
                method: 'PATCH',
                data,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`
                }
            }
        }
    }
};


// export const editTerm = (id, data) => ({
//     type: types.EDIT_TERM,
//     payload: {
//         request: {
//             url: `${baseURL}v1/admin/terms/${id}`,
//             method: 'PATCH',
//             data,
//             headers: {
//                 'Authorization': `Bearer ${localStorage.getItem('access')}`
//             }
//         }
//     }
// });

export const restoreTermVersion = (id, data) => ({
    type: types.RESTORE_TERM_VERSION,
    payload: {
        request: {
            url: `${baseURL}v1/admin/terms/${id}/restore`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                ...data
            }
        }
    }
});

export const getUsers = (data) => ({
    type: types.GET_USERS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users?${Object.keys(data).map(key => key + '=' + data[key]).join('&')}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getUser = (id) => ({
    type: types.GET_USER,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/${id}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        asPromise: true
    }
});

export const updateUser = (user) => ({
    type: types.UPDATE_USER,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/${user.id}`,
            data: {
                first_name: user.firstName,
                last_name: user.lastName,
            },
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        asPromise: true
    }
});

export const blockUser = (id) => ({
    type: types.BLOCK_USER,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/${id}?block_action=`,
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const deleteUser = (id) => ({
    type: types.DELETE_USER,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/${id}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const richTextUser = (id) => ({
    type: types.RICH_TEXT_USER,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/${id}?rich_text_action=`,
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const verifyUser = (id) => ({
    type: types.VERIFY_USER,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/${id}?verify_action=`,
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const changeRole = (id, role) => ({
    type: types.CHANGE_ROLE_USER,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/${id}/change_role`,
            method: 'PATCH',
            data: {
                role
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const makeCoAuthor = (id) => ({
    type: types.MAKE_CO_AUTHOR,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/${id}/make_co_author`,
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});


export const makeLogsViewer = (id, isLogsViewer = false) => ({
    type: types.MAKE_LOGS_VIEWER,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/${id}/make_logs_viewer`,
            method: 'POST',
            data: {is_logs_viewer: isLogsViewer},
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const impersonateUser = (email) => ({
    type: types.IMPERSONATE_USER,
    payload: {
        request: {
            url: `${baseURL}impersonation_by_suser`,
            data: {email},
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkBlockUser = (data) => ({
    type: types.BULK_BLOCK_USERS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users?block_action=`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkUnblockUser = (data) => ({
    type: types.BULK_BLOCK_USERS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users?unblock_action=`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkRichTextUser = (data) => ({
    type: types.BULK_RICH_TEXT_USERS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users?enable_rich_text_action=`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkPlainTextUser = (data) => ({
    type: types.BULK_RICH_TEXT_USERS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users?disable_rich_text_action=`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAdminSets = (data) => ({
    type: types.GET_ADMIN_SETS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/sets`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                ...data
            }
        }
    }
});

export const createSet = (data) => ({
    type: types.CREATE_SET,
    payload: {
        request: {
            url: `${baseURL}v1/admin/sets`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const adminPostMessage = (data) => ({
    // Todo - do
    // type: types.POST_TERM_MESSAGE,
    // payload: {
    //     request: {
    //         url: `${baseURL}v1/admin/term/message`,
    //         method: 'POST',
    //         data,
    //         headers: {
    //             'Authorization': `Bearer ${localStorage.getItem('access')}`
    //         }
    //     }
    // }
});

export const getSingleSet = (id) => ({
    type: types.GET_SINGLE_SET,
    payload: {
        request: {
            url: `${baseURL}v1/admin/sets/${id}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});
export const editSingleSet = (id, data) => ({
    type: types.EDIT_SET,
    payload: {
        request: {
            url: `${baseURL}v1/admin/sets/${id}`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const deleteSet = (id, data) => ({
    type: types.DELETE_SET,
    payload: {
        request: {
            url: `${baseURL}v1/admin/sets/${id}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});


export const bulkDeleteSets = (data) => ({
    type: types.BULK_DELETE_SET,
    payload: {
        request: {
            url: `${baseURL}v1/admin/sets`,
            method: 'DELETE',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const clearAdminSets = () => ({
    type: types.CLEAR_ADMIN_SETS
});

export const clearSingleSet = () => ({
    type: types.CLEAR_SET
});

export const getAdminTeams = (data = {}) => ({
    type: types.GET_TEAMS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/teams?${Object.keys(data).map(key => key + '=' + data[key]).join('&')}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const clearAdminTerms = () => ({
    type: types.CLEAR_ADMIN_TERMS
});

export const createAdminTeam = (data) => ({
    type: types.CREATE_TEAM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/teams`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAvailableUserRoles = (data = {}) => ({
    type: types.GET_AVAILABLE_USER_ROLES,
    payload: {
        request: {
            url: `${baseURL}v1/admin/roles`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAvailableCountries = (data = {}) => ({
    type: types.GET_AVAILABLE_COUNTRIES,
    payload: {
        request: {
            url: `${baseURL}v1/admin/countries`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAvailableTermTemplates = (data = {}) => ({
    type: types.GET_AVAILABLE_TERM_TEMPLATES,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates/types`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});


export const getSingleTeam = (id) => ({
    type: types.GET_SINGLE_TEAM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/teams/${id}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const editSingleTeam = (id, data) => ({
    type: types.EDIT_TEAM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/teams/${id}`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const deleteSingleTeam = (id, data) => ({
    type: types.DELETE_TEAM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/teams/${id}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkDeleteTeams = (data) => ({
    type: types.BULK_DELETE_TEAM,
    payload: {
        request: {
            url: `${baseURL}v1/admin/teams`,
            method: 'DELETE',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const clearSingleTeam = () => ({
    type: types.CLEAR_TEAM
});

export const getAdminLabels = (data) => ({
    type: types.GET_LABELS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/labels`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                ...data
            }
        }
    }
});

export const createAdminLabel = (data) => ({
    type: types.CREATE_LABEL,
    payload: {
        request: {
            url: `${baseURL}v1/admin/labels`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAdminSingleLabel = (id) => ({
    type: types.GET_SINGLE_LABEL,
    payload: {
        request: {
            url: `${baseURL}v1/admin/labels/${id}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const editAdminSingleLabel = (id, data) => ({
    type: types.EDIT_LABEL,
    payload: {
        request: {
            url: `${baseURL}v1/admin/labels/${id}`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const deleteAdminLabel = (id) => ({
    type: types.DELETE_LABEL,
    payload: {
        request: {
            url: `${baseURL}v1/admin/labels/${id}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkDeleteLabels = (data) => ({
    type: types.BULK_DELETE_LABEL,
    payload: {
        request: {
            url: `${baseURL}v1/admin/labels`,
            method: 'DELETE',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});


export const getAvailableLabels = () => ({
    type: types.GET_AVAILABEL_LABELS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/labels`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                all: 'true'
            }
        }
    }
});

/* LANGUAGES */

export const getAdminLanguages = (data) => ({
    type: types.GET_LANGUAGES,
    payload: {
        request: {
            url: `${baseURL}v1/admin/languages`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                ...data
            }
        }
    }
});

export const createAdminLanguage = (data) => ({
    type: types.CREATE_LANGUAGE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/languages`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAdminSingleLanguage = (id) => ({
    type: types.GET_SINGLE_LANGUAGE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/languages/${id}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const editAdminSingleLanguage = (id, data) => ({
    type: types.EDIT_LANGUAGE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/languages/${id}`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const deleteAdminLanguage = (id) => ({
    type: types.DELETE_LANGUAGE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/languages/${id}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkDeleteLanguages = (data) => ({
    type: types.BULK_DELETE_LANGUAGE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/languages`,
            method: 'DELETE',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

/* INFOBLOCKS */

export const getAdminInfoblocksTypes = () => ({
    type: types.GET_INFOBLOCKS_TYPES,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks/types`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAdminInfoblocksTitles = (data = '') => ({
    type: types.GET_INFOBLOCKS_TITLES,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks/all${data}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAdminInfoblocks = (data) => ({
    type: types.GET_INFOBLOCKS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                ...data
            }
        }
    }
});

export const createAdminInfoblock = (data) => ({
    type: types.CREATE_INFOBLOCK,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkEditInfoblocks = (data) => ({
    type: types.BULK_EDIT_INFOBLOCK,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkDeleteInfoblocks = (data) => ({
    type: types.BULK_DELETE_INFOBLOCK,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks`,
            method: 'DELETE',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAdminSingleInfoblock = (id) => ({
    type: types.GET_SINGLE_INFOBLOCK,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks/${id}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const editAdminSingleInfoblock = (id, data) => ({
    type: types.EDIT_INFOBLOCK,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks/${id}`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const deleteAdminInfoblock = (id) => ({
    type: types.DELETE_INFOBLOCK,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks/${id}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

/* TEMPLATES */

export const getAdminTemplates = (data) => ({
    type: types.GET_TEMPLATES,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                ...data
            }
        }
    }
});

export const createAdminTemplate = (data) => ({
    type: types.CREATE_TEMPLATE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkEditTemplates = (data) => ({
    type: types.BULK_EDIT_TEMPLATE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const bulkDeleteTemplates = (data) => ({
    type: types.BULK_DELETE_TEMPLATE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates`,
            method: 'DELETE',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAdminSingleTemplate = (id) => ({
    type: types.GET_SINGLE_TEMPLATE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates/${id}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const editAdminSingleTemplate = (id, data) => ({
    type: types.EDIT_TEMPLATE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates/${id}`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const deleteAdminTemplate = (id) => ({
    type: types.DELETE_TEMPLATE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates/${id}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAdminListTemplateItems = (id) => ({
    type: types.GET_LIST_TEMPLATE_ITEMS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates/list/items/${id}`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const getAdminAvailableTemplates = () => ({
    type: types.GET_AVAILABLE_TEMPLATES,
    payload: {
        request: {
            url: `${baseURL}v1/admin/templates/available`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});


/* LOGS */

export const fetchLogs = (data) => ({
    type: types.FETCH_LOGS,
    clearLogs: data.clearLogs !== undefined && data.clearLogs ? true : false,
    payload: {
        request: {
            url: `${baseURL}v1/admin/logger`,
            method: 'GET',
            params: {
                ...data
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const fetchLogUsers = (data) => ({
    type: types.FETCH_LOG_USERS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/logger/users`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const fetchTokens = (data) => ({
    type: types.FETCH_TOKENS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/tokens`,
            method: 'GET',
            params: {
                ...data
            },
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }

});

export const pauseToken = (token, data) => ({
    type: types.PAUSE_TOKEN,
    payload: {
        request: {
            url: `${baseURL}v1/admin/tokens/${token}`,
            method: 'PATCH',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const revokeToken = (token) => ({
    type: types.REVOKE_TOKEN,
    payload: {
        request: {
            url: `${baseURL}v1/admin/tokens/${token}`,
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const fetchDocumentEditors = () => ({
    type: types.FETCH_DOCUMENT_EDITORS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/get_document_editors`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const assignUsersToTemplate = (id, data) => ({
    type: types.ASSIGN_USERS_TO_TEMPLATE,
    payload: {
        request: {
            url: `${baseURL}v1/admin/users/assign_term/${id}`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const fetchInfoblockVersions = id => ({
    type: types.FETCH_INFOBLOCK_VERSIONS,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks/${id}/versions`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const removeInfoblockVersion = () => ({
    type: types.REMOVE_INFOBLOCK_VERSIONS
});

export const restoreInfoblockVersion = (id, data) => ({
    type: types.RESTORE_INFOBLOCK_VERSION,
    payload: {
        request: {
            url: `${baseURL}v1/admin/infoblocks/${id}/restore_version`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});


