import React from 'react';
import { Fade } from 'react-reveal';
import { FaPencil } from 'react-icons/lib/fa';
import ContributorsFroala from './ContributorsFroala';

const ContributorsSectionTextEdit = props => {
	const {
		user,
		theme,
		title,
		content,
		contentName,
		exampleContent,
		nameEditSect,
		isEditSect,
		handleSaveData,
		handleShowEditBlock,
		handleChangeFroala
	} = props;

	const createMarkup = data => {
		return { __html: data };
	};

	return (
		<div
			className={`page-section ${theme === 'blue' ? 'blue-theme' : null}`}
		>
			<div className='meet-team'>{title}</div>
			<div className='meet-team-box'>
				<div className='meet-team-text-wrapper'>
					<Fade left>
						<>
							{!isEditSect && (
								<div
									className='meet-team-text'
									dangerouslySetInnerHTML={createMarkup(
										content ? content : exampleContent
									)}
								/>
							)}

							{user.role === 'Admin' && (
								<FaPencil
									className='edit-contributors-btn'
									onClick={() =>
										handleShowEditBlock(nameEditSect)
									}
								/>
							)}

							{isEditSect && (
								<div className='edit-common-wrapper'>
									<ContributorsFroala
										minHeight={100}
										model={content}
										onModelChange={data =>
											handleChangeFroala(
												contentName,
												data
											)
										}
									/>

									<button
										className='save-edit-block'
										onClick={() =>
											handleSaveData(
												nameEditSect,
												contentName
											)
										}
									>
										save
									</button>
								</div>
							)}
						</>
					</Fade>
				</div>
			</div>
		</div>
	);
};

export default ContributorsSectionTextEdit;
