import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {fetchCurrentUserInfo} from "../store/auth/actions";
import {Route} from "react-router-dom";


class PrivateRoute extends Component {

    checkRoleAndRedirect(user, roles) {
        let result;
        let isAdmin = user.role === 'Admin';

        const orRoleOrPerm = this.props.orRoleOrPerm || false;
        let hasPermissionToViewByRole = true;
        let hasPermissionToViewByPerm = true;

        if (!isAdmin) {
            // validate by roles
            if (roles !== undefined && user.role === undefined) {
                hasPermissionToViewByRole = false;
            } else if (roles !== undefined && user.role !== undefined && roles.indexOf(user.role.toLocaleLowerCase()) === -1) {
                hasPermissionToViewByRole = false;
            }

            // validate by permissions
            if (this.props.isAuthor && !user.is_author) {
                hasPermissionToViewByPerm = false;
            } else if (this.props.isLogsViewer && !user.is_logs_viewer) {
                hasPermissionToViewByPerm = false;
            }
        }

        if (orRoleOrPerm) {
            result = hasPermissionToViewByRole || hasPermissionToViewByPerm;
        } else {
            result = hasPermissionToViewByRole && hasPermissionToViewByPerm;
        }

        // redirect to home page if user has no permissions
        !result && this.props.history.push('/');
        return result;
    }

    componentDidMount() {
        if (localStorage.getItem('access') && localStorage.getItem('refresh')) {
            if (this.props.role === undefined) {
                this.props.fetchCurrentUserInfo().then((currentUser) => (
                    this.checkRoleAndRedirect(currentUser.payload.data, this.props.roles)
                ));
            } else {
                this.checkRoleAndRedirect(this.props, this.props.roles)
            }
        } else {
            this.props.history.push('/');
        }
    }

    render() {
        const {isAuthorized, user, roles} = this.props;
        return (
            <>
                {isAuthorized && this.checkRoleAndRedirect(user, roles) && <Route {...this.props} />}
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    isAuthorized: state.auth.isAuthorized
});

const mapDispatchToProps = {
    fetchCurrentUserInfo
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PrivateRoute));
