import React from "react";

const ThankYouMessageBlock = () => {
  return (
    <div className="email-thanks-user-container">
      <h1>Thank You!</h1>
      <h4>
        your message has been sent,
        <br/>
        we will respond to you as soon as possible!
      </h4>
    </div>
  );
};

export default ThankYouMessageBlock;
