import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";
import {googleClientId, facebookAppId, baseURLForLink, linkedinAppId} from '../../configs/config'
import {
    fetchCurrentUserInfo,
    signIn,
    googleSignIn,
    facebookSignIn,
    linkedinSignIn,
    forgotPassword, clearErrors
} from "../../store/auth/actions";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import {NotificationManager} from "react-notifications";
import ModalForgotPassword from "./ModalForgotPassword";

class Login extends Component {

    state = {
        fields: [{
            key: 'email',
            type: 'text',
            data: '',
            isValid: false,
            isTouched: false,
            errorMess: 'Please enter a valid email address',
        }, {
            key: 'password',
            type: 'password',
            data: '',
            isValid: false,
            isTouched: false,
            errorMess: 'This field is required',
        }],
        showModalResetPassword: false,
    };
    
    setShowModalResetPassword = (showModalResetPassword) => {
        this.setState({
            showModalResetPassword
        })
    };
    
    sendForgotPassword = (email) => {
        this.setShowModalResetPassword(false);
        this.props.forgotPassword({email})
            .then(res => {
                NotificationManager.success('Check your email for a link to reset your password.')
            });
    };

    validationRules = {
        email: (value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value),
        password: (value) => value.length != 0
    }

    _handleFieldChange = (e, name) => {
        const value = e.target.value;
        const {fields} = this.state;

        this.setState({
            fields: [
                ...fields.map(field => field.key === name ? {
                    ...field,
                    data: this.validationRules[name](value) ? value : '',
                    isTouched: true,
                    isValid: this.validationRules[name](value)
                } : field)
            ]
        })
    }

    _handleSubmit = (e) => {
        e.preventDefault();
        const {fields} = this.state;
        const isValid = fields.filter(field => !this.validationRules[field.key](field.data))

        if (isValid.length !== 0) {
            this.setState({
                fields: [
                    ...fields.map(field => ({
                        ...field,
                        isTouched: true
                    }))
                ]
            })
        } else {
            const logInData = {email: '', password: ''}
            fields.forEach(field => logInData[field.key] = field.data)
            this.props.signIn(logInData)
                .then(() => this.props.fetchCurrentUserInfo())
                .then(() => this.props.history.push('/'));
        }
    }

    responseGoogle = (response) => {
        if (response.tokenId === undefined) {
            return false;
        }

        const googleSignInData = {id_token: response.tokenId};
        this.props.googleSignIn(googleSignInData)
                .then(() => this.props.fetchCurrentUserInfo())
                .then(() => this.props.history.push('/'));
    }

    responseFacebook = (response) => {
        if (response.accessToken === undefined) {
            return false;
        }

        const facebookSignInData = {id_token: response.accessToken};
        this.props.facebookSignIn(facebookSignInData)
                .then(() => this.props.fetchCurrentUserInfo())
                .then(() => this.props.history.push('/'));
    }

    handleSuccess = (data) => {
        this.props.linkedinSignIn(data)
                .then(() => this.props.fetchCurrentUserInfo())
                .then(() => this.props.history.push('/'));
    }

    handleFailure = (error) => {};

    componentDidMount() {
        const {isAuthorized, history, location, clearErrors} = this.props;

        clearErrors();

        if (isAuthorized) {
            history.push('/');
        }

        if (location.search === '?verified=') {
            NotificationManager.success('Account verified');
        }
    }

    render() {
        const {fields, showModalResetPassword} = this.state;
        const {errMsg} = this.props;
        return (
            <>
                <div className="auth-title-container">
                    <h1>Log in</h1>
                </div>
                <Container className="auth-container w-xs">
                    <div className={'log_in-wrapper'} >
                        {errMsg && typeof errMsg === 'string' && <div className='error-text-auth'>{errMsg}</div>}
                        <form onSubmit={(e) => this._handleSubmit(e)}>
                            {fields.map((field, index) => (
                                <div className="contact-block-wrapper" key={index}>
                                    <div className="field-wrapper">
                                        <h2>{field.key.charAt(0).toUpperCase() + field.key.slice(1)}</h2>
                                        <input className={!field.isValid && field.isTouched ? 'error-field' : ''}
                                                type={field.type}
                                                onChange={(e, name) => this._handleFieldChange(e, field.key)}/>
                                        {!field.isValid && field.isTouched &&
                                        <span className='error-text'>{field.errorMess}</span>}
                                    </div>
                                </div>
                            ))}

                            <div className="contact-block-wrapper auth-block auth-block--inline">
                                <button onClick={this._handleSubmit}>Log In</button>
                                <div className="forgot-password-wrapper">
                                    <span className="forgot-password"
                                        onClick={() => this.setShowModalResetPassword(true)}>
                                        Forgot password?
                                    </span>
                                </div>
                            </div>
                        </form>
                        <div className="social-buttons">
                            <GoogleLogin
                                clientId={googleClientId}
                                buttonText="GOOGLE"
                                onSuccess={(response) => this.responseGoogle(response)}
                                onFailure={(response) => this.responseGoogle(response)}
                                cookiePolicy={'single_host_origin'}
                                className="btn-google"
                            />
                            <FacebookLogin
                                textButton={'Facebook'}
                                tag={'button'}
                                appId={facebookAppId}
                                fields="name,email,picture"
                                callback={(response) => this.responseFacebook(response)}
                            />
                            <LinkedIn
                                clientId={linkedinAppId}
                                onFailure={this.handleFailure}
                                onSuccess={this.handleSuccess}
                                redirectUri={`${baseURLForLink}linkedin_auth`}
                                scope="r_liteprofile,r_emailaddress"
                            >
                                Linkedin
                            </LinkedIn>
                        </div>
                        <div className="contact-block-wrapper">
                            <div className="form-footer-link">
                                Still have not an account? <Link to={'/register'}>Sign Up</Link>
                            </div>
                        </div>
                    </div>
                    
                    <ModalForgotPassword
                        show={showModalResetPassword}
                        onCancelClick={() => this.setShowModalResetPassword(false)}
                        onSendClick={this.sendForgotPassword}
                    />
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isAuthorized: state.auth.isAuthorized,
    errMsg: state.auth.errMsg,
    isInvalidLogIn: state.auth.isInvalidLogIn,
    isBlocked: state.auth.isBlocked
});

const mapActionsToProps = (dispatch) => ({
    signIn: (data) => dispatch(signIn(data)),
    googleSignIn: (data) => dispatch(googleSignIn(data)),
    facebookSignIn: (data) => dispatch(facebookSignIn(data)),
    linkedinSignIn: (data) => dispatch(linkedinSignIn(data)),
    fetchCurrentUserInfo: () => dispatch(fetchCurrentUserInfo()),
    forgotPassword: (data) => dispatch(forgotPassword(data)),
    clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Login));
