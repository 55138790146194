import React, {useEffect, useState} from "react";
import ModalConfirmation from "../../../../helpers/ModalConfirmation";
import StyledCheckbox from "../../../../helpers/StyledCheckbox";

const ModalRestoreVersion = (props) => {

    const [deleteVersions, setDeleteVersions] = useState(false);

    useEffect(() => {
        if (deleteVersions) {
            setDeleteVersions(false)
        }
    }, [props.version]);

    return (
        <ModalConfirmation
            show={props.show}
            onHide={props.onHide}
            onSubmit={() => props.onSave(deleteVersions)}
            title={'Confirm Restore Version'}
            body={(
                <div>
                    <div>Warning: You are about to restore previous version. Please Confirm.</div>
                    <div className="modal-restore-version-checkbox">
                        <StyledCheckbox
                            checked={deleteVersions}
                            changeSelect={() => setDeleteVersions(!deleteVersions)}/>
                        <span className="modal-restore-version-delete">Delete all newer versions?</span>
                    </div>
                </div>
            )}
            cancelText={'Cancel'}
            submitText={'Yes, Restore'}
        />
    )
};

export default ModalRestoreVersion;