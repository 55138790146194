import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import InsertInfoblockModal from '../modals/InsertInfoblockModal';
import {faTrashAlt, faPlusCircle, faPlus, faLock} from "@fortawesome/free-solid-svg-icons/index";
import AddInfoblockModal from "../modals/AddInfoblockModal";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AcceptDeclineModal from "../modals/AcceptDeclineModal";

const inisialState = {
    insertShow: false,
    addShow: false,
    lockShow: false,
    deleteShow: false,
}

class InfoblockActions extends Component {
    state = {
        ...inisialState
    };

    insertBlock = () => this.setState({insertShow: true});
    addBlock = () => this.setState({addShow: true});
    deleteBlock = () => this.setState({deleteShow: true})

    hideModals = () => this.setState({...inisialState});

    render() {
        const {insertShow, addShow, deleteShow} = this.state;
        const {onDeleteSelected, onInsertBlock, onAddBlock, onLockSelected, user} = this.props;

        return (
            <div className={`infoblock-actions`}>
                    <span onClick={this.insertBlock}><FontAwesomeIcon icon={faPlusCircle} className={'plus-circle-icon'}/></span>
                    <span onClick={this.addBlock}><FontAwesomeIcon icon={faPlus} className={'plus-icon'}/></span>
                    {user && user.role === 'Admin' && <span onClick={onLockSelected}><FontAwesomeIcon icon={faLock} className={'lock-alt-icon'}/></span>}
                    <span onClick={this.deleteBlock}><FontAwesomeIcon icon={faTrashAlt} className={'trash-icon'}/></span>

                    <InsertInfoblockModal
                        show={insertShow}
                        onHide={this.hideModals}
                        onSave={(selected, singleInfoblock) => {
                            this.hideModals();
                            if (singleInfoblock.id !== undefined) {
                                onInsertBlock(selected, singleInfoblock)
                            }
                        }}
                    />

                    <AddInfoblockModal
                        show={addShow}
                        onHide={this.hideModals}
                        onSave={(title, content) => {this.hideModals(); onAddBlock(title, content)}}
                    />

                    <AcceptDeclineModal
                        show={deleteShow}
                        action={'delete'}
                        onHide={this.hideModals}
                        onSave={() => {this.hideModals(); onDeleteSelected()}}
                    />
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.auth.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InfoblockActions));