import React, {PureComponent} from 'react';

const CanvasJSReact = require('../../../libs/canvasjs/canvasjs.react').default;
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;


export default class SimpleBarChart extends PureComponent {

    addSymbols(e){
        const suffixes = ["", "K", "M", "B"];
        var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
        if(order > suffixes.length - 1)
            order = suffixes.length - 1;
        var suffix = suffixes[order];
        return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
    }

    render() {
        const {translations} = this.props;

        const options = {
            animationEnabled: true,
            theme: "light2",
            width: 500,
            axisY: {
                suffix: "%",
                maximum: 100,
                interval: 25,
                includeZero: false,
                labelAutoFit: true,
                tickLength: 30,
                tickColor: "transparent",
                gridDashType: "dot",
                gridThickness: 2,
                crosshair: {
                    enabled: true,
                    color: "#4bacc6",
                    labelBackgroundColor: "#4bacc6",
                    labelFormatter: function(e) {
                        return `${Math.round(e.value)}  %`;
                    },
                    labelFontStyle: "italic",
                }

            },
            axisX: {
                interval: 1,
                tickLength: 10,
                tickColor: "transparent",
                lineThickness: 10,
                lineColor: "white"

            },
            data: [{
                type: "bar",
                dataPoints: translations && translations.map( (item) => (
                    {
                        y: item.percent,
                        label: item.language
                    }
                ))
            }]
        }

        return (
            <CanvasJSChart options = {options}/>
        );
    }
}
