import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import TermFound from "./TermFound";
import SpinnerDownload from "../../helpers/SpinnerDownload";

const TermsFound = ({terms, hasMoreTerms, loadMoreTerms, isFetching, findByLabel}) => (
    <div className='found-terms-wrapper'>
        <InfiniteScroll
            pageStart={0}
            hasMore={hasMoreTerms}
            loadMore={() => loadMoreTerms()}
            loader={<div key={'spinner'} className="infinite-scroll-loader"><SpinnerDownload/></div>}
        >

            {terms.length !== 0 ? terms.map((term, index) => (
                    <TermFound
                        key={index}
                        termId={term.id}
                        term={term}
                        findByLabel={(data) => findByLabel(data)}
                    />
                )) :

                <div className='no_found-terms-wrapper'>
                    {(
                        <div className='block-no_found'>No such terms found</div>
                    )}
                </div>
            }
        </InfiniteScroll>
    </div>
);

export default TermsFound;
