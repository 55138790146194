import {Modal} from 'react-bootstrap';
import React, {useState} from "react";
import * as PropTypes from "prop-types";

const ModalNewVersionNotes = ({isOpen, onCancelClick, onSendClick}) => {

    const [value, setValue] = useState('');

    const cancel = () => {
        setValue('');
        onCancelClick();
    };

    const send = (value) => {
        setValue('');
        onSendClick(value);
    };

    return (
        <Modal show={isOpen} className="modal-dialog-translation-reject"
               size="lg" centered
               onHide={() => cancel()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>Create New Version</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Version Notes (optional)</p>
                <textarea value={value} onChange={(e) => setValue(e.target.value)} placeholder="Describe what is planned to change"/>
            </Modal.Body>
            <Modal.Footer>
                <div onClick={() => cancel()} className="cancel-button">Cancel</div>
                <div onClick={() => send(value)} className="console-button">Send</div>
            </Modal.Footer>
        </Modal>
    )
};

ModalNewVersionNotes.propTypes = {
    isOpen: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onSendClick: PropTypes.func
};

export default ModalNewVersionNotes;