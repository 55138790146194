import React, {Component} from "react";
import {connect} from "react-redux";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {managementConsolePaging} from "../../../configs/config";
import {
    bulkDeleteTemplates, bulkEditTemplates, deleteAdminTemplate,
    getAdminTemplates
} from "../../../store/console/actions";
import CustomFilterTable from "../../../helpers/CustomFilterTable";
import Paginator from "../../../helpers/Paginator";
import TemplateRow from "./TemplateRow";

class Templates extends Component {
    state = {
        errors: [],
        searchParams: {
            q: '',
            q_type: '',
            order_by: '',
            order_asc: 'asc',
            page: 1,
            per_page: managementConsolePaging
        },
        selected: []
    };

    _tableColumns = [
        {style: {width: '3%'}, className: 'console-select', checkboxesTitle: true},
        {style: {width: '15%'}, title: 'Code', titleSlug: 'code', className: 'console-template-code', input: {type: 'text', name: 'code'}},
        {style: {width: '9%'}, title: 'Title', titleSlug: 'title', className: 'console-template-title', input: {type: 'text', name: 'title'}},
        {style: {width: '16%'}, title: 'Type', titleSlug: 'type', className: 'console-template-type', input: {type: 'text', name: 'type'}},
        {style: {width: '11%'}, title: 'Host Term', titleSlug: 'host', className: 'console-template-host', input: {type: 'text', name: 'host'}},
        {style: {width: '11%'}, title: 'Info Blocks', titleSlug: 'infoblocks', className: 'console-template-infoblocks', input: {type: 'text', name: 'infoblocks'}},
        {style: {width: '11%'}, title: 'Status', titleSlug: 'status', className: 'console-template-status', input: {type: 'text', name: 'status'}},
        {style: {width: '12.5%'}, title: 'Date Created', titleSlug: 'created', className: 'console-template-created', input: {type: 'date', name: 'created'}},
        {style: {width: '12.5%'}, title: 'Date Modified', titleSlug: 'modified', className: 'console-template-modified', input: {type: 'date', name: 'modified'}},
        {style: {width: '12.5%'}, title: 'Created By', titleSlug: 'created_by', className: 'console-template-modified_by', input: {type: 'text', name: 'created_by'}},
        {style: {width: '3%'}, title: '', className: 'console-template-actions'}
    ];

    async componentDidMount() {
        await this.props.getAdminTemplates(this.state.searchParams)
    }

    _paginatorChangedPage = async (data) => {
        const {searchParams} = this.state;

        searchParams.page = data.selected + 1;
        this.setState({searchParams});

        await this.props.getAdminTemplates(searchParams);
    };

    onArrowClick = (order_by, isAsc) => {
        this.setState(({searchParams}) => ({
            searchParams: {
                ...searchParams,
                order_by,
                order_asc: isAsc ? 'asc' : 'desc'
            }
        }), () => {this.props.getAdminTemplates(this.state.searchParams)})
    };

    onColumnFiltersChanges = (filters) => {
        this.setState(({searchParams}) => ({
            searchParams: {
                ...searchParams,
                q_type: filters.type,
                q: filters.value

            }
        }), () => {this.props.getAdminTemplates(this.state.searchParams)})
    };

    deleteAction = async (item) => {
        await this.props.deleteAdminTemplate(item.id)
        await this.props.getAdminTemplates(this.state.searchParams)
    };

    duplicateTemplates = async () => {
        await this.props.bulkEditTemplates({templates: [...this.state.selected], duplicate: true})
        await this.props.getAdminTemplates(this.state.searchParams)
    };

    attachTemplates = async () => {

    };

    deleteSelectedTemplates = async () => {
        await this.props.bulkDeleteTemplates({templates: [...this.state.selected]});
        this.setState({selected: []})
        await this.props.getAdminTemplates(this.state.searchParams)
    };


    render() {
        const {templates} = this.props;

        const _tableFooterColumns = [{
            colspan: this._tableColumns.length,
            component: <Paginator
                pageCount={this.props.totalTemplates / this.state.searchParams.per_page}
                pageChanged={this._paginatorChangedPage}
                forcePage={this.state.searchParams.page - 1}
                className={"console-paginator"}
            />
        }];

        return (
            <div className="console-container-full console-terms-container">
                <div className="console-header row no-gutters">
                    <div className="console-header-lp" />
                    <div className="console-header-actions-line">
                        <div className="console-header-selected">
                            <span className="selected-rows">{this.state.selected.length} Selected</span>
                            <span className="delete-selected" onClick={this.duplicateTemplates}>
                                <FontAwesomeIcon icon={faTrashAlt} className={'lock-link'} /> duplicate
                            </span>
                            <span className="delete-selected" onClick={this.attachTemplates}>
                                <FontAwesomeIcon icon={faTrashAlt} className={'unlock-link'} /> attach to
                            </span>
                            <span className="delete-selected" onClick={this.deleteSelectedTemplates}>
                                <FontAwesomeIcon icon={faTrashAlt} className={'delete-link'} /> delete
                            </span>
                        </div>
                        <div>
                            <button className="console-button">
                                <Link to={'/management-console/templates/add'}>Add template</Link>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="console-body">
                    <CustomFilterTable
                        className={"console-table"}
                        columns={this._tableColumns}
                        footer={_tableFooterColumns}
                        items={templates}
                        onArrowClick={this.onArrowClick}
                        onFiltersChange={this.onColumnFiltersChanges}
                        deleteAction={this.deleteAction}
                        onChangeSelect={(selected) => this.setState({selected})}
                    >
                        <TemplateRow/>
                    </CustomFilterTable>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    templates: state.managementConsole.templates,
    totalTemplates: state.managementConsole.totalTemplates
});

const mapDispatchToProps = (dispatch) => ({
    getAdminTemplates: (data) => dispatch(getAdminTemplates(data)),
    deleteAdminTemplate: (id) => dispatch(deleteAdminTemplate(id)),
    bulkDeleteTemplates: (data) => dispatch(bulkDeleteTemplates(data)),
    bulkEditTemplates: (data) => dispatch(bulkEditTemplates(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
