import React, {Component} from "react";
import {Link} from "react-router-dom";
import StyledCheckbox from "../../../helpers/StyledCheckbox";
import ActionsWrapper from "../../../helpers/ActionsWrapper";

class LanguageRow extends Component {

    state = {
        showDeleteAction: false
    };

    setDeleteAction(showDeleteAction) {
        this.setState({
            showDeleteAction
        })
    }


    deleteItem(e, item) {
        e.preventDefault();
        e.stopPropagation();

        this.setDeleteAction(false);

        this.props.props.deleteAction(item);
    }

    render() {
        const {showDeleteAction} = this.state;
        const props = this.props;

        return (
            <tr>
                <td className={props.props.columns[0].className + '-td'}>
                    <StyledCheckbox
                        checked={props.item.checked}
                        changeSelect={() => props.props.toggleSelect(props.item.id)}/>
                </td>
                <td className={props.props.columns[1].className + '-td'}>
                    <Link to={'/management-console/languages/' + props.item.id}>{props.item.name}</Link>
                </td>
                <td className={props.props.columns[2].className + '-td'}>{props.item.english}</td>
                <td className={props.props.columns[3].className + '-td'}>{props.item.code}</td>
                <td className={props.props.columns[4].className + '-td console-delete-action-td'}>
                    <ActionsWrapper show={showDeleteAction}
                                    onClickOutside={() => this.setDeleteAction(false)}
                                    onClosedClick={() => this.setDeleteAction(true)}>
                        <span onClick={(e) => this.deleteItem(e, props.item)} className={"delete-item-action"}>
                            Delete
                        </span>
                    </ActionsWrapper>
                </td>
            </tr>
        )
    }
}

export default LanguageRow;