import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import _get from 'lodash/fp/get';
import {Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {createAdminLanguage, editAdminSingleLanguage, getAdminSingleLanguage} from "../../../store/console/actions";

class AddEditLanguage extends Component {
    state = {
        id: false,
        code: '',
        name: '',
        english: '',
        errors: [],
    };

    _changeValue(e, field) {
        this.setState({
            [field]: e.target.value
        });
    }

    publishLanguage() {
        const {code, name, english} = this.state;
        const {singleLanguage} = this.props;

        if (singleLanguage.id !== undefined) {
            this.props.editAdminSingleLanguage(singleLanguage.id, {code, name, english})
                .then(() => this.props.history.push('/management-console/languages'))
                .catch((response) => {
                    this.setState({
                        errors: response.payload.response.data.errors
                    })
                });
        } else {
            this.props.createAdminLanguage({code, name, english})
                .then(() => this.props.history.push('/management-console/languages'))
                .catch((response) => {
                    const errors = _get('payload.response.data.errors', response) || [];
                    this.setState({ errors })
                });
        }
    }

    ohHideErrorsModal() {
        this.setState({errors: []})
    }


    componentDidMount() {
        const {match: {params}} = this.props;

        if (params.languageId !== undefined) {
            this.props.getAdminSingleLanguage(params.languageId)
                .then((response) => {
                    this.setState({
                        id: response.payload.data.data.id,
                        code: response.payload.data.data.code,
                        name: response.payload.data.data.name,
                        english: response.payload.data.data.english
                    })
                })
        }
    }

    render() {
        const {singleLanguage} = this.props;
        const {code, name, english} = this.state;
        return (
            <>
                <div className="container-fluid">
                    <div className="row console-title">
                        <div className="col-md-12 console-title-cell">
                            <div className="col-xl-12">
                                <Link to={'/management-console/languages'} className="back-to-console">
                                    <FontAwesomeIcon icon={faChevronLeft}/>
                                    <span>Back to Console</span>
                                </Link>
                                <div>{singleLanguage.id === undefined ? 'Add' : 'Edit'} Language</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 console-container">
                            <form className="add-term-form">
                                <div className="row">
                                    <div className="col-md-12 box">
                                        <div className="row">
                                            <div className="col-md-12 form-group">
                                                <label>Language Code<span className="input-required">*</span></label>
                                                <input className="inputTerm"
                                                       type={"text"}
                                                       name={"code"}
                                                       value={code}
                                                       onChange={(e) => this._changeValue(e, 'code')}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md form-group">
                                                <label>Name<span className="input-required">*</span></label>
                                                <input name={"name"}
                                                       type={"text"}
                                                       className="inputTerm"
                                                       value={name}
                                                       onChange={(e) => this._changeValue(e, 'name')}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md form-group">
                                                <label>English name<span className="input-required"></span></label>
                                                <input name={"name"}
                                                       type={"text"}
                                                       className="inputTerm"
                                                       value={english}
                                                       onChange={(e) => this._changeValue(e, 'english')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="submit-add-term">
                                        <button type="button"
                                                onClick={() => this.publishLanguage()}
                                                className="console-button publish-label-button"
                                                disabled={!code || !name}>
                                            Save
                                        </button>

                                        <Modal
                                            show={this.state.errors.length !== 0}
                                            onHide={() => this.ohHideErrorsModal()}
                                        >
                                            <Modal.Body>
                                                {this.state.errors.map((item, index) => (
                                                    <div key={index}>{item}</div>
                                                ))}
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    singleLanguage: state.managementConsole.singleLanguage
});

const mapDispatchToProps = (dispatch) => ({
    createAdminLanguage: (data) => dispatch(createAdminLanguage(data)),
    getAdminSingleLanguage: (id) => dispatch(getAdminSingleLanguage(id)),
    editAdminSingleLanguage: (id, data) => dispatch(editAdminSingleLanguage(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditLanguage);
