import React, {Component} from "react";
import {FaCopy} from "react-icons/lib/fa";
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import {baseURLForLink, froala3Key} from "../../../configs/config";
import LocalNotification from "../../../helpers/LocalNotification";
import moment from "moment";

class PageTemplate extends Component {

    state = {
        extended_description: {
            text: '',
            isSaved: false,
        },
        footnotes: {
            text: '',
            isSaved: false,
        },
        acknowledgements: {
            text: '',
            isSaved: false,
        },
        newData: {},
        isClickedCite: false,
        isClickedCiteOnce: false,
    };

    inputCitation = React.createRef();

    _hendleChangeEditField = (data, name) => {
        this.setState(state => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    text: data,
                    isSaved: false
                }
            }
        })
    }

    onFocusEditField = (name) => {
        this.setState(state => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isSaved: false
                }
            }
        })
    }

    saveEditData = (name) => {
        this.setState(state => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isSaved: true
                },
                newData: {
                    ...state.newData,
                    [name]: state[name].text
                }
            }
        }, () => this.props.handleSaveChildData(this.state.newData));
    };

    createMarkup = (data) => {
        return {__html: data};
    };

    onCiteClicked = () => {
        this.setState({
            isClickedCite: true,
            isClickedCiteOnce: true
        });
        this.copyCitation()
    };

    copyCitation = () => {
        this.inputCitation.current.select();
        document.execCommand('copy');
    };

    buildCitation = () => {
        const {term} = this.props;
        const content = term.content;
        if (!term.content) {
            return '';
        }
        const citation = `BIM Dictionary (2019), ${content && content.title ? `${content.title},` : ''} version ${term.version ? term.version.number : 1}. `
            + `${baseURLForLink}${content && content.language ? content.language : 'en'}/${term.slug}/${term.version ? term.version.number : 1}. `
            + `last accessed ${moment().format('LL')}`;
        return citation.replace(/<\s*[^>]*>/g, '')
    };

    onCiteNotificationClose = () => {
        this.setState({
            isClickedCite: false
        })
    };

    componentWillUpdate(nextProps) {
        if (nextProps.clearChildrenState) {
            this.setState(state => {
                return {
                    ...state,
                    extended_description: {
                        text: '',
                        isSaved: false,
                    },
                    footnotes: {
                        text: '',
                        isSaved: false,
                    },
                    acknowledgements: {
                        text: '',
                        isSaved: false,
                    },
                }
            })
        }
    }

    render() {
        const {term, isEditing, showTranslation} = this.props;
        const {extended_description, footnotes, acknowledgements, isClickedCite} = this.state;
        const content = showTranslation ? term.my_translation : term.content;
        return (
            <>
                <div className="wrapper-term-body">
                    <div className="body-box">
                        <div className="terms-title">
                            <span>Extended Description</span>
                            {/*<FontAwesomeIcon icon={'link'}/>*/}
                        </div>
                        <div className="current-term-description edit_term_list-description margin-none fr-view">
                            {content && content.extended_description && (
                                <p className="description-body margin-none"
                                   dangerouslySetInnerHTML={this.createMarkup(content.extended_description.extended_description)}>

                                </p>
                            )}

                            {isEditing && (
                                <div className={extended_description.isSaved ? 'edit_text-saved' : ''}>
                                    <FroalaEditor
                                        tag='div'
                                        config={{
                                            htmlUntouched: true,
                                            fullPage: true,
                                            htmlRemoveTags: [],
                                            key: froala3Key,
                                            toolbarInline: true,
                                            charCounterCount: false,
                                            imageUploadParam: 'image_param',
                                            imageUploadURL: '/api/froala_upload_image',
                                            imageUploadMethod: 'POST',
                                            direction: 'ltr',
                                            attribution: false,
                                            indentMargin: 0,
                                            editorClass: 'editor-header-title',
                                            placeholderText: 'Enter Your Translation',
                                            toolbarButtons: {
                                                moreText: {
                                                    buttons: [
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strikeThrough',
                                                        'subscript',
                                                        'superscript',
                                                        'fontFamily',
                                                        'fontSize',
                                                        'textColor',
                                                        'backgroundColor',
                                                        'inlineClass',
                                                        'inlineStyle',
                                                        'clearFormatting'
                                                    ],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreParagraph: {
                                                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreRich: {
                                                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreMisc: {
                                                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                                                    align: 'right',
                                                    buttonsVisible: 2
                                                }
                                            }
                                        }}
                                        model={extended_description.text ? extended_description.text : (term.my_translation.extended_description ? term.my_translation.extended_description.extended_description : '')}
                                        onModelChange={(...data) => {
                                            this._hendleChangeEditField(data[0], 'extended_description');
                                        }}
                                    />
                                    <button disabled={!extended_description.text || extended_description.isSaved}
                                            className='save-edit-block'
                                            onClick={() => this.saveEditData('extended_description')}>save
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
                <div className="wrapper-term-footer">
                    <div className="footer-box">
                        <div className="terms-title">
                            <span>Footnotes </span>
                            {/*<FontAwesomeIcon icon={'link'}/>*/}
                        </div>
                        {content && content.extended_description && (
                            <p dangerouslySetInnerHTML={this.createMarkup(content.extended_description.footnotes)}></p>
                        )}

                        {isEditing && (
                            <div className="current-term-description edit_term_list-description">
                                <div className={footnotes.isSaved ? 'edit_text-saved' : ''}>
                                    <FroalaEditor
                                        tag='div'
                                        config={{
                                            htmlUntouched: true,
                                            fullPage: true,
                                            htmlRemoveTags: [],
                                            key: froala3Key,
                                            toolbarInline: true,
                                            imageUploadParam: 'image_param',
                                            imageUploadURL: '/api/froala_upload_image',
                                            imageUploadMethod: 'POST',
                                            charCounterCount: false,
                                            direction: 'ltr',
                                            attribution: false,
                                            indentMargin: 0,
                                            editorClass: 'editor-header-title',
                                            placeholderText: 'Enter Your Translation',
                                            toolbarButtons: {
                                                moreText: {
                                                    buttons: [
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strikeThrough',
                                                        'subscript',
                                                        'superscript',
                                                        'fontFamily',
                                                        'fontSize',
                                                        'textColor',
                                                        'backgroundColor',
                                                        'inlineClass',
                                                        'inlineStyle',
                                                        'clearFormatting'
                                                    ],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreParagraph: {
                                                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreRich: {
                                                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreMisc: {
                                                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                                                    align: 'right',
                                                    buttonsVisible: 2
                                                }
                                            }
                                        }}
                                        model={footnotes.text ? footnotes.text : (term.my_translation.extended_description ? term.my_translation.extended_description.footnotes : '')}
                                        onModelChange={(...data) => {
                                            this._hendleChangeEditField(data[0], 'footnotes');
                                        }}
                                    />
                                    <button disabled={!footnotes.text || footnotes.isSaved} className='save-edit-block'
                                            onClick={() => this.saveEditData('footnotes')}>save
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="footer-box">
                        <div className="terms-title">
                            <span>Acknowledgements</span>
                            {/*<FontAwesomeIcon icon={'link'}/>*/}
                        </div>
                        {content && content.extended_description && (
                            <p dangerouslySetInnerHTML={this.createMarkup(content.extended_description.acknowledgements)}></p>
                        )}

                        {isEditing && (
                            <div className="current-term-description edit_term_list-description">
                                <div className={acknowledgements.isSaved ? 'edit_text-saved' : ''}>
                                    <FroalaEditor
                                        tag='div'
                                        config={{
                                            htmlUntouched: true,
                                            fullPage: true,
                                            htmlRemoveTags: [],
                                            key: froala3Key,
                                            toolbarInline: true,
                                            imageUploadParam: 'image_param',
                                            imageUploadURL: '/api/froala_upload_image',
                                            imageUploadMethod: 'POST',
                                            charCounterCount: false,
                                            direction: 'ltr',
                                            attribution: false,
                                            indentMargin: 0,
                                            editorClass: 'editor-header-title',
                                            placeholderText: 'Enter Your Translation',
                                            toolbarButtons: {
                                                moreText: {
                                                    buttons: [
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strikeThrough',
                                                        'subscript',
                                                        'superscript',
                                                        'fontFamily',
                                                        'fontSize',
                                                        'textColor',
                                                        'backgroundColor',
                                                        'inlineClass',
                                                        'inlineStyle',
                                                        'clearFormatting'
                                                    ],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreParagraph: {
                                                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreRich: {
                                                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreMisc: {
                                                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                                                    align: 'right',
                                                    buttonsVisible: 2
                                                }
                                            }
                                        }}
                                        model={acknowledgements.text ? acknowledgements.text : (term.my_translation.extended_description ? term.my_translation.extended_description.acknowledgements : '')}
                                        onModelChange={(...data) => {
                                            this._hendleChangeEditField(data[0], 'acknowledgements');
                                        }}
                                    />
                                    <button disabled={!acknowledgements.text || acknowledgements.isSaved}
                                            className='save-edit-block'
                                            onClick={() => this.saveEditData('acknowledgements')}>save
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="terms-title mgTop">
                            <span>Cite this page as: </span>
                        </div>
                        <div className={'citation-notification-wrapper'}>
                            <LocalNotification
                                text={'Citation copied to clipboard'}
                                show={isClickedCite}
                                onClose={() => this.onCiteNotificationClose()}/>
                        </div>
                        <div className="cite-flex">
                            <div title="Copy citation">
                                <FaCopy className="cite-icon" onClick={() => this.onCiteClicked()}/>
                            </div>
                            <p>
                                {this.buildCitation()}
                            </p>
                            <input type="text"
                                   ref={this.inputCitation}
                                   className="hidden-input"
                                   value={this.buildCitation()}
                                   readOnly/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PageTemplate
