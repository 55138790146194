import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Container} from 'react-bootstrap';
import {clearAdminTerms, getAdminTerms} from "../../../../store/console/actions";
import AddTerms from "../../../../helpers/AddTerms";

class AddTermsToList extends Component {
    includedTerms = [];

    componentDidMount() {
        const {clearAdminTerms, getAdminTerms} = this.props;

        clearAdminTerms();

        getAdminTerms({all: true}, true);
    }

    onSave() {
        const {terms} = this.props;
        if (terms.length === 0) {
            return;
        }

        const termsToAdd = terms.filter((item) => this.includedTerms.indexOf(item.id) !== -1);
        this.props.onSave(termsToAdd);
    }

    render() {

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Terms To List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="manage-terms-modal-container">
                        {this.props.terms.length === 0
                            ? <div className="manage-terms-modal-loading" style={{width: '100%', height: '100%'}}>
                                Loading...
                            </div>
                            : <AddTerms
                                terms={this.props.terms}
                                includedTerms={this.props.includedTerms.map(term => term.id)}
                                termToExclude={this.props.termToExclude}
                                onIncludedTermsUpdate={(includedTerms) => {
                                    this.includedTerms = includedTerms;
                                }}/>}
                    </Container>
                </Modal.Body>
                <Modal.Footer className="team-person-save">
                    <span onClick={this.props.onHide}>Cancel</span>
                    <button className="console-button" onClick={() => this.onSave()}>Save</button>
                </Modal.Footer>
            </Modal>
        )
    }

}

const mapStateToProps = (state) => ({
    terms: state.managementConsole.items,
});

const mapDispatchToProps = { getAdminTerms, clearAdminTerms};

export default connect(mapStateToProps, mapDispatchToProps)(AddTermsToList);