import React, {useEffect, useState} from "react";
import {FaPencil, FaTrash} from "react-icons/lib/fa";


function DocumentTemplate(props) {
    const {template, parentCallback} = props;
    const [isEdit, setIsEdit] = useState(false);
    const [title, setTitle] = useState(template.title ? template.title : 'DocumentTemplate title');
    return (
        <div className="wrapper-manage-terms">
            <div className="template-box">
                {isEdit ?
                    <input type='text'
                           name='title'
                           value={title}
                           className={`inline-edit-template-title`}
                           onChange={editTemplate}/>
                    :
                    <span>{title}</span>
                }
                <FaPencil className='edit-icon' onClick={enableEdit}/>
            </div>
            {isEdit ?
                <div className={"action-buttons"}>
                    <button className={`cancel-infoblock-section`} onClick={resetDefault}>
                        Cancel
                    </button>
                    <button className={`save-infoblock-section`} onClick={saveChanges}>
                        Save
                    </button>
                </div>
                :
                ''
            }
        </div>
    );

    function enableEdit(event) {
        setIsEdit(true);
    }

    function resetDefault(event) {
        setTitle(template.title ? template.title: 'DocumentTemplate title');
        setIsEdit(false);
    }

    function saveChanges(event) {
        setIsEdit(false);
    }

    function editTemplate(event) {
        const value = event.target.value;
        setTitle(value);
        parentCallback('template', {...template,
            title: value
        });
    }
}

export default DocumentTemplate