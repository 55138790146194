import React from 'react'
import moment from "moment";


const TeamsList = ({ name, editors, coEditors, terms, percent, reviewers, lastActive }) => {
    return (
        <div className="contributors-teams-list">
            <div className="team-name">
                {name}
            </div>
            <div className="team-editors">
                <div>
                    {editors && editors.length !==0 && <div className='user-role'>Editors(s):&nbsp;</div>}
                    {editors && editors.map( (editor, index) => <div className="person" key={index}>
                        {editor.name}
                        &#8195;<span>{`${editor.country_name}, ${editor.city_name}, ${editor.affiliation}`}</span>
                    </div>)}
                </div>
                <div>
                    {coEditors && coEditors.length !==0 && <div className='user-role'>Co-Editor(s):&nbsp;</div>}
                    {coEditors && coEditors.map( (coEditor,index) => <div className="person" key={index}>
                        {coEditor.name}
                        &#8195;<span>{`${coEditor.country_name}, ${coEditor.city_name}, ${coEditor.affiliation}`}</span>
                    </div>)}
                </div>
                <div>
                    {reviewers && reviewers.length !==0 && <div className='user-role'>Reviewer(s):&nbsp;</div>}
                    {reviewers && reviewers.map( (reviewer,index) => <div className="person" key={index}>
                        {reviewer.name}
                        &#8195;<span>{`${reviewer.country_name}, ${reviewer.city_name}, ${reviewer.affiliation}`}</span>
                    </div>)}
                </div>
            </div>
            <div className="term-translated">
                <div className="title">{terms ? terms : '0'}</div>
            </div>
            <div className="term-percent">
                <div className="title">{percent ? percent : '0'}%</div>
            </div>
            <div className="team-last_active">
                {lastActive
                    ? moment(lastActive).format('LL')
                    : 'no data'
                }
            </div>
        </div>
    )
}

export default TeamsList