import React from 'react';
import Select from 'react-select';

class SelectForm extends React.Component {
  state = {
    selectedOption: null,
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });

    if (this.props.onChange !== undefined)
        this.props.onChange(selectedOption);
  };

  render() {
    const { selectedOption } = this.props;

    return (
        <div className={this.props.className}>
            <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={this.props.options}
            />
        </div>
    );
  }
}

export default SelectForm