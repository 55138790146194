import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {Container, Row, Col} from "reactstrap";
import { getCommonData, updateCommonData } from "../../store/common/actions";
import FroalaEditor from "react-froala-wysiwyg";
import {froala3Key} from "../../configs/config";


class MorePages extends Component {

    state = {
        newCommonData: this.props.commonData ? this.props.commonData : '',
    }
    saveEditData = () => {
        const path = this.props.match.params.pagePath;
        const data = this.state.newCommonData;
        this.props.updateCommonData(path, {content: data})
        this.props.history.push(`/${path}`);
    }
    createMarkup = (data) => {
        return {__html: data};
    }

    componentDidMount() {
        const pageName = this.props.match.params.pagePath;
        this.props.getCommonData(pageName);
    }

    handleModelChange = (...data) => {
        this.setState({newCommonData: data[0]});
    }


    render() {
        const {user, commonData} = this.props;
        const { newCommonData } = this.state;
        const pagePath = this.props.match.params.pagePath;

        return (
            <>

                {user.role === 'Admin' && <div className='term-switch-btn-wrapper main-term-page more-pages-wrapper'>
                    <div className='switch-btn-wrapper'>
                        <Link to={`/${pagePath}`} className="term-view ">
                            View
                        </Link>
                        <Link to={`/${pagePath}/edit`} className="term-edit switch-btn-active">
                            Edit
                        </Link>
                    </div>
                </div>}

                <Container className={'switch-term-container more-page-container'}>
                    <Row className={'current-term-row'}>
                        <Col
                            className={'current-term-col edit-page-col edit-common-wrapper'}
                            xl={12}
                        >
                            <FroalaEditor
                                tag='div'
                                config={{
                                    htmlUntouched: true,
                                    fullPage: true,
                                    htmlRemoveTags: [],
                                    key: froala3Key,
                                    toolbarInline: true,
                                    charCounterCount: false,
                                    imageUploadParam: 'image_param',
                                    imageUploadURL: '/api/froala_upload_image',
                                    imageUploadMethod: 'POST',
                                    editorClass: 'more-pages-editor',
                                    toolbarVisibleWithoutSelection: true,
                                    attribution: false,
                                    indentMargin: 0,
                                    toolbarButtons: {
                                        moreText: {
                                            buttons: [
                                                'bold',
                                                'italic',
                                                'underline',
                                                'strikeThrough',
                                                'subscript',
                                                'superscript',
                                                'fontFamily',
                                                'fontSize',
                                                'textColor',
                                                'backgroundColor',
                                                'inlineClass',
                                                'inlineStyle',
                                                'clearFormatting'
                                            ],
                                            align: 'left',
                                            buttonsVisible: 0
                                        },
                                        moreParagraph: {
                                            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                            align: 'left',
                                            buttonsVisible: 0
                                        },
                                        moreRich: {
                                            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                            align: 'left',
                                            buttonsVisible: 0
                                        },
                                        moreMisc: {
                                            buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                                            align: 'right',
                                            buttonsVisible: 2
                                        }
                                    }
                                }}
                                model={newCommonData ? newCommonData : (commonData ? commonData : 'enter your content' )}
                                onModelChange={this.handleModelChange}
                            />
                            <button className='save-edit-block' onClick={this.saveEditData}>save</button>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    commonData: state.common.commonData
});

const mapActionsToProps = (dispatch) => ({
    getCommonData: (pageName) => dispatch(getCommonData(pageName)),
    updateCommonData: (pageName, data) => dispatch(updateCommonData(pageName, data)),
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(MorePages));
