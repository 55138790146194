import { applyMiddleware, compose, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { requestsPromiseMiddleware } from 'redux-saga-requests';
import {createLogger} from "redux-logger";
import rootSaga from "./sagas";
import authReducer from "./auth/reducer";
import termsReducer from "./terms/reducer";
import consoleReducer from './console/reducer';
import dashboardReducer from "./dashboard/reducer";
import commonReducer from "./common/reducer";
import contributors from "./contributors/reducer";
import reviewersDashboardReducer from "./reviewers-dashboard/reducer";
import infoblocksReducer from "./infoblocks/reducer";
// import { refreshTokenMiddleware } from "../middlewares/refresh-token";

const sagaMiddleware = createSagaMiddleware();
const promiseMiddleware = requestsPromiseMiddleware({
  auto: true // if you with to promisify all request actions without explicit meta.asPromise true
});
const logger = createLogger({collapsed: true})

const rootReducer = combineReducers({
    auth: authReducer,
    terms: termsReducer,
    managementConsole: consoleReducer,
    dashboard: dashboardReducer,
    common: commonReducer,
    contributors: contributors,
    reviewersDashboard: reviewersDashboardReducer,
    infoblocks: infoblocksReducer
});

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(
            promiseMiddleware,
            logger,
            sagaMiddleware,
            // refreshTokenMiddleware
        ),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

sagaMiddleware.run(rootSaga);

export default store;
