import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

let timeout = null;

const GdprBlock = () => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('acceptGdpr') !== 'true' && !timeout) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                setShow(true)
            }, 10000)
        }
    }, [show]);

    if (!show) {
        return null;
    }

    return (
        <div className="gdpr-block-container">
             <div>
                 The BIM Dictionary uses cookies to enhance user experience. By continuing to use this site,<br/>
                 you acknowledge that you've read and agree to
                  our <Link to={'/terms-of-use'} target="_blank">Terms of use</Link> and <Link to={'/privacy-policy'} target="_blank">Privacy Policy</Link>.
             </div>
            <div className={'console-button'} onClick={() => closeGdprBlock(setShow)}>Got it!</div>
        </div>
    )
};

const closeGdprBlock = (setShow) => {
    localStorage.setItem('acceptGdpr', 'true');
    setShow(false)
};

export default GdprBlock;