import {Component} from "react";
import React from "react";
import {getAdminTerms} from "../store/console/actions";
import {connect} from "react-redux";
import SpinnerDownload from "./SpinnerDownload";
import AddTermsDnD from "./AddTermsDnD";


class AddTerms extends Component {

    state = {
        isFetchingTerms: true,
        includedTerms: [],
        excludedTerms: [],
        termsToInclude: [],
        termsToExclude: [],
        selectAllToInclude: false,
        selectAllToExclude: false,
        includedFilter: '',
        excludedFilter: '',
        showExcludedFilter: false,
        showIncludedFilter: false,
        draggingNotActiveElement: 0,
        lastSelectedExcludedElementIndex: -1,
        lastSelectedIncludedElementIndex: -1,
        draggingFrom: ''
    };

    _selectAllToInclude = () => {
        const {selectAllToInclude, excludedTerms} = this.state;

        this.setState({
            termsToInclude: selectAllToInclude === false ? [...excludedTerms] : [],
            selectAllToInclude: !selectAllToInclude
        });
    };

    _selectAllToExclude = () => {
        const {selectAllToExclude, includedTerms} = this.state;

        this.setState({
            termsToExclude: selectAllToExclude === false ? [...includedTerms] : [],
            selectAllToExclude: !selectAllToExclude
        });
    };

    _includeSelected = () => {
        const {termsToInclude, includedTerms, excludedTerms} = this.state;

        this.setState({
            includedTerms: [...includedTerms, ...termsToInclude].sort((a, b) => a - b),
            excludedTerms: excludedTerms.filter(id => !termsToInclude.includes(id)),
            termsToInclude: [],
            termsToExclude: [],
            selectAllToInclude: false,
            selectAllToExclude: false
        }, this.onIncludedTermsUpdate)
    };

    _includeOne = () => {
        const {includedTerms, excludedTerms, draggingNotActiveElement} = this.state;

        this.setState({
            includedTerms: [...includedTerms, draggingNotActiveElement].sort((a, b) => a - b),
            excludedTerms: excludedTerms.filter(id => draggingNotActiveElement !== id),
            termsToInclude: [],
            termsToExclude: [],
            selectAllToInclude: false,
            selectAllToExclude: false,
            draggingNotActiveElement: 0
        }, this.onIncludedTermsUpdate)
    };

    _excludeSelected = () => {
        const {termsToExclude, excludedTerms, includedTerms} = this.state;

        this.setState({
            includedTerms: includedTerms.filter(id => !termsToExclude.includes(id)),
            excludedTerms: [...excludedTerms, ...termsToExclude].sort((a, b) => a - b),
            termsToInclude: [],
            termsToExclude: [],
            selectAllToInclude: false,
            selectAllToExclude: false
        }, this.onIncludedTermsUpdate)
    };

    _excludeOne = () => {
        const {excludedTerms, includedTerms, draggingNotActiveElement} = this.state;

        this.setState({
            includedTerms: includedTerms.filter(id => draggingNotActiveElement !== id),
            excludedTerms: [...excludedTerms, draggingNotActiveElement].sort((a, b) => a - b),
            termsToInclude: [],
            termsToExclude: [],
            selectAllToInclude: false,
            selectAllToExclude: false
        }, this.onIncludedTermsUpdate)
    };

    onIncludedTermsUpdate = () => {
        this.props.onIncludedTermsUpdate(this.state.includedTerms);
    };

    componentDidMount() {
        if (this.props.terms && this.props.terms.length) {
            this.initTerms();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.terms.length && this.props.terms.length)
            || (!prevProps.includedTerms.length && this.props.includedTerms.length)) {
            this.initTerms();
            this.setState({isFetchingTerms: false})
        }
    }

    initTerms = () => {
        if (this.state.includedTerms.length === 0) {
            const excludedTerms = this.props.terms.map(term => term.id);
            this.setState({
                includedTerms: this.props.includedTerms,
                excludedTerms: excludedTerms.filter(id => !this.props.includedTerms.includes(id))
            })
        }

    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.excludedTerms.length !== 0) {
            return;
        }
        const {includedTerms, termToExclude} = nextProps;
        const newExcludedTerms = this.state.excludedTerms;
        const newIncludedTerms = [];

        includedTerms.map((term) => newIncludedTerms.push(term.id));
        if (termToExclude && !newExcludedTerms.includes(termToExclude.id)) {
            newExcludedTerms.push(termToExclude.id);
            newExcludedTerms.sort((a, b) => a - b);
        }
        this.setState({
            includedTerms: newIncludedTerms,
            excludedTerms: newExcludedTerms
        })
    }


    onFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    toggleExcludedFilter = () => {
        this.setState(({showExcludedFilter}) => ({
            showExcludedFilter: !showExcludedFilter
        }))
    };

    toggleIncludedFilter = () => {
        this.setState(({showIncludedFilter}) => ({
            showIncludedFilter: !showIncludedFilter
        }))
    };

    onExcludedTermClick = (event, id, index) => {
        const {terms} = this.props;
        const {lastSelectedExcludedElementIndex, excludedTerms, termsToInclude} = this.state;
        if (event.shiftKey && lastSelectedExcludedElementIndex !== -1) {
            const selected = [];
            for (let i = lastSelectedExcludedElementIndex; i >= 0 && i < excludedTerms.length;) {
                selected.push(excludedTerms[i]);
                if (excludedTerms[i] === id) {
                    break;
                }
                if (id < excludedTerms[lastSelectedExcludedElementIndex]) {
                    i--;
                } else {
                    i++;
                }
            }
            this.setState({
                termsToInclude: [...selected]
            });
            return;
        }
        const term = termsToInclude.find(item => item === id);
        if (event.ctrlKey) {
            if (term) {
                this.setState({
                    termsToInclude: termsToInclude.filter(item => item !== id)
                })
            } else {
                this.setState({
                    termsToInclude: [...termsToInclude, terms.find(term => term.id === id).id],
                    lastSelectedExcludedElementIndex: index
                })
            }
        } else {
            this.setState({
                termsToInclude: [id],
                lastSelectedExcludedElementIndex: index,
                selectAllToInclude: false
            })
        }
    };

    onIncludedTermClick = (event, id, index) => {
        const {terms} = this.props;
        const {lastSelectedIncludedElementIndex, includedTerms, termsToExclude} = this.state;

        if (event.shiftKey && lastSelectedIncludedElementIndex !== -1) {
            const selected = [];
            for (let i = lastSelectedIncludedElementIndex; i >= 0 && i < includedTerms.length;) {
                selected.push(includedTerms[i]);
                if (includedTerms[i] === id) {
                    break;
                }
                if (id < includedTerms[lastSelectedIncludedElementIndex]) {
                    i--;
                } else {
                    i++;
                }
            }
            this.setState({
                termsToExclude: [...selected]
            });
            return;
        }
        const term = termsToExclude.find(item => item === id);
        if (event.ctrlKey) {
            if (term) {
                this.setState({
                    termsToExclude: termsToExclude.filter(item => item !== id)
                })
            } else {
                this.setState({
                    termsToExclude: [...termsToExclude, terms.find(term => term.id === id).id],
                    lastSelectedIncludedElementIndex: index
                })
            }
        } else {
            this.setState({
                termsToExclude: [id],
                lastSelectedIncludedElementIndex: index,
                selectAllToExclude: false
            })
        }
    };

    onDragStart = (isSelected, id, draggingFrom) => {
        this.setState({
            draggingNotActiveElement: isSelected ? 0 : id,
            draggingFrom: draggingFrom
        })
    };

    onDragOver = (e) => {
        e.preventDefault()
    };

    onDragEnd = () => {
        this.setState({
            draggingNotActiveElement: 0,
            draggingFrom: ''
        })
    };

    onDropToIncluded = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.draggingNotActiveElement && this.state.draggingFrom === 'excluded') {
            this._includeOne();
        } else {
            this._includeSelected();
        }
    };

    onDropToExcluded = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.draggingNotActiveElement && this.state.draggingFrom === 'included') {
            this._excludeOne();
        } else {
            this._excludeSelected();
        }
    };

    findTerm = (id) => {
        let _term = this.props.terms.find(term => term.id === id);
        if (_term === -1) _term = null;
        return _term;
    };

    render() {
        const {terms, isEdit} = this.props;
        const {
            isFetchingTerms,
            includedTerms,
            excludedTerms,
            termsToInclude,
            termsToExclude,
            selectAllToInclude,
            selectAllToExclude,
            includedFilter,
            excludedFilter,
            showIncludedFilter,
            showExcludedFilter
        } = this.state;


        const excludedTermsFiltered = excludedTerms.filter((id) => {
            if (!terms) return [];
            return terms.find(term => {
                return (
                    term.id === id
                    && term.title.toLowerCase().indexOf(excludedFilter.toLowerCase()) !== -1
                )
            });
        });

        const includedTermsFiltered = includedTerms.filter((id) => {
            if (!terms) return [];
            return terms.find(term => {
                return (
                    term.id === id
                    && term.title.toLowerCase().indexOf(includedFilter.toLowerCase()) !== -1
                )
            });
        });

        return (
            <div className="row include-terms-row">

                <AddTermsDnD
                    toggleExcludedFilter={this.toggleExcludedFilter}
                    onFilterChange={this.onFilterChange}
                    onDropToExcluded={this.onDropToExcluded}
                    onDragOver={this.onDragOver}
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                    onExcludedTermClick={this.onExcludedTermClick}
                    selectAllToIncludeAction={this._selectAllToInclude}
                    includeSelectedAction={this._includeSelected}
                    toggleIncludedFilter={this.toggleIncludedFilter}
                    onDropToIncluded={this.onDropToIncluded}
                    selectAllToExcludeAction={this._selectAllToExclude}
                    excludeSelectedAction={this._excludeSelected}
                    onIncludedTermClick={this.onIncludedTermClick}

                    showExcludedFilter={showExcludedFilter}
                    excludedFilter={excludedFilter}
                    isFetchingTerms={isFetchingTerms}
                    isEdit={isEdit}
                    excludedTermIds={excludedTermsFiltered}
                    showIncludedFilter={showIncludedFilter}
                    includedFilter={includedFilter}
                    includedTermIds={includedTermsFiltered}
                    selectAllToExclude={selectAllToExclude}
                    termsToIncludeIds={termsToInclude}
                    termsToExcludeIds={termsToExclude}
                    terms={terms}
                    selectAllToInclude={selectAllToInclude}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    terms: state.managementConsole.items,
});

const mapDispatchToProps = (dispatch) => ({
    getAdminTerms: (data) => dispatch(getAdminTerms(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTerms);