import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import {publishTermEditorTranslation, unpublishEditorTerm, updateEditorTerm} from "../../store/dashboard/actions";
import StyledCheckbox from "../../helpers/StyledCheckbox";

class GeneralListRow extends Component {

    convertObjectToString = (item) => {
        if (typeof item === 'object') {
            return ''
        } else {
            return item
        }
    };

    state = {
        status: this.convertObjectToString(this.props.item.status)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item.status !== this.props.item.status) {
            this.setState({
                status: this.convertObjectToString(this.props.item.status)
            })
        }
    }

    publish = async () => {
        const {searchTerms} = this.props;
        await this.props.updateEditorTerm(this.props.item.id)
        searchTerms && await searchTerms();
    };

    singlePublish = async () => {
        const {searchTerms, updateDimmedModal} = this.props;
        let req = await this.props.publishTermEditorTranslation(this.props.item.id);
        updateDimmedModal && updateDimmedModal(true, req.payload.data.message ? req.payload.data.message : 'Something went wrong');
        searchTerms && await searchTerms();
    }


    unpublish = () => {
        this.props.unpublishEditorTerm(this.props.item.id)
            .then(res => {
                this.setState({
                    status: 'Draft'
                })
            })
    };

    render() {
        const props = this.props;
        const {status} = this.state;


        let statusDescription, action;
        switch (status) {
            case 'Draft':
                statusDescription = 'Draft';
                action = 'Continue';
                break;
            case 'Suggested':
                statusDescription = 'Sent for review';
                action = 'Update';
                break;
            case 'Rejected':
                statusDescription = 'Reviewer requested an update';
                action = 'Update';
                break;
            case 'Reviewed':
                statusDescription = 'Approved for publication';
                action = 'Publish';
                break;
            case 'Published':
                statusDescription = 'Published';
                action = 'Unpublish';
                break;
            case 'Archived':
                statusDescription = 'Archived';
                action = '';
                break;

            case 'NewestVersionNotTranslated':
                statusDescription = 'Newest Version Not Translated';
                action = 'Translate';
                break;

            default:
                statusDescription = 'Not Translated';
                action = 'Translate';
                break;
        }

        return (
            <tr>
                <td className={props.props.columns[0].className + '-td'}>
                    <StyledCheckbox
                        checked={props.item.checked}
                        changeSelect={() => props.props.toggleSelect(props.item.id)}
                    />
                </td>
                <td className={props.props.columns[1].className + '-td'}>
                    <Link to={{
                        pathname: `/${props.item.content.language}/${props.item.slug}/${props.item.version && props.item.version.number ? props.item.version.number : 1}/edit-view-page`,
                        state: {
                            termId: props.item.id
                        }
                    }} dangerouslySetInnerHTML={{__html: props.item.content && props.item.content.title}}/>
                    {props.item.content && props.item.content.acronym &&
                    <span className="acronym">&nbsp;({props.item.content.acronym})</span>}
                </td>
                <td className={props.props.columns[2].className + '-td'}>
                    {props.item.translated_title && this.convertObjectToString(props.item.translated_title)}
                </td>
                <td className={props.props.columns[3].className + '-td'}>
                    {props.item.termSet ? props.item.termSet : 'Set A'}
                </td>
                <td className={props.props.columns[4].className + '-td'}>
                    {props.item.version.number ? props.item.version.number : '1'}
                </td>
                <td className={props.props.columns[5].className + '-td'}>
                    {statusDescription}
                </td>
                {/*<td className={props.props.columns[6].className + '-td'}>*/}
                {/*{props.item.assigned ? props.item.assigned : '24 Jan, 2019'}<FontAwesomeIcon icon={'info-circle'}/>*/}
                {/*</td>*/}
                <td className={props.props.columns[6].className + '-td'}>
                    {/*{(status === 'Reviewed' || status === 'Published')*/}
                    {(status === 'Published')
                        ? <>
                            {(this.props.user.role === 'Editor') &&
                            <span onClick={() => this.unpublish()}>Unpublish</span>}
                        </>
                        : (status == "Reviewed") ?
                            <>
                                {(this.props.user.role === 'Editor') &&
                                <span onClick={() => this.singlePublish()}>{action}</span>}
                            </>
                            :
                            <Link to={{
                                pathname: `/${props.item.content.language}/${props.item.slug}/${props.item.version && props.item.version.number ? props.item.version.number : 1}/edit-view-page`,
                                state: {
                                    termId: props.item.id
                                }
                            }}>
                                {action}
                            </Link>
                    }
                </td>
            </tr>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

const mapDispatchToProps = {
    updateEditorTerm,
    unpublishEditorTerm,
    publishTermEditorTranslation
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralListRow);
