import React, {Component} from "react";
import {fetchNotifications, updateNotification} from "../../store/dashboard/actions";
import {connect} from "react-redux";
import NotificationRow from "./NotificationRow";
import CustomTable from "../../helpers/CustomTable";
import Paginator from "../../helpers/Paginator";
import SpinnerDownload from "../../helpers/SpinnerDownload";


class NotificationsTable extends Component {

    perPage = 10;

    state = {
        page: 0,
        isFetching: true
    };

    notificationsTableColumns = [
        {
            style: {
                width: '26%'
            },
            title: 'Term Title',
            className: 'notifications-table-title'
        }, {
            style: {
                width: '26%'
            },
            title: 'Type',
            className: 'notifications-table-type'
        }, {
            style: {
                width: '24%'
            },
            title: 'Notification Date',
            className: 'notifications-table-date'
        }, {
            style: {
                width: '22%'
            },
            title: 'Action',
            className: 'notifications-table-action'
        },
    ];

    paginatorChangedPage(data) {
        const page = data.selected;
        this.setState({
            page
        });
    }

    componentDidMount() {
        this.props.fetchNotifications()
            .then(() => this.setState({isFetching: false}))
            .catch(() => this.setState({isFetching: false}))
    }


    render() {
        const {notifications, updateNotification, fetchNotifications, notificationsTotal} = this.props;
        const {page, isFetching} = this.state;

        const pageCount = notifications.length / this.perPage;
        const pageItems = notifications.slice(page * this.perPage, page * this.perPage + this.perPage);

        const tableFooterColumns = [{
            colspan: 7,
            // component: <Paginator
            //     className={"console-paginator"}
            //     pageCount={pageCount}
            //     pageChanged={(data) => this.paginatorChangedPage(data)}
            //     forcePage={page}
            // />
        }];

        const markAllAsRead = async () => {
            let ids = [];
            pageItems && pageItems.map(pageItem => {
                ids.push(pageItem.id);
            });
            await updateNotification(ids);
            fetchNotifications()
        };

        const notificationsOnPageCount = pageItems.length;

        return (
            <div className="notifications-container">
                {isFetching && (
                    <div className='spinner-main-wrapper'>
                        <SpinnerDownload/>
                    </div>
                )}

                <h2>Notifications</h2>

                {notificationsOnPageCount !== 0 ? (
                        <>
                            <CustomTable
                                className={"dashboard-notifications-table"}
                                columns={this.notificationsTableColumns}
                                items={pageItems}
                                footer={tableFooterColumns}>
                                <NotificationRow/>
                            </CustomTable>

                            <div className="mark_all-text-wrapper">
                                <span
                                    onClick={markAllAsRead}
                                    className="mark_all-span">

                                    {
                                        `Mark all listed as read  
                                        ( ${notificationsOnPageCount} / ${notificationsTotal} )`
                                    }
                                </span>
                            </div>
                        </>
                    )
                    : (
                        <div className="block-no_found">
                            There are no notifications
                        </div>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    notifications: state.dashboard.notifications,
    notificationsTotal: state.dashboard.notificationsTotal,
});

const mapDispatchToProps = (dispatch) => ({
    updateNotification: (ids) => dispatch(updateNotification(ids)),
    fetchNotifications: () => dispatch(fetchNotifications(true))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTable);