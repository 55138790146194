import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import TermCard from './TermCard';

const TopTerms = ({ topTerms }) => (
	<React.Fragment>
		<div className='most-searched-terms-row'>
			<div className='new-term-header-col'>
				<p className='term-title'>Most searched terms</p>

				<Link className='new-term-header-link' to={'/terms/search'}>
					search for more
				</Link>
			</div>
		</div>
		<div className='top-terms'>
			{topTerms && topTerms.length > 0 && topTerms.map((term, index) => (
				<TermCard key={index} term={term} />
			))}
		</div>
	</React.Fragment>
);

export default withRouter(TopTerms);
