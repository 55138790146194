export const SIGN_IN = 'SIGN_IN';
export const GOOGLE_SIGN_IN = 'GOOGLE_SIGN_IN';
export const FACEBOOK_SIGN_IN = 'FACEBOOK_SIGN_IN';
export const LINKEDIN_SIGN_IN = 'LINKEDIN_SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_OTHER_TAB = 'SIGN_OUT_OTHER_TAB';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const FETCH_CURRENT_USER_INFO = 'FETCH_CURRENT_USER_INFO';
export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
export const CLEAR_NOTIFICATION_COUNT = 'CLEAR_NOTIFICATION_COUNT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
