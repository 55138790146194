import React, {Component, useState, useRef} from 'react'
import ReactDOM from 'react-dom'
import TemplateInfoblockActions from "./TemplateInfoblockActions";
import moment from "moment";
import InfoblockCopyLink from "./InfoblockCopyLink";
import Notification from "../../../../../../helpers/Notification";
import EmbeddedTermWrapper from "../../../../../../helpers/EmbeddedTermWrapper";
import {clearEmbeddedTerm, fetchEmbeddedTerm} from "../../../../../../store/terms/actions";
import {connect} from "react-redux";

const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.getBoundingClientRect().y);
};

class InfoblockContent extends Component {
    state = {
        isCopied: false
    };

    myRef = React.createRef();

    setIsCopied = (isCopied) => {
        this.setState({
            ...this.state,
            isCopied
        })
    };

    componentDidMount() {
        if (this.props.item.title === this.props.infoblockFromLink && this.myRef.current !== null) {
            scrollToRef(this.myRef);
        }

        const items = document.querySelectorAll('.replace-topic_embedded');
        items.forEach((element, i) => {
            if (element.innerHTML)
                ReactDOM.render(<EmbeddedTermWrapper id={i} title={element.innerHTML} {...this.props} />, element);
        });
    }

    createMarkup = (data) => {
        return <span dangerouslySetInnerHTML={{__html: data}}></span>
    };

    convertEmbeddedTerms() {
        const {termDialogs} = this.props;
        let content = this.props.item.content;
        if (content === undefined)
            return content;

        content = content.replace(/\[\[(.*?)\]\]/gim, '<span class="replace-topic_embedded">$1</span>');

        const contentEl = document.getElementById("current-term-row-for-view");
        if (contentEl) {
            if (contentEl.offsetWidth < 812) {
                content = content.replace(/height="([^"]+)"/g, '').replace(/width="([^"]+)"/g, '')  //replace iframe width height
            }
        }
        // content = content.replace(/width:.*px;/g, "width: 100%;")// replace images width
        let images = content.match(/<img.+?style=\"(.+?)\">/gm)
        if (images) {
            images.map(x => {
                let xReplaced = x.replace(/style=\"(.+?)\"/g, 'style="width: 100%;"');
                content = content.replace(x, xReplaced);
            });
        }
        return content;
    };

    render() {
        const {props} = this;
        const {isCopied} = this.state;
        const {termDialogs} = this.props;

        return (
            <div id={'info-block-' + props.item.id}>
                 <div className={`document-infoblock-title ${props.level === 1 ? 'top-title' : ''}`}
                     ref={this.myRef}>
                    <div>
                         <div
                            className={`document-infoblock-subtitle`}>{props.prefix}{props.index + 1}. {props.item.title}
                         </div>

                        {!props.isEdit &&
                        <InfoblockCopyLink
                            term={props.term}
                            block={props.item}
                            handleClickedLink={() => this.setIsCopied(true)}
                        />}

                        {props.isEdit && <TemplateInfoblockActions
                            infoblock={props.item}
                            user={props.user}
                            onDelete={props.onDelete}
                            onDuplicate={props.onDuplicate}
                            onHide={props.onHide}
                            onLockUnlock={props.onLockUnlock}
                            onEdit={props.onEdit}
                        />}
                    </div>

                    <div className={`infoblock-information`}
                         style={props.isEdit ? {marginTop: 0} : {marginTop: '-1.25rem'}}> {/*align infoblock information with infoblock title when mode is read*/}
                        {props.isEdit && <><span
                            className={`infoblock-code`}>{props.item.code}</span> | {props.item.edited_by}<br/></>}
                        {props.item.modified && moment(props.item.modified).format('lll')}
                    </div>

                    <Notification
                        text={'Link copied to clipboard'}
                        show={isCopied}
                        onClose={() => {
                            this.setIsCopied(false);
                        }}
                    />
                </div>

                <div className={`document-infoblock-content`}>
                    {this.createMarkup(this.convertEmbeddedTerms())}
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    embeddedTerm: state.terms.embeddedTerm,
    termDialogs: state.terms.termDialogs,
});

const mapActionsToProps = (dispatch) => ({
    fetchEmbeddedTerm: (title, lang, id) => dispatch(fetchEmbeddedTerm(title, lang, id)),
    clearEmbeddedTerm: () => dispatch(clearEmbeddedTerm()),
});

export default connect(mapStateToProps, mapActionsToProps)(InfoblockContent);
