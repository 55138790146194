import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TermHeader from "../Term/partials/TermHeader";
import Discussion from "../../helpers/Discussion";
import {Col, Container} from "reactstrap";
import ListTemplate from "../Term/partials/ListTemplate";
import PageTemplate from "../Term/partials/PageTemplate";
import {connect} from "react-redux";
import {approve, fetchReviewerTerm, reject, sendReviewerMessage} from "../../store/reviewers-dashboard/actions";
import * as PropTypes from "prop-types";
import ModalTranslationReject from "./ModalRejectTranslation";
import {fetchLanguageList} from "../../store/terms/actions";
import {fetchDocumentInfoblocks, fetchEditorInfoblocks} from "../../store/infoblocks/actions";
import {syncEditorInfoblocks} from "../../store/dashboard/actions";
import DocumentTemplate from "../ManagementConsole/Terms/templates/document/DocumentTemplate";
import DocumentInfoblocks from "../ManagementConsole/Terms/templates/document/leftSidebar/DocumentInfoblocks";


class TermTranslation extends Component {

    static propTypes = {
        user: PropTypes.object,
        term: PropTypes.object,
        approve: PropTypes.func,
        reject: PropTypes.func,
        fetchReviewerTerm: PropTypes.func,
        sendReviewerMessage: PropTypes.func,
        isApproving: PropTypes.bool
    };

    state = {
        isEmbeddedTermVisible: false,
        isOtherLanguage: false,
        isRejectModalOpen: false,
        direction: 'ltr'
    };

    componentDidMount() {
        const {fetchReviewerTerm, fetchLanguageList, match: {params: {termId}}, fetchDocumentInfoblocks} = this.props;
        fetchReviewerTerm(termId, this.props.user.translation_language.code);
        fetchLanguageList();
        fetchDocumentInfoblocks(termId);
        this.setState({
            direction: this.props.term.direction
        })
    }

    handleApprove = () => {
        this.setState({isApproving: true});
        this.props.approve(this.props.match.params.termId)
            .then(res => this.props.history.push('/reviewers-dashboard/approved'));
    };

    handleAskForUpdate = (rejectReason) => {
        this.props.reject(this.props.match.params.termId, rejectReason)
            .then(res => this.props.history.push('/reviewers-dashboard/sent-back'));

        this.updateModalState(false);
    };

    updateEmbeddedTermVisibility = (isEmbeddedTermVisible) => {
        this.setState({isEmbeddedTermVisible});
    };

    handleLanguageChange = (isOtherLanguage) => {
        const {match: {params: {termId}}, fetchDocumentInfoblocks, fetchEditorInfoblocks} = this.props;
        !isOtherLanguage ? fetchDocumentInfoblocks(termId) : fetchEditorInfoblocks(termId);
        this.setState({
            isOtherLanguage,
            direction: !isOtherLanguage ? 'ltr' : this.props.term.my_translation.direction
        });
    };

    updateModalState = (isOpen) => {
        this.setState({isRejectModalOpen: isOpen});
    };

    render() {
        const {term, user, sendReviewerMessage, langs, match: { params }} = this.props;
        const {isEmbeddedTermVisible, isOtherLanguage, isRejectModalOpen, isApproving} = this.state;
        const upd_term = {
            ...term,
            direction: this.state.direction
        }
        const content = isOtherLanguage ? upd_term.my_translation : upd_term.content;

        return (
            <div className="reviewer-term-container">
                <div className="reviewer-term-title-wrapper">
                    <div className="reviewer-term-title">
                        <Link to="/reviewers-dashboard" className="back-to">
                            <FontAwesomeIcon icon={'chevron-left'}/> Back To Terms List
                        </Link>
                        <h1>{' '}Term Translation{' '}</h1>
                        <span>Current Term Version: <span className='version'>Version 1 ↓</span></span>
                    </div>
                </div>
                <div className="translate-term-content">
                    <ModalTranslationReject isOpen={isRejectModalOpen}
                                            onSendClick={(rejectReason) => this.handleAskForUpdate(rejectReason)}
                                            onCancelClick={() => this.updateModalState(false)} />
                    <div className="upper-buttons-wrapper">
                        <div className='language-block'>
                                <span className={`left ${!isOtherLanguage ? "switch-btn-active" : ''}`}
                                      onClick={() => this.handleLanguageChange(false)}>
                                    English
                                </span>
                            <span className={`right ${isOtherLanguage ? "switch-btn-active" : ''} `}
                                  title={(langs.length && user.translation_language) ? langs.find(({code}) => code === user.translation_language.code).english : ''}
                                  onClick={() => this.handleLanguageChange(true)}>
                                    {user.translation_language ? user.translation_language.name : 'English'}
                                </span>
                        </div>
                        <div className="reviewers-actions">
                            <div onClick={() => this.updateModalState(true)}>Ask for update</div>
                            <div onClick={() => {
                                if (!isApproving) {
                                    this.handleApprove()
                                }
                            }}>{!isApproving ? 'Approve' : 'Approving'}</div>
                        </div>
                    </div>
                    <div className="translate-term-content-main">
                        <Container className={`switch-term-container w-sm ${term.extended_description_type === 2 && 'document-container '}`}>

                            {term.extended_description_type === 2 &&
                            <DocumentInfoblocks
                                onChange={this.changeDocumentTemplate}
                                syncAction={this.props.syncEditorInfoblocks}
                                isEdit={false}
                                infoblocks={isOtherLanguage ? this.props.translatedInfoblocks : this.props.infoblocks}
                            />}

                            <div
                                className={
                                    `current-term-row ${isEmbeddedTermVisible ? 'term-card-shadow_background' : ''}
                                    ${!term.extended_description_type ? 'rounded-edges' : ''}
                                    ${term.extended_description_type === 2 ? ' document-container' : ''}`
                                }>

                                <Col className={'current-term-col'} xl={12}>
                                    <TermHeader
                                        title={content && content.title}
                                        description={content && content.description}
                                        updateEmbeddedTermClick={this.updateEmbeddedTermVisibility}
                                        term={upd_term}
                                        parse={true}
                                        isTermBar={true}/>
                                    {term.extended_description_type !== false && (
                                        <>
                                            {term.extended_description_type === 0 &&
                                                <ListTemplate
                                                    term={upd_term}
                                                    showTranslation={isOtherLanguage}
                                                    language={user.translation_language ? user.translation_language.lang : 'en'}
                                                    updateEmbeddedTermClick={this.updateEmbeddedTermVisibility}
                                                />
                                            }

                                            {term.extended_description_type === 1 &&
                                                <PageTemplate
                                                    term={upd_term}
                                                    showTranslation={isOtherLanguage}
                                                    isEditing={false}
                                                />
                                            }
                                            {term.extended_description_type === 2 &&
                                                <DocumentTemplate
                                                    singleTerm={upd_term}
                                                    isEdit={false}
                                                    infoblocks={isOtherLanguage ? this.props.translatedInfoblocks : this.props.infoblocks}
                                                    fetchAction={() => {}}
                                                    syncAction={this.props.syncEditorInfoblocks}
                                                />
                                            }
                                        </>
                                    )}
                                </Col>
                            </div>
                        </Container>

                        <div className={`right-sidebar`}>
                            <Discussion
                                messages={term.messages || []}
                                sendComment={(data) => sendReviewerMessage(params.termId, {content: data})}
                                user={user}
                                termId={term.id}/>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    langs: state.terms.langs,
    term: state.reviewersDashboard.term,
    user: state.auth.user,

    infoblocks: state.terms.infoblocks,
    translatedInfoblocks: state.dashboard.documentInfoblocks
});

const mapDispatchToProps = {fetchReviewerTerm, reject, approve, sendReviewerMessage, fetchLanguageList, fetchDocumentInfoblocks, fetchEditorInfoblocks, syncEditorInfoblocks};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TermTranslation));