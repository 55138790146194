import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import {froala3Key} from "../../../configs/config";

const editor = 1;
const plainText = 2;

class NoteEditor extends Component {

    // without this variable FroalaEditor likes to crash whole page
    canShowEditor = true;

    static propTypes = {
        note: PropTypes.string.isRequired,
        edit: PropTypes.bool,
        onNoteChange: PropTypes.func,
        clearNote: PropTypes.bool
    };

    state = {
        textareaValue: this.props.note,
        editType: (this.props.user.role === 'Editor' || this.props.user.role === 'Co-Editor' || this.props.user.rich_text_editor) ? editor : plainText,
        edit: this.props.edit
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.clearNote && this.props.clearNote) {
            this.setState({
                textareaValue: '',
            })
        } else if (this.state.textareaValue === '' && this.state.textareaValue !== this.props.note) {
            this.setState({
                textareaValue: this.props.note,
            })
        }
    }

    onChange = (text) => {
        this.setState({textareaValue: text});
        if (this.props.onNoteChange) {
            this.props.onNoteChange(text);
        }
    };

    render() {
        const {note, edit} = this.props;
        const {textareaValue, editType} = this.state;
        return (
            <>
                {edit
                    ? <div className="note-editor">
                        {editType === editor && this.canShowEditor &&

                            <FroalaEditor
                                tag='div'
                                config={{
                                    htmlUntouched: true,
                                    fullPage: true,
                                    htmlRemoveTags: [],
                                    key: froala3Key,
                                    charCounterCount: false,
                                    imageUploadParam: 'image_param',
                                    imageUploadURL: '/api/froala_upload_image',
                                    imageUploadMethod: 'POST',
                                    toolbarVisibleWithoutSelection: true,
                                    attribution: false,
                                    indentMargin: 0,
                                    placeholderText: 'Enter Your Notes',
                                    toolbarButtons: {
                                        moreText: {
                                            buttons: [
                                                'bold',
                                                'italic',
                                                'underline',
                                                'strikeThrough',
                                                'subscript',
                                                'superscript',
                                                'fontFamily',
                                                'fontSize',
                                                'textColor',
                                                'backgroundColor',
                                                'inlineClass',
                                                'inlineStyle',
                                                'clearFormatting'
                                            ],
                                            align: 'left',
                                            buttonsVisible: 0
                                        },
                                        moreParagraph: {
                                            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                            align: 'left',
                                            buttonsVisible: 0
                                        },
                                        moreRich: {
                                            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                            align: 'left',
                                            buttonsVisible: 0
                                        },
                                        moreMisc: {
                                            buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                                            align: 'right',
                                            buttonsVisible: 2
                                        }
                                    }
                                }}
                                model={textareaValue}
                                onModelChange={( data, editor ) => {
                                    this.onChange(data);
                                }}
                            />
                        }
                        {editType === plainText &&
                            <textarea placeholder="add note" className="edit-note" value={textareaValue} maxLength={500}
                                  onChange={(e) => this.onChange(e.target.value)}/>
                        }
                    </div>
                    : <div className="note-editor collapse-description" dangerouslySetInnerHTML={{__html: textareaValue}}/>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default connect(mapStateToProps)(NoteEditor);
