import {baseURL} from "../../configs/config";
import * as types from "./types";

export const fetchNotifications = (loadInBackground) => ({
    type: types.FETCH_NOTIFICATIONS,
    payload: {
        request: {
            url: `${baseURL}v1/notifications`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        asPromise: true,
        takeLatest: true,
        loadInBackground
    }
});

export const updateNotification = (ids) => {
    ids = Array.isArray(ids) ? ids.join(",") : ids;

    return {
        type: types.UPDATE_NOTIFICATION,
        payload: {
            request: {
                url: `${baseURL}v1/notifications/mark_as_read/?ids=${ids}`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`
                }
            }
        }
    }
};

export const fetchEditorTerms = (data, loadInBackground) => ({
    type: types.FETCH_EDITOR_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                ...data
            }
        }
    },
    meta: {
        asPromise: true,
        loadInBackground
    }
});

export const fetchAuthorTerms = (loadInBackground) => ({
    type: types.FETCH_AUTHORS_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/author/terms`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        asPromise: true,
        loadInBackground
    }
});

export const fetchEditorSets = (loadInBackground) => ({
    type: types.FETCH_EDITOR_SETS,
    payload: {
        request: {
            url: `${baseURL}v1/editor/sets`,
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    },
    meta: {
        asPromise: true,
        loadInBackground
    }
});

export const fetchEditorTerm = (termId, lang = 'en') => ({
    type: types.FETCH_EDITOR_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/${termId}`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            params: {
                lang
            }
        }
    }
});

export const fetchEditorTermStatuses = () => ({
    type: types.FETCH_EDITOR_TERM_STATUSES,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/statuses`,
            method: 'GET',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
        },

    }
});

export const updateEditorTerm = (termId, data) => ({
    type: types.UPDATE_EDITOR_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/${termId}`,
            method: 'PATCH',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            data
        }
    }, meta: {
        asPromise: true
    }
});

export const publishAllEditorTranslations = () => ({
    type: types.PUBLISH_ALL_EDITOR_TRANSLATIONS,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/publish_all`,
            method: 'POST',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
        }
    }
});

export const publishTermEditorTranslation = (ids) => {
    ids = Array.isArray(ids) ? ids.join(",") : ids;

    return {
        // <id>: term id
        type: types.PUBLISH_TERM_EDITOR_TRANSLATION,
        payload: {
            request: {
                url: `${baseURL}v1/editor/terms/publish?ids=${ids}`,
                method: 'POST',
                headers: localStorage.getItem('access') && {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`
                },
            }
        }
    }
};

export const sendSelectedDraftToReviewTranslations = (ids) => {
    ids = Array.isArray(ids) ? ids.join(",") : ids;

    return {
        type: types.SEND_SELECTED_DRAFT_TO_REVIEW,
        payload: {
            request: {
                url: `${baseURL}v1/editor/terms/draft_to_review?ids=${ids}`,
                method: 'POST',
                headers: localStorage.getItem('access') && {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`
                },
            }
        }
    }
};

export const draftEditorTerm = (termId, data) => ({
    type: types.DRAFT_EDITOR_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/${termId}`,
            method: 'PATCH',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            data
        }
    }, meta: {
        asPromise: true
    }
});

export const unpublishEditorTerm = (termId) => ({
    type: types.UNPUBLISH_EDITOR_TERM,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/${termId}/unpublish`,
            method: 'PATCH',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
        }
    }, meta: {
        asPromise: true
    }
});

export const sendEditorMessage = (termId, data) => ({
    type: types.SEND_EDITOR_MESSAGE,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/${termId}`,
            method: 'POST',
            data,
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});

export const dwnSelectedTerms = (termsId) => ({
    type: types.DWN_SELECTED_TERMS,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/download?terms=${termsId.join()}`,
            method: 'GET',
            responseType: 'blob',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }
    }, meta: {
        asPromise: true
    }
});

export const dwnSets = (setsId, includeTranslations = false) => ({
    type: types.DWN_SETS,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/download?sets=${setsId.join()}&include_translations=${includeTranslations.toString()}`,
            method: 'GET',
            responseType: 'blob',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }
    }, meta: {
        asPromise: true
    }
});

export const downloadTermsForTranslate = () => ({
    type: types.DOWNLOAD_TERMS_FOR_TRANSLATE,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/download`,
            method: 'GET',
            responseType: 'blob',
            headers: localStorage.getItem('access') && {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            }
        }
    }
});

export const bulkTranslationUpload = (data) => ({
    type: types.BULK_TRANSLATION_UPLOAD,
    payload: {
        request: {
            url: `${baseURL}v1/editor/terms/download`,
            method: 'POST',
            data,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        }
    }
});