export const GET_ADMIN_TERMS = 'GET_ADMIN_TERMS';
export const CLEAR_ADMIN_TERMS = 'CLEAR_ADMIN_TERMS';
export const CREATE_TERM = 'CREATE_TERM';
export const EDIT_DRAFT_TERM = 'EDIT_DRAFT_TERM';
export const EDIT_TERM = 'EDIT_TERM';
export const MINOR_EDIT_TERM = 'MINOR_EDIT_TERM';
export const GET_SINGLE_TERM = 'GET_SINGLE_TERM';
export const UPDATE_SINGLE_TERM = 'UPDATE_SINGLE_TERM';
export const DELETE_TERM = 'DELETE_TERM';
export const BULK_CREATE_TERMS = 'BULK_CREATE_TERMS';
export const BULK_DELETE_TERMS = 'BULK_DELETE_TERMS';
export const RESTORE_TERM_VERSION = 'RESTORE_TERM_VERSION';

export const GET_ADMIN_SETS = 'GET_ADMIN_SETS';
export const CREATE_SET = 'CREATE_SET';
export const CLEAR_ADMIN_SETS = 'CLEAR_ADMIN_SETS';
export const GET_SINGLE_SET = 'GET_SINGLE_SET';
export const EDIT_SET = 'EDIT_SET';
export const CLEAR_SET = 'CLEAR_SET';
export const DELETE_SET = 'DELETE_SET';
export const BULK_DELETE_SET = 'BULK_DELETE_SET';

export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const BLOCK_USER = 'BLOCK_USER';
export const DELETE_USER = 'DELETE_USER';
export const RICH_TEXT_USER = 'RICH_TEXT_USER';
export const VERIFY_USER = 'VERIFY_USER';
export const MAKE_CO_AUTHOR = 'MAKE_CO_AUTHOR';
export const MAKE_LOGS_VIEWER = 'MAKE_LOGS_VIEWER';
export const IMPERSONATE_USER = 'IMPERSONATE_USER';
export const CHANGE_ROLE_USER = 'CHANGE_ROLE_USER';
export const BULK_BLOCK_USERS = 'BULK_BLOCK_USERS';
export const BULK_RICH_TEXT_USERS = 'BULK_RICH_TEXT_USERS';

export const GET_TEAMS = 'GET_TEAMS';
export const CREATE_TEAM = 'CREATE_TEAM';
export const GET_SINGLE_TEAM = 'GET_SINGLE_TEAM';
export const EDIT_TEAM = 'EDIT_TEAM';
export const CLEAR_TEAM = 'CLEAR_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const BULK_DELETE_TEAM = 'BULK_DELETE_TEAM';

export const GET_LABELS = 'GET_LABELS';
export const CREATE_LABEL = 'CREATE_LABEL';
export const GET_SINGLE_LABEL = 'GET_SINGLE_LABEL';
export const EDIT_LABEL = 'EDIT_LABEL';
export const DELETE_LABEL = 'DELETE_LABEL';
export const BULK_DELETE_LABEL = 'BULK_DELETE_LABEL';

export const GET_LANGUAGES = 'GET_LANGUAGES';
export const CREATE_LANGUAGE = 'CREATE_LANGUAGE';
export const GET_SINGLE_LANGUAGE = 'GET_SINGLE_LANGUAGE';
export const EDIT_LANGUAGE = 'EDIT_LANGUAGE';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const BULK_DELETE_LANGUAGE = 'BULK_DELETE_LANGUAGE';

export const GET_INFOBLOCKS_TYPES = 'GET_INFOBLOCKS_TYPES';
export const GET_INFOBLOCKS = 'GET_INFOBLOCKS';
export const GET_INFOBLOCKS_TITLES = 'GET_INFOBLOCKS_TITLES';
export const CREATE_INFOBLOCK = 'CREATE_INFOBLOCK';
export const GET_SINGLE_INFOBLOCK = 'GET_SINGLE_INFOBLOCK';
export const EDIT_INFOBLOCK = 'EDIT_INFOBLOCK';
export const DELETE_INFOBLOCK = 'DELETE_INFOBLOCK';
export const BULK_EDIT_INFOBLOCK = 'BULK_EDIT_INFOBLOCK';
export const BULK_DELETE_INFOBLOCK = 'BULK_DELETE_INFOBLOCK';
export const FETCH_INFOBLOCK_VERSIONS = 'FETCH_INFOBLOCK_VERSIONS';
export const REMOVE_INFOBLOCK_VERSIONS = 'REMOVE_INFOBLOCK_VERSIONS';
export const RESTORE_INFOBLOCK_VERSION = 'RESTORE_INFOBLOCK_VERSION';

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const GET_SINGLE_TEMPLATE = 'GET_SINGLE_TEMPLATE';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const BULK_EDIT_TEMPLATE = 'BULK_EDIT_TEMPLATE';
export const BULK_DELETE_TEMPLATE = 'BULK_DELETE_TEMPLATE';
export const GET_LIST_TEMPLATE_ITEMS = 'GET_LIST_TEMPLATE_ITEMS';
export const GET_AVAILABLE_TEMPLATES = 'GET_AVAILABLE_TEMPLATES';

export const POST_TERM_MESSAGE = 'POST_TERM_MESSAGE';

export const GET_AVAILABLE_USER_ROLES = 'GET_AVAILABLE_USER_ROLES';
export const GET_AVAILABLE_COUNTRIES = 'GET_AVAILABLE_COUNTRIES';
export const GET_AVAILABLE_TERM_TEMPLATES = 'GET_AVAILABLE_TERM_TEMPLATES';
export const GET_AVAILABEL_LABELS = 'GET_AVAILABLE_LABELS';
export const GET_ALL_SETS = 'GET_ALL_SETS';

export const FETCH_LOGS = 'FETCH_LOGS';
export const FETCH_LOG_USERS = 'FETCH_LOG_USERS';

export const FETCH_TOKENS = 'FETCH_TOKENS';
export const PAUSE_TOKEN = 'PAUSE_TOKEN';
export const REVOKE_TOKEN = 'REVOKE_TOKEN';

export const FETCH_DOCUMENT_EDITORS = 'FETCH_DOCUMENT_EDITORS';
export const ASSIGN_USERS_TO_TEMPLATE = 'ASSIGN_USERS_TO_TEMPLATE';
