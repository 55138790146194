import * as types from "./types";
import { baseURL } from "../../configs/config";


export const getCommonData = (pageName) => ({
    type: types.GET_COMMON_DATA,
    payload: {
        request: {
            url: `${baseURL}static/${pageName}`,
            method: 'GET',
        }
    }
});

export const getFooterData = () => ({
    type: types.GET_FOOTER_DATA,
    payload: {
        request: {
            url: `${baseURL}static/footer`,
            method: 'GET',
        }
    }
});

export const getAnnouncementTitle = () => ({
    type: types.GET_ANNOUNCEMENT_TITLE,
    payload: {
        request: {
            url: `${baseURL}static/announcement_title`,
            method: 'GET',
        }
    }
});

export const getAnnouncementBody = () => ({
    type: types.GET_ANNOUNCEMENT_BODY,
    payload: {
        request: {
            url: `${baseURL}static/announcement_body`,
            method: 'GET',
        }
    }
});

export const getAnnouncementLearnMore = () => ({
    type: types.GET_ANNOUNCEMENT_LEARN_MORE,
    payload: {
        request: {
            url: `${baseURL}static/announcement_learn_more`,
            method: 'GET',
        }
    }
});

export const updateAnnouncement = (path,  data) => ({
    type: types.UPDATE_ANNOUNCEMENT_DATA,
    payload: {
        request: {
            url: `${baseURL}static/${path}`,
            method: 'PATCH',
            headers: localStorage.getItem('access') &&  {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            data
        },
        meta: {
            path
        }
    }
});

export const updateCommonData = (pageName,  data) => ({
    type: types.UPDATE_COMMON_DATA,
    payload: {
        request: {
            url: `${baseURL}static/${pageName}`,
            method: 'PATCH',
            headers: localStorage.getItem('access') &&  {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            data
        }
    }
});
export const updateFooterData = (data) => ({
    type: types.UPDATE_FOOTER_DATA,
    payload: {
        request: {
            url: `${baseURL}static/footer`,
            method: 'PATCH',
            headers: localStorage.getItem('access') &&  {
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            },
            data
        }
    }
});

export const sendContactUsEmail = (data) => ({
    type: types.POST_CONTACT_US,
    payload: {
        request: {
            url: `${baseURL}contact`,
            method: 'POST',
            data
        }
    }
});

export const assignInfoblockFromLink = (block) => ({
    type: types.INFOBLOCK_FROM_LINK,
    payload:{
        data: block
    }
});