import React from "react";
import {Link} from "react-router-dom";
import {Col} from "reactstrap";
import ToggleFavourite from "./partials/ToggleFavourite"

const cutOffString = (string, number) => {
    if (string !== null && string.length > number) {
        let stringWithoutTags = string.replace(/<\s*[^>]*>/g, '');
        return `${stringWithoutTags.substring(0, number)}...`
    }

    return string
};

const TermCard = ({term}) => {
    const topPercent = term.top_percent ? term.top_percent : 1;
    return (
        <Col
            className="top-term"
            xl={4}
        >
            <div>
                <Link
                    to={`/${term.content.language}/${term.slug}/${term.version && term.version.number ? term.version.number : 1}`}
                    className="term-title"
                    dangerouslySetInnerHTML={{__html: cutOffString(term.content.title, 18)}}/>
                <p className="term-description"
                   dangerouslySetInnerHTML={{__html: cutOffString(term.content.description, 100)}}/>
            </div>

            <div className="term-rating">
            <span className="term-stars">
                <ToggleFavourite term={term} handleClickedStar={() => {
                    return false
                }}/>
                <span className="amount-stars">{term.favourite_count}</span>
            </span>

                <span className="term-graphic">
                <div className="chart-img"/>
                <span className="graphic-data">Top {topPercent}%</span>
            </span>
            </div>
        </Col>
    )
};

export default TermCard;
