import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {baseURLForLink} from "../../../../../../configs/config";

const handleCopyLink = (term, handleClickedLink, copyInput) => {
    if (copyInput) {
        copyInput.select();
        document.execCommand("copy");
        handleClickedLink();
    }
};

function InfoblockCopyLink(props) {
    const {term, handleClickedLink, block} = props;
    const [copyInput, setCopyInput] = useState(null);

    return (
        <div title="Click to copy link" className={'infoblock-link-container'}>
            <FontAwesomeIcon icon={'link'} className={'infoblock-link'} onClick={() => handleCopyLink(term, handleClickedLink, copyInput)} />
            {term && term.content &&
            <div className="hidden-input-wrapper">
                <input
                    className={'hidden-input'}
                    ref={ref => setCopyInput(ref)}
                    type="text"
                    readOnly
                    value={`${baseURLForLink}${term.content.language ? term.content.language : 'en'}/${term.slug}/${term.version && term.version.number ? term.version.number : 1}/${block.title}`}/>
            </div>}
        </div>
    )
};

export default InfoblockCopyLink;