import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {faCopy, faEye, faLock, faPlus, faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {FaPencil} from "react-icons/lib/fa";

class DocumentLegend extends Component {
    render() {
        return (
            <div className={`document-legend`}>
                <div className={`document-legend-row`}>
                    <div className={`document-legend-icon`}><FaPencil className='edit-icon'/></div>
                    <div className={`document-legent-content`}><span className={`bold`}>Edit:</span> only available if the Information Block is not locked</div>
                </div>

                <div className={`document-legend-row`}>
                    <div className={`document-legend-icon`}><FontAwesomeIcon icon={faEye} className={'eye-icon'}/></div>
                    <div className={`document-legent-content`}><span className={`bold`}>Hide:</span> press to turn off this section (same behaviour as unchecking the checkbox in the Navigator)</div>
                </div>

                <div className={`document-legend-row`}>
                    <div className={`document-legend-icon`}><FontAwesomeIcon icon={faCopy} className={'copy-icon'}/></div>
                    <div className={`document-legent-content`}><span className={`bold`}>Duplicate:</span> press to duplicate the Information Block; it will be placed directly below this block</div>
                </div>

                <div className={`document-legend-row`}>
                    <div className={`document-legend-icon`}><FontAwesomeIcon icon={faTrashAlt} className={'trash-icon'}/></div>
                    <div className={`document-legent-content`}><span className={`bold`}>Remove:</span> Information Block is removed from this document. If the Information block is not in any other document, then its status is set to "Orphaned"</div>
                </div>

                <div className={`document-legend-row`}>
                    <div className={`document-legend-icon`}><FontAwesomeIcon icon={faPlus} className={'plus-icon'}/></div>
                    <div className={`document-legent-content`}><span className={`bold`}>Add:</span> press to add a new blank Information Block. You will be prompted to add a title and a body. New blocks are numbered automatically</div>
                </div>

                <div className={`document-legend-row`}>
                    <div className={`document-legend-icon`}><FontAwesomeIcon icon={faPlusCircle} className={'plus-circle-icon'}/></div>
                    <div className={`document-legent-content`}><span className={`bold`}>Insert:</span> press to insert an existing Information Block. An inserted block cannot be edited but can be duplicated (the duplicate can be edited).</div>
                </div>

                <div className={`document-legend-row`}>
                    <div className={`document-legend-icon`}><FontAwesomeIcon icon={faLock} className={'lock-alt-icon'}/></div>
                    <div className={`document-legent-content`}><span className={`bold`}>Lock</span> (available to Admin only): press to lock this Information Block to disallow others from editing it. Other editors can still turn it off and change its navigation location/level.</div>
                </div>

                <div className={`document-legend-row`}>
                    <div className={`document-legend-icon`}><FontAwesomeIcon icon={faLock} className={'lock-alt-icon locked'}/></div>
                    <div className={`document-legent-content`}><span className={`bold`}>Locked:</span> this Information Block is locked because it is used in more than one document. Locked blocks can only be edited by admin through the Management Console</div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentLegend));