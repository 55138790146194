import React, {Component} from 'react'
import TemplateInfoblockActions from "./TemplateInfoblockActions";
import {fullTextareaConfig} from "../../../../../../configs/froalaConfigs";
import FroalaEditor from 'react-froala-wysiwyg';
import {Col, Row} from "react-bootstrap";

class InfoblockEditable extends Component {
    state = {
        id: 0,
        title: '',
        content: ''
    };

    changeValue = (value, field) => {
        this.setState({
            [field]: value
        });
    };

    saveBlock = () => {
        const {saveBlock} = this.props;
        let processedContent = this.state.content.replace(/&nbsp;/gi,'').replace(/ /g, '');
        if (this.state.title && processedContent && processedContent !== "<p></p>") {
            saveBlock({...this.state})
        } else {
            alert('Cannot save if empty')
        }
    };

    componentWillMount() {
        const {item} = this.props;
        this.setState({...item});
    }

    render() {
        const {level, prefix,item, index, onDelete, onCancelEdit, user} = this.props;
        const {title, content} = this.state;
        const ableToRemoveSection = user && user.role === 'Admin' ? true : false

        return (
            <>
                <div className={`document-infoblock-title ${level === 1 ? 'top-title' : ''}`}>
                    <div className={`document-infoblock-subtitle`}>{prefix}{index + 1}.
                        <input type={'text'}
                               value={title}
                               className={`inline-edit-infoblock-title`}
                               onChange={(e) => this.changeValue(e.target.value, 'title')}/>
                    </div>
                    <TemplateInfoblockActions
                        user={user}
                        isBlockEditing={true}
                        infoblock={item}
                        onDelete={() => {}}
                        onDuplicate={() => {}}
                        onHide={() => {}}
                        onLockUnlock={() => {}}
                        onEdit={() => {}}
                    />
                </div>

                <div className={`document-infoblock-content`}>
                    <FroalaEditor
                        tag='div'
                        config={{
                            htmlUntouched: true,
                            fullPage: true,
                            htmlRemoveTags: [],
                            ...fullTextareaConfig,
                            editorClass: 'inline-infoblock-content-editor',
                            toolbarVisibleWithoutSelection: true,
                            imageUploadParam: 'image_param',
                            imageUploadURL: '/api/froala_upload_image',
                            imageUploadMethod: 'POST',
                            toolbarInline: false
                        }}
                        model={content}
                        onModelChange={(...data) => this.changeValue(data[0], 'content')}
                    />
                </div>

                <div className={`editable-infoblock-buttons row`}>

                    <Col xl={6}>
                        {ableToRemoveSection &&
                            <button className={`remove-infoblock-section`} onClick={() => onDelete(item)}>
                                Remove Section
                            </button>
                        }
                    </Col>
                    <Col xl={6}>
                        <button className={`cancel-infoblock-section`} onClick={() => onCancelEdit(item)}>
                            Cancel
                        </button>
                        <button className={`save-infoblock-section`} onClick={this.saveBlock}>
                            Save
                        </button>
                    </Col>
                </div>
            </>
        )
    }
}

export default InfoblockEditable
