import {Component} from "react";
import React from "react";

class EditInput extends Component {

    state = {
        value: this.props.value,
    };

    onChange = (e) => {
        this.setState({
            value: e.target.value
        })
    };

    onCancel = () => {
        this.reset();
        this.props.onCancel();
    };

    reset = () => {
        this.setState({
            value: this.props.value
        })
    };

    render() {
        const {value} = this.state;
        const {onSave, edit, className, element, withCancel} = this.props;

        return (
            <>
                {edit
                    ? (<div className={className}>
                        {element === 'textarea'
                            ? <textarea type={"text"} value={value} onChange={this.onChange}/>
                            : <input type={"text"} value={value} onChange={this.onChange}/>
                        }
                        <div>
                            <button type={"button"} className={"console-button"} onClick={() => onSave(value)}>
                                Save
                            </button>
                            {withCancel &&
                                <span onClick={() => this.onCancel()}>
                                    Cancel
                                </span>}
                        </div>
                    </div>)
                    : <span>{value}</span>
                }
            </>

        )
    }
}

export default EditInput;