import React, {Component} from 'react'
import {connect} from "react-redux";
import {toggleFavourite} from "../../../store/terms/actions";

class ToggleFavourite extends Component {

    toggleFavourite() {
        const {term} = this.props;
        if (this.props.user.email === undefined) {
            this.props.handleClickedStar();
            return;
        }

        this.props.toggleFavourite(term)
            .then(() => {
                this.props.handleClickedStar && this.props.handleClickedStar()
            })
            .catch(e => {
                this.props.onError && this.props.onError(e)
            })
    }

    render() {
        const {term} = this.props;

        return (
            <img
                onClick={() => this.toggleFavourite()}
                className={`${term.is_favourite ? 'favourite-term' : ''} term-star`}
                src={`${static_url}assets/${term.is_favourite ? 'favourite-star.svg' : 'shape-star.svg'}`}
            />
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
});

const mapActionsToProps = (dispatch) => ({
    toggleFavourite: (term) => dispatch(toggleFavourite(term))
});

export default connect(mapStateToProps, mapActionsToProps)(ToggleFavourite);
