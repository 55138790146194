import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TopTerms from "../Term/TopTerms";
import {clearEmbeddedTerm, fetchHomeInfo, fetchTopTerms, getRandomTerm} from "../../store/terms/actions";
import {
    getAnnouncementTitle,
    getAnnouncementBody,
    updateAnnouncement,
    getAnnouncementLearnMore
} from "../../store/common/actions";
import RandomTerm from "../Term/RandomTerm";
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import {FaPencil} from "react-icons/lib/fa";
import {froala3Key} from "../../configs/config";
import moment from "moment";
import ContributorsSectionTextEdit from "../../helpers/ContributorsSectionTextEdit";
import {
    getContributorsSponsorSection,
    setContributorsParagraphs,
    updateContributorsParagraphs
} from "../../store/contributors/actions";

class Home extends Component {
    state = {
        announcementBlock: true,
        isEditTitle: false,
        isEditBody: false,
        isEditLearnMore: false,
        newAnnouncementsTitle: {data: ''},
        newAnnouncementsBody: {data: ''},
        learnMore: {data: ''},
        sponsor_sect_isEdit: false,
    };

    componentDidMount() {
        this.props.fetchTopTerms();
        this.props.fetchHomeInfo();
        this.props.getRandomTerm();
        this.props.getAnnouncementTitle();
        this.props.getAnnouncementBody();
        this.props.getAnnouncementLearnMore();
        this.props.fetchSponsorSection();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {announcement_title, announcement_body, announcement_learn_more} = this.props;
        if (prevProps.announcement_title !== announcement_title) {
            this.setState({newAnnouncementsTitle: announcement_title})
        }
        if (prevProps.announcement_body !== announcement_body) {
            this.setState({newAnnouncementsBody: announcement_body})
        }
        if (prevProps.announcement_learn_more !== announcement_learn_more) {
            this.setState({learnMore: announcement_learn_more})
        }
    }

    onChangeTranslation(language) {
        const {randomTerm} = this.props;

        this.props.getRandomTerm(randomTerm.id, language.code);
    };

    _handleModalWindowState = () => {
        if (this.props.user.role === 'Admin') {
            return;
        }
        localStorage.setItem('announcementBlock', `${moment().valueOf()}`);
        this.setState({
            announcementBlock: false
        })
    };

    createMarkup = (data) => {
        return {__html: data};
    };

    handleShowEditBlock(blockName) {
        const editingState = this.state[blockName];
        this.setState({
            ...this.state,
            [blockName]: !editingState
        });
    };

    handleChangeTitle = (...data) => {
        this.setState(({newAnnouncementsTitle}) => {
            return {
                newAnnouncementsTitle: {
                    ...newAnnouncementsTitle,
                    data: data[0]
                }
            }
        })
    };
    handleChangeBody = (...data) => {
        this.setState(({newAnnouncementsBody}) => {
            return {
                newAnnouncementsBody: {
                    ...newAnnouncementsBody,
                    data: data[0]
                }
            }
        })
    };
    handleChangeLearnMore = (...data) => {
        this.setState(({learnMore}) => {
            return {
                learnMore: {
                    ...learnMore,
                    data: data[0]
                }
            }
        })
    };

    saveEditData = (blockName, blockData) => {
        this.setState({
            ...this.state,
            [blockName]: false
        });
        let data;

        if (blockName !== "sponsor_sect_isEdit") {
            data = this.state[blockData].data;
            if (blockName === 'isEditTitle') {
                this.props.updateAnnouncement('announcement_title', {content: data});
            } else if (blockName === 'isEditBody') {
                this.props.updateAnnouncement('announcement_body', {content: data});
            } else if (blockName === 'isEditLearnMore') {
                this.props.updateAnnouncement('announcement_learn_more', {content: data});
            }
        } else {
            data = this.props[blockData];
            this.props.updateContributorsParagraphs(blockData, {content: data});
        }
    };

    handleChangeFroala = (path, data) => {
        this.props.setContributorsParagraphs(path, data);
    };

    render() {
        const {
            topTerms, randomTerm, langs, user, announcement_title, announcement_body, announcement_learn_more,
            announcementLastModified, sponsorSection
        } = this.props;
        const {
            announcementBlock, newAnnouncementsTitle, newAnnouncementsBody, learnMore, isEditTitle, isEditBody,
            isEditLearnMore, sponsor_sect_isEdit
        } = this.state;
        const isOpenModal = localStorage.getItem('announcementBlock') === null
            || user.role === 'Admin'
            || localStorage.getItem('announcementBlock') < announcementLastModified;
        const announcementsTitle = 'The BIM Dictionary is now an open-source project!';
        const announcementsBody = `The BIM Dictionary is now an open-source project on GitHub. Please read the full
                                    announcement on LinkedIn covering earlier BIM Dictionary milestones, the development
                                    path forward, and the drive to recruit a <span className="selected-words">Community Coordinator</span>.`;
        const sponsorExampleText = 'Local: Lorem ipsum dolor sit amet, consectetur adipiscing elit';

        return (
            <Container className="w-md">
                <div className='wrapper-announcement'>
                    {(isOpenModal && announcementBlock) &&
                    <div className={'announcement-block'}>
                        <div className="announcement-title">
                            {!isEditTitle &&
                            <div className="announcement-title-text"
                                 dangerouslySetInnerHTML={this.createMarkup(announcement_title ? announcement_title.data : announcementsTitle)}>
                            </div>}

                            {isEditTitle &&
                            <div className='edit-common-wrapper announcement-title-text'>
                                <FroalaEditor
                                    tag='div'
                                    config={{
                                        htmlUntouched: true,
                                        fullPage: true,
                                        htmlRemoveTags: [],
                                        key: froala3Key,
                                        toolbarInline: true,
                                        imageUploadParam: 'image_param',
                                        imageUploadURL: '/api/froala_upload_image',
                                        imageUploadMethod: 'POST',
                                        toolbarButtons: {
                                            moreText: {
                                                buttons: [
                                                    'bold',
                                                    'italic',
                                                    'underline',
                                                    'strikeThrough',
                                                    'subscript',
                                                    'superscript',
                                                    'fontFamily',
                                                    'fontSize',
                                                    'textColor',
                                                    'backgroundColor',
                                                    'inlineClass',
                                                    'inlineStyle',
                                                    'clearFormatting'
                                                ],
                                                align: 'left',
                                                buttonsVisible: 0
                                            },
                                            moreParagraph: {
                                                buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                                align: 'left',
                                                buttonsVisible: 0
                                            },
                                            moreRich: {
                                                buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                                align: 'left',
                                                buttonsVisible: 0
                                            },
                                            moreMisc: {
                                                buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                                                align: 'right',
                                                buttonsVisible: 2
                                            }
                                        }
                                    }}
                                    model={newAnnouncementsTitle.data}
                                    onModelChange={this.handleChangeTitle}
                                />

                                <button className='save-edit-block'
                                        onClick={() => this.saveEditData('isEditTitle', 'newAnnouncementsTitle')}>save
                                </button>
                            </div>}

                            {user.role === 'Admin' &&
                            <FaPencil className='edit-contributors-btn'
                                      onClick={() => this.handleShowEditBlock('isEditTitle')}/>}
                            <FontAwesomeIcon className={'close-announcement-icon'} icon={'times-circle'}
                                             onClick={() => this._handleModalWindowState()}/>
                        </div>

                        <div className="announcement-body-wrapper">
                            {!isEditBody &&
                            <div className="announcement-body"
                                 dangerouslySetInnerHTML={this.createMarkup(announcement_body ? announcement_body.data : announcementsBody)}>
                            </div>}
                            {isEditBody &&
                            <div className='edit-common-wrapper announcement-body'>
                                <FroalaEditor
                                    tag='div'
                                    config={{
                                        htmlUntouched: true,
                                        fullPage: true,
                                        htmlRemoveTags: [],
                                        key: froala3Key,
                                        toolbarInline: true,
                                        imageUploadParam: 'image_param',
                                        imageUploadURL: '/api/froala_upload_image',
                                        imageUploadMethod: 'POST',
                                        heightMin: 50,
                                        editorClass: 'announcement-body-editor',
                                        toolbarButtons: {
                                            moreText: {
                                                buttons: [
                                                    'bold',
                                                    'italic',
                                                    'underline',
                                                    'strikeThrough',
                                                    'subscript',
                                                    'superscript',
                                                    'fontFamily',
                                                    'fontSize',
                                                    'textColor',
                                                    'backgroundColor',
                                                    'inlineClass',
                                                    'inlineStyle',
                                                    'clearFormatting'
                                                ],
                                                align: 'left',
                                                buttonsVisible: 0
                                            },
                                            moreParagraph: {
                                                buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                                align: 'left',
                                                buttonsVisible: 0
                                            },
                                            moreRich: {
                                                buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                                align: 'left',
                                                buttonsVisible: 0
                                            },
                                            moreMisc: {
                                                buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                                                align: 'right',
                                                buttonsVisible: 2
                                            }
                                        }
                                    }}
                                    model={newAnnouncementsBody.data}
                                    onModelChange={this.handleChangeBody}
                                />

                                <button className='save-edit-block'
                                        onClick={() => this.saveEditData('isEditBody', 'newAnnouncementsBody')}>save
                                </button>
                            </div>}

                            {user.role === 'Admin' &&
                            <FaPencil className='edit-contributors-btn'
                                      onClick={() => this.handleShowEditBlock('isEditBody')}/>}
                        </div>

                        <div className="announcement-footer">
                            <div className="learn-more-container">
                                {isEditLearnMore ?
                                    <div className='edit-common-wrapper'>
                                        <FroalaEditor
                                            tag='div'
                                            config={{
                                                htmlUntouched: true,
                                                fullPage: true,
                                                htmlRemoveTags: [],
                                                key: froala3Key,
                                                toolbarInline: true,
                                                editorClass: 'announcement-learn-more-editor',
                                                imageUploadParam: 'image_param',
                                                imageUploadURL: '/api/froala_upload_image',
                                                imageUploadMethod: 'POST',
                                                toolbarButtons: {
                                                    moreText: {
                                                        buttons: [
                                                            'bold',
                                                            'italic',
                                                            'underline',
                                                            'strikeThrough',
                                                            'subscript',
                                                            'superscript',
                                                            'fontFamily',
                                                            'fontSize',
                                                            'textColor',
                                                            'backgroundColor',
                                                            'inlineClass',
                                                            'inlineStyle',
                                                            'clearFormatting'
                                                        ],
                                                        align: 'left',
                                                        buttonsVisible: 0
                                                    },
                                                    moreRich: {
                                                        buttons: ['insertLink'],
                                                        align: 'left',
                                                        buttonsVisible: 0
                                                    }
                                                }
                                            }}
                                            model={learnMore.data ? learnMore.data : 'Learn more'}
                                            onModelChange={this.handleChangeLearnMore}
                                        />
                                        <button className='save-edit-block'
                                                onClick={() => this.saveEditData('isEditLearnMore', 'learnMore')}>save
                                        </button>
                                    </div>
                                    :
                                    <span className={'details-link'}
                                          dangerouslySetInnerHTML={{__html: announcement_learn_more.data}}/>
                                }
                                {user.role === 'Admin' &&
                                <FaPencil className='edit-learn-more-btn'
                                          onClick={() => this.handleShowEditBlock('isEditLearnMore')}/>}
                            </div>

                            <span className="created-at">
                                    Posted {moment(announcementLastModified).format('LL HH:mm Z')}
                                </span>
                        </div>
                    </div>
                    }
                </div>

                <div className="home-body">
                    <div className="amount-row">
                        <div className="terms-to-search">
                            <div className="amount">
                                {this.props.info.terms_count}
                            </div>

                            <div className="description">
                                <p>terms to search, learn and share in many languages!</p>

                                <Link className="details-link-home" to={'/about'}>
                                    learn more
                                </Link>
                            </div>
                        </div>

                        <div className="teams-and-tens">
                            <div className="amount">
                                {this.props.info.teams_count}
                            </div>

                            <div className="description">
                                <p>teams and tens of volunteers from across the world!</p>

                                <Link className="details-link-home" to={'/contributors'}>
                                    meet the teams
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="new-term-header-row">
                        <div className={'new-term-header-col'}>
                            <p className="term-title">
                                Learn a new term every day!
                            </p>

                            <span className={'new-term-header-link'} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.props.clearEmbeddedTerm();
                                this.props.getRandomTerm();
                            }}>
                                show another term
                            </span>
                        </div>
                    </div>

                    <div className="bim-card-row">
                        <RandomTerm
                            term={randomTerm}
                            langs={langs}
                            onChangeTranslation={(language) => this.onChangeTranslation(language)}
                        />
                    </div>

                    <TopTerms topTerms={topTerms}/>

                    <div className="wrapper-contributors-page sponsor-section-wrapper">
                        <ContributorsSectionTextEdit
                            user={user}
                            title={"Sponsors and Supporters"}
                            content={sponsorSection}
                            exampleContent={sponsorExampleText}
                            contentName={"sponsorSection"}
                            nameEditSect={"sponsor_sect_isEdit"}
                            isEditSect={sponsor_sect_isEdit}
                            handleShowEditBlock={(sectName) => this.handleShowEditBlock(sectName)}
                            handleChangeFroala={(content, data) => this.handleChangeFroala(content, data)}
                            handleSaveData={(nameEditSect, content) => this.saveEditData(nameEditSect, content)}
                        />
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    topTerms: state.terms.topItems,
    info: state.terms.homeInfo,
    randomTerm: state.terms.item,
    langs: state.terms.langs,
    user: state.auth.user,
    announcement_title: state.common.announcement_title,
    announcement_body: state.common.announcement_body,
    announcement_learn_more: state.common.announcement_learn_more,
    announcementLastModified: state.common.announcementLastModified,
    sponsorSection: state.contributors.sponsorSection,
});

const mapDispatchToProps = (dispatch) => ({
    fetchHomeInfo: () => dispatch(fetchHomeInfo()),
    fetchTopTerms: () => dispatch(fetchTopTerms()),
    clearEmbeddedTerm: () => dispatch(clearEmbeddedTerm()),
    getAnnouncementTitle: () => dispatch(getAnnouncementTitle()),
    getAnnouncementBody: () => dispatch(getAnnouncementBody()),
    getAnnouncementLearnMore: () => dispatch(getAnnouncementLearnMore()),
    getRandomTerm: (termId, lang) => dispatch(getRandomTerm(termId, lang)),
    updateAnnouncement: (path, data) => dispatch(updateAnnouncement(path, data)),
    fetchSponsorSection: () => dispatch(getContributorsSponsorSection()),
    setContributorsParagraphs: (path, data) => dispatch(setContributorsParagraphs(path, data)),
    updateContributorsParagraphs: (path, data) => dispatch(updateContributorsParagraphs(path, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
