import React, {Component, useState} from "react";
import {connect} from "react-redux";
import axios from "axios";
import PrintHeader from "./Header";
import PrintFooter from "./Footer";
import Pdf from "react-to-pdf";
import PrintContent from "./Content";
import {PDFExport, savePDF} from '@progress/kendo-react-pdf';
import PageTemplate from "./PageTemplate";

// import {styles} from './fontStyles'

class PrintTermTemplate extends Component {
    pdfExportComponent;
    state = {
        isLoading: true,
    };

    componentDidMount() {
        this.setState({isLoading: true});
        let compToPrint = document.getElementById('component-for-print');
        let fontSize = 12;
        let lineHeight = 1.5;
        this.changeOlMargin(compToPrint, fontSize, lineHeight);
        this.changeUlStyles(compToPrint, fontSize, lineHeight);
        this.changePtagStyles(compToPrint, fontSize, lineHeight);
        this.changeSpanStyles(compToPrint, fontSize, lineHeight);
        this.changeEmtagStyles(compToPrint, fontSize, lineHeight);
        this.changeStrongtagStyles(compToPrint, fontSize, lineHeight);
        this.imagesSetMaxWidth(compToPrint, 532, fontSize, lineHeight);
        this.changeReplacedUlEls(compToPrint, 532, fontSize, lineHeight);
        this.setState({isLoading: false});
    }

    render() {
        const {term} = this.props;
        const {isLoading} = this.state;
        const file_name = term ? (term.content && term.content.title) || term.title : 'BimTerm';
        const PageTemplateWrapper = (libProps) => (<PageTemplate {...this.props} {...libProps}/>);

        const styleButtonLoading = {
            width: '100%',
            height: "44px",
            background: '#696969',
            color: 'white',
            fontSize: "18px",
            fontWeight: 'bold',
        };

        const styleButtonReady = {
            width: '100%',
            height: "44px",
            background: '#4bacc6',
            color: 'white',
            fontSize: "18px",
            fontWeight: 'bold',
        };

        return (
            <>
                <button disabled={isLoading} style={isLoading ? styleButtonLoading : styleButtonReady}
                        className="button" onClick={() => this.handleSavePdf()}>
                    {isLoading ? 'Loading...' : 'Export PDF (Beta)'}
                </button>
                <PDFExport
                    pageTemplate={PageTemplateWrapper}
                    margin={{left: 0, top: 98, right: 0, bottom: 140}}
                    paperSize="A4"
                    // margin={{left: 0, top: 0, right: 0, bottom: 0}}
                    ref={(component) => this.pdfExportComponent = component}
                    fileName={file_name}

                >
                    <div id={'component-for-print'} className={'main-div-component-print'}>
                        <PrintContent {...this.props}/>
                    </div>
                </PDFExport>
            </>
        )
    }

    imagesSetMaxWidth(comp, maxWidth, fontSize, lineHeight) {
        let elements = comp.getElementsByTagName("img");
        for (let i = 0; i < elements.length; i++) {
            let el = elements[i];
            el.style.maxWidth = `100%`;
            if (el.parentElement && el.parentElement.tagName === 'TD') {
                if (el.style.width) {
                    let elWidth = el.style.width;
                    if (elWidth.includes('px')) {
                        elWidth = elWidth.replace(/px/g, '');
                        let elWidthInt = parseInt(elWidth);
                        let nextWidth = elWidthInt > maxWidth ? maxWidth : elWidthInt;
                        el.parentElement.style.width = `${nextWidth}px`;
                        el.style.maxWidth = `${maxWidth}px`;
                    }
                }
            }
        }
    }

    changeOlMargin(comp, fontSize, lineHeight) {
        let elements = comp.getElementsByTagName("ol");
        for (let i = 0; i < elements.length; i++) {
            let el = elements[i];
            el.setAttribute('style', `font-size: ${fontSize}px; line-height: ${lineHeight};`);
        }
    }

    changeUlStyles(comp, fontSize, lineHeight) {
        let elements = comp.getElementsByTagName("ul");
        for (let i = 0; i < elements.length; i++) {
            let el = elements[i];
            el.setAttribute('style', `font-size: ${fontSize}px; line-height: ${lineHeight};`);
        }
    }

    changePtagStyles(comp, fontSize, lineHeight) {
        let elements = comp.getElementsByTagName("p");
        for (let i = 0; i < elements.length; i++) {
            let el = elements[i];
            el.setAttribute('style', `font-size: ${fontSize}px; line-height: ${lineHeight};`);
        }
    }

    changeEmtagStyles(comp, fontSize, lineHeight) {
        let elements = comp.getElementsByTagName("em");
        for (let i = 0; i < elements.length; i++) {
            let el = elements[i];
            el.setAttribute('style', `font-size: ${fontSize}px; line-height: ${lineHeight};`);
        }
    }

    changeStrongtagStyles(comp, fontSize, lineHeight) {
        let elements = comp.getElementsByTagName("strong");
        for (let i = 0; i < elements.length; i++) {
            let el = elements[i];
            el.setAttribute('style', `font-size: ${fontSize}px; line-height: ${lineHeight};`);
        }
    }

    changeSpanStyles(comp, fontSize, lineHeight) {
        let elements = comp.getElementsByTagName("span");
        for (let i = 0; i < elements.length; i++) {
            let el = elements[i];
            if (el.id === "term-description") {
                el.setAttribute('style', `font-size: ${fontSize}px; line-height: ${lineHeight}; display: block;`);
            } else {
                el.setAttribute('style', `font-size: ${fontSize}px; line-height: ${lineHeight};`);
            }
        }
    }

    changeReplacedUlEls(comp, fontSize, lineHeight) {
        let elements = comp.getElementsByClassName('replaced-ul');
        for (let i = 0; i < elements.length; i++) {
            let el = elements[i];
            el.style.paddingLeft = "18px";
        }
    }

    handleSavePdf() {
        this.pdfExportComponent.save();
    }
}

const mapStateToProps = (state) => ({
    infoblocks: state.infoblocks.infoblocks,
    term: state.terms.item,
    user: state.auth.user
});

export default connect(mapStateToProps)(PrintTermTemplate);
