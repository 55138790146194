import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { FaChevronUp, FaChevronDown } from "react-icons/lib/fa";
import moment from "moment";


const Discussion = (props) => {

    const [form, setValues] = useState({
      isOpen: false,
      content: '',
      commentLength: 0,
      editorMessages: []
    });

    const discussionBlockState = () => {
        setValues({
            ...form,
            isOpen: !isOpen,
        });
    }
    const createComment = (e) => {
        const commentValue = e.target.value;
        setValues({
            ...form,
            content: commentValue,
            commentLength: commentValue.length,
        });
    }
    const sendCommentMess = () => {
        const newEditorComment = {
            content: form.content,
            id: props.termId,
            created: moment().valueOf(),
            user_role: props.user.role,
            username: props.user.fullname
        }
        props.sendComment(content);
        setValues({
            ...form,
            content: '',
            commentLength: 0,
            editorMessages: [...form.editorMessages, newEditorComment],
        });
    }

    const { isOpen, content, commentLength, editorMessages } = form;

    const messagesCount = editorMessages.length + props.messages.length;

    return (
        <div className='discussion-block-wrapper'>
            <div className='discussion-block-title'>
                <span>Discussion  <div>({messagesCount} message{messagesCount !== 1 ? 's' : ''})</div></span>
                { isOpen
                  ? <FaChevronUp onClick={discussionBlockState}/>
                  : <FaChevronDown onClick={discussionBlockState}/>
                }
            </div>
            <Collapse isOpen={isOpen} className="discussion-items">
                <div>
                {props.messages.length !==0 && props.messages.map((item, index) => (
                    <div className="discussion-item" key={index}>
                        <div className="discussion-item-title">
                            <p>{item.username}<span> ({item.user_role})</span></p>
                            <span>{item.created && moment(item.created).format('LL')}</span>
                        </div>
                        <p>{item.content}</p>
                    </div>
                ))}
                {editorMessages.length > 0 && editorMessages.map((item, index) => (
                    <div className="discussion-item" key={index}>
                        <div className="discussion-item-title">
                            <p>{item.username}<span> ({item.user_role})</span></p>
                            <span>{item.created && moment(item.created).format('LL')}</span>
                        </div>
                        <p>{item.content}</p>
                    </div>
                ))}
                </div>

            </Collapse>
            <div className="discussion-item-comment">
                <textarea maxLength="500" placeholder="Your comment" value={content} onChange={createComment}/>
                <span><strong>{commentLength}</strong> /500</span>
            </div>
            <div className="add-reply-wrapper">
                <button disabled={content.length === 0} className='add-reply' onClick={sendCommentMess}>Add Reply</button>
            </div>
        </div>
    )

}
export default Discussion
