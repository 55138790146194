import {Modal} from 'react-bootstrap';
import React, {Component} from "react";
import * as PropTypes from "prop-types";

class ModalRejectTranslation extends Component {

    state = {
        value: ''
    };

    onChange = (e) => {
        this.setState({
            value: e.target.value
        })
    };

    render() {
        const {isOpen, onCancelClick, onSendClick} = this.props;
        const {value} = this.state;

        return (
            <Modal show={isOpen} className="modal-dialog-translation-reject"
                   size="lg" centered
                   onHide={() => onCancelClick()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span>Ask for update</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please provide the reason and describe your request to editor in details.</p>
                    <textarea value={value} onChange={this.onChange}/>
                </Modal.Body>
                <Modal.Footer>
                    <div onClick={() => onCancelClick()} className="cancel-button">Cancel</div>
                    <div onClick={() => onSendClick(value)} className="console-button">Send</div>
                </Modal.Footer>
            </Modal>
        )
    }
}

ModalRejectTranslation.propTypes = {
    isOpen: PropTypes.bool,
    onCancelClick: PropTypes.func,
    onSendClick: PropTypes.func
};

export default ModalRejectTranslation;