import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import { NotificationContainer } from 'react-notifications';
import { fetchCurrentUserInfo, signOut, signOutOtherTab } from "../store/auth/actions";
import Header from "../helpers/Header";
import Footer from "../helpers/Footer";
import { clearSearchedTerms } from "../store/terms/actions";
import LoadingOverlay from '../helpers/LoadingOverlay';
import Navigation from "./Navigation";

class MainApp extends Component {
    state = {
        isOpen: false,
    };

    _toggle = () => {
        this.setState({
          isOpen: !this.state.isOpen
        });
    };

    componentDidMount() {
        if (localStorage.getItem('access') && localStorage.getItem('refresh') && this.props.user.email === undefined) {
            this.props.fetchCurrentUserInfo();
        }
        window.addEventListener('storage', this.logout);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.clearSearchedTerms();
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.logout);
    }

    logout = (event) => {
        if (event.key === 'access' && event.newValue === null) {
            this.props.signOutOtherTab();
        }
    };

    render() {
        const { isOpen } = this.state;
        const { isAuthorized, signOut, user } = this.props;

        return (
            <div className="app">
                <div className='main-content-wrapper'>
                    <NotificationContainer />
                    <Header
                        user={user}
                        isOpen={isOpen}
                        isAuthorized={isAuthorized}
                        toggle={this._toggle}
                        signOut={signOut}
                    />
                    <div className='central-block-wrapper'>
                        <LoadingOverlay delay={500}/>
                        <Navigation />
                    </div>
                </div>

                <Container fluid={true} className={'footer'}>
                    <Footer />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isAuthorized: state.auth.isAuthorized    
});

const mapActionsToProps = (dispatch) => ({
    fetchCurrentUserInfo: () => dispatch(fetchCurrentUserInfo()),
    clearSearchedTerms: () => dispatch(clearSearchedTerms()),
    signOut: () => dispatch(signOut()),
    signOutOtherTab: () => dispatch(signOutOtherTab()),
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(MainApp));
