import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {Col, Container} from "reactstrap";
import {
    fetchDocumentInfoblocks, fetchLanguageList, fetchTerm, fetchTermBySlug,
    searchTerms, updateTermDialogShowStatus
} from "../../store/terms/actions";
import TermHeader from './partials/TermHeader'
import NotesBox from './partials/NotesBox'
import ListTemplate from "./partials/ListTemplate";
import PageTemplate from "./partials/PageTemplate";
import LocalNotification from "../../helpers/LocalNotification";
import DocumentTemplate from "../ManagementConsole/Terms/templates/document/DocumentTemplate";
import DocumentInfoblocks from "../ManagementConsole/Terms/templates/document/leftSidebar/DocumentInfoblocks";
import {Modal, Row} from "react-bootstrap";
import PrintTermTemplate from "./PrintTermTemplate/PrintTermTemplate";

class TermTemplate extends Component {

    state = {
        clickedEmbeddedTerm: false,
        notAuthorizedNotification: false,
    };

    async componentDidMount() {
        const {fetchTermBySlug, match: {params: {lang, slug, version}}, term} = this.props;

        if (term === undefined) {
            fetchTermBySlug(slug, lang, version);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.lang !== this.props.match.params.lang) {
            const {fetchTermBySlug, match: {params: {slug, lang, version}}, term} = this.props;
            fetchTermBySlug(slug, lang, version);
            this.props.fetchInfoblocks(term.id, lang);
        }
    }

    changeTranslation(language) {
        const {match: {params: {slug, version}}} = this.props;
        this.props.history.push(`/${language.code}/${slug}/${version}`);
    };

    updateEmbeddedTermClick = (data) => {
        this.setState({clickedEmbeddedTerm: data});
    };

    setNotAuthorizedNotification(notAuthorizedNotification) {
        this.setState({
            notAuthorizedNotification
        })
    };

    handleShowPrintTemplateDialogClick = event => {
        this.props.updateTermDialogShowStatus('showPrintTermTemplateDialog')
    };

    render() {
        const {isEditing, moveToEdit, term, handleSaveTemplateData, clearChildrenState, handleClearChildrenData, handleSaveChildData, handleSaved, showTranslation, dashbTerm, termDialogs} = this.props;
        const {clickedEmbeddedTerm, notAuthorizedNotification} = this.state;
        const forTranslation = !!this.props.forTranslation;

        const termContent = term.content || {};
        const termMaxVersion = term.max_version || {};
        const contentIsEnglish = termContent.language === 'en';
        const engContentVersion = termMaxVersion.number || 1;
        const translationContentVersion = termContent.version || 1;
        const contentIsNewest = engContentVersion === translationContentVersion;

        let title, description;
        if (Object.keys(term).length) {
            if (showTranslation) {
                title = term.my_translation.title;
                description = term.my_translation.description;
            } else {
                title = termContent && termContent.title;
                description = termContent && termContent.description;
            }
        }

        const styles = {};

        let reviewerRejectionMessege = dashbTerm && dashbTerm.messages && dashbTerm.messages[dashbTerm.messages.length - 1];
        let reviewerRejection = reviewerRejectionMessege ? reviewerRejectionMessege.content : null;

        return (
            <Container
                className={`switch-term-container clearfix w-sm ${term.extended_description_type === 2 && this.props.infoblocks && this.props.infoblocks.length !== 0 ? "document-container" : ""}`}
                style={styles}>

                {!contentIsNewest && termContent && (
                    <div style={{paddingBottom: '1.5rem'}}>
                        <div style={{
                            textAlign: 'center',
                            padding: '16px 16px',
                            backgroundColor: '#ff1a002b',
                            borderRadius: '0.5rem'
                        }}>
                            {(contentIsEnglish || termMaxVersion.already_has_actual_version) && (
                                <>
                                    There is a newer version of this term, click <Link
                                    to={`/${termContent.language}/${term.slug}/${engContentVersion}`}>
                                    here
                                </Link> to display it
                                </>
                            ) || (
                                <>
                                    You are viewing a translation for an <Link
                                    to={`/en/${term.slug}/${translationContentVersion}`}>
                                    older English version
                                </Link> of the term. Translation of the <Link
                                    to={`/en/${term.slug}/${engContentVersion}`}>
                                    current English version
                                </Link> will be available soon.
                                </>
                            )}
                        </div>
                    </div>
                )}

                {isEditing && reviewerRejection && (
                    <>
                        <div style={{'padding': '8px 16px', 'backgroundColor': '#ff1a002b', "marginBottom": "-10px"}}>
                            <h4 style={{'textAlign': 'center'}}>Reviewer`s rejections:</h4>
                            {reviewerRejection}
                        </div>
                        <br/>
                    </>
                )}

                {term.extended_description_type === 2 && this.props.infoblocks && this.props.infoblocks.length !== 0 && (
                    <DocumentInfoblocks
                        forTranslation={forTranslation}
                        isEdit={isEditing}
                    />
                )}

                <div id="current-term-row-for-view"
                     className={`current-term-row ${clickedEmbeddedTerm && 'term-card-shadow_background'} ${term.extended_description_type === null ? 'rounded-edges' : ''}`}
                     style={{
                         borderBottomLeftRadius: 0,
                         borderBottomRightRadius: 0,
                     }}
                >
                    <div className={'current-term-collection-wrapper'}>
                        <Col className={'current-term-col static'}>
                            <TermHeader
                                title={title}
                                description={description}
                                term={term}
                                onChangeTranslation={(language) => this.changeTranslation(language)}
                                isTermBar={moveToEdit}
                                isEditing={isEditing}
                                clearChildrenState={clearChildrenState}
                                handleClearChildrenData={handleClearChildrenData}
                                handleSaveChildData={(data) => handleSaveChildData(data)}
                                handleSaved={handleSaved}
                                updateEmbeddedTermClick={this.updateEmbeddedTermClick}
                                closeEmbeded={this.closeEmbeded}
                                printTemplateHandleClick={this.showHidePrintTemplateModal}
                            />
                            {term.extended_description_type !== false && (
                                <>
                                    {term.extended_description_type === 0 && (
                                        <ListTemplate
                                            term={term}
                                            updateEmbeddedTermClick={this.updateEmbeddedTermClick}
                                            closeEmbeded={this.closeEmbeded}
                                            isEdit={isEditing}
                                            showTranslation={showTranslation}
                                            handleSaveChildData={(data) => handleSaveTemplateData(data)}
                                            clearChildrenState={clearChildrenState}
                                            language={this.props.match.params.lang}
                                        />
                                    )}

                                    {(term.extended_description_type === 1 && Object.entries(termContent.extended_description).length !== 0) && (
                                        <PageTemplate
                                            term={term}
                                            isEditing={isEditing}
                                            showTranslation={showTranslation}
                                            clearChildrenState={clearChildrenState}
                                            handleSaved={handleSaved}
                                            handleSaveChildData={(data) => handleSaveTemplateData(data)}
                                        />
                                    )}

                                    {term.extended_description_type === 2 && (
                                        <DocumentTemplate
                                            singleTerm={term}
                                            isEdit={isEditing}
                                            fetchAction={this.props.fetchInfoblocks}
                                            updateEmbeddedTermClick={this.updateEmbeddedTermClick}
                                        />
                                    )}
                                </>
                            )}
                            <LocalNotification
                                text={'Please login to use this feature'}
                                show={notAuthorizedNotification}
                                onClose={() => this.setNotAuthorizedNotification(false)}/>
                        </Col>
                    </div>
                </div>

                {!moveToEdit && (
                    <NotesBox
                        term={term}
                        drawTopBorder={false}
                        onNotAuthorizedClick={() => this.setNotAuthorizedNotification(true)}/>
                )}

                {termDialogs && termDialogs.showPrintTermTemplateDialog !== undefined && (
                    <Modal
                        dialogClassName='modal-to-print'
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={termDialogs.showPrintTermTemplateDialog}
                        onHide={this.handleShowPrintTemplateDialogClick}
                    >
                        <PrintTermTemplate/>
                    </Modal>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    langs: state.terms.langs,
    isAuthorized: state.auth.isAuthorized,
    user: state.auth.user,
    infoblocks: state.infoblocks.infoblocks,
    dashbTerm: state.dashboard.term,
    termDialogs: state.terms.termDialogs,
});

const mapActionsToProps = (dispatch) => ({
    fetchTerm: (termId, lang) => dispatch(fetchTerm(termId, lang)),
    fetchTermBySlug: (slug, lang, version) => dispatch(fetchTermBySlug(slug, lang, version)),
    fetchLanguageList: () => dispatch(fetchLanguageList()),
    searchTerms: (data) => dispatch(searchTerms(data)),
    updateTermDialogShowStatus: (name) => dispatch(updateTermDialogShowStatus(name)),
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TermTemplate));
