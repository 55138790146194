import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {
    fetchTerm, searchTerms, fetchLanguageList, fetchTermBySlug
} from "../../store/terms/actions";
import TermTemplate from './TermTemplate'
import {fetchDocumentInfoblocks} from "../../store/infoblocks/actions";
import {assignInfoblockFromLink} from "../../store/common/actions";

class TermTemplateView extends Component {

    state = {
        is_newest: true
    }

    async componentDidMount() {
        const {fetchTermBySlug, match: {params: {lang, slug, version, infoblock}}} = this.props;
        const result = await fetchTermBySlug(slug, lang, version);
        if (result.payload.data.data.content.title === '' && result.payload.data.data.content.description === '') {
            this.props.history.push(`/en/${slug}/${result.payload.data.data.max_version.number}`);
            fetchTermBySlug(slug, 'en', result.payload.data.data.max_version.number)
            return;
        }

        if (infoblock !== undefined) {
            this.props.assignInfoblockFromLink(infoblock);
        }

        const term = result.payload.data.data
        this.props.fetchDocumentInfoblocks(term.id, term.content.language);

        this.setState({
            is_newest: result.payload.data.data.is_newest
        });
        setTimeout(() => this.setState({is_newest: true}), 2000)
    }

    componentDidUpdate(prevProps) {
        const {fetchTermBySlug, match: {params: {lang, slug, version}}} = this.props;
        if (prevProps.match.params.slug !== slug || prevProps.match.params.version !== version) {
            fetchTermBySlug(slug, lang, version);
        }
    }


    render() {
        const {term, fetchDocumentInfoblocks} = this.props;
        const {is_newest} = this.state;
        return (
            <TermTemplate term={term} is_newest={is_newest}
                          fetchInfoblocks={fetchDocumentInfoblocks}/>
        );
    }
}

const mapStateToProps = (state) => ({
    term: state.terms.item,
    infoblocks: state.terms.infoblocks,
});

const mapActionsToProps = {fetchTerm, fetchTermBySlug, fetchLanguageList, searchTerms, fetchDocumentInfoblocks, assignInfoblockFromLink};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TermTemplateView));
