import React, { Component } from "react";
import { connect } from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import { createNote, removeNote, editNote, fetchStarredTerms } from '../../store/terms/actions';
import NoteEditor from "../Term/partials/NoteEditor";

class StarredTermNote extends Component {
    state = {
        id: '',
        note: '',
        isEdit: false
    };

    componentDidMount() {
        const myNote = this.props.favouriteTerms.filter((term) => term.id === this.props.term.id)[0];
        this.setState({
            id: myNote.my_note ? myNote.my_note.id : '',
            note: myNote.my_note ? myNote.my_note.note : ''
        })
    }

    _handleSaveNote = async () => {
        const {note} = this.state;
        const {id} = this.props.term;

        if (this.state.id === '') {
            await this.props.createNote({ term_id: id, note: note });
            const createdNote = this.props.favouriteTerms.filter((term) => term.id === this.props.term.id)[0].my_note;
            this.setState({ id: createdNote.id, note: createdNote.note });
        } else {
            this.props.editNote({id: this.state.id, note: note });
        }
        this.setState({ isEdit: false });
    }

    _removeNote = () => {
        const {id} = this.state;
        const myNote = this.props.favouriteTerms.filter((term) => term.id === this.props.term.id)[0];
        
        if (myNote.my_note){ 
            this.props.removeNote(id);
            this.setState({ note: "", id: "" });
        } 
    }

    changeValue(note) {
        this.setState({note});
    }

    render(){
        const myNote = this.props.favouriteTerms.filter((term) => term.id === this.props.term.id)[0].my_note;
        const {note, isEdit} = this.state;

        return(
            <div className='starred_notes-body'>
                <div>
                <p className="starred_subtitle">Your Notes</p>
                    <div className='notes'>
                        {!isEdit
                            ? (myNote ? <div className="note-content" dangerouslySetInnerHTML={{__html: myNote.note }}/> : 'No notes')
                            : <form className="form-group">
                                <NoteEditor
                                    note={note}
                                    edit={isEdit}
                                    onNoteChange={(text) => this.changeValue(text)}/>
                                <button type="button"
                                        onClick={this._handleSaveNote}
                                        className="console-button">
                                    Save
                                </button>
                            </form>
                        }
                    </div>
                </div>                

                <div className='notes-btn'>
                    <div className='notes-btn-counter'>
                        {(this.props.user.role !== 'Editor' && !this.props.user.rich_text_editor) &&
                            <>
                                <strong>{isEdit ? note.length : (myNote && myNote.note ? myNote.note.length : 0)}</strong>/500
                            </>
                        }
                    </div>
                    <div className='notes-btn-trash'>
                        <FontAwesomeIcon icon={faTrashAlt} onClick={this._removeNote}/>
                    </div>
                    <div className='notes-btn-pencil'>
                        <FontAwesomeIcon icon={faPencilAlt} onClick={() => this.setState({isEdit: !isEdit})}/>
                    </div>
                </div>
            </div>
        )    
    }
}

const mapStateToProps = (state) => ({
    favouriteTerms: state.terms.favouriteTerms,
    user: state.auth.user
});

const mapActionsToProps = (dispatch) => ({
    fetchStarredTerms: () => dispatch(fetchStarredTerms()),
    createNote: (data) => dispatch(createNote(data)),
    removeNote: (id) => dispatch(removeNote(id)),
    editNote: (data) => dispatch(editNote(data)),
});

export default connect(mapStateToProps, mapActionsToProps)(StarredTermNote)