import React, {Component} from "react";
import {connect} from "react-redux";
import {FormGroup, Button, Row, Col, Container} from "reactstrap";
import {withRouter, Link} from "react-router-dom";
import {clearErrors, signUp} from "../../store/auth/actions";
import StyledCheckbox from "../../helpers/StyledCheckbox";
import {NotificationManager} from "react-notifications";

class Register extends Component {

    state = {
        email: {
            data: '',
            isValid: false,
            isTouched: false,
        },
        password: {
            data: '',
            isValid: false,
            isTouched: false,
        },
        firstName: {
            data: '',
            isValid: false,
            isTouched: false,
            isCorrect: true,
        },
        lastName: {
            data: '',
            isValid: false,
            isTouched: false,
            isCorrect: true,
        },
        confirmPassword: {
            data: '',
            isValid: false,
            isTouched: false,
        },
        acceptTerms: {
            data: false,
            isValid: false,
            isTouched: false
        },
        isConfirm: true,
        errorMess: 'This field is required',
        errorEmail: 'Please enter a valid email address',
        confirmError: 'Password confirmation does not match to original',
        acceptTermsError: 'You should accept Terms of Use and Privacy Policy',
        incorrectField: 'Incorrect data'
    }

    checkValidEmail(str) {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(str)
    }

    _handleFieldChange = (e, name) => {
        const value = e.target.value;

        let isValid = false

        if (value !== '') isValid = true
        if (name === 'email') isValid = this.checkValidEmail(value)

        const toState = {data: value, isTouched: true, isValid}

        if (name === 'firstName' || name === 'lastName') {
            toState.isCorrect = !this.checkValidEmail(value)
            !toState.isCorrect && (toState.isValid = toState.isCorrect)
        }

        if (name === 'confirmPassword') {
            const {password} = this.state
            this.setState({
                isConfirm: value === password.data
            })
        }

        if (name === 'password') {
            const {confirmPassword} = this.state
            this.setState({
                isConfirm: value === confirmPassword.data
            })
        }

        this.setState({
            [name]: toState
        })
    };

    onAcceptTermsToggle = () => {
        this.setState(({acceptTerms}) => ({
            acceptTerms: {
                data: !acceptTerms.data,
                isTouched: true,
                isValid: !acceptTerms.data
            }
        }));
    };

    _handleSubmit = () => {
        let isValidForm = true;
        ['email', 'firstName', 'lastName', 'confirmPassword', 'password', 'acceptTerms'].forEach(each => {
            let {isValid, isCorrect} = this.state[each]
            isCorrect = typeof isCorrect !== 'undefined' ? isCorrect : true

            if (!isValid || !isCorrect) {
                isValidForm = false;
                this.setState(state => ({
                    ...state,
                    [each]: {
                        ...state[each],
                        isTouched: true
                    }
                }))
            }
        })

        if (isValidForm) {
            const {email, password, firstName, lastName, confirmPassword} = this.state
            const signUpData = {
                email: email.data,
                password: password.data,
                first_name: firstName.data,
                last_name: lastName.data,
                confirm: confirmPassword.data
            }
            this.props.signUp(signUpData)
                .then(res => {
                    NotificationManager.success('Verification email sent, please check your inbox');
                    this.props.history.push('/')
                })
                .catch(res => {
                    window.scrollTo(0, 0);
                });
        }
    }

    componentDidMount() {
        const {isAuthorized, history, clearErrors} = this.props;

        clearErrors();

        if (isAuthorized) {
            history.push('/');
        }
    }

    componentDidUpdate(prevProps) {
        const {isAuthorized, history} = this.props;
        if (!prevProps.isAuthorized && isAuthorized) {
            history.push('/');
        }
    }

    render() {
        const {email, firstName, lastName, password, confirmPassword, acceptTerms, errorMess, errorEmail, isConfirm, confirmError, acceptTermsError, incorrectField} = this.state;
        const {errMsg} = this.props;
        return (
            <>
            <div className="auth-title-container">
                <h1>Sign Up</h1>
            </div>
            <Container className="auth-container w-xs">
                <div className={'log_in-wrapper'}>
                    {this.props.isInvalidLogIn &&
                    <div className='error-text-auth'>Please, check your password or click Forgot Password</div>}
                    {errMsg &&
                    <div className='error-text-auth-message'>
                        {Object.keys(errMsg).map((err) => {
                            if (!Array.isArray(errMsg[err])) {
                                return;
                            }
                            return errMsg[err].map((field) => <div key={field}>{field}</div>)
                        })}
                    </div>}
                    <div className="contact-block-wrapper">
                        <div className="field-wrapper">
                            <h2>Email</h2>
                            <input className={!email.isValid && email.isTouched ? 'error-field' : ''} type="text"
                                   onChange={(e, name) => this._handleFieldChange(e, 'email')}/>
                            {!email.isValid && email.isTouched && <span className='error-text'>{errorEmail}</span>}
                        </div>
                    </div>
                    <div className="contact-block-wrapper row flex-row">
                        <div className="col-md-6">
                            <div className="field-wrapper">
                                <h2>First name</h2>
                                <input className={!firstName.isValid && firstName.isTouched ? 'error-field' : ''}
                                       type="text" onChange={(e, name) => this._handleFieldChange(e, 'firstName')}/>
                                {!firstName.isValid && firstName.isCorrect && firstName.isTouched &&
                                <span className='error-text'>{errorMess}</span>}
                                {!firstName.isCorrect && !firstName.isValid && firstName.isTouched &&
                                <span className='error-text'>{incorrectField}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="field-wrapper">
                                <h2>Last name</h2>
                                <input className={!lastName.isValid && lastName.isTouched ? 'error-field' : ''}
                                       type="text" onChange={(e, name) => this._handleFieldChange(e, 'lastName')}/>
                                {!lastName.isValid && lastName.isCorrect && lastName.isTouched &&
                                <span className='error-text'>{errorMess}</span>}
                                {!lastName.isCorrect && !lastName.isValid && lastName.isTouched &&
                                <span className='error-text'>{incorrectField}</span>}
                            </div>
                        </div>
                    </div>

                    <div className="contact-block-wrapper">
                        <div className="field-wrapper">
                            <h2>Password</h2>
                            <input className={!password.isValid && password.isTouched ? 'error-field' : ''}
                                   type="password" onChange={(e, name) => this._handleFieldChange(e, 'password')}/>
                            {!password.isValid && password.isTouched && <span className='error-text'>{errorMess}</span>}
                            {!confirmPassword.isValid && confirmPassword.isTouched && !isConfirm &&
                            <span className='error-text'>{confirmError}</span>}
                        </div>
                    </div>
                    <div className="password-description">Minimum 15 characters including uppercase, lowercase, numbers
                        and special characters (e.g. $%&)
                    </div>
                    <div className="contact-block-wrapper">
                        <div className="field-wrapper">
                            <h2>Confirm password</h2>
                            <input
                                className={!confirmPassword.isValid && confirmPassword.isTouched ? 'error-field' : ''}
                                type="password" onChange={(e, name) => this._handleFieldChange(e, 'confirmPassword')}/>
                            {!confirmPassword.isValid && confirmPassword.isTouched &&
                            <span className='error-text'>{errorMess}</span>}
                            {confirmPassword.isValid && confirmPassword.isTouched && !isConfirm &&
                            <span className='error-text'>{confirmError}</span>}
                        </div>
                    </div>
                    <div className="contact-block-wrapper">
                        <div className="field-wrapper">
                            <div className="row-field-wrapper">
                                <StyledCheckbox changeSelect={() => this.onAcceptTermsToggle()}/>
                                <h2 style={{marginLeft: 20}}>&nbsp;I accept <Link to="/terms-of-use">Terms of
                                    Use</Link> and <Link to="/privacy-policy">Privacy policy</Link></h2>
                            </div>
                            {!acceptTerms.isValid && acceptTerms.isTouched &&
                            <span className='error-text'>{acceptTermsError}</span>}
                        </div>
                    </div>
                    <div className="contact-block-wrapper auth-block">
                        <button onClick={this._handleSubmit}>Sign Up</button>
                    </div>
                    <div className="contact-block-wrapper">
                        <div className="form-footer-link">
                            Already have an account? <Link to={'/login'}>Log In</Link>
                        </div>
                    </div>
                </div>
            </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isError: state.auth.isError,
    isAuthorized: state.auth.isAuthorized,
    errMsg: state.auth.errMsg
});

const mapActionsToProps = (dispatch) => ({
    signUp: (data) => dispatch(signUp(data)),
    clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Register));
