import React from "react";
import Logs from "../ManagementConsole/Log/Logs";
import {Container} from 'reactstrap';


const LogsByPermission = props => {
    return (
        <Container className="public-logs-container">
            <Logs isAdmin={false}/>
        </Container>
    )
};

export default LogsByPermission;