import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {clearEmbeddedTerm, fetchEmbeddedTerm} from "../../../store/terms/actions";
import EmbeddedTerm from "../../../helpers/EmbeddedTerm";
import reactStringReplace from 'react-string-replace';
import RelatedInfo from "./RelatedInfo";
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import TermBar from "./TermBar";
import {froala3Key} from "../../../configs/config";

class TermHeader extends Component {

    state = {
        title: {
            text: this.props.term.my_translation && this.props.term.my_translation.title ? this.props.term.my_translation.title : '',
            isSaved: true
        },
        description: {
            text: '',
            isSaved: false
        },
        acronym: {
            text: this.props.term.my_translation && this.props.term.my_translation.acronym ? this.props.term.my_translation.acronym : '',
            isSaved: true
        },
        plural_title: {
            text: this.props.term.my_translation && this.props.term.my_translation.plural_title ? this.props.term.my_translation.plural_title : '',
            isSaved: true
        },
        similar_terms: {
            text: this.props.term.my_translation && this.props.term.my_translation.similar_terms ? this.props.term.my_translation.similar_terms : '',
            isSaved: true
        },
        newData: {},
        clickedEmbeddedTerm: false
    };

    _hendleChangeEditField = (data, name) => {
        this.setState(state => {
            return {
                ...state,
                [name]: {
                    text: data,
                    isSaved: false
                }
            }
        })
    };

    saveEditData = (name) => {
        this.setState(state => {
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isSaved: true
                },
                newData: {
                    ...state.newData,
                    [name]: state[name].text
                }
            }
        }, () => this.props.handleSaveChildData(this.state.newData));
    };

    createMarkup = (data) => {
        return reactStringReplace(data, /(.*)/, (match, i) => (
            <span
                className='inline-topic_inner'
                key={`${i + Math.random()}_inner`}
                dangerouslySetInnerHTML={{__html: match}}/>));
    };

    onClickEmbedded(title, i) {
        const {term, fetchEmbeddedTerm, updateEmbeddedTermClick} = this.props;
        const {embeddedTerm} = this.props;
        title = title.replace(/(<span class='found-word'>)|(<\/span>)/gi, '');
        if (embeddedTerm.title !== undefined && embeddedTerm.title.toLowerCase() === title.toLowerCase()) {
            if (!this.state.clickedEmbeddedTerm) {
                this.setState({clickedEmbeddedTerm: true});
                updateEmbeddedTermClick(true);
            } else {
                this.setState({clickedEmbeddedTerm: false});
                updateEmbeddedTermClick(false);
            }
            return;
        }
        fetchEmbeddedTerm(title, term.content.language, i)
            .then((value) => {
                this.setState({clickedEmbeddedTerm: true});
                this.props.updateEmbeddedTermClick(true);
            });
    };

    closeEmbeded = () => {
        this.props.updateEmbeddedTermClick(false);
        this.setState({
            clickedEmbeddedTerm: false
        });
    };

    convertEmbeddedTerms() {
        const {embeddedTerm, description, parse} = this.props;
        if (description === undefined)
            return description;

        let parsed_desc = description
        // const regex = new RegExp(/(<.*?>)/)
        if (!!parse && description) {
            const descriptionText = description.match(/(<span.*?<\/span>)/)
            if (descriptionText) {
                parsed_desc = descriptionText[0].replace(/(<.*?>)/, '')
            }
        }
        return reactStringReplace(parsed_desc, new RegExp(/\[\[(.*?)]]/), (match, i) => (
            <span className='inline-topic_embedded'
                  key={`${i}_embedded`}
                  onClick={() => this.onClickEmbedded(match, i)}>
                <span dangerouslySetInnerHTML={{__html: match}}/>
                {i === embeddedTerm.id && this.state.clickedEmbeddedTerm && embeddedTerm.title &&
                <EmbeddedTerm
                    regExp={new RegExp(/\[\[(.*?)]]/)}
                    term={embeddedTerm}
                    title={embeddedTerm.title}
                    description={embeddedTerm.description}
                    closeEmbeded={this.closeEmbeded}/>
                }
            </span>
        ))
    };

    componentWillUpdate(nextProps) {
        if (nextProps.clearChildrenState) {
            this.setState(state => {
                return {
                    ...state,
                    title: {
                        text: this.props.term.my_translation && this.props.term.my_translation.title ? this.props.term.my_translation.title : '',
                        isSaved: false,
                    },
                    description: {
                        text: '',
                        isSaved: false,
                    },
                    plural_title: {
                        text: this.props.term.my_translation && this.props.term.my_translation.plural_title ? this.props.term.my_translation.plural_title : '',
                        isSaved: false,
                    },
                    acronym: {
                        text: this.props.term.my_translation && this.props.term.my_translation.acronym ? this.props.term.my_translation.acronym : '',
                        isSaved: false,
                    },
                    similar_terms: {
                        text: this.props.term.my_translation && this.props.term.my_translation.similar_terms ? this.props.term.my_translation.similar_terms : '',
                        isSaved: true
                    }
                }
            });
            this.props.handleClearChildrenData();
        }
    };

    componentWillUnmount() {
        this.props.clearEmbeddedTerm();
    };

    handleChangeTitle = (...data) => {
        this.setState({newAnnouncementsTitle: data[0]});
    };

    render() {
        const {title, description, plural_title, acronym, similar_terms} = this.state;
        const {term, isEditing} = this.props;

        return (
            <div className="wrapper-term-header">
                <div className="current-term-header">
                    <div className="current-term-title">
                        <div className={`${term.content && (term.content.language === 'ar' || term.content.langauge === 'fa') ? 'arabic' : ''} ${term.direction !== undefined ? term.direction : ''}`}>
                            <span dangerouslySetInnerHTML={{__html: this.props.title}}/>
                            {term.content && term.content.acronym && <span>&nbsp;({term.content.acronym})</span>}
                        </div>
                        {isEditing &&
                            <div className={title.isSaved ? 'edit_text-saved' : ''}>
                                <input className="title-edit-block"
                                       placeholder="Translate Title. To keep English Title, enter it again"
                                       type={"text"}
                                       dir={term.direction}
                                       value={title.text}
                                       onChange={(e) => {
                                           this._hendleChangeEditField(e.target.value, 'title')
                                       }}/>
                                <button disabled={!title.text || title.isSaved} className='save-edit-block'
                                        onClick={() => this.saveEditData('title')}>save
                                </button>
                            </div>}

                        {isEditing &&
                            <div className={acronym.isSaved ? 'edit_text-saved' : ''}>
                                <input className="title-edit-block"
                                       placeholder="Add Acronym if applicable"
                                       type={"text"}
                                       dir={term.direction}
                                       value={acronym.text}
                                       onChange={(e) => {
                                           this._hendleChangeEditField(e.target.value, 'acronym')
                                       }}/>
                                <button disabled={!acronym.text || acronym.isSaved} className='save-edit-block'
                                        onClick={() => this.saveEditData('acronym')}>save
                                </button>
                            </div>}

                        {isEditing &&
                            <div className={plural_title.isSaved ? 'edit_text-saved' : ''}>
                                <input className="title-edit-block"
                                       placeholder="Enter multiple plurals separated by commas"
                                       type={"text"}
                                       dir={term.direction}
                                       value={plural_title.text}
                                       onChange={(e) => {
                                           this._hendleChangeEditField(e.target.value, 'plural_title')
                                       }}/>
                                <button disabled={!plural_title.text || plural_title.isSaved} className='save-edit-block'
                                        onClick={() => this.saveEditData('plural_title')}>save
                                </button>
                            </div>}

                        {isEditing &&
                            <div className={similar_terms.isSaved ? 'edit_text-saved' : ''}>
                                <input className="title-edit-block"
                                       placeholder="Enter similar terms separated by commas"
                                       type={"text"}
                                       dir={term.direction}
                                       value={similar_terms.text}
                                       onChange={(e) => {
                                           this._hendleChangeEditField(e.target.value, 'similar_terms')
                                       }}/>
                                <button disabled={!similar_terms.text || similar_terms.isSaved} className='save-edit-block'
                                        onClick={() => this.saveEditData('similar_terms')}>save
                                </button>
                            </div>}

                    </div>
                        {!this.props.isTermBar &&
                            <TermBar term={term} showNotifications onChangeTranslation={this.props.onChangeTranslation}/>
                        }
                </div>
                <div className="current-term-description edit_term_list-description">
                    <div className={`${term.content && (term.content.language === 'ar' || term.content.langauge === 'fa') ? 'arabic' : ''} ${term.direction !== undefined ? term.direction : ''}`}>
                        {this.createMarkup(this.convertEmbeddedTerms())}
                    {/*                            {!!this.props.parse ?
                            this.convertEmbeddedTerms():
                            this.createMarkup(this.convertEmbeddedTerms())
                        }*/}
                    </div>
                    {isEditing &&
                    <div className={description.isSaved ? 'edit_text-saved' : ''}>

                        <FroalaEditor
                            tag='div'
                            config={{
                                htmlUntouched: true,
                                fullPage: true,
                                htmlRemoveTags: [],
                                key: froala3Key,
                                toolbarInline: true,
                                charCounterCount: false,
                                imageUploadParam: 'image_param',
                                imageUploadURL: '/api/froala_upload_image',
                                imageUploadMethod: 'POST',
                                direction: term.direction,
                                toolbarVisibleWithoutSelection: true,
                                attribution: false,
                                indentMargin: 0,
                                editorClass: 'editor-header-title',
                                placeholderText: 'Enter translation of the Summary Description (mandatory)',
                                toolbarButtons: {
                                    'moreText': {
                                        'buttons': ['bold', 'italic']
                                    },
                                    'moreMisc': {
                                        'buttons': ['undo', 'redo'],
                                        'align': 'right',
                                        'buttonsVisible': 2
                                    }
                                }
                            }}
                            model={description.text ? description.text
                                : (term.my_translation && term.my_translation.description
                                    ? term.my_translation.description : '')}
                            onModelChange={(...data) => {
                                this._hendleChangeEditField(data[0], 'description');
                            }}
                        />
                        <button disabled={!description.text || description.isSaved} className='save-edit-block'
                                onClick={() => this.saveEditData('description')}>save
                        </button>
                    </div>
                    }
                </div>
                <RelatedInfo term={term} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    embeddedTerm: state.terms.embeddedTerm,
});

const mapActionsToProps = (dispatch) => ({
    fetchEmbeddedTerm: (title, lang, id) => dispatch(fetchEmbeddedTerm(title, lang, id)),
    clearEmbeddedTerm: () => dispatch(clearEmbeddedTerm()),
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TermHeader));
