export const FETCH_CONTRIBUTORS = 'FETCH_CONTRIBUTORS';
export const FETCH_ALL_TEAMS = 'FETCH_ALL_TEAMS';
export const FETCH_EDITORS = 'FETCH_EDITORS';
export const FETCH_CONTRIBUTORS_MEET_SECTION = 'FETCH_CONTRIBUTORS_MEET_SECTION';
export const FETCH_CONTRIBUTORS_SPONSOR_SECTION = 'FETCH_CONTRIBUTORS_SPONSOR_SECTION';
export const FETCH_CONTRIBUTORS_ACKNOWLEDGEMENTS = 'FETCH_CONTRIBUTORS_ACKNOWLEDGEMENTS';
export const FETCH_TOPIC_CURATORS = 'FETCH_TOPIC_CURATORS';
export const UPDATE_CONTRIBUTORS_PARAGRAPHS = 'UPDATE_CONTRIBUTORS_PARAGRAPHS';
export const SET_CONTRIBUTORS_PARAGRAPHS = 'SET_CONTRIBUTORS_PARAGRAPHS';
export const CLEAR_CONTRIBUTORS_DATA = 'CLEAR_CONTRIBUTORS_DATA';
