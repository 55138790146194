import React, {Component} from "react";
import { connect } from "react-redux";
import {fetchEmbeddedTerm, clearEmbeddedTerm } from "../../../../store/terms/actions";
import {FaPencil, FaTrash} from "react-icons/lib/fa";
import Paginator from "../../../../helpers/Paginator";
import EmbeddedTerm from "../../../../helpers/EmbeddedTerm";
import reactStringReplace from 'react-string-replace';
import EditInput from "../../../../helpers/EditInput";
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import {froala3Key} from "../../../../configs/config";
import {Link} from "react-router-dom";

class ListTemplate extends Component {
    state = {
        per_page: 3,
        page: 0,
        clickedEmbeddedTerm: false,
        isEditTitle: false,
        isEditContent: false,
        titleValue: this.props.title ? this.props.title : '',
        contentValue: this.props.content ? this.props.content: ''
    };

    _paginatorChangedPage(data) {
        const page = data.selected;
        this.setState({
            page
        });
    }

    createMarkup = (data) => {
        return reactStringReplace(data, /(.*)/, (match, i) => (<span className='inline-topic_inner' key={`${i+Math.random()}_inner`} dangerouslySetInnerHTML={{__html: match}}></span>));
    };

    onClickEmbedded(title, i, term) {
        const { fetchEmbeddedTerm } = this.props;
        const {embeddedTerm} = this.props;
        title = title.replace(/(<span class='found-word'>)|(<\/span>)/gi, '');

        if (embeddedTerm.title !== undefined && embeddedTerm.title.toLowerCase() === title.toLowerCase()) {
            this.setState({clickedEmbeddedTerm: !this.state.clickedEmbeddedTerm});
            this.props.updateEmbeddedTermClick(!this.state.clickedEmbeddedTerm);
            return
        }

        fetchEmbeddedTerm(title, 'en', term.id)
            .then((value) => {
                this.setState({clickedEmbeddedTerm: true});
                this.props.updateEmbeddedTermClick(true);
            });
    }

    closeEmbeded = () => {
        this.props.updateEmbeddedTermClick(false);
        this.setState({
            clickedEmbeddedTerm: false
        });
    }

    convertEmbeddedTerms(term) {
        const {embeddedTerm} = this.props;
        if (term && term.content === undefined)
            return term.content;
        if (term && term.content) {
            return reactStringReplace(term.content, new RegExp(/\[\[(.*?)\]\]/, "gim"), (match, i) => (
                <span className='inline-topic_embedded' key={`${i}_embedded`} onClick={() => this.onClickEmbedded(match, i, term)}><span dangerouslySetInnerHTML={{__html: match}}></span>
                    {match === embeddedTerm.title && this.state.clickedEmbeddedTerm && embeddedTerm.title &&
                    <EmbeddedTerm regExp={new RegExp(/\[\[(.*?)\]\]/, "gim")} term={embeddedTerm} title={embeddedTerm.title} content={embeddedTerm.content} closeEmbeded={this.closeEmbeded}/>
                    }
                </span>
            ))
        }
    }

    toggleEditTitle = (isEditTitle) => {
        this.setState({
            ...this.state,
            isEditTitle
        })
    };

    toggleEditContent = (isEditContent) => {
        this.setState({
            ...this.state,
            isEditContent

        })
    };

    saveTitle = () => {
        this.props.onTitleSave(this.state.titleValue);
        this.toggleEditTitle(false)
    };

    saveContent = () => {
        this.props.onContentSave(this.state.contentValue);
        this.toggleEditContent(false)
    };

    componentWillUnmount() {
        this.props.clearEmbeddedTerm();
    }

    createMarkupField = (data) => {
        return {__html: data};
    };

    onChangeTitle = (data) => {
        this.setState({
            titleValue: data
        })
    };
    onChangeContent = (data) => {
        this.setState({
            contentValue: data
        })
    };

    render() {
        const {page, per_page, isEditTitle, isEditContent, titleValue, contentValue} = this.state;
        const {termsList, title, content, isEdit} = this.props;
        let {editListTemplate} = this.props;
        const limit = ((page * per_page) + per_page < termsList.length) ? (page * per_page) + per_page : termsList.length;

        if (editListTemplate === undefined) {
            editListTemplate = isEdit
        }

        return (
            <>
                {isEdit &&
                    <div className="wrapper-manage-terms">
                    <div className={`template-box ${editListTemplate ? `template-box-edit` : ''} ${isEditTitle ? 'title-edit_block' : ''}`}>
                        {!isEditTitle && <span dangerouslySetInnerHTML={this.createMarkupField(titleValue ? titleValue : (title ? title : 'List Template Title'))}></span>}
                        {isEditTitle &&
                        <>
                            <FroalaEditor
                                tag='div'
                                config={{
                                    htmlUntouched: true,
                                    fullPage: true,
                                    htmlRemoveTags: [],
                                    key: froala3Key,
                                    imageUploadParam: 'image_param',
                                    imageUploadURL: '/api/froala_upload_image',
                                    imageUploadMethod: 'POST',
                                    toolbarInline: true,
                                    charCounterCount: false,
                                    direction: 'ltr',
                                    toolbarVisibleWithoutSelection: true,
                                    attribution: false,
                                    indentMargin: 0,
                                    toolbarButtons: {
                                        'moreText': {
                                            'buttons': ['bold', 'italic']
                                        },
                                        'moreMisc': {
                                            'buttons': ['undo', 'redo'],
                                            'align': 'right',
                                            'buttonsVisible': 2
                                        }
                                    }
                                }}
                                model={titleValue ? titleValue : (title ? title : 'List Template Title')}
                                onModelChange={(...data) => {
                                    this.onChangeTitle(data[0]);
                                }}
                            />
                            {editListTemplate && <div className="console_edit-title-editing">
                                <button className='save-edit_btn'
                                        onClick={() => this.saveTitle()}>save
                                </button>
                                <span onClick={() => this.toggleEditTitle(false)}>Cancel</span>
                            </div>
                            }
                        </>}

                        {editListTemplate && !isEditTitle && <FaPencil className="pencil" onClick={() => this.toggleEditTitle(true)}/>}
                        {isEdit && !isEditTitle && <FaTrash onClick={() => this.props.removeTemplate()}/>}
                    </div>
                    {editListTemplate && (<button onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.props.addTerms()}}>Manage Terms</button>)}
                </div>
                }

                <div className="wrapper-list-template-terms">
                    {!isEditContent && <span className='description' dangerouslySetInnerHTML={this.createMarkupField(contentValue ? contentValue : (content ? content : 'Content'))}></span>}
                    {isEditContent &&
                        <div className={`current-term-description ${isEditContent ? 'description-edit_block' : ''}`}>
                            <FroalaEditor
                                tag='div'
                                config={{
                                    htmlUntouched: true,
                                    fullPage: true,
                                    htmlRemoveTags: [],
                                    key: froala3Key,
                                    toolbarInline: true,
                                    charCounterCount: false,
                                    direction: 'ltr',
                                    toolbarVisibleWithoutSelection: true,
                                    attribution: false,
                                    indentMargin: 0,
                                    imageUploadParam: 'image_param',
                                    imageUploadURL: '/api/froala_upload_image',
                                    imageUploadMethod: 'POST',
                                    editorClass: 'description-edit-input',
                                    toolbarButtons: {
                                        'moreText': {
                                            'buttons': ['bold', 'italic']
                                        },
                                        'moreRich': {
                                            'buttons': ['insertLink']
                                        },
                                        'moreMisc': {
                                            'buttons': ['html', 'undo', 'redo'],
                                            'align': 'right',
                                            'buttonsVisible': 3
                                        }
                                    }

                                }}
                                model={contentValue ? contentValue : (content ? content : 'Content')}
                                onModelChange={(...data) => {
                                    this.onChangeContent(data[0]);
                                }}
                            />

                            {editListTemplate && <div className="console_edit-title-editing">
                                <button className='save-edit_btn'
                                        onClick={() => this.saveContent()}>save
                                </button>
                                <span onClick={() => this.toggleEditContent(false)}>Cancel</span>
                            </div>
                            }
                        </div>
                    }
                    {editListTemplate && !isEditContent &&
                    <FaPencil className="pencil" onClick={() => this.toggleEditContent(true)}/>
                    }

                    {termsList && termsList.slice(page * per_page, limit).map((term, index) => (
                        <div className="wrapper-term-list" key={index}>
                            <div className="list-title"  >
                                <Link to={`/en/${term.slug}/${term.version ? term.version : '1'}`} dangerouslySetInnerHTML={{__html: term.title}}/>
                            </div>
                            <div className="description">
                                <div className="text-content">
                                    {this.createMarkup(this.convertEmbeddedTerms(term))}
                                </div>
                                <div className="icons-delete">
                                    {editListTemplate && (<FaTrash onClick={() => this.props.removeTerm(term)}/>)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {termsList.length !== 0 && (<div className="paginator-wrapper">
                    <Paginator
                        pageCount={termsList.length / per_page}
                        pageChanged={(data) => this._paginatorChangedPage(data)}
                        forcePage={page}
                        className={"console-paginator"}
                    />
                </div>)}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    embeddedTerm: state.terms.embeddedTerm,
    user: state.auth.user
});

const mapActionsToProps = (dispatch) => ({
    fetchEmbeddedTerm: (title, lang, id) => dispatch(fetchEmbeddedTerm(title, lang, id)),
    clearEmbeddedTerm: () => dispatch(clearEmbeddedTerm()),
});

export default connect(mapStateToProps, mapActionsToProps)(ListTemplate);
