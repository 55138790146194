import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';

const SelectedLabelsWrap = styled.div``;

const Item = styled.div`
	display: flex;
	align-items: flex-start;
	padding: 13px 10px;
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	font-size: 20px;
	line-height: 1.3;
	border-bottom: 1px solid #dedede;
	.item__title {
		flex: 2;
	}
	.item__controls {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		height: 27px;
	}
	.item-controls__icon {
		display: inline-block;
		vertical-align: middle;
		margin-right: 15px;
		height: 100%;
		color: #4bacc6;
		cursor: pointer;
		transition: opacity 0.25s ease;
		&:hover {
			opacity: 0.6;
		}
		&:last-child {
			margin-right: 0;
		}
		&.is-disabled {
			pointer-events: none;
    	opacity: 0.5;
		}
		svg {
			width: auto;
			height: 100%;
		}
	}
`;

const SelectedLabelRow = props => {
	const { selectedRows, deleteRow, dangerouslySetInnerHTML, deleteDisabled } = props;

	return (
		<SelectedLabelsWrap>
			{selectedRows.map((row, index) => (
				<Item key={index}>
					{
						dangerouslySetInnerHTML 
							? <div className='item__title' dangerouslySetInnerHTML={{__html: row.title}}></div>
							: <div className='item__title'>{row.label ? row.label : row.title}</div>
					}
					<div className='item__controls'>
						<span className={`item-controls__icon ${deleteDisabled ? 'is-disabled' : ''}`}>
							<FontAwesomeIcon
								icon={faTrashAlt}
								onClick={() => deleteRow(row)}
							/>
						</span>
					</div>
				</Item>
			))}
		</SelectedLabelsWrap>
	);
};

export default SelectedLabelRow;
