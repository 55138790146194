import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Modal, Container, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import {getAdminInfoblocksTitles, getAdminSingleInfoblock} from "../../../../../../store/console/actions";
import {fetchInfoblocksTypes, fetchTermsWithBlocks} from "../../../../../../store/infoblocks/actions";
import Filter from "../../../../../../helpers/Filter";


class InsertInfoblockModal extends Component {
    state = {
        selected: {},
        singleInfoblock: {},
        chosenParams: []
    };

    _filterParams = [
        {name: 'Term:', title: false, queryName: 'term', _getData: () => this.props.termsWithBlocks},
        {name: 'Type:', title: false,queryName: 'type', _getData: () => [{id: 0, title: 'Rich Text'}]},
        {name: 'Status:', title: false, queryName: 'status', _getData: () => this.props.infoblocksStatuses}
    ];


    toggleChooseAllFilterParams = () => {
        this.setState(state => ({chosenParams: state.chosenParams.length === 0 ?  [...this._filterParams.map(param => {param.title = false; return param;})] : []}))
    };

    _removeParam = (paramIndex, param) => {
        const {chosenParams} = this.state;
        this.setState({
            chosenParams: chosenParams.map((param, index) => {
                if (index === paramIndex) {
                    param.title = false;
                }
                return param;
            })
        }, () => this.callSearchAction());
    };

    chooseParamData = (selected, name) => {
        const {chosenParams} = this.state;

        this.setState({
            chosenParams: chosenParams.map((param, index) => {
                if (param.queryName === name) {
                    param.title = selected;
                }
                return param;
            })
        }, () => this.callSearchAction());
    };

    callSearchAction = () => {
        const {chosenParams} = this.state;
        this.props.getAdminInfoblocksTitles(
            `?${chosenParams
                .filter(param => param.title !== false)
                .map(param => param.queryName + '=' + param.title.value)
                .join('&')}`
        )
    };

    /**/
    selectInfoblock = async (selected) => {
        this.setState({selected});
        const response = await this.props.getAdminSingleInfoblock(selected.value);
        this.setState({singleInfoblock: response.payload.data.data});
    };
    onSave = () => this.props.onSave(this.state.selected, this.state.singleInfoblock);

    componentDidMount() {
        this.props.getAdminInfoblocksTitles();
        this.props.fetchTermsWithBlocks();
        this.props.fetchInfoblocksTypes();
    }

    clearData = () => {
        this.setState({singleInfoblock: {}});
        this.props.onHide();
    };

    render() {
        const {infoblocksTitles, infoblocks} = this.props;
        const {singleInfoblock, chosenParams} = this.state;

        const existingIds = infoblocks.flatRecursive().map((item) => item.id);
        const items = infoblocksTitles.filter((item) => !existingIds.includes(item.id));

        return (
            <Modal
                className="wrapper-base-modal insert-infoblock-modal"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.clearData}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Insert Information Block
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <Filter
                                    toggleChooseAllFilterParams={this.toggleChooseAllFilterParams}
                                    removeParam={this._removeParam}
                                    chosenParams={chosenParams}
                                    chooseParamData={this.chooseParamData}
                                    dontShowClear={true}
                                />
                            </Col>

                            <Col xs={12} md={12}>
                                <Select
                                    className="custom-param-data-select"
                                    onChange={this.selectInfoblock}
                                    options={items.map((item) => ({
                                        value: item.id,
                                        label: item.title
                                    }))}
                                    name={'type'}
                                    classNamePrefix="react-select"
                                />

                            </Col>

                            <Col xs={12} md={12}>
                                {singleInfoblock && <div className={`preview-content`}>
                                    <div className={`title-preview-content`}>Preview Content</div>
                                    {singleInfoblock.content && singleInfoblock.content !== '' &&
                                        <div dangerouslySetInnerHTML={{__html: singleInfoblock.content}} className={`content-preview-content`}/>
                                    }
                                </div>}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer className="delete-extended">
                    <span onClick={this.clearData}>Cancel</span>
                    <button className="console-button" onClick={this.onSave}>
                        Insert
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    infoblocksTitles: state.managementConsole.infoblocksTitles,
    singleInfoblock: state.managementConsole.singleInfoblock,
    infoblocks: state.infoblocks.infoblocks,
    termsWithBlocks: state.infoblocks.termsWithBlocks,
    infoblocksStatuses: state.infoblocks.infoblocksStatuses
});

const mapDispatchToProps = {getAdminSingleInfoblock, getAdminInfoblocksTitles, fetchTermsWithBlocks, fetchInfoblocksTypes};

export default connect(mapStateToProps, mapDispatchToProps)(InsertInfoblockModal)