import {success, error} from "redux-saga-requests";
import * as types from "./types";

const initialState = {
    isFetching: false,
    isError: false,
    errMsg: '',
    errorStatus: null,
    terms: [],
    term: {},
    notifications: [],
    notificationsTotal: []
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_EDITOR_TERM:
        case types.FETCH_AUTHORS_TERMS:
        case types.FETCH_EDITOR_TERM_STATUSES:
        // case types.FETCH_SEARCH_TERMS:
        case types.FETCH_NOTIFICATIONS:
        case types.DRAFT_EDITOR_TERM:
        case types.UPDATE_EDITOR_TERM:
        case types.SEND_EDITOR_MESSAGE:
        case types.FETCH_EDITOR_TERMS: {
            const loadInBackground = action.meta && action.meta.loadInBackground;
            return {
                ...state,
                isFetching: loadInBackground ? false : true,
                isError: false
            };
        }

        case success(types.FETCH_NOTIFICATIONS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                notifications: action.payload.data.data,
                notificationsTotal: action.payload.data.total
            };
        }

        case error(types.FETCH_NOTIFICATIONS): {
            return {
                ...state,
                isFetching: false,
                isError: true,
                notifications: [],
                notificationsTotal: 0,
                errMsg: action.payload.response.data,
                errorStatus: action.payload.response.status
            };
        }

        case success(types.UPDATE_NOTIFICATION): {
            return {
                ...state
            }
        }

        case error(types.UPDATE_NOTIFICATION): {
            return {
                ...state,
                errMsg: action.payload.response.data,
            };
        }

        case success(types.FETCH_EDITOR_TERM): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                term: action.payload.data.data
            };
        }

        case success(types.FETCH_EDITOR_TERM_STATUSES): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                statuses: action.payload.data
            };
        }

        case success(types.FETCH_EDITOR_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                terms: action.payload.data.data,
                totalTerms: action.payload.data.total,
                hasMoreTerms: action.payload.data.has_next_page
            };
        }

        case success(types.FETCH_AUTHORS_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                terms: action.payload.data.data
            };
        }

        case success(types.DRAFT_EDITOR_TERM): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                term: action.payload.data.data
            };
        }
        case success(types.UPDATE_EDITOR_TERM): {
            return {
                ...state,
                isFetching: false,
                isError: false
            };
        }
        case success(types.SEND_EDITOR_MESSAGE): {
            return {
                ...state,
                isFetching: false,
            };
        }

        case error(types.FETCH_EDITOR_TERM):
        case error(types.FETCH_EDITOR_TERM_STATUSES):
        case error(types.DRAFT_EDITOR_TERM):
        case error(types.UPDATE_EDITOR_TERM):
        case error(types.SEND_EDITOR_MESSAGE):
        case error(types.FETCH_EDITOR_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: true,
                errMsg: action.payload.response.data,
                errorStatus: action.payload.response.status
            };
        }

        default:
            return state;
    }
};

export default dashboardReducer;
