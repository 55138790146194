import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Container, Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {getAdminTeams, getUsers} from "../../../store/console/actions";

class ShareSetModal extends Component {
    state = {
        usersAndTeams: [],
        itemsToShare: []
    };

    _selectUser(selectedUser) {
        if (selectedUser === null) {
            return;
        }

        const {itemsToShare, usersAndTeams} = this.state;
        const itemToAdd = usersAndTeams[selectedUser.value];

        if (itemsToShare.findIndex(item => (item.id === itemToAdd.id && item.type === itemToAdd.type)) === -1) {
            this.setState({
                itemsToShare: [...itemsToShare, itemToAdd]
            });
        }
    }

    _deleteUser(userToDelete) {
        const {itemsToShare} = this.state;

        this.setState({
            itemsToShare: itemsToShare.filter(user => !(user.id === userToDelete.id && user.type === userToDelete.type))
        })
    }

    onSave() {
        const {itemsToShare} = this.state;

        this.props.onSave({itemsToShare});
    }

    componentDidMount() {
        Promise.all([
            this.props.getAdminUsers({all: true}),
            this.props.getAdminTeams({all: true}),
        ]).then(values => {
            const usersAndTeams = [
                ...values[0].payload.data.data.map(item => ({type: 0, name: item.fullname,  id: item.id})),
                ...values[1].payload.data.data.map(item => ({type: 1, name: item.name, id: item.id}))
            ].sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0);

            this.setState({
                usersAndTeams
            });
        })
    }

    render() {
        const {itemsToShare, usersAndTeams} = this.state;

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Share Set
                        <div>{this.props.name}</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <div className="select-team-title">
                                Select individual or team
                            </div>
                            <Col className="padding-none" xs={12} md={12}>
                                <Select
                                    onChange={(selected) => this._selectUser(selected)}
                                    value={{}}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={usersAndTeams.map((item, index) => ({
                                        value: index,
                                        label: item.name
                                    }))}
                                />
                            </Col>
                            <Col xs={12} md={12}>
                                {itemsToShare && itemsToShare.map((user, index) => (
                                    <Row className="team-person" key={index}>
                                        <Col xs={11} md={11}>
                                            {user.name}
                                        </Col>
                                        <Col xs={1} md={1}>
                                            <FontAwesomeIcon icon={faTrashAlt} className={'delete-user'}
                                                             onClick={() => this._deleteUser(user)}/>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="team-person-save">
                    <span onClick={this.props.onHide}>Cancel</span>
                    <button className="console-button" onClick={() => this.onSave()}>Save</button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    users: state.managementConsole.users,
    teams: state.managementConsole.teams
});

const mapDispatchToProps = (dispatch) => ({
    getAdminUsers: (data) => dispatch(getUsers(data)),
    getAdminTeams: (data) => dispatch(getAdminTeams(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareSetModal);