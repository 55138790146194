import React, {Component} from "react";
import {connect} from "react-redux";
import SimpleBarChart from './SimpleBarChart'
import CustomActiveShape from './CustomActiveShape'
import TeamsList from './partials/TeamsList'
import LanguageCard from './LanguageCard'
import ReactSimpleMap from './ReactSimpleMap'
import VisibilitySensor from "react-visibility-sensor";
import {Fade} from 'react-reveal';
import {
    fetchAllTeams,
    fetchContributors,
    getContributorsMeetSection,
    getContributorsSponsorSection,
    getContributorsAcknowledgementsSection,
    getContributorsTopicCuratorsSection,
    updateContributorsParagraphs,
    setContributorsParagraphs,
    clearContributorsData
} from "../../store/contributors/actions";
import ContributorsSectionTextEdit from "../../helpers/ContributorsSectionTextEdit";


class Contributors extends Component {

    state = {
        teamsName: 'language',

        chartsIsVisible: false,
        showAllTeams: false,
        showTopicCurators: false,
        showAcknowledgements: false,
        showAllTopicCurators: false,
        showAllCountryEditors: false,

        sponsor_sect_data: '',
        sponsor_sect_isEdit: false,

        meet_sect_data: '',
        meet_sect_isEdit: false,

        acknowledgements_sect_data: '',
        acknowledgements_sect_isEdit: false,

        topic_curators_sect_data: '',
        topic_curators_sect_isEdit: false
    };

    onChangeChart = (isVisible) => {
        if (isVisible) this.setState({
            ...this.state,
            chartsIsVisible: true
        })
    };

    setVisibleSection = (isVisible, sectName) => {
        if (isVisible) this.setState({
            ...this.state,
            [sectName]: true
        })
    }

    showAllTeams = (active) => {
        const {fetchAllTeams, allTeams} = this.props;
        if (allTeams !== 0) fetchAllTeams();
        this.setState({
            ...this.state,
            showAllTeams: active
        })
    };

    selectTeam = (name) => {
        this.setState({
            ...this.state,
            teamsName: name
        })
    };

    handleShowEditBlock(blockName) {
        const editingState = this.state[blockName];
        this.setState({
            ...this.state,
            [blockName]: !editingState
        });
    };

    componentWillMount() {
        this.props.fetchContributors();
        this.props.fetchMeetSection();
        // this.props.fetchSponsorSection();
        this.props.fetchAcknowledgementsSection();
        this.props.fetchTopicCuratorsSection();
    }

    componentWillUnmount() {
        this.props.clearContributorsData();
    }

    saveEditData = (blockName, blockData) => {
        const data = this.props[blockData];
        this.setState({
            ...this.state,
            [blockName]: false
        });
        this.props.updateContributorsParagraphs(blockData ,{content: data});
    };

    handleAcknowledgements = (...data) => {
        this.setState({acknowledgements: data[0]});
    };

    handleChangeFroala = (path, data) => {
        this.props.setContributorsParagraphs(path, data);
    };


    render() {
        const {contributorsData, allTeams, user, sponsorSection, meetSection, acknowledgementsSection, topicCuratorsSection} = this.props;
        const {sponsor_sect_isEdit, meet_sect_isEdit, acknowledgements_sect_isEdit, topic_curators_sect_isEdit,
                chartsIsVisible, showAcknowledgements, showTopicCurators, teamsName, showAllTeams} = this.state;
        const meetExampleText = '1Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.';
        const sponsorExampleText = 'Local: Lorem ipsum dolor sit amet, consectetur adipiscing elit';
        const acknowledgementsExampleText = 'Local: acknowledgementsExampleText'
        const topicCuratorsExampleText = 'Local: topicCuratorsExampleText'
        
        return (
            <div>
                <div className="legend-page-wrapper">
                    <div className="title">
                        Contributors
                    </div>
                </div>

                <div className="wrapper-contributors-page container">
                    {/*<ContributorsSectionTextEdit */}
                    {/*    user={user}*/}
                    {/*    title={"Sponsors and Supporters"}*/}
                    {/*    content={sponsorSection}*/}
                    {/*    exampleContent={sponsorExampleText}*/}
                    {/*    contentName={"sponsorSection"}*/}
                    {/*    nameEditSect={"sponsor_sect_isEdit"}*/}
                    {/*    isEditSect={sponsor_sect_isEdit}*/}
                    {/*    handleShowEditBlock={(sectName) => this.handleShowEditBlock(sectName)}*/}
                    {/*    handleChangeFroala={(content, data) => this.handleChangeFroala(content, data)}*/}
                    {/*    handleSaveData={(nameEditSect, content) => this.saveEditData(nameEditSect, content)}*/}
                    {/*/>*/}

                    <ContributorsSectionTextEdit 
                        user={user}
                        title={"Meet the team!"}
                        content={meetSection}
                        exampleContent={meetExampleText}
                        contentName={"meetSection"}
                        nameEditSect={"meet_sect_isEdit"}
                        isEditSect={meet_sect_isEdit}
                        handleShowEditBlock={(sectName) => this.handleShowEditBlock(sectName)}
                        handleChangeFroala={(content, data) => this.handleChangeFroala(content, data)}
                        handleSaveData={(nameEditSect, content) => this.saveEditData(nameEditSect, content)}
                    />

                    <div className="page-section d-none d-xl-block">
                        <div className="meet-team map">Contribution Map</div>
                        <ReactSimpleMap countries={contributorsData.countries}/>
                    
                        <VisibilitySensor onChange={this.onChangeChart}>
                            <span className='flag-transparent'>!</span>
                        </VisibilitySensor>
                        <div className="wrapper-charts">
                            {chartsIsVisible && <>
                                <SimpleBarChart translations={contributorsData.translations}/>
                                <CustomActiveShape translations={contributorsData.translations}/>
                            </>}
                        </div>
                    </div>

                    <div className="page-section">
                        <div className="wrapper-language-teams">
                            <Fade left>
                                <div className="wrapper-left">
                                    <div className="language-btn-wrapper">
                                        <button
                                            className={`language-btn ${teamsName == 'language' ? 'active-teams-name' : ''}`}
                                            onClick={() => this.selectTeam('language')}>Language Teams
                                        </button>
                                        {/*<button*/}
                                            {/*className={`language-btn active-team ${teamsName == 'active' ? 'active-teams-name' : ''}`}*/}
                                            {/*onClick={() => this.selectTeam('active')}>Most active teams*/}
                                        {/*</button>*/}
                                        {/*<button*/}
                                            {/*className={`language-btn newest-team ${teamsName == 'newest' ? 'active-teams-name' : ''}`}*/}
                                            {/*onClick={() => this.selectTeam('newest')}>Newest teams*/}
                                        {/*</button>*/}
                                        {/*<button className="language-btn show-all-btn"*/}
                                        {/*        onClick={() => this.showAllTeams(true)}>Show all*/}
                                        {/*</button>*/}
                                    </div>
                                </div>
                                <div className="wrapper-right">
                                    <button className={`language-btn show-all-btn ${showAllTeams ? '' : 'active-btn-show'}`}
                                            onClick={() => this.showAllTeams(true)}>Show all
                                    </button>
                                    <img className={`${!showAllTeams ? '' : 'active-btn-show'}`}
                                        src={`${static_url}assets/menu-6.svg`} onClick={() => this.showAllTeams(false)}/>
                                </div>
                            </Fade>
                        </div>
                        <div className="wrapper-language-card card--position row card-list">
                            {!showAllTeams &&
                                <>
                                    {teamsName === 'language' && contributorsData.teams && contributorsData.teams.map((item, index) => {
                                        return <div className="item col-12 col-md-6 col-xl-4" key={index}>
                                            <LanguageCard name={item.name} editors={item.editor}
                                                terms={item.translated_terms} percent={item.percent}
                                                coEditors={item[`co-editor`]} reviewers={item.reviewer}
                                                lastActive={item.last_active}/>
                                        </div>
                                    })}
                                    {teamsName === 'active' && contributorsData.active_teams && contributorsData.active_teams.map((item, index) => {
                                        return <div className="item col-12 col-md-6 col-xl-4" key={index}>
                                            <LanguageCard name={item.name} editors={item.editor}
                                                terms={item.translated_terms} percent={item.percent}
                                                coEditors={item[`co-editor`]} reviewers={item.reviewer}
                                                lastActive={item.last_active}/>
                                        </div>
                                    })}
                                    {teamsName === 'newest' && contributorsData.newest_teams && contributorsData.newest_teams.map((item, index) => {
                                        return <div className="item col-12 col-md-6 col-xl-4" key={index}>
                                            <LanguageCard name={item.name} editors={item.editor}
                                                terms={item.translated_terms} percent={item.percent}
                                                coEditors={item[`co-editor`]} reviewers={item.reviewer}
                                                lastActive={item.last_active}/>
                                        </div>
                                    })}
                                </>
                            }

                            {showAllTeams &&
                                <>
                                    <div className="contributors-teams_list-wrapper">
                                        <div className="teams_list-name">Team</div>
                                        <div className="teams_list-team-members">Team Members</div>
                                        <div className="teams_list-translated">Number Translated</div>
                                        <div className="teams_list-percentage">Percentage Translated</div>
                                        <div className="teams_list-coEditors">Last Active</div>
                                        {/*<div className="teams_list-percent">*/}
                                        {/*    Percent*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="teams_list-items scrollbox">
                                        {teamsName === 'language' && allTeams.teams && allTeams.teams.map((item, index) => {
                                            return <TeamsList key={index} name={item.name} editors={item.editor}
                                                            terms={item.translated_terms} percent={item.percent}
                                                            coEditors={item[`co-editor`]} reviewers={item.reviewer}
                                                            lastActive={item.last_active}/>
                                        })}
                                        {teamsName === 'active' && allTeams.active_teams && allTeams.active_teams.map((item, index) => {
                                            return <TeamsList key={index} name={item.name} editors={item.editor}
                                                            terms={item.translated_terms} percent={item.percent}
                                                            coEditors={item[`co-editor`]} reviewers={item.reviewer}
                                                            lastActive={item.last_active}/>
                                        })}
                                        {teamsName === 'newest' && allTeams.newest_teams && allTeams.newest_teams.map((item, index) => {
                                            return <TeamsList key={index} name={item.name} editors={item.editor}
                                                            terms={item.translated_terms} percent={item.percent}
                                                            coEditors={item[`co-editor`]} reviewers={item.reviewer}
                                                            lastActive={item.last_active}/>
                                        })}
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    {/* <div className="page-section">
                        <TopicCurators/>
                    </div> */}

                    {/* <div className="page-section">
                        <CountryEditors/>
                    </div> */}
                    
                    <ContributorsSectionTextEdit
                        theme={"blue"}
                        user={user}
                        title={"Topic Curators"}
                        content={topicCuratorsSection}
                        exampleContent={topicCuratorsExampleText}
                        contentName={"topicCuratorsSection"}
                        nameEditSect={"topic_curators_sect_isEdit"}
                        isEditSect={topic_curators_sect_isEdit}
                        handleShowEditBlock={(sectName) => this.handleShowEditBlock(sectName)}
                        handleChangeFroala={(content, data) => this.handleChangeFroala(content, data)}
                        handleSaveData={(nameEditSect, content) => this.saveEditData(nameEditSect, content)}
                    />

                    <ContributorsSectionTextEdit
                        theme={"blue"}
                        user={user}
                        title={"Acknowledgements"}
                        content={acknowledgementsSection}
                        exampleContent={acknowledgementsExampleText}
                        contentName={"acknowledgementsSection"}
                        nameEditSect={"acknowledgements_sect_isEdit"}
                        isEditSect={acknowledgements_sect_isEdit}
                        handleShowEditBlock={(sectName) => this.handleShowEditBlock(sectName)}
                        handleChangeFroala={(content, data) => this.handleChangeFroala(content, data)}
                        handleSaveData={(nameEditSect, content) => this.saveEditData(nameEditSect, content)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    contributorsData: state.contributors.contributorsData,
    allTeams: state.contributors.allTeams,
    meetSection: state.contributors.meetSection,
    sponsorSection: state.contributors.sponsorSection,
    acknowledgementsSection: state.contributors.acknowledgementsSection,
    topicCuratorsSection: state.contributors.topicCuratorsSection,
    user: state.auth.user,
});

const mapActionsToProps = (dispatch) => ({
    fetchContributors: () => dispatch(fetchContributors()),
    fetchAllTeams: () => dispatch(fetchAllTeams()),
    fetchMeetSection: () => dispatch(getContributorsMeetSection()),
    fetchSponsorSection: () => dispatch(getContributorsSponsorSection()),
    fetchAcknowledgementsSection: () => dispatch(getContributorsAcknowledgementsSection()),
    fetchTopicCuratorsSection: () => dispatch(getContributorsTopicCuratorsSection()),
    updateContributorsParagraphs: (path, data) => dispatch(updateContributorsParagraphs(path, data)),
    setContributorsParagraphs: (path, data) => dispatch(setContributorsParagraphs(path, data)),
    clearContributorsData: () => dispatch(clearContributorsData())
});

export default connect(mapStateToProps, mapActionsToProps)(Contributors)