import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Col, Collapse, Container, Row} from "reactstrap";
import {deleteUserAccount, editUserProfile} from "../../store/auth/actions";

import ImageUpload from './ImageUpload'
import SelectForm from './Select'
import {fetchLanguageList} from "../../store/terms/actions";
import ModalConfirmation from "../../helpers/ModalConfirmation";
import {NotificationManager} from "react-notifications";

class MyProfile extends Component {

    state = {
        isCollapseOpened: false,
        selectedLanguage: false,
        secondarySelectedLanguage: false,
        firstName: '',
        lastName: '',
        avatar: '',
        password: '',
        old_password: '',
        confirm: '',
        errorMes: 'Wrong password',
        errors: {},
        showModalDeleteAccount: false
    };

    _handleFieldChange = (e, name) => {
        const value = e.target.value;

        if (value.length != 0) {
            this.setState({
                [name]: value
            })
        }
    }
    _toggleCollapse = () => {
        const {isCollapseOpened} = this.state;

        this.setState({
            isCollapseOpened: !isCollapseOpened
        });
    };

    _handleSubmit = (e) => {
        e.preventDefault()
        const { user, isInvalidChangePass } = this.props;
        const { password, old_password, confirm, firstName, lastName, secondarySelectedLanguage, avatar} = this.state;

        const formData = new FormData();
        if (avatar) {
            formData.append('avatar', avatar);
        }

        formData.append('email', user.email);
        formData.append('first_name', firstName ? firstName : user.first_name);
        formData.append('last_name', lastName ? lastName : user.last_name);
        if (password !== '') {
            formData.append('password', password !== '' ? password : null);
            formData.append('old_password', old_password !== '' ? old_password : null);
            formData.append('confirm', confirm !== '' ? confirm : null);
        }
        formData.append('language', 'en');
        if (secondarySelectedLanguage) {
            formData.append("secondary_language", secondarySelectedLanguage.value);
        } else {
            formData.append("secondary_language", null);
        }

        this.props.editUserProfile(formData)
            .then( () => {
                if (user.role === 'Editor' || user.role === 'Co-Editor') this.props.history.push('/editors-dashboard');
                if (user.role === 'Admin') this.props.history.push('/management-console');
                if (user.role === 'User') this.props.history.push('/');
            })
            .catch( (data) => {
                const {payload: {response: {data: {errors}}}} = data;
                this.setState({
                    errors
                });
            })
    };

    changeLanguage = (selected) => {
        this.setState({
            selectedLanguage: selected,
            secondarySelectedLanguage: false
        });
    };

    changeSecondaryLanguage = (selected) => {
        if (selected.value === false) {
            this.setState({
                secondarySelectedLanguage: false
            })
        } else  {
            this.setState({
                secondarySelectedLanguage: selected
            })
        }
    };

    clearSecondaryLanguage = () => {
        this.setState({
            secondarySelectedLanguage: false
        })
    };

    componentDidMount() {
        this.props.fetchLanguageList()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {user} = nextProps;
        if (user.email !== undefined) {
            this.setState({
                selectedLanguage: {
                    value: 'en',
                    label: 'English'
                },
                // selectedLanguage: {
                //     value: user.language && user.language.code,
                //     label: user.language && user.language.name,
                // },
                secondarySelectedLanguage: (user.secondary_language) ? {
                    value: user.secondary_language.code,
                    label: user.secondary_language.name
                } : false
            });
        }
    }
    avatarSetState = (data) => {
        this.setState({
            avatar: data
        });
    };

    setShowModalDeleteAccount(showModalDeleteAccount) {
        this.setState({
            showModalDeleteAccount
        })
    }

    deleteAccount = () => {
        this.setShowModalDeleteAccount(false);
        this.props.deleteUserAccount()
            .then(res => {
                NotificationManager.success('Your account has been deleted')
            });
    };

    render() {
        const { errorMes, errors, selectedLanguage, secondarySelectedLanguage, showModalDeleteAccount } = this.state;
        const { user, isInvalidChangePass } = this.props;
        return (
            <>
                <div className="auth-title-container">
                    <h1>My Account</h1>
                </div>
                <Container className="auth-container w-xs">
                    <div className={'log_in-wrapper'} >
                        <div className='user-avatar-block'>
                            <ImageUpload
                                setAvatar={(data) => this.avatarSetState(data)}
                                avatarImg={user.avatar}
                            />
                            {errors.avatar && errors.avatar.map((item, index) => (
                                <div key={index}><span className='error-text'>{item}</span></div>
                            ))}
                        </div>

                        <div className="contact-block-wrapper row flex-row">
                            <div className="col-md-6">
                                <div className="field-wrapper">
                                    <h2>First Name</h2>
                                    <input type="text" defaultValue={user.first_name ? user.first_name : ''} onChange={( e, name ) => this._handleFieldChange(e, 'firstName')}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="field-wrapper">
                                    <h2>Last Name</h2>
                                    <input type="text" defaultValue={user.last_name ? user.last_name : ''} onChange={( e, name ) => this._handleFieldChange(e, 'lastName')}/>
                                </div>
                            </div>
                        </div>
                    
                        
                        <div className="contact-block-wrapper">
                            <div className="field-wrapper">
                                <div className={'disabled'}>
                                    <h2>Primary Dictionary Language</h2>
                                    <SelectForm
                                        selectedOption={selectedLanguage}
                                        onChange={(selected) => this.changeLanguage(selected)}
                                        className={'profile-language'}
                                        options={this.props.langs.map(item => ({
                                            value: item.code,
                                            label: item.name
                                        }))}
                                    />
                                </div>
                                <div className={`secondary-language-wrapper ${selectedLanguage && selectedLanguage.value === 'en' ? `` : `disabled`}`}>
                                    <div>
                                        <h2 className="secondary-language-title">Secondary Dictionary Language</h2>
                                        &nbsp;
                                        <span className="secondary-language-clear" onClick={this.clearSecondaryLanguage}>clear</span>

                                    </div>
                                    <SelectForm
                                        selectedOption={secondarySelectedLanguage}
                                        onChange={(selected) => this.changeSecondaryLanguage(selected)}
                                        className={'profile-language'}
                                        options={[
                                            {value: false, label: 'Select...'},
                                            ...this.props.langs
                                                .filter(item => item.code === selectedLanguage.value ? false : true)
                                                .map(item => ({value: item.code, label: item.name}))
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`change-password
                            ${this.state.isCollapseOpened
                            ? 'change-password-opened'
                            : 'change-password-closed'}
                        `}>
                            {!this.state.isCollapseOpened
                            && <Button
                                type={'button'}
                                color={'link'}
                                outline
                                onClick={() => this._toggleCollapse()}
                                className={'open-form-btn'}
                            >
                                Change password
                            </Button>}

                            <Collapse isOpen={this.state.isCollapseOpened}>
                                <div className={'change-password-title'}>
                                    <span>Change Password</span>

                                    <Button
                                        type={'button'}
                                        color={'link'}
                                        className={'close-form-btn'}
                                        onClick={() => this._toggleCollapse()}
                                    >
                                        &times;
                                    </Button>
                                </div>

                                <div className="contact-block-wrapper change-password-block">
                                    <div className="password-description">Minimum 15 characters including uppercase, lowercase, numbers and special characters (e.g. $%&)</div>
                                    <div className="field-wrapper">
                                        <input className={`change-password-field ${isInvalidChangePass ? 'error-field' : ''}`} type='password' name='old_password' placeholder='Old Password' onChange={( e, name ) => this._handleFieldChange(e, 'old_password')}/>
                                    </div>

                                    <div className="field-wrapper">
                                        <input className={`change-password-field ${isInvalidChangePass ? 'error-field' : ''}`} type='password' name='password' placeholder='New Password' onChange={( e, name ) => this._handleFieldChange(e, 'password')}/>
                                    </div>

                                    <div className="field-wrapper">
                                        <input className={`change-password-field ${isInvalidChangePass ? 'error-field' : ''}`} type='password' name='confirm' placeholder='Retype Password' onChange={( e, name ) => this._handleFieldChange(e, 'confirm')}/>
                                    </div>

                                    {errors.password && errors.password.map((item, index) => (
                                        <div key={index}><span className='error-text'>{item}</span></div>
                                    ))}
                                </div>
                            </Collapse>
                        </div>
                        <div className={'delete-account'} onClick={() => this.setShowModalDeleteAccount(true)}>
                            Delete account
                        </div>
                        
                        <ModalConfirmation
                            show={showModalDeleteAccount}
                            title={'Warning'}
                            body={
                                <div>
                                    <strong>Warning</strong>: You are about to delete your account. All your account details, lists, notes, and preferences will be deleted and cannot be retrieved. You can however continue to use the site as a guest. Are you sure?
                                </div>
                            }
                            submitText={'Delete My Account'}
                            cancelText={'Cancel'}
                            onSubmit={() => this.deleteAccount()}
                            onHide={() => this.setShowModalDeleteAccount(false)}
                        />
                        <div className="contact-block-wrapper auth-block">
                            <button onClick={this._handleSubmit}>Save</button>
                        </div>
                    </div>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isAuthorized: state.auth.isAuthorized,
    isInvalidChangePass: state.auth.isInvalidChangePass,
    langs: state.terms.langs
});

const mapActionsToProps = (dispatch) => ({
    editUserProfile: (data) => dispatch(editUserProfile(data)),
    fetchLanguageList: () => dispatch(fetchLanguageList()),
    deleteUserAccount: () => dispatch(deleteUserAccount())
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(MyProfile));
