import React, {Component} from "react";
import {fetchLogs, fetchLogUsers, makeLogsViewer} from "../../../store/console/actions";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import Select from "react-select";
import Log from "./Log";
import Calendar from "../../../helpers/Calendar";
import moment from "moment";
import {managementConsolePaging} from '../../../configs/config';
import MenuViewableByUsers from "./MenuViewableByUsers";

class Logs extends Component {

    static propTypes = {
        fetchLogs: PropTypes.func,
        logs: PropTypes.array,
    };

    state = {
        searchParams: {
            description: '',
            user: null, // it is a string
            page: 1,
            per_page: managementConsolePaging
        },
        start: 0,
        end: 0,
        chosenUser: {},
        chosenDate: {},
        showUserFilter: false,
        showDateFilter: false,
        showCalendar: false,
        searchByDescription: ''
    };

    componentDidMount() {
        this.fetchLogs();
        this.props.fetchLogUsers({
            "forLogs": "true",
            "all": "true"
        });
    }

    fetchLogs = () => {
        const {searchParams, start, end} = this.state;
        const data = Object.assign({}, searchParams);
        if (start !== 0) {
            data.start = start;
        }
        if (end !== 0) {
            data.end = end;
        }

        if (searchParams.page === 1) {
            data.clearLogs = true
        }

        this.props.fetchLogs(data);
    };

    toggleShowUserFilter = () => {
        this.setState(({showUserFilter}) => ({
            showUserFilter: !showUserFilter
        }))
    };

    chooseUser = (selected) => {
        this.setState((state) => ({
            chosenUser: selected,
            showUserFilter: false,
            searchParams: {
                ...state.searchParams,
                page: 1,
                user: selected.value
            }
        }), this.fetchLogs)

    };

    onSearchDescription = (e) => {
        e.persist();
        this.setState(({searchParams}) => ({
            searchParams: {
                ...searchParams,
                page: 1,
                description: e.target.value
            }
        }), this.fetchLogs)
    };

    toggleCalendar = () => {
        this.setState(({showCalendar}) => ({
            showCalendar: !showCalendar
        }))
    };

    submitDate = (data) => {
        this.setState({
            start: data.from,
            end: data.to ? data.to : 0,
            showCalendar: false,
            searchParams: {
                ...this.state.searchParams,
                page: 1
            }
        }, this.fetchLogs)
    };

    cancelDate = () => {
        this.setState({
            start: 0,
            end: 0,
            showCalendar: false,
            searchParams: {
                ...this.state.searchParams,
                page: 1
            }
        }, this.fetchLogs)
    };

    paginateLogs() {
        this.setState((state) => ({
            searchParams: {
                ...state.searchParams,
                page: this.state.searchParams.page + 1
            }
        }), this.fetchLogs)
    }

    render() {
        const {logs, users, isAdmin} = this.props;
        const {chosenUser, showUserFilter, searchParams, showCalendar, start, end} = this.state;

        return (
            <div className="logs-container console-container-full">
                <div className="logs-header">
                    <div className="logs-header-element">
                        <input type="text" className="console-search" placeholder="Search by activity description"
                               value={searchParams.description} onChange={this.onSearchDescription}/>

                        <div className="console-filter">
                            <Select
                                className={"logs-user-filter-set"}
                                classNamePrefix="react-select-teams"
                                onChange={(selected) => this.chooseUser(selected)}
                                value={chosenUser.value ? chosenUser : {value: 'all', label: 'All Users'}}
                                options={[{value: null, label: 'All users'}, ...users.map((item) => ({
                                    value: item.id,
                                    label: item.fullname
                                }))]}
                            />
                        </div>

                        <div className="console-filter">
                            <span className="logs-date-filter" onClick={() => this.toggleCalendar()}>
                                {start !== 0
                                    ?
                                    <>
                                        {moment(start).format("DD-MM-YYYY")}
                                        {' - '}
                                        {end !== 0 ? `${moment(end).format("DD-MM-YYYY")}` : 'Today'}
                                    </>
                                    : <>All time</>
                                }
                            </span>
                            {showCalendar && (
                                <Calendar
                                    from={start}
                                    to={end}
                                    onSubmit={this.submitDate}
                                    onCancel={this.cancelDate}/>
                            )}
                        </div>
                    </div>

                    {isAdmin && (
                        <div className="logs-header-element">
                            <MenuViewableByUsers
                                users={users}
                                makeLogsViewerAction={this.props.makeLogsViewer}
                            />
                        </div>
                    )}

                </div>
                <div className="logs-list">
                    {logs && logs.map((log, i) => {
                        return (
                            <Log key={log.id}
                                 log={log}
                                 prevLog={i > 0 ? logs[i - 1] : null}
                                 isFirst={i === 0}
                                 isLast={i === logs.length - 1}/>
                        )
                    })}
                </div>
                {this.props.hasMoreLogs && (
                    <div className="logs-footer">
                        <div className="console-button" onClick={() => this.paginateLogs()}>
                            Show More Activity
                        </div>
                    </div>
                )}
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    logs: state.managementConsole.logs,
    users: state.managementConsole.logUsers,
    hasMoreLogs: state.managementConsole.hasMoreLogs
});

const mapDispatchToProps = {fetchLogs, fetchLogUsers, makeLogsViewer};

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
