import React, {useEffect, useState} from "react";
import Notification from "../../../helpers/Notification";
import {connect} from "react-redux";

const TermNotifications = ({term, user, isClickedStar, isClickedLink, onLinkClose, onStarClose, starError}) => {
    
    let starMessage;
    if (starError) {
        if (starError.payload
                && starError.payload.response
                && starError.payload.response.data.message === "Favourite terms limit is reached") {
            starMessage = 'You can only have 100 items in your list'
        } else {
            starMessage = 'Error occurred';
        }
    } else {
        if (user.email !== undefined) {
            if (term.is_favourite) {
                starMessage = 'Added to Starred Terms';
            } else {
                starMessage = 'Removed from Starred Terms';
            }
        } else {
            starMessage = 'Please log-in to use this function';
        }
    }
    
    return (
        <>
            {term && user &&
                <Notification text={starMessage}
                              show={isClickedStar || starError}
                              onClose={() => onStarClose()}
                />
            }
            {term &&
                <Notification text={'Link copied to clipboard'}
                             show={isClickedLink}
                             onClose={() => onLinkClose()}
                />
            }
        </>
    )
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(TermNotifications);