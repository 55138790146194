import React, {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from "moment";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchNotifications, updateNotification} from "../../store/dashboard/actions";

const NEW_TERM = 0;
const NEED_TRANSLATION = 1;
const TERM_ACCEPTED = 2;
const TERM_REJECTED = 3;
const TERM_SENT_TO_REVIEW = 4;
const READY_FOR_PUBLISH = 5;

const NotificationRow = (props) => {

    const {status, term, id} = props.item;
    const user = props.user;

    let link = '';
    if (user.role === 'Reviewer') {
        link = `/translate-view-page/${term.id}`;
    } else {
        link = `/${term.content.language}/${term.slug}/${term.version && term.version.number ? term.version.number : 1}/edit-view-page`;
    }

    let action = '';
    switch (status) {
        case NEW_TERM:
            action = 'Translate';
            break;
        case NEED_TRANSLATION:
            action = 'Translate';
            break;
        case TERM_ACCEPTED:
            action = 'Publish';
            break;
        case TERM_REJECTED:
            action = 'Update';
            break;
        case TERM_SENT_TO_REVIEW:
            action = 'Review';
            break;
        case READY_FOR_PUBLISH:
            action = 'Publish';
            break;
    }

    const notificationEventHandler = (redirection) => async event => {
        await props.updateNotification(id);
        !redirection && props.fetchNotifications();
        redirection && props.history.push(link);
    };

    return (
        <tr>
            <td className={props.props.columns[0].className + '-td'}>
                <span style={{'cursor': 'pointer'}} onClick={notificationEventHandler(true)}>{term && term.title}</span>
            </td>
            <td
                className={`${props.props.columns[1].className}-td 
                ${status === NEED_TRANSLATION && 'need-translation'}`}
            >
                <span>{props.item.type}</span>
            </td>
            <td className={props.props.columns[2].className + '-td'}>
                {moment(props.item.created).format("LL")}
                <FontAwesomeIcon icon={'info-circle'} className={'bim-info-circle'}/>
            </td>
            <td className={props.props.columns[3].className + '-td'}>
                <span style={{'cursor': 'pointer'}} onClick={notificationEventHandler(true)}>{action}</span>
                {' | '}
                <span onClick={notificationEventHandler(false)}>
                    Mark as read
                </span>
            </td>
        </tr>
    );
};


const mapStateToProps = (state) => ({
    user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
    updateNotification: (ids) => dispatch(updateNotification(ids)),
    fetchNotifications: () => dispatch(fetchNotifications()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationRow));
