import React, {Component} from "react";
import {connect} from "react-redux";
import SaveTeamModal from './SaveTeamModal';
import Select from 'react-select';
import {managementConsolePaging} from '../../../configs/config';

import {
    bulkDeleteTeams,
    clearSingleTeam,
    createAdminTeam,
    deleteSingleTeam,
    editSingleTeam,
    getAdminTeams,
    getAdminTerms,
    getAvailableCountries,
    getSingleTeam
} from "../../../store/console/actions";
import TeamRow from "./TeamRow";
import Paginator from "../../../helpers/Paginator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShareAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import CustomTableWithSelecting from "../../../helpers/CustomTableWithSelecting";
import _get from "lodash/fp/get";
import {Modal} from "react-bootstrap";

class Teams extends Component {

    state = {
        searchParams: {
            country: '',
            q: '',
            page: 1,
            per_page: managementConsolePaging
        },
        modalShow: '',
        chosenSet: {},
        showSetFilter: false,
        selected: [],
        error: null
    };

    _tableColumns = [{
        style: {
            width: '3%'
        },
        className: 'console-select',
        checkboxesTitle: true
    }, {
        style: {
            width: '25%'
        },
        title: 'Team Name',
        className: 'console-terms-title'
    }, {
        style: {
            width: '17%'
        },
        title: 'Country',
        className: 'console-terms-languages'
    }, {
        style: {
            width: '11%'
        },
        title: 'Team Members',
        className: 'console-terms-sets'
    }, {
        style: {
            width: '14.5%'
        },
        title: 'Registered Date',
        className: 'console-terms-created'
    }, {
        style: {
            width: '16.5%'
        },
        title: 'Latest Activity',
        className: 'console-terms-modificated'
    }, {
        style: {},
        title: '',
        className: 'console-teres-actions'
    }];

    constructor(props) {
        super(props);

        this._paginatorChangedPage = this._paginatorChangedPage.bind(this);
        this._modalClose = this._modalClose.bind(this);
    }

    /* methods */
    _paginatorChangedPage(data) {
        const {searchParams} = this.state;

        searchParams.page = data.selected + 1;
        this.setState({searchParams});

        this.props.getAdminTeams(searchParams);
    }

    _searchByName(e) {
        const {searchParams} = this.state;

        if (e.target.value === searchParams.q) {
            return false;
        }

        searchParams.q = e.target.value;
        searchParams.page = 1;
        this.setState({searchParams}, () => {
            this.props.getAdminTeams(searchParams);
        });
    }

    componentDidMount() {
        if (this.props.allCountries.length === 0) {
            this.props.getModalAvailableCountries()
        }
    }

    _editTeam(selectedTeam) {
        this.props.clearSingleTeam();
        this.props.getSingleTeam(selectedTeam.id)
            .then(() => this.setState({modalShow: 'edit'}))
    }

    _modalAddShow() {
        this.props.clearSingleTeam();
        this.setState({
            modalShow: 'add'
        })
    }

    _modalClose() {
        this.setState({
            modalShow: ''
        })
    }

    _deleteAction(team) {
        const {searchParams} = this.state;

        this.props.deleteSingleTeam(team.id)
            .then(() => this.props.getAdminTeams(searchParams))
    }

    _addTeam(data) {
        const {singleTeam} = this.props;
        const dataObj = {
            name: data.teamName,
            sets: data.teamSets,
            users: data.teamUsers,
            type: data.teamType,
            type_id: data.teamTypeId
        };
        const callbackFn = () => {
            const {searchParams} = this.state;
            this._modalClose();
            this.props.getAdminTeams(searchParams);
            this.props.clearSingleTeam();
        };

        const errorCallbackFnc = resp => {
            this.setState({
                ...this.state,
                error: _get('payload.response.data.error', resp) || 'Something went wrong',
            });
        };

        if (singleTeam.id === undefined) {
            return this.props.createAdminTeam(dataObj)
                .then(callbackFn)
                .catch(resp => errorCallbackFnc(resp));
        } else {
            return this.props.editSingleTeam(singleTeam.id, dataObj)
                .then(callbackFn)
                .catch(resp => errorCallbackFnc(resp));
        }
    }

    _removeError() {
        this.setState({...this.state,
            error: null
        })
    }

    _selectSet(selected) {
        const {searchParams} = this.state;

        if (selected.value !== 'all') {
            searchParams.country = selected.value;
            this.setState({
                chosenSet: selected,
                showSetFilter: false,
                searchParams
            })
        } else {
            searchParams.country = '';
            this.setState({
                chosenSet: {},
                showSetFilter: false,
                searchParams
            })
        }

        this.props.getAdminTeams(searchParams);
    }

    _showSetsFilter() {
        const {showSetFilter} = this.state;

        this.setState({
            showSetFilter: !showSetFilter
        });
    }

    deleteSelectedTeams() {
        const {selected, searchParams} = this.state;

        this.props.bulkDeleteTeams({teams: selected})
            .then(() => this.props.getAdminTeams(searchParams))
    }

    render() {
        const {teams, allCountries} = this.props;
        const {modalShow, chosenSet, showSetFilter} = this.state;

        const _tableFooterColumns = [{
            colspan: 7,
            component: <Paginator
                pageCount={this.props.totalTeams / this.state.searchParams.per_page}
                pageChanged={this._paginatorChangedPage}
                forcePage={this.state.searchParams.page - 1}
                className={"console-paginator"}
            />
        }];

        return (
            <div className="console-container-full console-terms-container console-teams-container">
                <div className="console-header">
                    <div className="console-header-lp">
                        <input type="text" className="console-search" placeholder="Search by team name"
                               onChange={(e) => this._searchByName(e)}/>

                        <div className="console-filter console-sets-select countries">
                            <Select
                                className={"terms-filter-set"}
                                classNamePrefix="react-select-teams"
                                onChange={(selected) => this._selectSet(selected)}
                                value={chosenSet.value !== undefined ? chosenSet : {
                                    value: 'all',
                                    label: 'All Countries'
                                }}
                                options={[{
                                    value: 'all',
                                    label: 'All Countries'
                                }, ...allCountries.map((item, index) => ({
                                    value: item[0],
                                    label: item[1]
                                }))]}
                            />
                        </div>

                    </div>

                    <div className="console-header-actions-line">
                        <div className="console-header-selected">
                            <span className="selected-rows">{this.state.selected.length} Selected</span>
                            <span className="delete-selected" onClick={() => this.deleteSelectedTeams()}>
                                <FontAwesomeIcon icon={faTrashAlt} className={'delete-link'}/> remove
                            </span>
                        </div>
                        <button className="console-button" onClick={() => this._modalAddShow()}>
                            Add Team
                        </button>
                    </div>
                </div>

                <div className="console-body">

                    <CustomTableWithSelecting
                        className={"console-table"}
                        columns={this._tableColumns}
                        footer={_tableFooterColumns}
                        items={teams}
                        editTeam={(team) => this._editTeam(team)}
                        deleteAction={(item) => this._deleteAction(item)}
                        onChangeSelect={(selected) => {
                            this.setState({
                                selected
                            });
                        }}
                    >
                        <TeamRow/>
                    </CustomTableWithSelecting>
                </div>

                <SaveTeamModal
                    show={modalShow}
                    onHide={this._modalClose}
                    onSave={(data) => this._addTeam(data)}
                />

                <Modal
                    show={this.state.error ? true : false}
                    onHide={() => this._removeError()}
                >
                    <Modal.Body>
                        {this.state.error}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    teams: state.managementConsole.teams,
    totalTeams: state.managementConsole.totalTeams,
    hasMoreTeams: state.managementConsole.hasMoreTeams,
    singleTeam: state.managementConsole.singleTeam,
    allCountries: state.managementConsole.availableCountries
});

const mapDispatchToProps = (dispatch) => ({
    getAdminTeams: (data) => dispatch(getAdminTeams(data)),
    createAdminTeam: (data) => dispatch(createAdminTeam(data)),
    getSingleTeam: (id) => dispatch(getSingleTeam(id)),
    editSingleTeam: (id, data) => dispatch(editSingleTeam(id, data)),
    clearSingleTeam: () => dispatch(clearSingleTeam()),
    deleteSingleTeam: (id) => dispatch(deleteSingleTeam(id)),
    getModalAvailableCountries: () => dispatch(getAvailableCountries()),
    getAdminTerms: (data) => dispatch(getAdminTerms(data)),
    bulkDeleteTeams: (data) => dispatch(bulkDeleteTeams(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);