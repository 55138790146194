import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import {clearErrors, resetPassword} from "../../store/auth/actions";
import {withRouter} from "react-router";
import {NotificationManager} from 'react-notifications'

const ResetPassword = (props) => {
    const {resetPassword, history, location, errMsg, clearErrors} = props;

    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [error, setError] = useState('');

    const validate = useCallback(() => {
        clearErrors();
        if (!password || !confirm) {
            setError('Passwords should not be empty');
            return;
        }
        if (password !== confirm) {
            setError('Password confirmation does not match to original');
            return;
        }
        setError('');
        resetPassword(extractToken(location.search), {password, confirm})
            .then(res => {
                NotificationManager.success('Your password has been reset successfully');
                history.push('/login')
            })

    }, [password, confirm]);

    useEffect(() => {
        return () => {
            clearErrors()
        }
    }, []);

    return (
        <div className="reset-password-container">
            <div className="management-console-title reset-password-title">Reset password</div>
            <div className="reset-password-wrapper">
                {error && <div className="reset-password-error">{error}</div>}
                {errMsg &&
                <div className='error-text-auth-message'>
                    {Object.keys(errMsg.errors).map((err) => {
                        return errMsg.errors[err].map((field) => <div className="reset-password-error" key={field}>{field}</div>)
                    })}
                </div>}
                <input type="password"
                       className="reset-password-input"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       placeholder={"New password"}/>
                <input type="password"
                       className="reset-password-input"
                       value={confirm}
                       onChange={(e) => setConfirm(e.target.value)}
                       placeholder={"Confirm new password"}/>
                <div className={"console-button"} onClick={() => validate()}>Submit</div>
                <div className="password-description">Rules: Minimum 15 characters including uppercase, lowercase, numbers and special characters (e.g. $%&)</div>
            </div>
        </div>
    )
};

const extractToken = (text) => {
    const match = /token=(.*)/.exec(text);
    if (match) {
        return match[1]
    }
};

const mapStateToProps = (state) => ({
    errMsg: state.auth.errMsg
});

const mapDispatchToProps = { resetPassword, clearErrors };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));