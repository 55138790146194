import React, {Component} from "react";
import ListTemplate from "../../Terms/templates/ListTemplate";
import ModalAddTerms from "../../Terms/templates/ModalAddTerms";

class ListTemplateHelper extends Component {
    state = {
        modalShow: false
    };

    modalShow = (modalShow) => this.setState({modalShow});
    updateEmbeddedTermClick = (data) => this.setState({clickedEmbeddedTerm: data});
    saveListTemplateTitle = (title) => this.props.changeData('title', title);
    saveListTemplateContent = (content) => this.props.changeData('content', content);

    addTermsToList = (data) => {
        const mappedSelectedTerms = data.map(({id, title}) => ({
            id, title
        }));
        this.setState({modalShow: false});
        this.props.changeData('listTemplateTerms', mappedSelectedTerms);
    };

    removeTermFromListTemplate = (selectedTerm) => {
        const {template: {listTemplateTerms}} = this.props;

        if (listTemplateTerms !== undefined)
            this.props.changeData('listTemplateTerms', [...listTemplateTerms.filter(item => item.id !== selectedTerm.id)]);
    };

    render() {
        const {modalShow} = this.state;
        const {removeTemplate, template, isEdit} = this.props;

        const title = template.title !== undefined ? template.title : '';
        const content = template.content !== undefined ? template.content : '';
        const listTemplateTerms = template.listTemplateTerms !== undefined ? template.listTemplateTerms : [];

        return (<>
            <ListTemplate
                removeTemplate={removeTemplate}
                addTerms={() => this.modalShow(true)}
                termsList={listTemplateTerms}
                isEdit={isEdit}
                removeTerm={this.removeTermFromListTemplate}
                updateEmbeddedTermClick={this.updateEmbeddedTermClick}
                onTitleSave={this.saveListTemplateTitle}
                onContentSave={this.saveListTemplateContent}
                title={title}
                content={content}
            />
            <ModalAddTerms
                show={modalShow}
                includedTerms={listTemplateTerms}
                onHide={() => this.modalShow(false)}
                onSave={this.addTermsToList}
            />
        </>)
    }
}


export default ListTemplateHelper;