import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Select from 'react-select';
import {Modal} from 'react-bootstrap';
import {bulkCreateTerms, bulkDeleteTerms, deleteTerm, getAdminTerms, getAllSets} from "../../../store/console/actions";
import TermRow from "./TermRow";
import {fetchLanguageList} from "../../../store/terms/actions";
import Paginator from "../../../helpers/Paginator";
import {managementConsolePaging} from '../../../configs/config';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import ModalTermsUploadResult from "./templates/ModalTermsUploadResult";
import CustomFilterTable from "../../../helpers/CustomFilterTable";

class Terms extends Component {

    state = {
        areLangsVisible: false,
        showSetFilter: false,
        showLangFilter: false,
        errors: [],
        chosenLang: {},
        chosenSet: {},
        searchParams: {
            lang: '',
            set: '',
            q: '',
            q_type: '',
            order_by: '',
            order_asc: 'asc',
            page: 1,
            per_page: managementConsolePaging
        },
        selected: [],
        showModalTermsUploadResult: false,
        uploaded: [],
        rejected: [],
        isFetchTerms: true
    };

    hiddenInputRef = React.createRef();

    _tableColumns = [{
        style: {
            width: '3%'
        },
        className: 'console-select',
        checkboxesTitle: true
    }, {
        style: {
            width: '15%'
        },
        title: 'Term Title',
        titleSlug: 'title',
        className: 'console-terms-title',
        input: {
            type: 'text',
            name: 'title'
        },
        isSortable: true
    }, {
        style: {
            width: '9%'
        },
        title: 'Template',
        titleSlug: 'template',
        className: 'console-terms-template',
        input: {
            type: 'text',
            name: 'template'
        },
        isSortable: true
    }, {
        style: {
            width: '14%'
        },
        title: 'Languages',
        titleSlug: 'languages',
        className: 'console-terms-languages',
        input: {
            type: 'text',
            name: 'languages'
        },
        isSortable: true
    }, {
        style: {
            width: '11%'
        },
        title: 'co-Authors',
        titleSlug: 'co-authors',
        className: 'console-terms-co-authors',
        input: {
            type: 'text',
            name: 'co_authors'
        },
        isSortable: true
    }, {
        style: {
            width: '11%'
        },
        title: 'Sets',
        titleSlug: 'sets',
        className: 'console-terms-sets',
        input: {
            type: 'text',
            name: 'sets'
        },
        isSortable: true
    }, {
        style: {
            width: '12.5%'
        },
        title: 'Date Created',
        titleSlug: 'created',
        className: 'console-terms-created',
        input: {
            type: 'date',
            name: 'created'
        },
        isSortable: true
    }, {
        style: {
            width: '12.5%'
        },
        title: 'Date Modified',
        titleSlug: 'modified',
        className: 'console-terms-modificated',
        input: {
            type: 'date',
            name: 'modified'
        },
        isSortable: true
    }, {
        style: {
            width: '3%'
        },
        title: 'Version',
        titleSlug: 'version',
        className: 'console-terms-version',
        input: {
            type: 'text',
            name: 'version'
        },
        isSortable: true
    }, {
        style: {
            width: '3%'
        },
        title: '',
        className: 'console-teres-actions'
    }];

    /* methods */
    _paginatorChangedPage(data) {
        const {searchParams} = this.state;

        searchParams.page = data.selected + 1;
        this.setState({searchParams});

        !this.state.isFetchTerms && this.props.getAdminTerms(searchParams);
    }

    _searchByName(e) {
        const {searchParams} = this.state;

        if (e.target.value === searchParams.q) {
            return false;
        }

        searchParams.q = e.target.value;
        searchParams.q_type = '';
        searchParams.page = 1;
        this.setState({searchParams}, () => {
            this.props.getAdminTerms(searchParams);
        });
    }

    _showLangs = () => {
        const {areLangsVisible} = this.state;

        this.setState({
            areLangsVisible: !areLangsVisible
        });
    };

    _showSetsFilter() {
        const {showSetFilter} = this.state;

        this.setState({
            showSetFilter: !showSetFilter
        });
    }

    _showLangFilter() {
        const {showLangFilter} = this.state;

        this.setState({
            showLangFilter: !showLangFilter
        });
    }

    _selectSet(selected) {
        const {searchParams} = this.state;

        if (selected.value !== 'all') {
            searchParams.set = selected.value;
            this.setState({
                chosenSet: selected,
                showSetFilter: false,
                searchParams
            })
        } else {
            searchParams.set = '';
            this.setState({
                chosenSet: {},
                showSetFilter: false,
                searchParams
            })
        }

        this.props.getAdminTerms(searchParams);
    }

    _selectLang(selected) {
        const {searchParams} = this.state;

        if (selected.value !== 'all') {
            searchParams.lang = selected.value;
            this.setState({
                chosenLang: selected,
                showLangFilter: false,
                searchParams
            })
        } else {
            searchParams.lang = '';
            this.setState({
                chosenLang: {},
                showLangFilter: false,
                searchParams
            })
        }

        this.props.getAdminTerms(searchParams);
    }

    _deleteAction(term) {
        const {searchParams} = this.state;

        this.props.deleteTerm(term.id)
            .then(() => this.props.getAdminTerms(searchParams))
    }

    _bulkTermsUplaod() {
        this.hiddenInputRef.current.click()
    }

    _handleFileChanged(e) {
        const {searchParams} = this.state;
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {

            const formData = new FormData();
            formData.append('file', file);

            this.props.bulkCreateTerms(formData)
                .then((res) => {
                    const {uploaded, rejected} = res.payload.data;
                    this.setState({
                        showModalTermsUploadResult: true,
                        uploaded,
                        rejected
                    });
                    this.props.getAdminTerms(searchParams)
                })
                .catch((values) => {
                    this.setState({
                        errors: values.payload.response.data.errors
                    })
                });
            this.hiddenInputRef.current.value = "";
        };
        reader.readAsDataURL(file)
    }

    deleteSelectedTerms() {
        const {selected, searchParams} = this.state;

        this.props.bulkDeleteTerms({terms: selected})
            .then(() => {
                this.setState({
                    selected: []
                });
                this.props.getAdminTerms(searchParams)
            })
    }

    onArrowClick = (order_by, isAsc) => {
        this.setState(({searchParams}) => ({
            searchParams: {
                ...searchParams,
                order_by,
                order_asc: isAsc ? 'asc' : 'desc'
            }
        }), () => this.props.getAdminTerms(this.state.searchParams))
    };

    onColumnFiltersChanges = (filters) => {
        this.setState(({searchParams}) => ({
            searchParams: {
                ...searchParams,
                q_type: filters.type,
                q: filters.value

            }
        }), () => this.props.getAdminTerms(this.state.searchParams))
    };

    ohHideErrorsModal() {
        this.setState({
            errors: []
        })
    }

    async componentDidMount() {
        await this.props.getAdminTerms(this.state.searchParams);
        this.setState({isFetchTerms: false})

        window.addEventListener('click', this._closeModelisErthLanguage, false)

        if (this.props.langs.length === 0) {
            this.props.fetchLanguageList();
        }
        if (this.props.allSets.length === 0) {
            this.props.getAllSets();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this._closeModelisErthLanguage, false)
    }

    render() {
        const {terms, langs, allSets} = this.props;
        const {chosenLang, chosenSet, showSetFilter, showLangFilter, isFetchTerms} = this.state;
        const _tableFooterColumns = [{
            colspan: this._tableColumns.length,
            component: <Paginator
                pageCount={this.props.totalTerms / this.state.searchParams.per_page}
                pageChanged={(data) => this._paginatorChangedPage(data)}
                forcePage={this.state.searchParams.page - 1}
                className={"console-paginator"}
            />
        }];

        return (
            <div className="console-container-full console-terms-container">
                <div className="console-header">
                    <div className="console-header-lp">
                        {/*<input type="text" className="console-search" placeholder="Search by term name"*/}
                        {/*       onChange={(e) => this._searchByName(e)}/>*/}

                        <div className="console-filter console-sets-select">
                            <Select
                                className={"custom-param-data-select terms-filter-set"}
                                classNamePrefix="react-select"
                                onChange={(selected) => this._selectLang(selected)}
                                value={chosenLang.value !== undefined ? chosenLang : {
                                    value: 'all',
                                    label: 'All Languages'
                                }}
                                options={[{value: 'all', label: 'All Languages'}, ...langs.map((item, index) => ({
                                    value: item.code,
                                    label: item.name
                                }))]}
                            />
                        </div>

                        <div className="console-filter console-languages-select">
                            <Select
                                className={"custom-param-data-select terms-filter-set"}
                                classNamePrefix="react-select"
                                onChange={(selected) => this._selectSet(selected)}
                                value={chosenSet.value !== undefined ? chosenSet : {value: 'all', label: 'All Sets'}}
                                options={[{value: 'all', label: 'All Sets'}, ...allSets.map((item, index) => ({
                                    value: item.id,
                                    label: item.name
                                }))]}
                            />
                        </div>
                    </div>

                    <div className="console-header-actions-line">
                        <div className="console-header-selected">
                            <span className="selected-rows">{this.state.selected.length} Selected</span>
                            <span className="delete-selected" onClick={() => this.deleteSelectedTerms()}>
                                <FontAwesomeIcon icon={faTrashAlt} className={'delete-link'}/> remove
                            </span>
                        </div>
                        <div>
                            <button className="console-button">
                                <Link to={'/management-console/terms/add-term'}>Add Term</Link>
                            </button>
                            <button className="console-button console-add-multiple-terms"
                                    onClick={() => this._bulkTermsUplaod()}>
                                <input type={"file"}
                                       className={"hidden-input-file"}
                                       ref={this.hiddenInputRef}
                                       onChange={(e) => this._handleFileChanged(e)}/>
                                Add Multiple Terms
                            </button>
                        </div>
                        <Modal
                            show={this.state.errors.length !== 0}
                            onHide={() => this.ohHideErrorsModal()}

                        >
                            <Modal.Body>
                                {this.state.errors.map((item, index) => (
                                    <div key={index}>{item}</div>
                                ))}
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>

                <div className="console-body">
                    <CustomFilterTable
                        className={"console-table"}
                        columns={this._tableColumns}
                        footer={_tableFooterColumns}
                        items={terms}
                        languages={langs}
                        onArrowClick={this.onArrowClick}
                        onFiltersChange={this.onColumnFiltersChanges}
                        deleteAction={(item) => this._deleteAction(item)}
                        onChangeSelect={(selected) => {
                            this.setState({
                                selected
                            });
                        }}
                    >
                        {!isFetchTerms ? <TermRow/> : null}
                    </CustomFilterTable>
                </div>

                <ModalTermsUploadResult
                    show={this.state.showModalTermsUploadResult}
                    onExit={() => {
                        this.setState({
                            showModalTermsUploadResult: false
                        })
                    }}
                    uploaded={this.state.uploaded}
                    rejected={this.state.rejected}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    terms: state.managementConsole.items,
    totalTerms: state.managementConsole.totalTerms,
    hasMoreTerms: state.managementConsole.hasMoreTerms,
    langs: state.terms.langs,
    allSets: state.managementConsole.allSets
});

const mapDispatchToProps = (dispatch) => ({
    getAdminTerms: (data) => dispatch(getAdminTerms(data)),
    fetchLanguageList: () => dispatch(fetchLanguageList()),
    getAllSets: () => dispatch(getAllSets()),
    bulkCreateTerms: (data) => dispatch(bulkCreateTerms(data)),
    deleteTerm: (id) => dispatch(deleteTerm(id)),
    bulkDeleteTerms: (data) => dispatch(bulkDeleteTerms(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
