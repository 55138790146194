import React from "react";
import {Link} from "react-router-dom";
import TemplateInfoblockRows from "../../ManagementConsole/Terms/templates/document/partials/TemplateInfoblockRows";
import CiteRow from "../../ManagementConsole/Terms/templates/document/partials/CiteRow";
import {FaCopy} from "react-icons/lib/fa";
import TermNotifications from "../partials/TermNotifications";
import {baseURLForLink} from "../../../configs/config";
import moment from "moment";
import TermAdditionalInfo from "./content/TermAdditional";
import PrintInfoblocksRows from "./content/InfoblocksRows";
import CiteInfo from "./content/CiteInfo";
import PrintHeader from "./Header";
import PrintFooter from "./Footer";


function PrintContent(props) {
    const {term, infoblocks, user} = props;
    const isArabic = term.content && (term.content.language === 'ar' || term.content.langauge === 'fa') ? true : false;
    const termDirection = term.direction ? term.direction : 'ltr';

    function buildCitation() {
        const content = term.content;
        if (!term.content) {
            return '';
        }
        const citation = `BIM Dictionary (2019), ${content && content.title ? `${content.title},` : ''} version ${term.version ? term.version.number : 1}. `
            + `${baseURLForLink}${content && content.language ? content.language : 'en'}/${term.slug}/${term.version ? term.version.number : 1}. `
            + `last accessed ${moment().format('LL')}`;
        return citation.replace(/<\s*[^>]*>/g, '')
    };

    let termContet = term.content.description;
    let termContetProcessed = termContet.replace(/\[\[/g, '').replace(/\]\]/g, '');

    return (
        <div style={{position: 'relative'}}>
            {/* HEADER */}
            {/*<div style={{position: "absolute", top: 0, width: '100%'}}>*/}
            {/*    <PrintHeader {...props}/>*/}
            {/*</div>*/}

            <div className='containerCls' style={{textAlign: termDirection === 'ltr' ? 'left' : 'right'}}>

                {/* Term description */}

                <div className={`${isArabic ? 'arabic' : ''}`}>
                    <span id='term-description'>{termContetProcessed}</span>
                </div>


                {/* Related term info */}
                <TermAdditionalInfo term={term}/>


                {/*  Infoblocks  */}
                {infoblocks && <PrintInfoblocksRows
                    term={term}
                    infoblocks={infoblocks}
                />
                }

                {/*  Cite of the form  */}
                <CiteInfo term={term}/>

            </div>

            {/*  FOOTER  */}
            {/*<div style={{position: "absolute", bottom: 0, width: '100%'}}>*/}
            {/*    <PrintFooter user={props.user}/>*/}
            {/*</div>*/}
        </div>
    )
}

export default PrintContent;
