import React, {useState} from "react";
import CopyTermIcon from "../../../helpers/CopyTermIcon";
import ToggleFavourite from "./ToggleFavourite";
import * as PropTypes from "prop-types";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import TermNotifications from "./TermNotifications";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-regular-svg-icons/faCopy";
import {faEdit} from "@fortawesome/free-regular-svg-icons/faEdit";

const TermLinks = (props) => {
    const {term, className, showCopy, showFavourite, showNotifications, favouriteEnabled, history, handleClickedStar, handleClickedLink} = props;

    const [isClickedLink, setClickedLink] = useState(false);
    const [isClickedStar, setClickedStar] = useState(false);
    const [starError, setStarError] = useState(null);

    const onExtendedDescriptionClick = () => {
        history.push(`/${term.content.language ? term.content.language : 'en'}/${term.slug}/${term.version && term.version.number ? term.version.number : 1}`);
    };

    const handleStar = () => {
        setClickedStar(true);
        setStarError(null);
        handleClickedStar()
    };

    const handleLink = () => {
        setClickedLink(true);
        handleClickedLink();
    };

    return (
        <>
            <ul className={`term-links ${className ? className : ''}`}>
                {term.extended_description_type !== null && term.extended_description_type === 1 &&
                    <li>
                        <div className='note-symbol-icon' onClick={onExtendedDescriptionClick} title="Term includes a paper" />
                    </li>
                }
                {term.extended_description_type !== null && term.extended_description_type === 0 &&
                    <li>
                        <div className='triangle-icon' onClick={onExtendedDescriptionClick} title="Term includes a list">
                            <img src={`${static_url}assets/extended-type-list.svg`}/>
                        </div>
                    </li>
                }
                {term.extended_description_type !== null && term.extended_description_type === 2 &&
                    <li>
                        <div className='triangle-icon' onClick={onExtendedDescriptionClick} title="Term includes a document" style={{border: 'none'}}>
                            <FontAwesomeIcon icon={faCopy} className={'copy'} style={{color: '#f37f72'}}/>
                        </div>
                    </li>
                }
                {
                    term.has_note && 
                    <li className='term-link-wrapper' title="User Note">
                        <FontAwesomeIcon icon={['far', 'sticky-note']} className={'term-link'}/>
                    </li>
                }
                {
                    showCopy
                    && <li className='term-link-wrapper'>
                        <CopyTermIcon term={term} handleClickedLink={handleLink}/>
                    </li>
                }
                {
                    showFavourite
                    && <li className="term-link-wrapper">
                        {
                            favouriteEnabled
                            ? <ToggleFavourite term={term} handleClickedStar={handleStar} onError={(e) => setStarError(e)}/>
                            : <img className={'term-star'} src={`${static_url}assets/favourite-star.svg`} onClick={handleStar}/>
                        }
                    </li>
                }
            </ul>
            {showNotifications && 
                <TermNotifications
                    term={term}
                    isClickedLink={isClickedLink}
                    isClickedStar={isClickedStar}
                    starError={starError}
                    onLinkClose={() => setClickedLink(false)}
                    onStarClose={() => {
                        setClickedStar(false);
                        setStarError(null);
                    }}
                />}
        </>
    )
};

TermLinks.propTypes = {
    term: PropTypes.object.isRequired,
    className: PropTypes.string,
    showCopy: PropTypes.bool,
    showFavourite: PropTypes.bool,
    favouriteEnabled: PropTypes.bool,
    handleClickedStar: PropTypes.func,
    handleClickedLink: PropTypes.func
};

export default withRouter(TermLinks);
