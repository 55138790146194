import React, {Component} from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import CustomTableWithSelecting from "./CustomTableWithSelecting";
import TableHeadCellSorting from "./TableHeadCellSorting";

class CustomFilterTable extends Component {
    static propTypes = {
        items: PropTypes.array,
        children: PropTypes.object,
        columns: PropTypes.array,
        footer: PropTypes.array,
        className: PropTypes.string,
        onChangeSelect: PropTypes.func,
        onArrowClick: PropTypes.func,
        onFiltersChange: PropTypes.func,
    };

    state = {
        order_by: -1,
        order_asc: true,
        filters: {},
    };

    onTitleArrowClick = (titleSlug) => {
        this.setState(
            ({order_by, order_asc}) => ({
                order_by: titleSlug,
                order_asc: !order_asc,
            }),
            () => {
                this.props.onArrowClick &&
                this.props.onArrowClick(
                    this.state.order_by,
                    this.state.order_asc
                );
            }
        );
    };

    onFilterChange = (e) => {
        e.persist();
        const name = e.target.name;
        let value = e.target.value;
        if (e.target.value && e.target.type === "date") {
            value = moment(e.target.value, "YYYY-MM-DD").valueOf();
        }
        this.setState(
            ({filters}) => ({
                filters: {
                    // TODO: uncomment for multiple filters
                    // ...filters,
                    [name]: e.target.value,
                },
            }),
            () => {
                this.props.onFiltersChange &&
                this.props.onFiltersChange({
                    type: value ? name : "",
                    value: value,
                });
            }
        );
    };

    render() {
        const {children, columns, drawFilters = true} = this.props;
        const {filters, order_by, order_asc} = this.state;

        columns.forEach((field) => {
            if (!field.titleSlug || !field.isSortable) {
                return;
            }

            field.component = (
                <TableHeadCellSorting
                    title={field.title}
                    isSort={field.titleSlug ? true : false}
                    showArrowUp={order_by === field.titleSlug && !order_asc}
                    onArrowClick={() => this.onTitleArrowClick(field.titleSlug)}
                />
            );

            if (!field.input) {
                return;
            }
            const {type, name} = field.input;

            field.input.component = (
                <input
                    type={type}
                    name={name}
                    value={filters[name] || ""}
                    className='console-table-header-input'
                    onChange={this.onFilterChange}
                />
            );
        });

        return (
            <CustomTableWithSelecting {...this.props} drawFilters={drawFilters}>
                {children}
            </CustomTableWithSelecting>
        );
    }
}

export default CustomFilterTable;
