import React, {Component} from "react";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Teams from "./Teams/Teams";
import Logs from "./Log/Logs";
import Terms from "./Terms/Terms";
import Sets from "./Sets/Sets";
import Users from "./Users/Users";
import Labels from "./Labels/Labels";
import Languages from "./Languages/Languages";
import Tokens from "./Tokens/Tokens";
import InfoBlocks from "./InfoBlocks/InfoBlocks";
import Templates from "./Templates/Templates";

class ManagementConsole extends Component {

    onTabSelect = (index) => {
        const {history} = this.props;
        switch (index) {
            case 0:
                history.push('/management-console/terms');
                break;
            case 1:
                history.push('/management-console/sets');
                break;
            case 2:
                history.push('/management-console/users');
                break;
            case 3:
                history.push('/management-console/teams');
                break;
            case 4:
                history.push('/management-console/labels');
                break;
            case 5:
                history.push('/management-console/languages');
                break;
            case 6:
                history.push('/management-console/infoblocks');
                break;
            case 7:
                history.push('/management-console/templates');
                break;
            case 9:
                history.push('/management-console/log');
                break;
            case 8:
                history.push('/management-console/tokens');
                break;
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="console-title management-console-title">Management Console</div>
                <div className="console-container">
                    <Tabs className="console-tabs" selectedIndex={this.props.tabIndex} onSelect={this.onTabSelect}>
                        <TabList>
                            <Tab>Terms</Tab>
                            <Tab>Sets</Tab>
                            <Tab>Users</Tab>
                            <Tab>Teams</Tab>
                            <Tab>Labels</Tab>
                            <Tab>Languages</Tab>
                            <Tab>Info Blocks</Tab>
                            <Tab>Templates</Tab>
                            <Tab>Tokens</Tab>
                            <Tab>Log</Tab>
                        </TabList>

                        <TabPanel>
                            <Terms/>
                        </TabPanel>

                        <TabPanel>
                            <Sets/>
                        </TabPanel>

                        <TabPanel>
                            <Users/>
                        </TabPanel>

                        <TabPanel>
                            <Teams/>
                        </TabPanel>

                        <TabPanel>
                            <Labels/>
                        </TabPanel>

                        <TabPanel>
                            <Languages/>
                        </TabPanel>

                        <TabPanel>
                            <InfoBlocks/>
                        </TabPanel>

                        <TabPanel>
                            <Templates/>
                        </TabPanel>

                        <TabPanel>
                            <Tokens/>
                        </TabPanel>

                        <TabPanel>
                            <Logs isAdmin={true}/>
                        </TabPanel>
                    </Tabs>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(ManagementConsole));