import {success, error} from "redux-saga-requests";
import * as types from "./types";

const initialState = {
    isFetching: false,
    isError: false,
    errMsg: '',
    errorStatus: null,
    contributorsData:{},
    editorList: [],
    allTeams: {},
    meetSection: '',
    sponsorSection: '',
    acknowledgementsSection: '',
    topicCuratorsSection: ''
};

const contributors = (state = initialState, action) => {
    switch (action.type) {

        case types.FETCH_CONTRIBUTORS:
        case types.FETCH_ALL_TEAMS:
        case types.FETCH_CONTRIBUTORS_MEET_SECTION:
        case types.FETCH_CONTRIBUTORS_SPONSOR_SECTION:
        case types.UPDATE_CONTRIBUTORS_PARAGRAPHS:
        case types.FETCH_EDITORS: {
            return {
                ...state,
                isFetching: true,
                isError: false
            };
        }

        case types.CLEAR_CONTRIBUTORS_DATA: {
            return {
                ...state,
                meetSection: '',
                sponsorSection: '',
                acknowledgementsSection: ''
            }
        }

        case success(types.FETCH_CONTRIBUTORS_MEET_SECTION): {
            return {
                ...state,
                isFetching: false,
                meetSection: action.payload.data.data
            };
        }

        case success(types.FETCH_CONTRIBUTORS_SPONSOR_SECTION): {
            return {
                ...state,
                isFetching: false,
                sponsorSection: action.payload.data.data
            };
        }

        case success(types.FETCH_TOPIC_CURATORS): {
            return {
                ...state,
                isFetching: false,
                topicCuratorsSection: action.payload.data.data
            };
        }

        case success(types.FETCH_CONTRIBUTORS_ACKNOWLEDGEMENTS): {
            return {
                ...state,
                isFetching: false,
                acknowledgementsSection: action.payload.data.data
            };
        }

        case types.SET_CONTRIBUTORS_PARAGRAPHS: {
            return {
                ...state,
                [action.payload.path]: action.payload.data
            };
        }

        case success(types.UPDATE_CONTRIBUTORS_PARAGRAPHS): {
            const fieldName = action.meta.requestAction.payload.meta.path;
            return {
                ...state,
                isFetching: false,
                [fieldName]: action.payload.data.data
            };
        }

        case success(types.FETCH_CONTRIBUTORS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                contributorsData: action.payload.data.data
            };
        }

        case success(types.FETCH_EDITORS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                editorList: action.payload.data.data
            };
        }

        case success(types.FETCH_ALL_TEAMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                allTeams: action.payload.data.data
            };
        }


        case error(types.FETCH_EDITORS):
        case error(types.FETCH_ALL_TEAMS):
        case error(types.FETCH_CONTRIBUTORS_MEET_SECTION):
        case error(types.UPDATE_CONTRIBUTORS_PARAGRAPHS):
        case error(types.FETCH_CONTRIBUTORS): {
            return {
                ...state,
                isFetching: false,
                isError: true,
                errMsg: action.payload.response.data,
                errorStatus: action.payload.response.status
            };
        }


        default:
            return state;
    }
};

export default contributors;
