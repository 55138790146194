import React from "react";
import {FaCopy} from "react-icons/lib/fa";
import {baseURLForLink} from "../../../../configs/config";
import moment from "moment";

function CiteInfo(props) {
    const {term} = props;

    function buildCitation() {
        const content = term.content;
        if (!term.content) {
            return '';
        }
        const citation = `BIM Dictionary (2019), ${content && content.title ? `${content.title},` : ''} version ${term.version ? term.version.number : 1}. `
            + `${baseURLForLink}${content && content.language ? content.language : 'en'}/${term.slug}/${term.version ? term.version.number : 1}. `
            + `last accessed ${moment().format('LL')}`;
        return citation.replace(/<\s*[^>]*>/g, '')
    };

    return (
        <div>
            <hr/>
            <div className='cite-title'>Cite this page as:</div>
            <div className='cite-text'>{buildCitation()}</div>
        </div>
    )
}

export default CiteInfo