import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {clearEmbeddedTerm, fetchEmbeddedTerm, setSearchLabel} from "../../store/terms/actions";
import EmbeddedTerm from "../../helpers/EmbeddedTerm";
import reactStringReplace from 'react-string-replace';
import * as PropTypes from "prop-types";
import TermCardView from "./TermCardView";


class RandomTerm extends Component {

    static propTypes = {
        term: PropTypes.object.isRequired,
        langs: PropTypes.array,
        onChangeTranslation: PropTypes.func,
        setSearchLabel: PropTypes.func,
        fetchEmbeddedTerm: PropTypes.func,
        clearEmbeddedTerm: PropTypes.func,
    };

    state = {
        clickedEmbeddedTerm: false,
    };

    acceptSearchLabel = (data) => {
        this.props.setSearchLabel(data.item);
        this.props.history.push('/terms/search');
    };

    onClickEmbedded(title, i) {

        const {term, fetchEmbeddedTerm} = this.props;
        const {embeddedTerm} = this.props;
        title = title.replace(/(<span class='found-word'>)|(<\/span>)/gi, '');

        if (embeddedTerm.title !== undefined && embeddedTerm.title.toLowerCase() === title.toLowerCase()) {
            this.setState({clickedEmbeddedTerm: !this.state.clickedEmbeddedTerm});
            return
        }

        fetchEmbeddedTerm(title, term.content.language, i)
            .then((value) => {
                this.setState({clickedEmbeddedTerm: true});
            });
    }

    closeEmbeded = () => {
        this.setState({
            clickedEmbeddedTerm: false
        })
    };

    convertEmbeddedTerms() {
        const {embeddedTerm, term} = this.props;
        if (term && term.content && term.content.description === undefined)
            return term.content.description;
        if (term && term.content && term.content.description) {
            return reactStringReplace(term.content.description, new RegExp(/\[\[(.*?)\]\]/, "gim"), (match, i) => (
                <span className='inline-topic_embedded' key={`${i}_embedded`}
                      onClick={() => this.onClickEmbedded(match, i)}><span dangerouslySetInnerHTML={{__html: match}}></span>
                    {i === embeddedTerm.id && this.state.clickedEmbeddedTerm && embeddedTerm.title &&
                    <EmbeddedTerm regExp={new RegExp(/\[\[(.*?)\]\]/, "gim")} term={embeddedTerm}
                                  title={embeddedTerm.title} description={embeddedTerm.description}
                                  closeEmbeded={this.closeEmbeded}/>
                    }
                </span>
            ))

        }
    }

    componentWillUnmount() {
        this.props.clearEmbeddedTerm();
    }


    render() {
        const {term, langs, onChangeTranslation} = this.props;
        const {clickedEmbeddedTerm} = this.state;
        return (
            <TermCardView
                title={term.content && term.content.title}
                convertEmbeddedTerms={() => this.convertEmbeddedTerms()}
                onChangeTranslation={onChangeTranslation}
                term={term}
                langs={langs}
                clickedEmbeddedTerm={clickedEmbeddedTerm}
                acceptSearchLabel={({item, term}) => this.acceptSearchLabel({item, term})}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    embeddedTerm: state.terms.embeddedTerm,
});

const mapActionsToProps = {setSearchLabel, fetchEmbeddedTerm, clearEmbeddedTerm};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(RandomTerm));
