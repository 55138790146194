import React, {Component} from 'react'
import StyledCheckbox from "../../../../../../helpers/StyledCheckbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {faLock} from "@fortawesome/free-solid-svg-icons/index";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    excludeInfoblocks, includeInfoblocks, openCloseInfoblock, selectInfoblock,
    showHideInfoblock
} from "../../../../../../store/infoblocks/actions";
import Cookies from 'js-cookie';
import scroller from './utils/scroller';

class DocumentInfoblockRow extends Component {
    level = 0;

    findIndex = (blocks, id, level) => {
        for (const [index, item] of Object.entries(blocks)) {
            if (item.id === id) {
                this.level = level;
                return parseInt(index) + 1;
            }

            if (item.children.length !== 0) {
                const foundIndex = this.findIndex(item.children, id, level + 1);
                if (foundIndex !== undefined) {
                    return (parseInt(index) + 1) + '.' + foundIndex;
                }
            }
        }
    };

    toggleChechtItem = async (item) => {
        await this.props.showHideInfoblock(item.id);
        if (this.props.user.id) {
            item.checked ? this.props.includeInfoblocks({'ids': [item.id]}) : this.props.excludeInfoblocks({'ids': [item.id]})
        }
    };

    selectInfoblock = (item) => {
        scroller('info-block-'+ item.id, window.innerWidth <= 767);
        this.props.selectInfoblock(item.id)
    };

    checkAndShowHideBlock = (item) => {
        if (!item.expanded) {
            const cookie = Cookies.get('expanded');
            const expanded = cookie ? JSON.parse(cookie) : [];
            expanded.push(item.id);
            Cookies.set('expanded', JSON.stringify(expanded.filter((v, i, a) => a.indexOf(v) === i)),
                { path: this.props.location.pathname });
        } else {
            const cookie = Cookies.get('expanded');
            let expanded = cookie ? JSON.parse(cookie) : [];
            if (expanded.length) {
                const index = expanded.indexOf(item.id);
                if (index !== -1) expanded.splice(index, 1);
                Cookies.set('expanded', JSON.stringify(expanded), { path: this.props.location.pathname });
            }
        }
        this.showHideBlock(item)

    };

    showHideBlock = (item) => {
        this.props.openCloseInfoblock(item.id);
    };

    render() {
        const {item, selectedInfoblockId, infoblocks} = this.props;
        const prefix = this.findIndex(infoblocks, item.id, 0);
        const {user} = this.props;

        return (
            <div className={`document-added-infoblocks`}>
                <div key={item.id} className={`document-infoblock-row`}>
                    <StyledCheckbox
                        styles={{left: 0}}
                        checked={item.checked}
                        changeSelect={() => this.toggleChechtItem(item)}/>

                    <div className={`${item.id === selectedInfoblockId ? 'selected' : ''} document-infoblock-title`} onClick={() => this.selectInfoblock(item)}>
                        <div className={`${this.level === 0 ? 'bordered' : ''} document-infoblock-subtitle`} style={{paddingLeft: 0}}>
                            {`${prefix !== undefined ? prefix : ''}. ${item.title}`}

                            {item.locked && (user.role === 'Editor' || user.role ==='Admin') && <FontAwesomeIcon icon={faLock} className={'lock-alt-icon'}/>}

                            {item.children && item.children.length !== 0 &&
                                <FontAwesomeIcon
                                    icon={`${item.expanded === true ? 'chevron-down' : 'chevron-up'}`}
                                    className={'document-infoblock-arrow'}
                                    onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.checkAndShowHideBlock(item)}}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({
    selectedInfoblockId: state.infoblocks.selectedInfoblockId,
    infoblocks: state.infoblocks.infoblocks,
    user: state.auth.user
});

const mapDispatchToProps = {openCloseInfoblock, showHideInfoblock, includeInfoblocks, excludeInfoblocks, selectInfoblock};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentInfoblockRow));

// export default DocumentInfoblockRows