import React from "react";
import {Modal} from "react-bootstrap";


const ModalTermsUploadResult = ({ show, onExit, uploaded, rejected }) => {
    return (
        <Modal show={show} className="modal-terms-upload-result"
               size="lg" centered
               onHide={() => onExit()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>Terms Upload Result</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div>
                        {uploaded && uploaded.length > 0 &&
                            <>
                                <div className="block-title-uploaded">
                                    Terms uploaded:
                                </div>
                                {uploaded.map((item, i) => (
                                    <div className="block-item" key={i}>{i + 1}. {item}</div>
                                ))}
                            </>
                        }
                    </div>
                    <div>
                        {rejected && rejected.length > 0 &&
                            <>
                                <div className="block-title-rejected">
                                    Terms rejected:
                                </div>
                                {rejected.map((item, i) => (
                                    <div className="block-item" key={i}>{i + 1}. {item}</div>
                                ))}
                            </>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div onClick={() => onExit()} className="console-button">Ok</div>
            </Modal.Footer>
        </Modal>
    )

};

export default ModalTermsUploadResult;