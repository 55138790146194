import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Select from "react-select";

const ModalChangeRole = ({show, currentRole, allRoles, onCancelClick, onSendClick}) => {

    const [role, setChosenRole] = useState({value: 'default', label: currentRole});

    useEffect(() => {
        setChosenRole({value: 'default', label: currentRole})
    }, [currentRole]);

    const send = () => {
        if (role.value === 'default') {
            onCancelClick();
            return;
        }
        onSendClick(role.value);
    };

    return (
        <Modal show={show} className="modal-user-change-role"
               size="md" centered
               aria-labelledby="contained-modal-title-vcenter"
               onHide={() => onCancelClick()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>Change user role</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Select
                    className={"terms-filter-set"}
                    onChange={(selected) => setChosenRole(selected)}
                    value={role}
                    options={allRoles.map((item, index) => ({
                        value: item.id,
                        label: item.name
                    }))}
                />
            </Modal.Body>
            <Modal.Footer>
                <div onClick={() => onCancelClick()} className="cancel-button">Cancel</div>
                <div onClick={() => send()} className="console-button">Change</div>
            </Modal.Footer>
        </Modal>
    )
};

const mapStateToProps = (state) => ({
    allRoles: state.managementConsole.availableUserRoles
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangeRole);