import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {bulkDeleteLanguages, deleteAdminLanguage, getAdminLanguages} from "../../../store/console/actions";
import Paginator from "../../../helpers/Paginator";
import LanguageRow from "./LanguageRow";
import { managementConsolePaging } from '../../../configs/config';
import CustomTableWithSelecting from "../../../helpers/CustomTableWithSelecting";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

class Languages extends Component {
    state = {
        searchParams: {
            per_page: managementConsolePaging,
            page: 1
        },
        selected: []
    };

    _tableColumns = [{
        style: {
            width: '3%'
        },
        className: 'console-select',
        checkboxesTitle: true
    }, {
        style: {
            width: '47%'
        },
        title: 'Language Name',
        className: 'console-terms-title'
    }, {
        style: {
            width: '25%'
        },
        title: 'Name In English',
        className: 'console-terms-language-in-english'
    }, {
        title: 'Language Code',
        className: 'console-terms-sets'
    }, {
        style: {
            width: '3%'
        },
        title: '',
        className: 'console-terms-actions'
    }];

    _paginatorChangedPage(page) {
        const {searchParams} = this.state;

        searchParams.page = page.selected + 1;
        this.setState({searchParams});

        this.props.getAdminLanguages(searchParams);
    }

    _deleteAction(language) {
        const {searchParams} = this.state;

        this.props.deleteAdminLanguage(language.id)
            .then(() => this.props.getAdminLanguages(searchParams))
    }

    deleteSelectedLanguages() {
        const {selected, searchParams} = this.state;

        this.props.bulkDeleteLanguages({languages: selected})
            .then(() => this.props.getAdminLanguages(searchParams))
    }

    render() {
        const { languages } = this.props;

        const _tableFooterColumns = [{
            colspan: 7,
            component: <Paginator
                pageCount={this.props.totalLanguages / this.state.searchParams.per_page}
                pageChanged={(data) => this._paginatorChangedPage(data)}
                forcePage={this.state.searchParams.page - 1}
                className={"console-paginator"}
            />
        }];

        return (
            <div className="console-container-full console-terms-container">
                <div className="console-header row no-gutters">                   
                    <div className="console-header-lp" /> 
                    <div className="console-header-actions-line">
                        <div className="console-header-selected">
                            <span className="selected-rows">{this.state.selected.length} Selected</span>
                            <span className="delete-selected" onClick={() => this.deleteSelectedLanguages()}>
                                <FontAwesomeIcon icon={faTrashAlt} className={'delete-link'} /> remove
                            </span>
                        </div>
                        <button className="console-button">
                            <Link to={'/management-console/languages/add-language'}>Create New Language</Link>
                        </button>
                    </div>
                </div>

                <div className="console-body">

                    <CustomTableWithSelecting
                        className={"console-table"}
                        columns={this._tableColumns}
                        footer={_tableFooterColumns}
                        items={languages}
                        deleteAction={(item) => this._deleteAction(item)}
                        onChangeSelect={(selected) => {
                            this.setState({
                                selected
                            });
                        }}
                    >
                        <LanguageRow />
                    </CustomTableWithSelecting>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    languages: state.managementConsole.languages,
    totalLanguages: state.managementConsole.totalLanguages
});

const mapDispatchToProps = (dispatch) => ({
    getAdminLanguages: (data) => dispatch(getAdminLanguages(data)),
    deleteAdminLanguage: (id) => dispatch(deleteAdminLanguage(id)),
    bulkDeleteLanguages: (data) => dispatch(bulkDeleteLanguages(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
