import React from "react";
import {Route, Switch, withRouter, Redirect} from "react-router-dom";
import Home from "./Home/Home";
import Contributors from "./Contributors/Contributors";
import EditorsDashboard from "./EditorsDashboard/EditorsDashboard";
import ManagementConsole from "./ManagementConsole/ManagementConsole";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import MyProfile from "./MyProfile/MyProfile";
import AddTerm from "./ManagementConsole/Terms/AddTerm";
import AddEditSet from "./ManagementConsole/Sets/AddEditSet";
import PrivateRoute from "./PrivateRoute";
import EditTerm from "./ManagementConsole/Terms/EditTerm";
import StarredTerms from "./StarredTerms/StarredTerms";
import MorePages from "./MorePages/MorePages";
import MorePagesEdit from "./MorePages/MorePagesEdit";
import ContactUs from "./MorePages/ContactUs";
import AddEditLabel from "./ManagementConsole/Labels/AddEditLabel";
import TermTemplateView from "./Term/TermTemplateView";
import TermPageForEditors from "./Term/TermPageForEditors";
import SearchPage from './SearchForm/SearchPage';
import {LinkedInPopUp} from 'react-linkedin-login-oauth2';
import ReviewersDashboard from "./ReviewersDashboard/ReviewersDashboard";
import TermPageForReviewers from "./ReviewersDashboard/TermPageForReviewers";
import AddEditLanguage from "./ManagementConsole/Languages/AddEditLanguage";
import InfoBlock from "./ManagementConsole/InfoBlocks/InfoBlock";
import Template from "./ManagementConsole/Templates/Template";
import AllTerms from "./AllTerms/AllTerms";
import RedirectTerm from "./Term/RedirectTerm";
import NotFoundPage from '../helpers/NotFoundPage';
import ResetPassword from "./Auth/ResetPassword";
import AuthorsDashboard from "./AuthorsDashboard/AuthorsDashboard";
import Logs from "./ManagementConsole/Log/Logs";
import LogsByPermission from "./Logs/LogsByPermission";

const Navigation = () => {
    return (
        <Switch>
            <Route
                exact
                path={'/'}
                component={Home}/>

            <Route
                exact
                path="/linkedin_auth"
                component={LinkedInPopUp}/>

            <Route
                path={'/contributors'}
                component={Contributors}/>

            <Route
                path={'/login'}
                component={Login}/>

            <Route
                path={'/register'}
                component={Register}/>

            <Redirect
                from='/basic'
                to='/basics'/>

            <Route
                path={'/basics'}
                component={MorePages} exact/>

            <Route
                path={'/features'}
                component={MorePages} exact/>

            <Route
                path={'/participate'}
                component={MorePages} exact/>

            <Route
                path={'/forum'}
                component={MorePages} exact/>

            <Route
                path={'/terms-of-use'}
                component={MorePages} exact/>

            <Route
                path={'/privacy-policy'}
                component={MorePages} exact/>

            <Route
                path={'/licenses'}
                component={MorePages} exact/>

            <Route
                path={'/all-terms'}
                component={AllTerms} exact/>

            <Route
                path={'/about'}
                component={MorePages} exact/>

            <Route
                path={'/contact'}
                component={ContactUs} exact/>

            <PrivateRoute
                roles={['admin']}
                path={'/:pagePath/edit'}
                component={MorePagesEdit} exact/>

            <Route
                path={'/terms/search'}
                component={SearchPage} exact/>

            <Route
                path={'/starred-terms'}
                component={StarredTerms}/>

            <Route
                path={'/reset'}
                component={ResetPassword}/>

            <PrivateRoute
                path={'/my-account'}
                component={MyProfile}/>

            <PrivateRoute
                roles={['editor', 'co-editor']}
                path={'/editors-dashboard'}
                component={EditorsDashboard}/>

            <PrivateRoute
                isAuthor={true}
                path={'/authors-dashboard'}
                component={AuthorsDashboard}/>

            {/* Management Console */}

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/terms/add-term'}
                component={AddTerm}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/terms/:termId/draft'}
                component={AddTerm}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/terms/:termId/minor-edit'}
                component={AddTerm}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/terms/:termId'}
                isAuthor={true}
                orRoleOrPerm={true}
                component={EditTerm}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/terms'}
                render={() => <ManagementConsole tabIndex={0}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/sets/add-set'}
                component={AddEditSet}/>
            <PrivateRoute
                roles={['admin']}
                path={'/management-console/sets/:setId'}
                component={AddEditSet}/>
            <PrivateRoute
                roles={['admin']}
                path={'/management-console/sets'}
                render={() => <ManagementConsole tabIndex={1}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/users'}
                render={() => <ManagementConsole tabIndex={2}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/teams'}
                render={() => <ManagementConsole tabIndex={3}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/labels/add-label'}
                component={AddEditLabel}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/labels/:labelId'}
                component={AddEditLabel}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/labels'}
                render={() => <ManagementConsole tabIndex={4}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/languages/add-language'}
                component={AddEditLanguage}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/languages/:languageId'}
                component={AddEditLanguage}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/languages'}
                render={() => <ManagementConsole tabIndex={5}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/infoblocks/add'}
                component={InfoBlock}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/infoblocks/:infoblockId'}
                component={InfoBlock}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/infoblocks'}
                render={() => <ManagementConsole tabIndex={6}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/templates/add'}
                component={Template}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/templates/:templateId'}
                component={Template}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/templates'}
                render={() => <ManagementConsole tabIndex={7}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/log'}
                render={() => <ManagementConsole tabIndex={9}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console/tokens'}
                render={() => <ManagementConsole tabIndex={8}/>}/>

            <PrivateRoute
                roles={['admin']}
                path={'/management-console'}
                render={() => <ManagementConsole tabIndex={0}/>}/>

            {/* Reviewer */}

            <PrivateRoute
                roles={['reviewer']}
                path={'/reviewers-dashboard/to-be-reviewed'}
                render={() => <ReviewersDashboard tabIndex={0}/>}/>

            <PrivateRoute
                roles={['reviewer']}
                path={'/reviewers-dashboard/sent-back'}
                render={() => <ReviewersDashboard tabIndex={1}/>}/>

            <PrivateRoute
                roles={['reviewer']}
                path={'/reviewers-dashboard/approved'}
                render={() => <ReviewersDashboard tabIndex={2}/>}/>

            <PrivateRoute
                roles={['reviewer']}
                path={'/reviewers-dashboard'}
                render={() => <ReviewersDashboard tabIndex={0}/>}/>

            {/*  =========  */}

            <Route
                path={'/:lang/:slug/:version'}
                component={TermTemplateView}
                exact/>

            <PrivateRoute
                roles={['editor', 'co-editor', 'author']}
                path={'/:lang/:slug/:version/edit-view-page'}
                component={TermPageForEditors}
                exact/>

            <Route
                path={'/:lang/:slug/:version/:infoblock'}
                component={TermTemplateView}
                exact/>

            <PrivateRoute
                roles={['editor', 'co-editor', 'reviewer', 'admin']}
                path={'/help'}
                component={MorePages}
                exact/>

            <PrivateRoute
                roles={['reviewer']}
                path={'/translate-view-page/:termId'}
                component={TermPageForReviewers}
                exact/>

            <PrivateRoute
                roles={['editor', 'co-editor', 'reviewer', 'admin', 'user']}
                path={'/logs'}
                isLogsViewer={true}
                component={LogsByPermission}/>

            <Route
                path={'/:slug'}
                component={RedirectTerm}
                exact/>

            <Route component={NotFoundPage}/>
        </Switch>
    );
};

export default withRouter(Navigation);
