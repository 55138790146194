import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import TemplateInfoblockRows from "./partials/TemplateInfoblockRows";
import InsertInfoblockModal from "./modals/InsertInfoblockModal";
import AddInfoblockModal from "./modals/AddInfoblockModal";
import {FaPencil, FaTrash} from "react-icons/lib/fa";
import {connect} from "react-redux";
import {
    addInfoblock, duplicateInfoblock, editInfoblock, insertInfoblock, lockUnlockInfoblock,
    removeInfoblock, saveInfoblock, showHideInfoblock
} from "../../../../../store/infoblocks/actions";
import AssignToUserModal from "./modals/AssignToUsersModal";
import {assignUsersToTemplate, fetchDocumentEditors} from "../../../../../store/console/actions";
import CiteRow from "./partials/CiteRow";
import AcceptDeclineModal from "./modals/AcceptDeclineModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import TermTemplateRow from "./partials/TermTemplateRow";
import scroller from "./leftSidebar/utils/scroller";

const initialState = {
    insertShow: false,
    addShow: false,
    assignShow: false,
    deleteShow: false,
    duplicateShow: false,
    hoveredInfoblock: {},
    isScrollUpVisible: false,
};

const scrollToTop = () => {
    scroller('document-template-navigator', window.innerWidth <= 767);
}

class DocumentTemplate extends Component {
    state = {
        ...initialState,
        infoblocks: []
    };

    async componentDidMount() {
        const {singleTerm, fetchAction, user, is_blank, isEdit} = this.props;

        window.addEventListener('scroll', this.showScrollUp);

        if (singleTerm && fetchAction && !is_blank) {
            let language = 'en';
            language = singleTerm.content && singleTerm.content.language ? singleTerm.content.language : language;
            if (user.role === 'Editor' && singleTerm.status && singleTerm.status === "Reviewed") {
                await fetchAction(singleTerm.id, true);
            } else {
                await fetchAction(singleTerm.id, language);
            }
        }

        if (user.role === 'Admin') {
            this.props.fetchDocumentEditors()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.showScrollUp);
    }

    showScrollUp = () => {
        const { isScrollUpVisible } = this.state;
        if (!isScrollUpVisible && window.scrollY > 300) {
            this.setState({ isScrollUpVisible: true });
        } else if (isScrollUpVisible && window.scrollY < 300) {
            this.setState({ isScrollUpVisible: false });
        }
    }

    /* actions */

    onDeleteBlock = (item) => this.props.removeInfoblock(item.id);
    onDuplicateBlock = (item) => this.props.duplicateInfoblock(item.id);
    onHideBlock = (item) => this.props.showHideInfoblock(item.id);
    onLockUnlockBlock = (item) => this.props.lockUnlockInfoblock(item.id);
    onEditBlock = (item) => this.props.editInfoblock(item.id);
    onSaveBlock = (item) => this.props.saveInfoblock(item.id, item);

    onInsertBlock = (selected) => {
        const {hoveredInfoblock} = this.state;
        const {singleInfoblock} = this.props;

        const block = {
            id: selected.value,
            content: singleInfoblock.content,
            title: selected.label,
            checked: true,
            children: []
        };
        this.props.insertInfoblock(hoveredInfoblock.id, block);
    };

    onAddBlock = (title, content) => {
        const {hoveredInfoblock} = this.state;
        const block = {
            is_new: true,
            title: title,
            content: content,
            checked: true,
            children: []
        };
        this.props.addInfoblock(hoveredInfoblock.id, block);
    };

    /* methods for modals */

    onInsert = (item) => this.setState({hoveredInfoblock: item, insertShow: true});
    onAdd = (item) => this.setState({hoveredInfoblock: item, addShow: true});
    onShowAssign = () => this.setState({assignShow: true});
    onDelete = (item) => this.setState({hoveredInfoblock: item, deleteShow: true});
    onDuplicate = (item) => () => {
        this.setState({hoveredInfoblock: item});
        if (item.id !== undefined) {
            this.onDuplicateBlock(item)
        }
    };

    hideModals = () => this.setState({...initialState});

    render() {
        const {infoblocks, singleTerm, removeTemplate} = this.props;
        const {hoveredInfoblock, isScrollUpVisible} = this.state;

        return (
            <div>
                {this.props.isEdit && this.props.user.role === 'Admin' && <div className="wrapper-manage-terms">
                    <TermTemplateRow
                        singleTerm={singleTerm}
                        removeTemplate={removeTemplate}
                    />
                </div>}

                <TemplateInfoblockRows
                    term={singleTerm}
                    isEdit={this.props.isEdit}
                    infoblocks={infoblocks}
                    onDelete={this.onDelete}
                    onDuplicate={this.onDuplicate}
                    onHide={this.onHideBlock}
                    onLockUnlock={this.onLockUnlockBlock}
                    onEdit={this.onEditBlock}
                    onSave={this.onSaveBlock}
                    onInsert={this.onInsert}
                    onAdd={this.onAdd}
                    updateEmbeddedTermClick={this.props.updateEmbeddedTermClick}
                />

                {isScrollUpVisible && (
                    <div className="document-mobile-scroll-up" onClick={scrollToTop}>
                        <FontAwesomeIcon icon="chevron-up" color="#4bacc6" />
                    </div>
                )}

                <CiteRow
                    term={this.props.singleTerm}
                />

                {this.props.isEdit && <>
                    <InsertInfoblockModal
                        show={this.state.insertShow}
                        onHide={this.hideModals}
                        onSave={(selected, singleInfoblock) => {
                            this.hideModals();
                            if (singleInfoblock.id !== undefined) {
                                this.onInsertBlock(singleInfoblock)
                            }
                        }}
                    />

                    <AddInfoblockModal
                        show={this.state.addShow}
                        onHide={this.hideModals}
                        onSave={(title, content) => {
                            this.hideModals();
                            this.onAddBlock(title, content)
                        }}
                    />

                    <AcceptDeclineModal
                        show={this.state.deleteShow}
                        onHide={this.hideModals}
                        action={'delete'}
                        onSave={() => {
                            if (hoveredInfoblock.id !== undefined) {
                                this.onDeleteBlock(hoveredInfoblock)
                            }
                            this.hideModals();
                        }}
                    />

                    <AcceptDeclineModal
                        show={this.state.duplicateShow}
                        onHide={this.hideModals}
                        action={'duplicate'}
                        onSave={() => {
                            if (hoveredInfoblock.id !== undefined) {
                                this.onDuplicateBlock(hoveredInfoblock)
                            }
                            this.hideModals();
                        }}
                    />
                </>}

                {this.props.user.role === 'Admin' && this.props.isEdit && <div style={{textAlign: 'center'}}>
                    <AssignToUserModal
                        show={this.state.assignShow}
                        onHide={this.hideModals}
                        users={this.props.documentEditors}
                        singleTerm={singleTerm}
                        onSave={(users) => {
                            if (singleTerm !== undefined) {
                                this.props.assignUsersToTemplate(singleTerm.id, {users});
                            }
                            this.hideModals();
                        }}
                    />
                    <button className="console-button" onClick={this.onShowAssign}>
                        Assign Users to Term
                    </button>
                </div>}


            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    infoblocks: state.infoblocks.infoblocks,
    documentEditors: state.managementConsole.documentEditors,
    singleInfoblock: state.managementConsole.singleInfoblock,
    user: state.auth.user
});

const mapDispatchToProps = {
    addInfoblock, insertInfoblock, duplicateInfoblock, removeInfoblock, showHideInfoblock, editInfoblock,
    saveInfoblock, lockUnlockInfoblock, fetchDocumentEditors, assignUsersToTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentTemplate));