import {Component} from "react";
import React from "react";
import moment from "moment";
import {NotificationManager} from "react-notifications";
import * as PropTypes from "prop-types";

class Calendar extends Component {

    static propTypes = {
        from: PropTypes.number,
        to: PropTypes.number,
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func
    };

    state = {
        from: this.props.from !== 0 ? moment(this.props.from).format("YYYY-MM-DD") : '',
        to: this.props.to !== 0 ? moment(this.props.to).format("YYYY-MM-DD") : ''
    };

    onDateChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    submit = () => {
        const fromTimestamp = moment(this.state.from, "YYYY-MM-DD").valueOf();
        const toTimestamp = moment(this.state.to, "YYYY-MM-DD").valueOf();
        if (isNaN(fromTimestamp)) {
            NotificationManager.error('From should be valid date');
            return;
        }
        if (toTimestamp < fromTimestamp) {
            NotificationManager.error('From should be earlier than To');
            return;
        }
        const dateRange = {};
        dateRange.from = fromTimestamp;
        if (!isNaN(toTimestamp)) {
            dateRange.to = toTimestamp;
        }
        this.props.onSubmit(dateRange);
    };

    cancel = () => {
        this.props.onCancel();
    };

    render() {
        const {from, to} = this.state;

        return (
            <div className="console-datepicker">
                <div className="datepicker-line">
                    <span>From</span>
                    <input type="date" value={from} onKeyDown={(e) => e.preventDefault()} name="from" onChange={this.onDateChange}/>
                </div>
                <div className="datepicker-line">
                    <span>To</span>
                    <input type="date" value={to} onKeyDown={(e) => e.preventDefault()} name="to" onChange={this.onDateChange}/>
                </div>
                <div className={"console-button"} onClick={() => this.submit()}>Ok</div>
                <div className={"console-button"} onClick={() => this.cancel()}>Cancel</div>
            </div>
        )
    }
}

export default Calendar;