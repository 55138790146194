import React, {Component} from "react";
import {connect} from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {fetchLanguageList} from "../../../store/terms/actions";
import {EarthSvg} from "../../../../../dictionary/core/static/static/assets/Icon-Lang-Earth.9a9d7e96.png"
import {config} from '../../../configs/config'

class EarthLanguage extends Component {
    state = {
        areLangsVisible: false,
        chosenLang: {
            code: 'en',
            name: 'english'
        }
    };

    _showLangs = () => {
        const {areLangsVisible} = this.state;

        this.setState({
            areLangsVisible: !areLangsVisible
        });
    };

    _checkTermLanguage = (lang) => {
        const {term} = this.props;
        let result = false;
        if (term.available_languages) {
            term.available_languages.forEach(termLang => {
                if (termLang.language === lang.code) {
                    result = true;
                }
            });
        }
        return result
    };

    _chooseTermTranslation = (term, lang) => {

        if (this._checkTermLanguage(lang)) {
            this.setState({
                chosenLang: {...lang}
            });

            if (this.props.onChangeTranslation !== undefined)
                this.props.onChangeTranslation(lang);
        }

        // if (term.available_translations && term.available_translations.includes(lang.code) && term.content.language !== lang.code) {
        //     this.setState({
        //         chosenLang: {...lang}
        //     });
        //
        //     if (this.props.onChangeTranslation !== undefined)
        //         this.props.onChangeTranslation(lang);
        // }
    };

    _onlyAvailableTranslations = (lang) => {
        let result = this._checkTermLanguage(lang)
        return result
    };

    _closeModelisErthLanguage = (e) => {
        if (e.target.className !== `earth-americas`
            && e.target.className !== `lang-dropdown active-earth-americas`
            && e.target.className !== `lang-code-list`) {
            this.setState({
                areLangsVisible: false
            });
        }
    };

    componentDidMount() {
        window.addEventListener('click', this._closeModelisErthLanguage, false);

        if (this.props.langs.length === 0) {
            this.props.fetchLanguageList();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this._closeModelisErthLanguage, false)
    }

    render() {
        const {langs, term, showLangUnderIcon} = this.props;
        const {areLangsVisible, chosenLang} = this.state;

        let languageName = '';
        if (this.props.langs && this.props.langs.length !== 0 && term.content && term.content.language) {
            const language = langs.find(({code}) => code === term.content.language);
            languageName = language.english;
        }

        return (
            <div className={`lang-dropdown lang--earth lang-dropdown-with-sign ${
                areLangsVisible && 'active-earth-americas'
            }`}>
                <img className={`earth-americas`} onClick={() => this._showLangs()}
                     src={`${static_url}assets/Icon-Lang-Earth.9a9d7e96.png`}/>
                <span className="language-name">{showLangUnderIcon && (languageName ? languageName :
                    <span>&nbsp;</span>)}</span>
                <ReactCSSTransitionGroup
                    transitionName="example"
                    transitionEnterTimeout={300}
                    transitionLeaveTimeout={300}
                >
                    {areLangsVisible && (
                        <div className="lang-code-list">
                            {langs.filter(this._onlyAvailableTranslations).map((lang, index) => (
                                    <span
                                        key={index}
                                        className={`lang-code ${this._checkTermLanguage(lang)
                                            ? 'available-lang'
                                            : 'unavailable-lang'
                                        } ${
                                            term.content && term.content.language === lang.code && 'current-lang'
                                        }`}
                                        onClick={() => this._chooseTermTranslation(term, lang)}
                                    >
                                        {lang.code}
                                    </span>
                                )
                            )}
                        </div>
                    )}
                </ReactCSSTransitionGroup>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    langs: state.terms.langs,
});
const mapActionsToProps = {fetchLanguageList};

export default connect(mapStateToProps, mapActionsToProps)(EarthLanguage);
