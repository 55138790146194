import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal, Container, Row, Col, Collapse} from 'react-bootstrap';
import Select from 'react-select';
import {
    getAdminSets,
    getAvailableCountries,
    getAvailableUserRoles,
    getTeams,
    getUsers
} from "../../../store/console/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons';
import {fetchLanguageList} from "../../../store/terms/actions";

const defaultUserSettings = {
    user: null,
    role: null,
    title: null,
    affiliation: null,
    country: null,
    city: null,
    canEditWithoutReview: false,
    isUserEditing: false,
};

class SaveTeamModal extends Component {
    state = {
        teamName: '',
        teamType: false,
        teamTypeId: false,
        userRowShow: false,
        teamUsers: [],
        teamSets: [],
        availableSets: [],
        availableUsers: [],
        userSettings: {...defaultUserSettings}
    };

    teamTypeOptions = [
        {value: 0, label: 'Language Team'},
        {value: 1, label: 'Country Team'},
        {value: 2, label: 'Topic Team'},
    ];

    userTitles = [
        {value: 0, label: 'Mr.'},
        {value: 1, label: 'Ms.'},
        {value: 2, label: 'Dr.'},
    ];

    _changeTeamName(e) {
        this.setState({
            teamName: e.target.value
        })
    }

    _selectUser(selected) {
        this.setState({
            userRowShow: selected !== null,
            userSettings: selected === null ? {...defaultUserSettings} : {...defaultUserSettings, user: selected}           
        });
    }

    _selectUserRole(selected) {
        const {userSettings} = this.state;
        this.setState({userSettings: {...userSettings, role: selected}})
    }

    _selectUserTitle(selected) {
        const {userSettings} = this.state;
        this.setState({userSettings: {...userSettings, title: selected}})
    }

    _changeUserAffiliation(e) {
        const {userSettings} = this.state;
        this.setState({userSettings: {...userSettings, affiliation: e.target.value}})
    }

    _selectUserCountry(selected) {
        const {userSettings} = this.state;
        this.setState({userSettings: {...userSettings, country: selected}})
    }

    _changeUserCity(e) {
        const {userSettings} = this.state;
        this.setState({userSettings: {...userSettings, city: e.target.value}})
    }

    _addUserToTeam() {
        const {userSettings, teamUsers, availableUsers} = this.state;        
        if (!userSettings.isUserEditing) {
            if (teamUsers.findIndex(user => user.user.value === userSettings.user.value) !== -1) {
                userSettings.isUserEditing = true;
            }
        }
        
        this.setState({
            userRowShow: false,
            userSettings: {...defaultUserSettings},
            teamUsers: !userSettings.isUserEditing ?
                [...teamUsers, userSettings] :
                [...teamUsers.map(user => user.user.value === userSettings.user.value ? userSettings : user)],
            availableUsers: availableUsers.filter((user) => user.fullname !== userSettings.user.label)
        });
    }

    _cancelAddingUser() {
        this.setState({
            userRowShow: false,
            userSettings: {...defaultUserSettings}
        });
    }

    _changeUserRole(selected, currentUser) {
        const {teamUsers} = this.state;
        this.setState({
            teamUsers: [...teamUsers.map(user => user.user.value === currentUser.user.value ?
                {...currentUser, role: selected} :
                user
            )]
        });
    }

    _publishWithoutReview(currentUser) {
        const {teamUsers} = this.state;
        this.setState({
            teamUsers: [...teamUsers.map(user => user.user.value === currentUser.user.value ?
                {...currentUser, canEditWithoutReview: !currentUser.canEditWithoutReview} :
                user
            )]
        });
    }

    _editUser(user) {
        this.setState({
            userRowShow: true,
            userSettings: {...user, isUserEditing: true}
        });
    }

    _deleteUser(currentUser) {        
        const {teamUsers, availableUsers} = this.state;
        availableUsers.push({fullname: currentUser.user.label, id: currentUser.user.value});
        const sortedUsers = availableUsers.sort((a, b) => a.id - b.id);
        this.setState({
            teamUsers: teamUsers.filter(user => user.user.value !== currentUser.user.value),
            availableUsers: sortedUsers
        })
    }

    _selectSetToShare(selected) {
        const {teamSets, availableSets} = this.state;
        if (teamSets.findIndex(set => set.value === selected.value) !== -1) {
            return;
        }
        this.setState({
            teamSets: [...teamSets, selected],
            availableSets: availableSets.filter((set) => set.id !== selected.value)
        });
    }

    _deleteSet(currentSet) {
        const {teamSets, availableSets} = this.state;
        availableSets.push({id: currentSet.value, name: currentSet.label});
        const sortedSets = availableSets.sort((a, b) => a.id - b.id);
        this.setState({
            teamSets: teamSets.filter(set => set.value !== currentSet.value),
            availableSets: sortedSets
        })
    }

    _changeTeamType(selectedType) {
        this.setState({
            teamType: selectedType,
            teamTypeId: false
        });
    }

    _changeTeamTypeId(selectedId) {
        this.setState({
            teamTypeId: selectedId
        });
    }

    async onSave() {
        const {teamName, teamSets, teamType, teamTypeId, teamUsers} = this.state;
        await this.props.onSave({teamName, teamSets, teamType, teamTypeId, teamUsers})

        this.setState({
            teamName: '',
            teamType: false,
            teamTypeId: false,
            teamUsers: [],
            teamSets: [],
        })
    }

    componentDidMount() {
        this.props.getModalAdminUsers({all: true});
        this.props.getModalAdminSets({all: true});
        this.props.fetchModalLanguageList();
        this.props.getModalAvailableUserRoles();
        this.props.getModalAvailableCountries();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show !== 'add' && prevProps.show === 'add') {
            this.resetFields();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show === 'add') {
            this.resetFields();
            return;
        }

        const {users, singleTeam, sets} = nextProps;
        if (singleTeam.users){
            const usersId = [];
            singleTeam.users.map((user) => (usersId.push(user.user.value)));
            const availableUsers = users.filter((user) =>  !usersId.includes(user.id));
            this.setState({
                availableUsers: availableUsers,
                availableSets: sets
            });
        } else {
            this.setState({
                availableUsers: users,
                availableSets: sets
            });
        }
        if (singleTeam.id !== undefined) {
            this.setState({
                teamName: singleTeam.name,
                teamType: singleTeam.type,
                teamTypeId: singleTeam.type_id,
                teamUsers: singleTeam.users,
                teamSets: singleTeam.sets,
            })
        }
    }

    resetFields() {
        const {users, sets} = this.props;

        this.setState({
            teamName: '',
            teamType: false,
            teamTypeId: false,
            userRowShow: false,
            teamUsers: [],
            teamSets: [],
            availableSets: sets,
            availableUsers: users,
            userSettings: {...defaultUserSettings}
        })
    }

    render() {
        const {langs, availableUserRoles, availableCountries, singleTeam} = this.props;
        const {teamName, userRowShow, teamSets, teamUsers, availableUsers, availableSets, userSettings, teamType, teamTypeId} = this.state;
        
        const typeOptions = (teamType === false || teamType.value === 0) ? langs.map(lang => ({
            value: lang.code,
            label: lang.name
        })) : (teamType.value === 1) ? availableCountries.map(country => ({
            value: country[0],
            label: country[1]
        })) : [];

        return (
            <Modal
                className="wrapper-base-modal save-team-modal"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show !== ''}
                onHide={this.props.onHide} 
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.show === 'edit' ? 'Edit' : 'Create new'} Team
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="form-modal-team" xs={12} md={12}>
                                <label>Team Name<span className="input-required">*</span></label>
                                <input
                                    className="team-input"
                                    type={"text"}
                                    value={teamName || ""}
                                    onChange={(e) => this._changeTeamName(e)}
                                />
                            </Col>
                            <Col className="form-modal-team" xs={12} md={6}>
                                <label>Team Type<span className="input-required">*</span></label>
                                <Select
                                    onChange={(selected) => this._changeTeamType(selected)}
                                    value={teamType || ""}
                                    options={this.teamTypeOptions}
                                />
                            </Col>
                            {teamType.value !== 2 &&
                                <Col className="form-modal-team" xs={12} md={6}>
                                    <label>
                                        {teamType.value ===  1 ? `Select Country` : `Select Language`}
                                        <span className="input-required">*</span>
                                    </label>
                                    <Select
                                        onChange={(selected) => this._changeTeamTypeId(selected)}
                                        value={teamTypeId || ""}
                                        options={typeOptions}
                                    />
                                </Col>
                            }
                            <Col className="form-modal-team" xs={12} md={12}>
                                <label>Add Team Member</label>
                                <Select
                                    onChange={(selected) => this._selectUser(selected)}
                                    value={userSettings.user}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={availableUsers.map(user => ({
                                        value: user.id,
                                        label: user.fullname
                                    }))}
                                />

                                <Collapse className="team-member" in={userRowShow}>
                                    <Row>
                                        <Col xs={6} md={4}>
                                            <label>Select Role<span className="input-required">*</span></label>
                                            <Select
                                                onChange={(selected) => this._selectUserRole(selected)}
                                                value={userSettings.role || ""}
                                                options={availableUserRoles.map(role => ({
                                                    value: role.id,
                                                    label: role.name
                                                }))}
                                            />
                                        </Col>
                                        <Col xs={6} md={2}>
                                            <label>Title<span className="input-required">*</span></label>
                                            <Select
                                                value={userSettings.title || ""}
                                                onChange={(selected) => this._selectUserTitle(selected)}
                                                options={this.userTitles}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <label>Affiliation</label>
                                            <input
                                                className="team-input"
                                                value={userSettings.affiliation || ""}
                                                type={"text"}
                                                onChange={(e) => this._changeUserAffiliation(e)}
                                            />
                                        </Col>

                                        <Col className="input-margin" xs={12} md={6}>
                                            <label>Country<span className="input-required">*</span></label>
                                            <Select
                                                value={userSettings.country || ""}
                                                onChange={(selected) => this._selectUserCountry(selected)}
                                                options={availableCountries.map(country => ({
                                                    value: country[0],
                                                    label: country[1]
                                                }))}
                                            />
                                        </Col>
                                        <Col className="input-margin" xs={12} md={6}>
                                            <label>City</label>
                                            <input
                                                className="team-input"
                                                value={userSettings.city || ""}
                                                type={"text"}
                                                onChange={(e) => this._changeUserCity(e)}
                                            />
                                        </Col>

                                        <Col className="control-btn" xs={12} md={12}>
                                            <button disabled={!userSettings.role || !userSettings.title || !userSettings.country}
                                                    className="console-button"
                                                    onClick={() => this._addUserToTeam()}>
                                                {!userSettings.isUserEditing ? 'Add' : 'Save'}
                                            </button>
                                            <span onClick={() => this._cancelAddingUser()}>Cancel</span>
                                        </Col>
                                    </Row>
                                </Collapse>
                            </Col>
                            <Col xs={12} md={12}>
                                {teamUsers && teamUsers.map(user => (
                                    <Row key={user.user.value} className="team-member-item">
                                        <Col className="wrapper-item nopadding">
                                            {user.user.label}
                                        </Col>
                                        <Col className="wrapper-item">
                                            <Select
                                                onChange={(selected) => this._changeUserRole(selected, user)}
                                                value={user.role || ""}
                                                options={availableUserRoles.map(role => ({
                                                    value: role.id,
                                                    label: role.name
                                                }))}
                                            />
                                        </Col>
                                        <Col className="editor-checkbox">
                                            {user.role.label === 'Editor' && (
                                                <label className="check-box">
                                                    <input
                                                        type={"checkbox"}
                                                        checked={user.canEditWithoutReview}
                                                        onChange={() => this._publishWithoutReview(user)}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <p className="select-set-all">Can publish without review</p>
                                                </label>
                                            )}
                                        </Col>
                                        <Col className="team-edit nopadding">
                                            <FontAwesomeIcon icon={faPencilAlt} className={'edit-user'}
                                                             onClick={() => this._editUser(user)}/>
                                            <FontAwesomeIcon icon={faTrashAlt} className={'delete-user'}
                                                             onClick={() => this._deleteUser(user)}/>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                            <Col className="form-modal-team" xs={12} md={12}>
                                <label>Share Set(s)</label>
                                <Select
                                    onChange={(selected) => this._selectSetToShare(selected)}
                                    value={null}
                                    options={availableSets.map(set => {
                                        return {
                                            value: set.id,
                                            label: set.name
                                        }
                                    })}
                                />

                                
                                {teamSets && teamSets.map(set => (                                    
                                        <Row key={set.value} className="set-item">
                                        <Col xs={11} md={11} className="nopadding">
                                            {set.label}
                                        </Col>
                                        <Col xs={1} md={1} className="nopadding d-flex justify-content-end">
                                            <FontAwesomeIcon icon={faTrashAlt} className={'delete-user'}
                                                             onClick={() => this._deleteSet(set)}/>
                                        </Col>
                                    </Row>                                                                      
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer className="delete-extended">
                    <span onClick={this.props.onHide}>Cancel</span>
                    <button disabled={!teamName || !teamType || !teamTypeId}                    
                            className="console-button"
                            onClick={() => this.onSave()}>
                        Save
                    </button>
                </Modal.Footer>


            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    users: state.managementConsole.users,
    sets: state.managementConsole.sets,
    langs: state.terms.langs,
    availableUserRoles: state.managementConsole.availableUserRoles,
    availableCountries: state.managementConsole.availableCountries,
    singleTeam: state.managementConsole.singleTeam
});

const mapDispatchToProps = (dispatch) => ({
    getModalAdminUsers: (data) => dispatch(getUsers(data)),
    getModalAdminSets: () => dispatch(getAdminSets()),
    fetchModalLanguageList: () => dispatch(fetchLanguageList()),
    getModalAvailableUserRoles: () => dispatch(getAvailableUserRoles()),
    getModalAvailableCountries: () => dispatch(getAvailableCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveTeamModal);