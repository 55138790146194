import React, {useState} from "react";
import {Link} from "react-router-dom";
import TermLinks from "../Term/partials/TermLinks";
import Notification from "../../helpers/Notification";

const StarredRow = (props) => {

    const { term, handleToggle, isLink, className, checked, handleChange, handleClickedStar, id } = props;

    const [isClickedLink, setClickedLink] = useState(false);

    return (
        <div className={className}>
            <div className="title-box">
                <label className="check-box">
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => handleChange(id)}
                    />
                    <span className="checkmark"></span>
                </label>
                <span>
                {isLink ?
                <Link
                    to={`/${term.content.language}/${term.slug}/${term.version && term.version.number ? term.version.number : 1}`}
                    className="starred-title"
                    dangerouslySetInnerHTML={{__html: term.content.title}}
                >
                </Link>

                :
                <div
                    className="starred-title"
                    onClick={() => handleToggle(id)}
                    dangerouslySetInnerHTML={{__html: term.content.title}}
                >
                </div>}
                {term.content && term.content.acronym && <span className="acronym">&nbsp;({term.content.acronym})</span>}</span>
            </div>
            <TermLinks term={term} className={'favourite'}
                       showCopy
                       showFavourite
                       handleClickedStar={handleClickedStar}
                       handleClickedLink={() => setClickedLink(true)}
                       />
            <Notification text={'Link copied to clipboard'}
                          show={isClickedLink}
                          onClose={() => {
                              setClickedLink(false);
                          }}
            />
        </div>
    )
};

export default StarredRow
