import React, {Component} from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import ActionsWrapper from "../../../helpers/ActionsWrapper";

class TokensRow extends Component {

    static propTypes = {
        onSuspendToken: PropTypes.func,
        onBlockUser: PropTypes.func,
        onRevokeToken: PropTypes.func,
        onNameClick: PropTypes.func,
        item: PropTypes.object,
        props: PropTypes.object
    };

    state = {
        showActions: false
    };

    setActions = (showActions) => {
        this.setState({
            showActions
        })
    };

    suspendToken = () => {
        this.props.props.onSuspendToken(this.props.item.id)
    };

    blockUser = () => {
        this.props.props.onBlockUser(this.props.item.user.id)
    };

    revokeToken = () => {
        this.props.props.onRevokeToken(this.props.item.id)
    };

    onNameClick = (e) => {
        this.props.props.onNameClick(this.props.item.user.name);
    };

    render() {
        const {props, item} = this.props;
        const {showActions} = this.state;

        return (
            <tr className={`${item.is_pause ? 'suspended-token' : ''}`}>
                <td className={props.columns[0].className + '-td'}>
                    {item.license_token}
                </td>
                <td className={props.columns[1].className + '-td'}>
                    {item.webpage_address}
                </td>
                <td className={props.columns[2].className + '-td'}>
                    <span onClick={this.onNameClick}>{item.user.name}</span>
                </td>
                <td className={props.columns[3].className + '-td'}>
                    {item.role.name}
                </td>
                <td className={props.columns[4].className + '-td'}>
                    {moment(item.created).format('LL')}
                </td>
                <td className={props.columns[5].className + '-td console-delete-action-td'}>
                    <ActionsWrapper show={showActions}
                                    onClickOutside={() => this.setActions(false)}
                                    onClosedClick={() => this.setActions(true)}>
                        <div className={"delete-item-action"}>
                            <div onClick={() => this.suspendToken()} >
                                {item.is_pause ? 'Resume token' : 'Suspend token'}
                            </div>
                            <div onClick={() => this.revokeToken()}>
                                Revoke Token
                            </div>
                        </div>
                    </ActionsWrapper>
                </td>
            </tr>
        )
    }
}

export default TokensRow;
