import React, {Component} from "react";
import CustomTable from "../../../helpers/CustomTable";
import Paginator from "../../../helpers/Paginator";
import {connect} from "react-redux";
import {blockUser, fetchTokens, getAvailableUserRoles, pauseToken, revokeToken} from "../../../store/console/actions";
import TokensRow from "./TokensRow";
import Select from "react-select";
import * as PropTypes from "prop-types";
import {managementConsolePaging} from "../../../configs/config";

class Tokens extends Component {

    static propTypes = {
        blockUser: PropTypes.func,
        revokeToken: PropTypes.func,
        getAvailableUserRoles: PropTypes.func,
        allRoles: PropTypes.array,
        tokens: PropTypes.array
    };

    state = {
        searchParams: {
            q: '',
            role: null,
            page: 1,
            per_page: managementConsolePaging
        },
        chosenRole: {},
        showRolesFilter: false
    };

    tableColumns = [{
        style: {
            width: '35%'
        },
        title: 'Token',
        className: 'console-tokens-token'
    }, {
        style: {
            width: '20%'
        },
        title: 'Website URL',
        className: 'console-tokens-website'
    }, {
        style: {
            width: '15%'
        },
        title: 'User',
        titleSlug: 'user',
        className: 'console-tokens-user'
    }, {
        style: {
            width: '10%'
        },
        title: 'User Role',
        className: 'console-tokens-role'
    }, {
        style: {
            width: '15%'
        },
        title: 'Created Date',
        titleSlug: 'date',
        className: 'console-tokens-created'
    }, {
        style: {},
        title: '',
        className: 'console-tokens-actions'
    }];

    componentDidMount() {
        this.fetchTokens();
        this.props.getAvailableUserRoles();
    }

    pageChanged = (data) => {
        this.setState(({searchParams}) => {
            return {
                searchParams: {
                    ...searchParams,
                    page: data.selected + 1
                }
            }
        }, this.fetchTokens)
    };

    onSearchQueryChange = (e) => {
        e.persist();
        this.setState((state) => {
            return {
                searchParams: {
                    ...state.searchParams,
                    q: e.target.value
                }
            }
        }, this.fetchTokens)
    };

    fetchTokens = () => {
        this.props.fetchTokens(this.state.searchParams);
    };

    chooseRole = (selected) => {
        this.setState((state) => ({
            chosenRole: selected,
            showRolesFilter: false,
            searchParams: {
                ...state.searchParams,
                role: selected.value
            }
        }), this.fetchTokens)
    };

    toggleShowRolesFilter = () => {
        this.setState(({showRolesFilter}) => ({
            showRolesFilter: !showRolesFilter
        }))
    };

    suspendToken = (token) => {
        const formData = new FormData();
        formData.append("pause_token", "");
        this.props.pauseToken(token, formData)
            .then(() => this.fetchTokens());
    };

    blockUser = (userId) => {
        this.props.blockUser(userId)
            .then(() => this.fetchTokens());
    };

    revokeToken = (token) => {
        this.props.revokeToken(token)
            .then(() => this.fetchTokens());
    };

    onNameClick = (name) => {
        this.setState(({searchParams}) => {
            return {
                searchParams: {
                    ...searchParams,
                    q: name
                }
            }
        }, this.fetchTokens)
    };

    onArrowClick = (order_by, isAsc) => {
        this.setState(({searchParams}) => ({
            searchParams: {
                ...searchParams,
                order_by,
                order_asc: isAsc ? 'asc' : 'desc'
            }
        }), () => this.fetchTokens(this.state.searchParams))
    };

    render() {
        const {tokens, allRoles} = this.props;
        const {searchParams, chosenRole, showRolesFilter} = this.state;

        const _tableFooterColumns = [{
            colspan: 7,
            component: <Paginator
                pageCount={this.props.totalTokens / this.state.searchParams.per_page}
                pageChanged={this.pageChanged}
                forcePage={this.state.searchParams.page - 1}
                className={"console-paginator"}
            />
        }];
        return (
            <div className="console-container-full tokens-container">
                <div className="tokens-header">
                    <input type="text" className="console-search" placeholder="Search by user name and website URL"
                           value={searchParams.q} onChange={this.onSearchQueryChange}/>
                    <div className="console-filter">
                        <Select
                            className={"logs-roles-filter"}
                            classNamePrefix="react-select-teams"
                            onChange={(selected) => this.chooseRole(selected)}
                            value={chosenRole.value ? chosenRole : {value: 'all', label: 'All roles'}}
                            options={[{value: null, label: 'All roles'}, ...allRoles.map(({id, name}) => ({
                                value: id,
                                label: name
                            }))]}
                        />
                    </div>
                </div>
                <div className="tokens-list">
                    <CustomTable
                        className={"console-table"}
                        columns={this.tableColumns}
                        footer={_tableFooterColumns}
                        items={tokens}
                        onArrowClick={this.onArrowClick}
                        onSuspendToken={this.suspendToken}
                        onBlockUser={this.blockUser}
                        onRevokeToken={this.revokeToken}
                        onNameClick={this.onNameClick}
                    >
                        <TokensRow/>
                    </CustomTable>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    tokens: state.managementConsole.tokens,
    totalTokens: state.managementConsole.totalTokens,
    hasMoreTokens: state.managementConsole.hasMoreTokens,
    allRoles: state.managementConsole.availableUserRoles
});

const mapDispatchToProps = {fetchTokens, getAvailableUserRoles, revokeToken, pauseToken, blockUser};

export default connect(mapStateToProps, mapDispatchToProps)(Tokens);