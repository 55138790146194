import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardFooter, Collapse, Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FaTrash, FaPencil} from "react-icons/lib/fa";
import {createNote, removeNote, editNote} from '../../../store/terms/actions'
import NoteEditor from "./NoteEditor";

class NotesBox extends Component {
    state = {
        areNotesVisible: false,
        toggleNoteState: true,
        changeNote: false,
        value: this.props.term.my_note ? this.props.term.my_note.note : '',
        drawTopBorder: this.props.drawTopBorder || false,
        clearNote: false
    };

    _showNotes = () => {
        const {areNotesVisible} = this.state;

        this.setState({
            areNotesVisible: !areNotesVisible
        });
    };

    _toggleNote = () => {
        const {toggleNoteState} = this.state;

        this.setState({toggleNoteState: !toggleNoteState})
    };

    _removeNote = () => {
        const {id} = this.props.term.my_note;

        this.props.removeNote(id);
        this.setState({value: "", clearNote: true});
        this._showNotes()
    };

    _editNote = () => {
        const {value, changeNote} = this.state;
        const {id} = this.props.term.my_note;

        if (changeNote) {
            this.props.editNote({id, note: value});
            this._showNotes();
            this.setState({
                toggleNoteState: true,
                changeNote: false
            })
        } else {
            this._showNotes()
        }
    };

    _handleChange = (value) => {
        this.setState({
            value,
            clearNote: false,
            changeNote: true
        })
    };

    _createNote = () => {
        const {value} = this.state;
        const {id} = this.props.term;

        if (value !== '') {
            this.props.createNote({term_id: id, note: value});
            this._showNotes();
            this.setState({toggleNoteState: true})
        } else {
            this._showNotes()
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.term.my_note && this.props.term.my_note)
            || (prevProps.term.my_note !== this.props.term.my_note)) {
            this.setState({
                value: this.props.term.my_note ? this.props.term.my_note.note : ''
            })
        }
    }

    render() {
        const {term, isAuthorized, temFavouriteOpen, onNotAuthorizedClick, user} = this.props;
        const {areNotesVisible, toggleNoteState, value} = this.state;
        const drawTopBorder = this.props.drawTopBorder !== undefined ? this.props.drawTopBorder : this.state.drawTopBorder;

        return (
            <>
                {isAuthorized ?
                    <div className={`notes-block ${!drawTopBorder ? "hide-top-border" : ''}`}>
                        {
                            temFavouriteOpen && term.my_note === null ?
                            null
                            :
                            <div className={`notes-row ${
                                term.my_note !== null
                                    ? 'has-notes'
                                    : 'has-not-notes'
                            } ${areNotesVisible ? 'open-notes-block' : ''}`}>
                                {!areNotesVisible
                                && <Button color={'link'} type={'button'} onClick={() => this._showNotes()} className={`open-notes-btns ${ term.my_note !== null ? 'has-note' : '' }`}>
                                        <span>{term.my_note ? 'edit note' : 'add note'}</span>
                                    </Button>}

                                    <Collapse isOpen={areNotesVisible || temFavouriteOpen}
                                          onExited={()=> {this.setState({ drawTopBorder: false})}}
                                          onEntering={()=> {this.setState({ drawTopBorder: true}) }}>
                                        <Card className="no-top-border-radius">
                                            <CardBody>
                                                {temFavouriteOpen && <p className="title-note">Your Notes</p>}
                                                {areNotesVisible
                                                && <NoteEditor note={value}
                                                            edit={!term.my_note || !toggleNoteState}
                                                            clearNote={this.state.clearNote}
                                                            onNoteChange={(note) => this._handleChange(note)}/> }
                                            </CardBody>
                                            <CardFooter className={'collapse-footer'}>

                                                <Button color={'link'} type={'submit'}
                                                        onClick={term.my_note ? this._editNote : this._createNote}
                                                        className={'close-notes-btns'}>
                                                    <span>click here to save and close this note</span>
                                                    <FontAwesomeIcon icon={'chevron-up'}
                                                                     className={'close-notes-arrow'}/>
                                                </Button>

                                                 <div className="notes-btns edit_buttons-wrapper">
                                                     {(user.role !== 'Editor' && user.role !== 'Co-Editor' && !user.rich_text_editor) &&
                                                        <span className="notes-length-wrapper">
                                                            <span className="current-notes-length">{value.length}</span>
                                                            /500
                                                        </span>
                                                     }
                                                     {term.my_note &&
                                                         <>
                                                             <div className='icon-wrapper'>
                                                                <Button
                                                                    onClick={this._removeNote}
                                                                    type={'submit'} color={'link'}
                                                                    className='deleted-button'>
                                                                    <FaTrash/>
                                                                </Button>
                                                                <div className="main-block delete-block">
                                                                    <span >delete note</span>
                                                                    <span className='down_block'></span>
                                                                </div>
                                                            </div>
                                                            <div className='icon-wrapper'>
                                                                <Button
                                                                    onClick={this._toggleNote}
                                                                    type={'submit'} color={'link'}
                                                                    className='edited-button'>
                                                                    <FaPencil/>
                                                                </Button>
                                                                <div className="main-block edit-block">
                                                                    <span>edit note</span>
                                                                    <span className='down_block'></span>
                                                                </div>
                                                            </div>
                                                         </>}
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Collapse>
                                </div>
                        }
                    </div>
                    : <div className={`notes-row 'has-not-notes'`}>
                        <Button color={'link'} type={'button'} onClick={() => onNotAuthorizedClick()} className={`open-notes-btns`}>
                            <span>add note</span>
                        </Button>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthorized: state.auth.isAuthorized,
    user: state.auth.user
});

const mapActionsToProps = (dispatch) => ({
    createNote: (data) => dispatch(createNote(data)),
    removeNote: (id) => dispatch(removeNote(id)),
    editNote: (data) => dispatch(editNote(data)),
});

export default connect(mapStateToProps, mapActionsToProps)(NotesBox);
