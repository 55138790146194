import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import StyledCheckbox from "../../../../../../helpers/StyledCheckbox";
import InfoblockActions from "./InfoblocksActions"
import {
    addInfoblock, bulkDeleteInfoblocks, bulkLockInfoblocks, excludeInfoblocks, includeInfoblocks,
    insertInfoblock, openCloseInfoblock, selectAllInfoblocks, selectInfoblock, showHideInfoblock, syncInfoblocks
} from "../../../../../../store/infoblocks/actions";
import SortableTree, {getVisibleNodeCount} from 'react-sortable-tree';
import NodeRenderer from "./NodeRenderer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class DocumentInfoblocks extends Component {
    state = {
        infoblocks: [],
        checkedInfoblocks: false,
        shoudRender: false,
        isContentCollapsed: false,
    };

    componentWillReceiveProps(props) {
        // const {selectedInfoblockId} = this.props;
        // const flatted = props.infoblocks.flat(Infinity);
        // const selectedIndex = flatted.findIndex(item => item.id === this.state.selectedInfoblockId);
        // this.props.selectInfoblock(selectedIndex === -1 ? false : selectedInfoblockId);
    }

    toggleSelect = () => {
        const {infoblocks} = this.props;
        const {checkedInfoblocks} = this.state;
        this.props.selectAllInfoblocks();
        if (this.props.user.id) {
            this.props.includeInfoblocks({'ids': infoblocks.flat(Infinity).map((item) => item.id)});
        }
        this.setState({checkedInfoblocks: !checkedInfoblocks})
    };

    onLockSelected = () => this.props.bulkLockInfoblocks();
    onDeleteSelected = () => this.props.bulkDeleteInfoblocks();

    onAddBlock = (title, content) => {
        const {selectedInfoblockId} = this.props;
        const block = {
            is_new: true,
            title: title,
            content: content,
            checked: true,
            children: []
        };
        this.props.addInfoblock(selectedInfoblockId, block);
    };

    onInsertBlock = (selected, singleInfoblock) => {
        const {selectedInfoblockId} = this.props;
        const block = {
            id: selected.value,
            content: singleInfoblock.content,
            title: selected.label,
            checked: true,
            children: []
        };
        this.props.insertInfoblock(selectedInfoblockId, block);
    };

    handleTreeOnChange = treeData => {
        this.props.syncInfoblocks(treeData);
    };

    componentWillUnmount() {
        this.setState({shoudRender: false})
    }

    componentDidMount() {
        this.setState({shoudRender: true});
    }

    handleCollapseContent = () => {
        this.setState(({ isContentCollapsed }) => ({ isContentCollapsed: !isContentCollapsed }));
    }

    render() {
        const {checkedInfoblocks, isContentCollapsed} = this.state;
        const {infoblocks, user, assingedUsers} = this.props;
        const isEdit = this.props.isEdit !== undefined ? this.props.isEdit : false;
        const coAuthors = assingedUsers && Array.isArray(assingedUsers) ? assingedUsers : [];
        const isCoAuthor = coAuthors.includes(user.id);
        const forTranslation = !!this.props.forTranslation;
        const isDraggable = isEdit && !forTranslation && (isCoAuthor || user.role === 'Admin');

        const count = getVisibleNodeCount({treeData: infoblocks});

        return (
            <>
                {(user.role === 'Admin' || isCoAuthor
                    || ['Editor', 'Reviewer', undefined].includes(user.role) && infoblocks.length > 0)
                && <div className={'document-infoblocks-selector'} id='document-template-navigator'>
                    <div className={`document-infoblocks-head ${isContentCollapsed ? 'collapsed' : ''}`}>
                        {!isContentCollapsed
                            ? (
                                <div className={`select-infoblocks`}>
                                    <StyledCheckbox
                                        checked={checkedInfoblocks}
                                        changeSelect={this.toggleSelect}
                                    />
                                    select all
                                </div>
                            ) : (
                                <div className={`select-infoblocks`}>
                                    Navigator
                                </div>
                            )}


                        <div className={`collapse-infoblocks-content`} onClick={this.handleCollapseContent}>
                            <FontAwesomeIcon
                                icon={isContentCollapsed ? 'chevron-down' : 'chevron-up'}
                                className='icon'
                            />
                        </div>


                        {isEdit && (user.role === 'Admin' || isCoAuthor) && !isContentCollapsed &&
                        <InfoblockActions
                            onInsertBlock={this.onInsertBlock}
                            onDeleteSelected={this.onDeleteSelected}
                            onLockSelected={this.onLockSelected}
                            onAddBlock={this.onAddBlock}
                        />}
                    </div>

                    {!isContentCollapsed && (
                        <div
                            className={'document-infoblocks-content'}
                            style={{height: count * 40}}
                        >
                            {this.state.shoudRender
                            && <SortableTree
                                onChange={this.handleTreeOnChange}
                                treeData={infoblocks}
                                maxDepth={5}
                                nodeContentRenderer={NodeRenderer}
                                placeholderRenderer={() => <></>}
                                canDrag={isDraggable}
                                scaffoldBlockPxWidth={20}
                                rowHeight={35}
                            />}
                        </div>
                    )}
                </div>
                }
            </>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    infoblocks: state.infoblocks.infoblocks,
    selectedInfoblockId: state.infoblocks.selectedInfoblockId,
    assingedUsers: state.managementConsole.singleTerm.assigned_users,
});

const mapDispatchToProps = {
    addInfoblock, insertInfoblock, showHideInfoblock, openCloseInfoblock, bulkLockInfoblocks, bulkDeleteInfoblocks,
    selectAllInfoblocks, includeInfoblocks, excludeInfoblocks, syncInfoblocks, selectInfoblock
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentInfoblocks));