export const CLEAR_ALL_TERMS = 'CLEAR_ALL_TERMS';
export const FETCH_ALL_TERMS = 'FETCH_ALL_TERMS';
export const FETCH_TOP_TERMS = 'FETCH_TOP_TERMS';
export const SEARCH_TERMS = 'SEARCH_TERMS';
export const FETCH_TERMS = 'FETCH_TERMS';
export const FETCH_TERM = 'FETCH_TERM';
export const FETCH_TERM_BY_SLUG = 'FETCH_TERM_BY_SLUG';
export const FETCH_VERSION_BY_SLUG = 'FETCH_VERSION_BY_SLUG';

export const FETCH_SEARCH_TERM = 'FETCH_SEARCH_TERM';
export const FETCH_MORE_TERMS = 'FETCH_MORE_TERMS';
export const STARRED_TERMS_FILTERS = 'STARRED_TERMS_FILTERS';
export const FETCH_EMBEDDED_TERM = 'FETCH_EMBEDDED_TERM';
export const CLEAR_EMBEDDED_TERM = 'CLEAR_EMBEDDED_TERM';

export const CHANGE_FOUND_TERM_LANG = 'CHANGE_FOUND_TERM_LANG';

export const SEARCH_WORD = 'SEARCH_WORD';
export const SEARCH_CHECKBOX = 'SEARCH_CHECKBOX';
export const SEARCH_LABEL = 'SEARCH_LABEL';
export const FETCH_LABEL_LIST = 'FETCH_LABEL_LIST';
export const TOGGLE_FAVOURITE = 'TOGGLE_FAVOURITE';
export const FETCH_LANGUAGE_LIST = 'FETCH_LANGUAGE_LIST';
export const CLEAN_TERMS_FOUND_LIST = 'CLEAN_TERMS_FOUND_LIST';
export const CREATE_NOTE = 'CREATE_NOTE';
export const REMOVE_NOTE = 'REMOVE_NOTE';
export const EDIT_NOTE = 'EDIT_NOTE';
export const FETCH_HOME_INFO = 'FETCH_HOME_INFO';
export const CLEAR_SEARCHED_TERMS = 'CLEAR_SEARCHED_TERMS';
export const FETCH_LIST_ITEMS = 'FETCH_LIST_ITEMS';
export const CLEAR_SEARCH_FORM = 'CLEAR_SEARCH_FORM';
export const GET_RANDOM_TERM = 'GET_RANDOM_TERM';
export const FETCH_STARRED_TERMS="FETCH_STARRED_TERMS";
export const REMOVE_STARRED_TERMS="REMOVE_STARRED_TERMS";
export const CHANGE_LANGUAGE_IN_SEARCH = 'CHANGE_LANGUAGE_IN_SEARCH';
export const CREATE_EMBED_LIST = "CREATE_EMBED_LIST";
export const UPDATE_TERM_DIALOG_SHOW_STATUS = "UPDATE_TERM_DIALOG_SHOW_STATUS";