export const UNPUBLISH_EDITOR_TERM = 'UNPUBLISH_EDITOR_TERM';
export const UPDATE_EDITOR_TERM = 'UPDATE_EDITOR_TERM';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const DOWNLOAD_ASSIGNMENT_TERMS = 'DOWNLOAD_ASSIGNMENT_TERMS';
export const UPLOAD_MULTIPLE_TERMS = 'UPLOAD_MULTIPLE_TERMS';
export const FETCH_EDITOR_TERM = 'FETCH_EDITOR_TERM';
export const FETCH_EDITOR_TERM_STATUSES = 'FETCH_EDITOR_TERM_STATUSES';
export const FETCH_EDITOR_TERMS = 'FETCH_EDITOR_TERMS';
export const FETCH_AUTHORS_TERMS = 'FETCH_AUTHORS_TERMS';
export const FETCH_EDITOR_SETS = 'FETCH_EDITOR_SETS';
export const DRAFT_EDITOR_TERM = 'DRAFT_EDITOR_TERM';
export const SEND_EDITOR_MESSAGE = 'SEND_EDITOR_MESSAGE';
export const DOWNLOAD_TERMS_FOR_TRANSLATE = 'DOWNLOAD_TERMS_FOR_TRANSLATE';
export const DWN_SELECTED_TERMS = 'DWN_SELECTED_TERMS';
export const DWN_SETS = 'DWN_SETS';
export const BULK_TRANSLATION_UPLOAD = 'BULK_TRANSLATION_UPLOAD';
export const PUBLISH_ALL_EDITOR_TRANSLATIONS = 'PUBLISH_ALL_EDITOR_TRANSLATIONS';
export const PUBLISH_TERM_EDITOR_TRANSLATION = 'PUBLISH_TERM_EDITOR_TRANSLATION';
export const SEND_SELECTED_DRAFT_TO_REVIEW = 'SEND_SELECTED_DRAFT_TO_REVIEW';