import React from 'react'
import moment from "moment";


const LanguageCard = ({name, editors, coEditors, terms, percent, reviewers, lastActive}) => {
    return (
        <div className="language-card">
            <div className="title-card">
                <div>{name}</div>
            </div>
            <div className="description-cards">  
                <div className="description-card">
                    <div className="position">Editor:</div>
                    <div className="person-wrapper">
                        {editors && editors.map((editor, index) => <div className="person" key={index}>
                            <span className="person-name">{editor.name}</span>
                            <div className="person-text">
                                <span>{editor.country_name},</span>
                                <span>{editor.city_name},</span>
                                <span>{editor.affiliation}</span>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className="description-card">
                    <div className="position person-middle">Co-Editor(s):</div>
                    <div className="person-wrapper">
                        {coEditors && coEditors.map((coEditor, index) => <div className="person person-middle" key={index}>
                            <span className="person-name">{coEditor.name}</span>
                            <div className="person-text">
                                <span>{coEditor.country_name},</span>
                                <span>{coEditor.city_name},</span>
                                <span>{coEditor.affiliation}</span>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className="description-card">
                    <div className="position">Reviewer(s):</div>
                    <div className="person-wrapper">
                        {reviewers && reviewers.map((reviewer, index) => <div className="person" key={index}>
                            <span className="person-name">{reviewer.name}</span>
                            <div className="person-text">
                                <span>{reviewer.country_name},</span>
                                <span>{reviewer.city_name},</span>
                                <span>{reviewer.affiliation}</span>
                            </div>
                        </div>)}
                    </div>
                </div>
                <div className="description-card">
                    <div className="position person-middle">Last Active:</div>
                    <div className="person-wrapper">
                        <div className="time-wrapper">
                        {lastActive
                            ? moment(lastActive).format('LL')
                            : 'no data'
                        }
                        </div>
                    </div>
                </div>
            </div>   
            <div className="terms-translated">
                <div className="term-box">
                    <div className="title">{terms ? terms : '0'}</div>
                    <span>Terms translated</span>
                </div>
                <div className="term-box">
                    <div className="title">{percent ? percent : '0'}%</div>
                    <span>of translations are up to date</span>
                </div>
            </div>
        </div>
    )
}

export default LanguageCard