import React, {Component} from "react";
import moment from "moment";
import StyledCheckbox from "../../../helpers/StyledCheckbox";
import ActionsWrapper from "../../../helpers/ActionsWrapper";

class UserRow extends Component {

    state = {
        showActions: false
    };

    setShowActions(showActions) {
        this.setState({
            showActions
        })
    }

    editUser(e, id) {
        e.preventDefault();
        e.stopPropagation();

        this.setShowActions(false);

        this.props.props.editUserAction(id);
    }

    blockUser(e, item) {
        e.preventDefault();
        e.stopPropagation();

        this.setShowActions(false);

        this.props.props.blockAction(item);
    }

    deleteUser(e, item) {
        e.preventDefault();
        e.stopPropagation();

        this.setShowActions(false);

        this.props.props.deleteAction(item);
    }

    richTextEditor(e, item) {
        e.preventDefault();
        e.stopPropagation();

        this.setShowActions(false);

        this.props.props.richTextEditorAction(item);
    }

    verifyUser(e, item) {
        e.preventDefault();
        e.stopPropagation();

        this.setShowActions(false);

        this.props.props.verifyUserAction(item);
    }

    changeRole = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        this.setShowActions(false);

        this.props.props.changeRole(item);
    };

    makeCoAuthor = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        this.setShowActions(false);

        this.props.props.makeCoAuthorAction(item);
    };

    impersonateUser = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        this.setShowActions(false);

        this.props.props.impersonateUserAction(item);
    };

    render() {
        const {showActions} = this.state;
        const props = this.props;
        return (
            <tr className={!props.item.is_active ? 'blocked-user' : undefined} style={{backgroundColor: props.item.has_only_fullname && props.item.is_active? '#ebf3ff' : undefined}}>
                <td className={props.props.columns[0].className + '-td'}>
                    <StyledCheckbox
                        checked={props.item.checked}
                        changeSelect={() => props.props.toggleSelect(props.item.id)}/>
                </td>
                <td className={props.props.columns[1].className + '-td'}>
                    {props.item.fullname}
                </td>
                <td className={props.props.columns[2].className + '-td'}>
                    {props.item.role}
                    {props.item.is_author && ': co-Author'}
                </td>
                <td className={props.props.columns[3].className + '-td'}>
                    {props.item.rich_text_editor && `Rich Text`}
                </td>
                <td className={props.props.columns[4].className + '-td'}>
                    {props.item.language_english ? props.item.language_english : "-"}
                </td>
                <td className={props.props.columns[5].className + '-td'}>
                    {props.item.sets ? props.item.sets : "-"}
                </td>
                <td className={props.props.columns[6].className + '-td'}>
                    {props.item.registered && moment(props.item.registered).format('LL')}
                </td>
                <td className={props.props.columns[7].className + '-td'}>
                    {props.item.latest_activity && moment(props.item.latest_activity).format('LL')}
                </td>
                <td className={props.props.columns[8].className + '-td console-delete-action-td'}>
                    <ActionsWrapper show={showActions}
                                    onClickOutside={() => this.setShowActions(false)}
                                    onClosedClick={() => this.setShowActions(true)}>
                        <div className={"delete-item-action"}>
                            <div onClick={(e) => this.editUser(e, props.item.id)}>
                                Edit
                            </div>
                            <div onClick={(e) => this.blockUser(e, props.item)}>
                                {props.item.is_active ? 'Block' : 'Unblock'}
                            </div>
                            <div onClick={(e) => this.deleteUser(e, props.item)}>
                                Delete
                            </div>
                            <div onClick={(e) => this.richTextEditor(e, props.item)}>
                                {!props.item.rich_text_editor ? 'Enable rich text' : 'Disable rich text'}
                            </div>
                            {!props.item.is_verified && (<div onClick={(e) => this.verifyUser(e, props.item)}>
                                Verify
                            </div>)}
                            <div onClick={(e) => this.changeRole(e, props.item)}>
                                Change role
                            </div>
                            <div onClick={(e) => this.makeCoAuthor(e, props.item)}>
                                {props.item.is_author ? "Remove Co-Author" : "Make Co-Author"}
                            </div>
                            <div onClick={(e) => this.impersonateUser(e, props.item)}>
                                Impersonate
                            </div>
                        </div>
                    </ActionsWrapper>
                </td>
            </tr>
        )
    }
}


export default UserRow;