import {baseURLForLink} from "../configs/config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {updateTermDialogShowStatus} from "../store/terms/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

function PrintTermTemplateIcon(props) {
    const {updateTermDialogShowStatus} = props;

    function updateShowDialogStatus(event) {
        updateTermDialogShowStatus('showPrintTermTemplateDialog')
    }
    return (
        <img
            onClick={updateShowDialogStatus}
            src={`${static_url}assets/printer-p.svg`}
            alt="Print TermTemplate" width="30" height="30"
            style={{'cursor': 'pointer'}}
            className={'term-star print--icon'}
        />
    )
};

const mapStateToProps = (state) => ({

});

const madDispatchToProps = dispatch => ({
    updateTermDialogShowStatus: (name) => dispatch(updateTermDialogShowStatus(name)),
});

export default connect(mapStateToProps, madDispatchToProps)(PrintTermTemplateIcon);