import React from "react";
import {Navbar} from "reactstrap";
import {NavLink} from "react-router-dom";
import moment from "moment";

function PrintHeader(props) {
    const {term} = props;

    const headerImg = `${location.origin}/api/static/assets/logo.4c5d90cf.svg`;

    const termInfo = {
        position: 'absolute',
        zIndex: 2,
        top: '50%',
        right: "30px",
        textAlign: 'right',
        lineHeight: 1.1,
        color: 'white',
        fontSize: '10px',
        transform: 'translateY(-50%)',
        maxWidth: '250px',
    };

    const titleStyle = {
        fontWeight: 700,
        fontSize: '22px',
        marginBottom: '-8px'
    };

    const termTittle = term ? (term.content && term.content.title) : term.title;
    const currentDate = moment(new Date()).format('MMMM DD, YYYY');

    return (
        <>
            <Navbar style={{'background': '#4bacc6', maxHeight: '87px'}} light expand="md">
                <div className={'navbar-brand'}>
                    <img crossOrigin='anonymous' src={headerImg}
                         height={72} width={262} style={{marginLeft: '4px'}}
                    />
                </div>
                <a href={location.href} style={termInfo}>
                    <div style={titleStyle}>{termTittle}</div><br/>
                    <div>
                        {term.version && term.version.number ? 'v' + term.version.number + ' | ' : ''}
                        {currentDate}
                    </div>
                </a>
            </Navbar>
        </>
    )
}

export default PrintHeader;