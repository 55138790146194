import {baseURLForLink} from "../configs/config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";

const handleCopyLink = (term, handleClickedLink, copyInput) => {
    copyInput.select();
    document.execCommand("copy");
    handleClickedLink();
};

const CopyTermIcon = ({term, handleClickedLink}) => {
    const [copyInput, setCopyInput] = useState(null);

    return (
        <div title="Click to copy link" className={'term-link-container'}>
            <FontAwesomeIcon icon={'link'} className={'term-link'} onClick={() => handleCopyLink(term, handleClickedLink, copyInput)} />
            {term.content &&
                <div className="hidden-input-wrapper">
                    <input
                        className={'hidden-input'}
                        ref={ref => setCopyInput(ref)}
                        type="text"
                        readOnly
                        value={`${baseURLForLink}${term.content.language ? term.content.language : 'en'}/${term.slug}/${term.version && term.version.number ? term.version.number : 1}`}/>
                </div>}
            </div>
    )
};

export default CopyTermIcon;