// export const baseURL = 'http://localhost:8000/api/';
// export const baseURLForLink = 'http://localhost:8000/';
export const managementConsolePaging = 20;
//export const googleClientId = '918955217441-ue7fp934va9c48uho5l4mn6ch506n53p.apps.googleusercontent.com';
export const baseURL = 'https://bimdictionary.com/api/';
export const baseURLForLink = 'https://bimdictionary.com/';
export const googleClientId = '707060328933-8ap35lbeaskk2o3fa90lqgiol5m3c5sb.apps.googleusercontent.com';
export const facebookAppId = '689086538186030';
export const linkedinAppId = '812wy574yn2xoo';
export const froala3Key = 'OXC1lB3A3A14B9B7C7kOPVi1b1b2Le2e1G1AWMSGSAFDTGHWsE7D7D6F5A1I4H4C3B6A4==';

// export const googleClientId = '918955217441-ue7fp934va9c48uho5l4mn6ch506n53p.apps.googleusercontent.com';
// export const facebookAppId = '1074860282697203';
// export const linkedinAppId = '861tijui5ts8k4';
// export const baseURL = 'http://ec2-13-238-128-198.ap-southeast-2.compute.amazonaws.com:8000/api/';
// export const baseURLForLink = 'http://ec2-13-238-128-198.ap-southeast-2.compute.amazonaws.com:8000/';

