import React, {Component} from "react";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import ReviewsList from "./ReviewsList";
import {withRouter} from "react-router-dom";
import {fetchApprovedTerms, fetchRejectedTerms, fetchToBeReviewedTerms} from "../../store/reviewers-dashboard/actions";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import NotificationsTable from "../EditorsDashboard/NotificationsTable";
import {clearNotificationsCount} from "../../store/auth/actions";

class ReviewersDashboard extends Component {

    static propTypes = {
        fetchToBeReviewedTerms: PropTypes.func.isRequired,
        fetchSentBackTerms: PropTypes.func.isRequired,
        fetchApprovedTerms: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        tabIndex: PropTypes.number
    };

    onTabSelect = (index) => {
        const {history} = this.props;
        switch (index) {
            case 0:
                history.push('/reviewers-dashboard/to-be-reviewed');
                break;
            case 1:
                history.push('/reviewers-dashboard/sent-back');
                break;
            case 2:
                history.push('/reviewers-dashboard/approved');
                break;
        }
    };

    componentDidMount() {
        this.props.clearNotificationsCount();
    }

    render() {
        const {fetchToBeReviewedTerms, fetchSentBackTerms, fetchApprovedTerms, user, tabIndex} = this.props;
        return (
            <div className="reviewers-dashboard-container">
                <div className="reviewers-dashboard-title">Language {user.role} Dashboard</div>
                <div className="container">
                    <NotificationsTable/>
                    <div className="table">
                        <Tabs className="reviewers-dashboard-tabs" selectedIndex={tabIndex}
                              onSelect={this.onTabSelect}>
                            <TabList>
                                <Tab><span>To Be Reviewed</span></Tab>
                                <Tab><span>Sent Back for Updates</span></Tab>
                                <Tab><span>Approved for Publication</span></Tab>
                            </TabList>

                            <TabPanel>
                                <ReviewsList fetchFunc={fetchToBeReviewedTerms}/>
                            </TabPanel>

                            <TabPanel>
                                <ReviewsList fetchFunc={fetchSentBackTerms}/>
                            </TabPanel>

                            <TabPanel>
                                <ReviewsList fetchFunc={fetchApprovedTerms}/>
                            </TabPanel>

                        </Tabs>

                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

const mapDispatchToProps = {fetchToBeReviewedTerms, fetchSentBackTerms: fetchRejectedTerms, fetchApprovedTerms, clearNotificationsCount};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReviewersDashboard));