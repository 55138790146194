import { success, error } from "redux-saga-requests";
import * as types from "./types";

const initialState = {
    user: {},
    isInvalidLogIn: false,
    isBlocked: false,
    isInvalidChangePass: false,
    isAuthorized: false,
    isFetching: false,
    isError: false,
    errMsg: '',
    errorStatus: null,
    message: ''
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.EDIT_USER_PROFILE:
        case types.DELETE_USER_ACCOUNT:
        case types.FETCH_CURRENT_USER_INFO:
        case types.SIGN_UP:
        case types.SIGN_OUT:
        case types.SIGN_IN:
        case types.FORGOT_PASSWORD:
        case types.FACEBOOK_SIGN_IN:
        case types.GOOGLE_SIGN_IN:
        case types.LINKEDIN_SIGN_IN:
        case types.REFRESH_TOKEN: {
            return {
                ...state,
                isFetching: true,
                isError: false
            };
        }

        case success(types.SIGN_IN):
        case success(types.FACEBOOK_SIGN_IN):
        case success(types.LINKEDIN_SIGN_IN):
        case success(types.GOOGLE_SIGN_IN): {
            localStorage.setItem('access', action.payload.data.access);
            localStorage.setItem('refresh', action.payload.data.refresh);

            return {
                ...state,
                isFetching: false
            };
        }
        case error(types.SIGN_IN):
        case error(types.FACEBOOK_SIGN_IN):
        case error(types.LINKEDIN_SIGN_IN):
        case error(types.GOOGLE_SIGN_IN): {
          return {
              ...state,
              isFetching: false,
              errMsg: action.payload.response.data.message
          };
        }

        case success(types.SIGN_UP): {

            return {
                ...state,
                isFetching: false
            };
        }

        case types.CLEAR_NOTIFICATION_COUNT: {
            return {
                ...state,
                user: { ...state.user, notifications_count: 0}
            }
        }

        case types.SIGN_OUT_OTHER_TAB:
        case success(types.DELETE_USER_ACCOUNT): {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');

            location.reload(false);

            return {
                ...state,
                isFetching: false,
                user: {},
                isAuthorized: false
            };
        }

        case success(types.SIGN_OUT): {
            let adminAccess = localStorage.getItem("adminAccess");
            let adminRefresh = localStorage.getItem("adminRefresh");
            if (adminAccess && adminRefresh) {
                localStorage.setItem('access', adminAccess);
                localStorage.setItem('refresh', adminRefresh);
                localStorage.removeItem('adminAccess');
                localStorage.removeItem('adminAccess');
                location.assign("/management-console/users")
            } else {
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
                location.reload()
            }
            return state;
        }

        case success(types.REFRESH_TOKEN): {
            localStorage.setItem('access', action.payload.data.access);

            return {
                ...state,
                isFetching: false,
            };
        }

        case success(types.FETCH_CURRENT_USER_INFO): {
            return  {
                ...state,
                isFetching: false,
                isError: false,
                user: action.payload.data,
                isAuthorized: true
            };
        }

        case success(types.EDIT_USER_PROFILE): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                user: action.payload.data
            };
        }

        case success(types.FORGOT_PASSWORD): {
            return {
                ...state,
                isFetching: false,
                isError: false
            }
        }

        case error(types.EDIT_USER_PROFILE): {
          return {
              ...state,
              isFetching: false,
              isInvalidChangePass: true
          };
        }

        case error(types.FORGOT_PASSWORD): {
            return {
                ...state,
                isFetching: false,
                isError: true,
                errMsg: action.payload.response.data.message,
                errorStatus: action.payload.response.status
            };
        }

        case error(types.DELETE_USER_ACCOUNT):
        case error(types.FETCH_CURRENT_USER_INFO):
        case error(types.SIGN_UP):
        case error(types.SIGN_OUT):
        case error(types.RESET_PASSWORD):
        case error(types.REFRESH_TOKEN): {
            return {
                ...state,
                isFetching: false,
                isError: true,
                errMsg: action.payload.response.data,
                errorStatus: action.payload.response.status
            };
        }

        case types.CLEAR_ERRORS: {
            return {
                ...state,
                isError: false,
                errMsg: '',
                errorStatus: null
            }
        }

        default:
            return state;
    }
};

export default authReducer;
