import {FaPencil, FaTrash} from "react-icons/lib/fa";
import React, {useState} from "react";
import {updateSingleTerm} from "../../../../../../store/console/actions";
import {connect} from "react-redux";

function TermTemplateRow(props) {
    const {singleTerm, removeTemplate, updateSingleTerm} = props;
    const [isEdit, setIsEdit] = useState(false);
    const [title, setTitle] = useState(singleTerm.extended_description.title);
    return (
        <>
            <div className="template-box">
                <div className="document-template">
                    <span>Document Template</span>
                    <FaTrash onClick={removeTemplate}/>
                </div>
                <div className="template-title">
                    {isEdit ?
                        <input type='text'
                               name='title'
                               value={title}
                               className={`inline-edit-template-title`}
                               onChange={editTemplate}
                        />
                        :
                        <span>{title}</span>
                    }
                    <FaPencil className='edit-icon' onClick={enableEdit}/>
                </div>
            </div>
            {isEdit ?
                <div className={"action-buttons"}>
                    <button className={`cancel-infoblock-section`} onClick={resetDefault}>
                        Cancel
                    </button>
                    <button className={`save-infoblock-section`} onClick={saveChanges}>
                        Save
                    </button>
                </div>
                :
                ''
            }
        </>
    );

    function enableEdit(event) {
        setIsEdit(true);
    }

    function resetDefault(event) {
        setTitle(singleTerm.extended_description.title);
        setIsEdit(false);
    }

    function saveChanges(event) {
        updateSingleTerm({
            ...singleTerm,
            extended_description: {
                ...singleTerm.extended_description,
                new_title: title
            }
        });
        setIsEdit(false);
    }

    function editTemplate(event) {
        setTitle(event.target.value)
    }
}

const mapDispatchToProps = dispatch => ({
    updateSingleTerm: data => dispatch(updateSingleTerm(data)),
});

export default connect(null, mapDispatchToProps)(TermTemplateRow);