import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";

const TableHeadCellSorting = ({title, showArrowUp, onArrowClick, isSort}) => {
    return (
        <div className='table-header-title'>
            <span>{title}&nbsp;</span>
            {isSort && (
                <div onClick={() => onArrowClick()}>
                    {showArrowUp ? (
                        <FontAwesomeIcon icon={faCaretUp}/>
                    ) : (
                        <FontAwesomeIcon icon={faCaretDown}/>
                    )}
                </div>
            )}
        </div>
    );
};

export default TableHeadCellSorting;
