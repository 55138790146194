import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FaComments, FaInfoCircle} from "react-icons/lib/fa";
import Paginator from "../../helpers/Paginator";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import moment from "moment";
import {clearReviewerTerms} from "../../store/reviewers-dashboard/actions";

class ReviewsList extends Component {

    perPage = 10;

    static propTypes = {
        fetchFunc: PropTypes.func.isRequired,
        clearReviewerTerms: PropTypes.func.isRequired,
        terms: PropTypes.array,
        page: PropTypes.number
    };

    state = {
        page: 0
    };

    componentDidMount() {
        this.props.fetchFunc();
    }

    handlePageChange = ({selected}) => {
        this.setState({
            page: selected
        })
    };

    componentWillUnmount() {
        this.props.clearReviewerTerms();
    }

    render() {
        const {terms} = this.props;
        const {page} = this.state;

        const pageCount = terms.length / this.perPage;
        const pageItems = terms.slice(page * this.perPage, page * this.perPage + this.perPage);

        return (
            <div>
                <div className="to-be-reviewed-list">
                    <div className="grid-row list-header">
                        <div>
                            Term Name
                        </div>
                        <div>
                            Modification Date
                        </div>
                        <div>
                            Revision
                        </div>
                        <div>
                            Action Requested by
                        </div>
                    </div>
                    {
                        pageItems.map(term => {
                            return (
                                <div key={term.id} className="grid-row list-row">
                                    <div className="title">
                                        <Link
                                            to={`/translate-view-page/${term.id}`}
                                            dangerouslySetInnerHTML={{__html: term.title}}
                                        />
                                        {/*{term.content && term.content.acronym && (*/}
                                        {/*    <span className="acronym">&nbsp;({term.content.acronym})</span>*/}
                                        {/*)}*/}
                                        {/*<div className="comments-block">*/}
                                        {/*    <FaComments/>*/}
                                        {/*    <span>*/}
                                        {/*        3*/}
                                        {/*    </span>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div>
                                        {moment(term.modify_date).format('ll')}
                                        &nbsp;&nbsp;
                                        <FaInfoCircle className="info-icon" />
                                    </div>
                                    <div>
                                        {term.revision}
                                        &nbsp;&nbsp;
                                        <FaInfoCircle className="info-icon" />
                                    </div>
                                    <div>
                                        {term.action_requested_by}
                                    </div>
                                    <div>
                                        <Link to={`/translate-view-page/${term.id}`}>
                                            Review
                                        </Link>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <Paginator
                    pageCount={pageCount}
                    pageChanged={(data) => this.handlePageChange(data)}
                    forcePage={page}
                    className={"console-paginator"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    terms: state.reviewersDashboard.terms
});

const mapDispatchToProps = { clearReviewerTerms };

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsList);