import React from "react";
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import {froala3Key} from "../configs/config";

const defaultConfig = {
    htmlUntouched: true,
    fullPage: true,
    htmlRemoveTags: [],
    key: froala3Key,
    charCounterCount: false,
    imageUploadParam: 'image_param',
    imageUploadURL: '/api/froala_upload_image',
    imageUploadMethod: 'POST',
    toolbarButtons: {
        moreText: {
            buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'fontFamily',
                'fontSize',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'inlineStyle',
                'clearFormatting'
            ],
            align: 'left',
            buttonsVisible: 0
        },
        moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
            align: 'left',
            buttonsVisible: 0
        },
        moreRich: {
            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
            align: 'left',
            buttonsVisible: 0
        },
        moreMisc: {
            buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 2
        }
    }
};

const ContributorsFroala = (props) => {
    if (props.editorClass) {
        defaultConfig.editorClass = props.editorClass
    }
    if (props.height) {
        defaultConfig.height = props.height
    }
    return (
        <FroalaEditor
            tag='div'
            config={{
                ...defaultConfig,
                htmlUntouched: true,
                fullPage: true,
                htmlRemoveTags: [],
            }}
            {...props}
        />
    )
};

export default ContributorsFroala;