import { success, error } from "redux-saga-requests";
import * as types from "./types";
import response from './response.json'

const initialState = {
    isFetching: false,
    isError: false,
    errMsg: '',
    errorStatus: null,
    items: [],
    item: {
        linked_terms: [],
        labels: []
    },
    embeddedTerm: {},
    searchItem: {},
    topItems: [],
    searchWord: '',
    searchCheckbox: {
        search_descriptions: false,
        exclude: false
    },
    searchLabel: {},
    listItems: [],
    itemsFound: [],
    hasMoreTerms: false,
    favouriteItems: [],
    langs: [],
    labels: [],
    homeInfo: {},
    searchFormClear: false,
    favouriteTerms: [],
    favouriteTermsFiltred: [],
    filtrationResultNull: false,
    embeddedList: [],
    responseContributors: response,
    termDialogs: {
        showPrintTermTemplateDialog: false,
    },
};

const termsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_TERMS:
        case types.FETCH_ALL_TERMS:
        case types.FETCH_TERM:
        case types.FETCH_TERM_BY_SLUG:
        case types.FETCH_SEARCH_TERM:
        case types.FETCH_LIST_ITEMS:
        case types.CHANGE_LANGUAGE_IN_SEARCH:
        case types.TOGGLE_FAVOURITE:
        case types.FETCH_EMBEDDED_TERM:
        case types.GET_RANDOM_TERM:
        case types.FETCH_LANGUAGE_LIST:
        case types.SEARCH_TERMS:
        case types.FETCH_HOME_INFO:
        case types.FETCH_TOP_TERMS:
        case types.CREATE_NOTE:
        case types.REMOVE_NOTE:
        case types.EDIT_NOTE:
        case types.FETCH_STARRED_TERMS:
        case types.REMOVE_STARRED_TERMS: {
            return {
                ...state,
                isFetching: true,
                isError: false
            };
        }

        case types.SEARCH_WORD: {
            return {
                ...state,
                searchWord: action.payload.data
            };
        }

        case types.SEARCH_LABEL: {
            return {
                ...state,
                searchLabel: action.payload.data
            };
        }

        case types.UPDATE_TERM_DIALOG_SHOW_STATUS: {
            let name = action.payload;
            return {
                ...state,
                termDialogs: {
                    ...state.termDialogs,
                    [name]: !state.termDialogs[name]
                }
            }
        }

        case types.CHANGE_FOUND_TERM_LANG: {
            return {
                ...state,
                isFetching: false,
                isError: false,
                itemsFound: state.itemsFound.map((item) => item.id === action.payload.data ? state.item : item),
            };
        }


        case types.SEARCH_CHECKBOX: {
            return {
                ...state,
                searchCheckbox: {
                    ...state.searchCheckbox,
                    ...action.payload.data
                }
            };
        }
        case types.CLEAR_SEARCH_FORM: {
            return {
                ...state,
                searchFormClear: action.payload.data
            }
        }

        case types.CLEAR_SEARCHED_TERMS: {
            return {
                ...state,
                isFetching: false,
                isError: false,
                itemsFound: [],
                hasMoreTerms: false,
                item: { ...state.item, my_note: null }
            }
        }

        case types.CLEAR_ALL_TERMS: {
            return {
                ...state,
                items: []
            }
        }

        case success(types.FETCH_TOP_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                topItems: action.payload.data.data
            };
        }

        case success(types.FETCH_LIST_ITEMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                listItems: action.payload.data.data
            };
        }

        case success(types.SEARCH_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                itemsFound: action.payload.data.data,
                hasMoreTerms: action.payload.data.has_next_page
            };
        }

        case success(types.FETCH_MORE_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                itemsFound: state.itemsFound.concat(action.payload.data.data),
                hasMoreTerms: action.payload.data.has_next_page
            };
        }

        case success(types.FETCH_LANGUAGE_LIST): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                langs: action.payload.data.languages
            };
        }

        case success(types.FETCH_LABEL_LIST): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                labels: action.payload.data.data
            };
        }

        case success(types.GET_RANDOM_TERM):
        case success(types.FETCH_TERM_BY_SLUG):
        case success(types.FETCH_TERM): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                item: action.payload.data.data
            };
        }

        case success(types.FETCH_EMBEDDED_TERM): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                embeddedTerm: {...action.payload.data.data, id: action.meta.id},
            };
        }

        case types.CLEAR_EMBEDDED_TERM: {
            return {
                ...state,
                embeddedTerm: {}
            };
        }

        case success(types.FETCH_SEARCH_TERM): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                searchItem: action.payload.data.data
            };
        }

        case success(types.CHANGE_LANGUAGE_IN_SEARCH): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                itemsFound: state.itemsFound.map((item) => item.id === action.payload.data.id ? { ...action.payload.data } : item),
            };
        }

        case success(types.FETCH_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: [...state.items, ...action.payload.data.data],
                hasMoreTerms: action.payload.data.has_next_page
            };
        }

        case success(types.FETCH_ALL_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                items: action.payload.data.data
            };
        }

        case success(types.FETCH_HOME_INFO): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                homeInfo: action.payload.data
            };
        }

        case success(types.TOGGLE_FAVOURITE): {
            const { data } = action.payload;

            return {
                ...state,
                isFetching: false,
                isError: false,
                items: state.items.map((item) => item.id === data.id ? { ...item, is_favourite: !item.is_favourite } : item),
                topItems: state.topItems.map((item) => item.id === data.id ? { 
                    ...item,
                    is_favourite: !item.is_favourite,
                    favourite_count: action.payload.data.added ? item.favourite_count + 1 : item.favourite_count - 1
                } : item),
                favouriteTermsFiltred: state.favouriteTermsFiltred.map((item) => item.id === data.id ? { ...item, is_favourite: !item.is_favourite } : item)
                    .filter((item) => item.is_favourite),
                itemsFound: state.itemsFound.map((item) => item.id === data.id ? { ...item, is_favourite: !item.is_favourite } : item),
                item: {
                    ...state.item,
                    is_favourite: state.item.id === data.id ? !state.item.is_favourite : state.item.is_favourite
                },
                listItems: state.listItems.map((item) => item.id === data.id ? { ...item, is_favourite: !item.is_favourite } : item),
            };
        }

        case success(types.CREATE_NOTE): {
            const { data } = action.meta;
            const { id } = action.payload.data;
            const itemsFound = state.itemsFound.map(itemFound => {
                if(itemFound.id === data.term_id) {
                    return {
                        ...itemFound,
                        my_note: {id: id, note: data.note}
                    }
                }

                return itemFound
            })

            const favouriteTerms = state.favouriteTerms.map(favourite => {
                if(favourite ? favourite.id === data.term_id : false) {
                    return {
                        ...favourite,
                        my_note: {id: id, note: data.note}
                    }
                }
                return favourite
            });

            const favouriteTermsFiltred = state.favouriteTermsFiltred.map(favourite => {
                if(favourite ? favourite.id === data.term_id : false) {
                    return {
                        ...favourite,
                        my_note: {id: id, note: data.note}
                    }
                }
                return favourite
            });

            return {
                ...state,
                itemsFound,
                isFetching: false,
                isError: false,
                item: {...state.item, my_note: {id: id, note: data.note}},
                favouriteTerms,
                favouriteTermsFiltred
            }
        }

        case success(types.REMOVE_NOTE): {
            const { meta } = action;
            const itemsFound = state.itemsFound.map(itemFound => {
                if(itemFound.my_note ? itemFound.my_note.id === meta.id : false) {
                    return {
                        ...itemFound,
                        my_note: null
                    }
                }

                return itemFound
            })

            const favouriteTerms = state.favouriteTerms.map(favourite => {
                if(favourite.my_note ? favourite.my_note.id === meta.id : false) {
                    return {
                        ...favourite,
                        my_note: null
                    }
                }

                return favourite
            })

            const favouriteTermsFiltred = state.favouriteTermsFiltred.map(favourite => {
                if(favourite.my_note ? favourite.my_note.id === meta.id : false) {
                    return {
                        ...favourite,
                        my_note: null
                    }
                }

                return favourite
            })

            return {
                ...state,
                itemsFound,
                isFetching: false,
                isError: false,
                item: {...state.item, my_note: null},
                favouriteTerms,
                favouriteTermsFiltred
            }
        }


        case success(types.EDIT_NOTE): {
            const { note, id } = action.meta.data;

            const itemsFound = state.itemsFound.map(itemFound => {
                if(itemFound.my_note ? itemFound.my_note.id === id : false) {
                    return {
                        ...itemFound,
                        my_note: {...itemFound.my_note, note}
                    }
                }

                return itemFound
            })

            const favouriteTerms = state.favouriteTerms.map(favourite => {
                if(favourite.my_note ? favourite.my_note.id === id : false) {
                    return {
                        ...favourite,
                        my_note: {...favourite.my_note, note}
                    }
                }

                return favourite
            })

            return {
                ...state,
                itemsFound,
                isFetching: false,
                isError: false,
                item: {...state.item, my_note: {id, note}},
                favouriteTerms
            }
        }

        case success(types.FETCH_STARRED_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                favouriteTerms: action.payload.data.data,
                favouriteTermsFiltred: action.payload.data.data
            };
        }

        case types.STARRED_TERMS_FILTERS: {
            const { label, lang } = action.payload.data;
            let filtredTermList = [];
            if(label === '' && lang === '') {
                filtredTermList = [...state.favouriteTerms]
            } else if (lang !== '') {
                filtredTermList = state.favouriteTerms.filter(term => term.available_translations.filter( itemLang => itemLang === lang ).length > 0);
                if (label !== '') {
                    filtredTermList = filtredTermList.filter(item => item.labels.filter( itemLabel => itemLabel.id === label ).length > 0);
                }
            } else if(label !== '') {
                filtredTermList = state.favouriteTerms.filter(item => item.labels.filter( itemLabel => itemLabel.id === label ).length > 0);
                if (lang !== '') {
                    filtredTermList = filtredTermList.filter(item => item.labels.filter( itemLang => itemLang.id === label ).length > 0);
                }
            }

            return {
                ...state,
                isFetching: false,
                isError: false,
                filtrationResultNull: filtredTermList.length === 0 ? true : false,
                favouriteTermsFiltred: filtredTermList,
            };
        }

        case success(types.REMOVE_STARRED_TERMS): {
            const { terms } = action.meta.data;
            const favouriteTerms = state.favouriteTerms.filter(favourite => !terms.includes(favourite.id))
            const favouriteTermsFiltred = state.favouriteTermsFiltred.filter(favourite => !terms.includes(favourite.id))

            return {
                ...state,
                isFetching: false,
                isError: false,
                favouriteTerms,
                favouriteTermsFiltred
            };
        }

        case success(types.CREATE_EMBED_LIST): {
            const { data } = action.payload;

            return {
                ...state,
                isFetching: false,
                isError: false,
                embeddedList: action.payload.data
            };
        }

        case types.CLEAN_TERMS_FOUND_LIST: {
            return {
                ...state,
                isFetching: false,
                isError: false,
                itemsFound: action.data.itemsFound
            };
        }

        case error(types.FETCH_TERMS):
        case error(types.FETCH_ALL_TERMS):
        case error(types.FETCH_TERM_BY_SLUG):
        case error(types.FETCH_LIST_ITEMS):
        case error(types.CHANGE_LANGUAGE_IN_SEARCH):
        case error(types.FETCH_TERM):
        case error(types.FETCH_EMBEDDED_TERM):
        case error(types.FETCH_SEARCH_TERM):
        case error(types.SEARCH_WORD):
        case error(types.CHANGE_FOUND_TERM_LANG):
        case error(types.GET_RANDOM_TERM):
        case error(types.CLEAN_TERMS_FOUND_LIST):
        case error(types.FETCH_LANGUAGE_LIST):
        case error(types.FETCH_LABEL_LIST):
        case error(types.FETCH_HOME_INFO):
        case error(types.FETCH_TOP_TERMS):
        case error(types.SEARCH_TERMS):
        case error(types.TOGGLE_FAVOURITE):
        case error(types.CREATE_NOTE):
        case error(types.REMOVE_NOTE):
        case error(types.EDIT_NOTE):
        case error(types.FETCH_STARRED_TERMS):
        case error(types.REMOVE_STARRED_TERMS): {
            return {
                ...state,
                isFetching: false,
                isError: true,
                errMsg: action.payload.response.data,
                errorStatus: action.payload.response.status
            };
        }

        default:
            return state;
    }
};

export default termsReducer;
