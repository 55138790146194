import React, { Component } from "react";
import { connect } from "react-redux";
import { Container} from "reactstrap";
import {NotificationManager} from "react-notifications";
import { sendContactUsEmail } from "../../../store/common/actions";
// COMPONENTS
import ThankYouMessageBlock from "./components/ThankYouMessageBlock";


class ContactUs extends Component {
    state = {
        firstName: {
            data: '',
            isValid: false,
            isTouched: false,
        },
        lastName: {
            data: '',
            isValid: false,
            isTouched: false,
        },
        email: {
            data: '',
            isValid: false,
            isTouched: false,

        },
        comment: {
            data: '',
            isValid: false,
            isTouched: false,
        },
        agreement: {
            isValid: false,
            isTouched: false,
        },
        errorMess: 'This field is required',
        errorEmail: 'Please enter a valid email address',
        showSucceedBlock: false,
        isLoading: false
    }

    _handleFieldChange = (e, name) => {
        const {agreement} = this.state;
        const value = e.target.value;
        if ( name === 'email') {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
                this.setState({
                    email: {
                        data: value,
                        isTouched: true,
                        isValid: true
                    }
                })
            } else {
                this.setState({
                    email: {
                        data: '',
                        isTouched: true,
                        isValid: false
                    }
                })
            }
        } else if (name === 'agreement') {
            this.setState({
                agreement : {
                    isValid: !agreement.isValid,
                    isTouched: true,
                }
            })
        } else {
            if (value.length != 0) {
                this.setState({
                    [name]: {
                        data: value,
                        isTouched: true,
                        isValid: true,
                    }
                })
            } else {
                this.setState({
                    [name]: {
                        data: '',
                        isTouched: true,
                        isValid: false,
                    }
                })
            }
        }
    };

    setShowSucceedBlock = (value) => this.setState({showSucceedBlock: value});
    setIsLoading = (value) => this.setState({isLoading: value});

    _handleSubmit = () => {
        const {firstName, lastName, email, comment, agreement} = this.state;
        let isValid = true;
        ['firstName', 'lastName', 'email', 'comment', 'agreement'].forEach(each => {
            if(!this.state[each].isValid) {
                isValid = false;
                this.setState(state => ({
                    ...state,
                    [each]: {
                        ...state[each],
                        isTouched: true
                    }
                }))
            }
        });

        if(isValid) {
            this.setIsLoading(true);
            this.props.sendContactUsEmail({
                firstName,
                lastName,
                email,
                comment
            }).then(resp => {
                const respData = resp.payload.data || {};
                const emailStatus = respData.success || false;

                if (emailStatus) {
                    this.setShowSucceedBlock(true);
                } else {
                    NotificationManager.error('Something went wrong. Email has not been sent.');
                }
                this.setIsLoading(false);
            }).catch(err => {
                NotificationManager.error('Something went wrong. Email has not been sent.');
                this.setIsLoading(false);
            })
        }
    };

    render() {
        const { firstName, lastName, email, comment, agreement, errorMess, errorEmail, showSucceedBlock} = this.state;
        return (
          <>
              {/* CONTACT US FORM */}
              {!showSucceedBlock && (
                <Container className='w-sm more-page-container'>
                    <div className="contact-block-wrapper">
                        <h1>Contact Us</h1>
                        <p>Have a question, suggestion or comment? Please send us a message; we’d love to hear from
                            you!</p>
                        <div className="contact-form-name">
                            <h2>Name <span>*</span></h2>
                            <div className='form-name-input_wrapper'>
                                <div className="input-first-name">
                                    <input className={!firstName.isValid && firstName.isTouched ? 'error-field' : ''}
                                           type="text" onChange={(e, name) => this._handleFieldChange(e, 'firstName')}/>
                                    <span>First</span>
                                    {!firstName.isValid && firstName.isTouched &&
                                    <span className='error-text'>{errorMess}</span>}
                                </div>
                                <div className="input-last-name">
                                    <input className={!lastName.isValid && lastName.isTouched ? 'error-field' : ''}
                                           type="text" onChange={(e, name) => this._handleFieldChange(e, 'lastName')}/>
                                    <span>Last</span>
                                    {!lastName.isValid && lastName.isTouched &&
                                    <span className='error-text'>{errorMess}</span>}
                                </div>
                            </div>
                        </div>
                        <div className='form-email-input_wrapper'>
                            <h2>Email <span>*</span></h2>
                            <input className={!email.isValid && email.isTouched ? 'error-field' : ''} type="email"
                                   onChange={(e, name) => this._handleFieldChange(e, 'email')}/>
                            {!email.isValid && email.isTouched && <span className='error-text'>{errorEmail}</span>}
                        </div>
                        <div className='form-comment-input_wrapper'>
                            <h2>Comment or Message <span>*</span></h2>
                            <textarea className={!comment.isValid && comment.isTouched ? 'error-field' : ''} rows="6"
                                      onChange={(e, name) => this._handleFieldChange(e, 'comment')}></textarea>
                            {!comment.isValid && comment.isTouched && <span className='error-text'>{errorMess}</span>}
                        </div>
                        <div className='form-agreement-chekbox_wrapper conditions'>
                            <h2>GDPR Agreement <span>*</span></h2>
                            <div className="chekbox_wrapper-block condition-flag-container">
                                <input className='search-form-flag' type="checkbox" id="agreement" name="subscribe"
                                       value="GDPR-Agreement"
                                       onClick={(e, name) => this._handleFieldChange(e, 'agreement')}/>
                                <label htmlFor="agreement">I consent to having this website store my submitted
                                    information
                                    so they can respond to my inquiry. </label>
                                {!agreement.isValid && agreement.isTouched &&
                                <span className='error-text chekbox_error'>{errorMess}</span>}
                            </div>
                        </div>
                        <button
                          onClick={this._handleSubmit}
                          disabled={this.state.isLoading}
                        >
                            {this.state.isLoading ? "Loading..." : "Send Message"}
                        </button>
                    </div>
                </Container>
              )}

              {/* SUCCEED BLOCK */}
              {showSucceedBlock && (
                <ThankYouMessageBlock/>
              )}
          </>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapActionsToProps = (dispatch) => ({
    sendContactUsEmail: (data) => dispatch(sendContactUsEmail(data))
});

export default connect(mapStateToProps, mapActionsToProps)(ContactUs);
