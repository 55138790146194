import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {FaFacebook, FaTwitter, FaLinkedin, FaPencil} from "react-icons/lib/fa";
import {Row, Col} from "reactstrap";
import {getFooterData, updateFooterData} from "../store/common/actions";
import {froala3Key} from '../configs/config';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';


class Footer extends Component {
    state = {
        newFooterData: '',
        IsEditing: false,
        footerContent: ''
    };
    saveEditData = () => {
        const data = this.state.newFooterData;
        this.setState({
            ...this.state,
            IsEditing: false
        });
        this.props.updateFooterData({content: data});
    };

    componentDidMount() {
        this.props.getFooterData()
    };

    createMarkup = (data) => {
        return {__html: data};
    };

    handleShowEditFooter() {
        const editingState = this.state.IsEditing;
        this.setState({IsEditing: !editingState});
    };

    handleModelChange = (...data) => {
        this.setState({newFooterData: data[0]});
    };

    render() {
        const {footerData, user} = this.props;
        const {newFooterData, IsEditing} = this.state;
        const textContent = `The BIM Dictionary is an Open Source free to use project, part of the BIMe Initiative, and will expand through the efforts of Volunteer Supporters and Official Sponsor`;

        return (
            <>
                <Row className="first-footer-row">
                    <Col
                        className="first-footer-col no"
                    >
                        <div className="footer-left-side">
                            <a href="https://BIMexcellence.org"><div className="footer-logo"/></a>
                            <div className="footer-site-description_wrapper">
                                {!IsEditing &&
                                <>{footerData && <div className='footer-site-description'
                                                    dangerouslySetInnerHTML={this.createMarkup(footerData)}></div>}
                                {!footerData && <div className="footer-site-description">
                                    The <span>BIM Dictionary</span> is an <span>Open Source</span> &amp; free
                                    to use project, part of the BIMe Initiative, and
                                    will expand through the efforts of Volunteer
                                    Supporters and Official Sponsor
                                </div>}
                                </>}
                                {user.role === 'Admin' && <FaPencil className='edit-footer-btn' onClick={() => this.handleShowEditFooter()}/>}
                                {IsEditing &&
                                <div className='edit-common-wrapper footer-edit'>
                                    <FroalaEditor
                                        tag='div'
                                        config={{
                                            htmlUntouched: true,
                                            fullPage: true,
                                            htmlRemoveTags: [],
                                            key: froala3Key,
                                            toolbarInline: true,
                                            imageUploadParam: 'image_param',
                                            imageUploadURL: '/api/froala_upload_image',
                                            imageUploadMethod: 'POST',
                                            toolbarButtons: {
                                                moreText: {
                                                    buttons: [
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strikeThrough',
                                                        'subscript',
                                                        'superscript',
                                                        'fontFamily',
                                                        'fontSize',
                                                        'textColor',
                                                        'backgroundColor',
                                                        'inlineClass',
                                                        'inlineStyle',
                                                        'clearFormatting'
                                                    ],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreParagraph: {
                                                    buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreRich: {
                                                    buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                                                    align: 'left',
                                                    buttonsVisible: 0
                                                },
                                                moreMisc: {
                                                    buttons: ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                                                    align: 'right',
                                                    buttonsVisible: 2
                                                }
                                            }
                                        }}
                                        model={newFooterData ? newFooterData : (footerData ? footerData : textContent)}
                                        onModelChange={this.handleModelChange}
                                    />

                                    <button className='save-edit-block'
                                            onClick={this.saveEditData}>save
                                    </button>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="footer-lists">
                            <div className="footer-nav-wrapper">
                                <ul className="footer-nav-links-1">
                                    <li>
                                        <Link to={'/features'}>
                                            Features
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/contributors'}>
                                            Contributors
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/about'}>
                                            About
                                        </Link>
                                    </li>
                                </ul>

                                <ul className="footer-nav-links-2">
                                    <li>
                                        <Link to={'/participate'}>
                                            Participate
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/contact'}>
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="https://BIMexcellence.org/newsletter">
                                            Newsletter
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <ul className="social-links">
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/BIMe-Initiative-105390040796964/">
                                        <FaFacebook className={'social-footer-link'}/>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com/BIMeInitiative">
                                        <FaTwitter className={'social-footer-link'}/>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.linkedin.com/showcase/bim-excellence">
                                        <FaLinkedin className={'social-footer-link'}/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className="second-footer-row">
                    <div className="second-footer-col">
                        <a href="https://creativecommons.org/licenses/by-sa/4.0/"><div className="cc-footer-logo"/></a>
                        <div className="page-description">
                            This page is provided as part of the BIMe Initiative. Dictionary terms
                            and descriptions are provided under a Creative Commons Attribution-ShareAlike 4.0
                            International
                            License. Permissions beyond the scope of this license are <Link to="/licenses">available here</Link>
                        </div>
                        <ul className="second-footer-links">
                            <li>
                                <Link to="/terms-of-use">
                                    Terms of Use
                                </Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">
                                    Privacy Policy
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Row>
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    footerData: state.common.footerData,
    user: state.auth.user,
});

const mapActionsToProps = (dispatch) => ({
    getFooterData: () => dispatch(getFooterData()),
    updateFooterData: (data) => dispatch(updateFooterData(data)),
});

export default connect(mapStateToProps, mapActionsToProps)(Footer);