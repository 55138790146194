import React from "react";

const HtmlOption = ({ innerProps, children, isFocused, ...props }) => (
    <div
        {...innerProps}
        className={`similar-terms-option ${isFocused ? 'focused' : ''}`}
        dangerouslySetInnerHTML={{__html: children}}
    >
    </div>
);

export default HtmlOption;