import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {ErrorMessage} from "formik";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {
    createTerm,
    getAdminSets,
    getAvailableLabels,
    getSingleTerm,
    getAdminTerms,
    editDraftTerm, clearAdminTerms, minorEditTerm
} from "../../../store/console/actions";
import Select from 'react-select';
import HtmlOption from "../../../helpers/HtmlOption";
import SelectedLabelRow from "../../../helpers/SelectedLabelRow";

class AddTerm extends Component {

    state = {
        title: '',
        description: '',
        plural: '',
        acronym: '',
        similar_terms: '',
        labels: [],
        sets: [],
        items: [],
        linked_terms: [],
        availableLabels: [],
        availableSets: [],
        availableItems: [],
        isFetchingAvailableItems: false
    }

    customStyleSelect = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "2px solid #4bacc6 !important" : "1px solid #707070",
            borderRadius: "5px",
            height: "50px",
            boxShadow: state.isFocused ? 0 : 0
        })
    };

    fields = [{
        type: 'text',
        name: 'acronym',
        required: false,
        label: 'Acronym ',
        clarification: ' (if applicable)'
    }, {
        type: 'text',
        name: 'plural',
        required: false,
        label: 'Term Plural Tense ',
        clarification: ' (separate with coma)'
    }];

    saveDraft() {
        const {match: {params}} = this.props;
        const {title, description} = this.state;
        const data = {...this.getData(), draft: true };
        const redirect = () => this.props.history.push('/management-console/terms');

        if (this.isMinorEdit()) {
            title && description && this.props.minorEditTerm(params.termId, this.state).then(redirect);
            return;
        }

        if (params.termId === undefined) {
            title && description && this.props.createTerm(data).then(redirect);
        } else {
            title && description && this.props.editDraftTerm(params.termId, data).then(redirect);
        }
    }

    publishTerm() {
        const {match: {params}} = this.props;
        const {title, description} = this.state;
        const data = this.getData();
        const redirect = () => this.props.history.push('/management-console/terms');

        if (this.isMinorEdit()) {
            title && description && this.props.minorEditTerm(params.termId, data).then(redirect);
            return;
        }

        if (params.termId === undefined) {
            title && description && this.props.createTerm(data).then(redirect);
        } else {
            title && description && this.props.editDraftTerm(params.termId, data).then(redirect);
        }
    }

    getData = () => {
        const {acronym, title, similar_terms, description, sets, labels, plural, linked_terms} = this.state;
        return {acronym, title, similar_terms, description, sets, labels, plural, linked_terms};
    };

    isMinorEdit = () => {
        const splitUrl = this.props.match.url.split('/');
        const lastPart = splitUrl[splitUrl.length - 1];
        return lastPart === 'minor-edit';
    };

    changeValue(e, field) {
        this.setState({
            [field]: e.target.value
        });
    }

    async componentDidMount() {
        const {match: {params}, clearAdminTerms} = this.props;

        clearAdminTerms();
        if (params.termId !== undefined) {
            Promise.all([
                this.props.getSingleTerm(params.termId).catch((e) => {
                    this.props.history.push('/management-console')
                }),
                this.props.getAdminSets(),
                this.props.getAvailableLabels()
            ]).then(values => {
                const data = values[0].payload.data;
                const labelsId = [], itemsId = [], setsId  = [];

                data.sets.map((set) => (setsId.push(set.id)));
                const newSets = data.sets.map(obj => ({...obj, value: obj.id, title: obj.name }))
                const availableSets = values[1].payload.data.data.filter((set) => !setsId.includes(set.id));

                data.labels.map((label) => (labelsId.push(label.id)));
                const newLabels = data.labels.map(obj => ({...obj, value: obj.id }));
                const availableLabels = values[2].payload.data.data.filter((label) => !labelsId.includes(label.id));

                data.linked_terms.map((item) => (itemsId.push(item.id)));
                const newItems = data.linked_terms.map(obj => ({...obj, value: obj.id }));

                this.setState({
                    title: data.content.title,
                    description: data.content.description,
                    plural:  data.content.plural_title,
                    acronym:  data.content.acronym,
                    similar_terms:  data.similar_terms || '',
                    labels: newLabels,
                    sets: newSets,
                    items: newItems,
                    linked_terms: itemsId,
                    availableLabels: availableLabels,
                    availableSets: availableSets,
                });
                return itemsId;
            }).then(async linkedTerms => {
                this.setState({ isFetchingAvailableItems: true })
                const adminTerms = await this.props.getAdminTerms({all: true}, true);
                adminTerms && this.setState({ isFetchingAvailableItems: false }) 

                const availableItems = adminTerms.payload.data.data.filter((item) => !linkedTerms.includes(item.id));
                this.setState({
                    availableItems
                })
            })
        } else {
            this.props.getAdminSets();
            this.props.getAvailableLabels();
            this.setState({ isFetchingAvailableItems: true })
            const adminTerms = await this.props.getAdminTerms({all: true}, true);
            adminTerms && this.setState({ isFetchingAvailableItems: false }) 
        }
    }

    componentWillReceiveProps(nextProps) {
        const {match: {params}} = this.props;
        if (params.termId !== undefined) {
            this.setState({
                availableLabels: this.state.availableLabels ? this.state.availableLabels : nextProps.availableLabels,
                availableSets: this.state.availableSets.length ? this.state.availableSets : nextProps.allSets,
                availableItems: this.state.availableItems.length ? this.state.availableItems : nextProps.items
            })
        } else {
            this.setState({
                availableLabels: nextProps.availableLabels,
                availableSets: nextProps.allSets,
                availableItems: nextProps.items
            })
        }
    }

    _deleteLabel(currentLabel) {
        const {labels, availableLabels} = this.state;
        availableLabels.push({id: currentLabel.id, value: currentLabel.value, title: currentLabel.title});
        const sortedLabels = availableLabels.sort((a, b) => a.id - b.id);
        this.setState({
            labels: labels.filter(label => label.id !== currentLabel.id),
            availableLabels: sortedLabels
        })
    }

    _deleteSet(currentSet) {
        const {sets, availableSets} = this.state;
        availableSets.push({id: currentSet.id, name: currentSet.name, value: currentSet.value, title: currentSet.title});
        const sortedSets = availableSets.sort((a, b) => a.id - b.id);
        this.setState({
            sets: sets.filter(set => set.id !== currentSet.id),
            availableSets: sortedSets
        })
    }

    _deleteItem(currentItem) {
        const {linked_terms, availableItems, items} = this.state;
        availableItems.push({id: currentItem.value, value: currentItem.value, title: currentItem.title, slug: currentItem.slug});
        const sortedItems = availableItems.sort((a, b) => a.id - b.id);
        this.setState({
            items: items.filter(item => item.id !== currentItem.id),
            linked_terms: linked_terms.filter(term => term !== currentItem.id),
            availableItems: sortedItems
        })
    }

    _selectLabels(selected) {
        const {labels, availableLabels} = this.state;
        this.setState({
            labels: [...labels, {id: selected.value, title: selected.label, value: selected.value}],
            availableLabels: availableLabels.filter((label) => label.id !== selected.value)
        })
    }

    _selectSets(selected) {
        const {sets, availableSets} = this.state;
        this.setState({
            sets: [...sets, {id: selected.value, name: selected.label, title: selected.label, value: selected.value}],
            availableSets: availableSets.filter(set => set.id !== selected.value)
        })
    }

    _selectItems(selected) {
        const {linked_terms, availableItems, items} = this.state;
        this.setState({
            linked_terms: [...linked_terms, selected.value],
            items: [...items, {id: selected.value, title: selected.label, value: selected.value}],
            availableItems: availableItems.filter((item) => item.id !== selected.value)
        })
    }

    render() {
        const {labels, sets, items, title, description, availableItems, isFetchingAvailableItems} = this.state;
        return (
            <>
                <div className="container-fluid">
                    <div className="row console-title">
                        <div className="col-md-12 console-title-cell">
                            <div className="col-xl-12">
                                <Link to={'/management-console/terms'} className="back-to-console">
                                    <FontAwesomeIcon icon={faChevronLeft}/>
                                    <span>Back to Console</span>
                                </Link>
                                <div>{this.isMinorEdit() ?  'Edit Term' : 'Add Term'}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 console-container">
                            <form className="add-term-form">
                                <div className="row no-gutters">
                                    <div className="box block-wrapper">
                                        <div className="row no-gutters">
                                            <div className="form-group field-wrapper">
                                                <label>Term <span className="input-required"> * </span></label>
                                                <input className="inputTerm"
                                                       type='text'
                                                       name='title'
                                                       value={this.state['title']}
                                                       onChange={(e) => this.changeValue(e, 'title')}
                                                />
                                                {<ErrorMessage
                                                    className={'validation-error-message'}
                                                    name='title'
                                                    component={'div'}
                                                />}
                                            </div>
                                        </div>
                                        <div className="row no-gutters">
                                            {this.fields.map((field, index) => (
                                                <div className={`col-md-6 form-group field-Item-${index}`}  key={index}>
                                                    <label>{field.label} <span>{field.clarification}</span></label>
                                                    <input
                                                        className="inputTerm"
                                                        type={field.type}
                                                        name={field.name}
                                                        value={this.state[field.name] || ''}
                                                        onChange={(e) => this.changeValue(e, field.name)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="row no-gutters">
                                            <div className="col-md-6 form-group field-Item-0 similar-terms">
                                                <label>Similar Terms <span>(separate with coma)</span></label>
                                                <input className="inputTerm"
                                                       type='text'
                                                       name='similar_terms'
                                                       value={this.state['similar_terms']}
                                                       onChange={(e) => this.changeValue(e, 'similar_terms')}
                                                />
                                            </div>

                                            <div className="col-md-6 form-group field-Item-1 similar-terms">
                                                <label>Linked Terms <span>(Also refer to)</span></label>
                                                <Select
                                                    className="similar-terms-select"
                                                    styles={this.customStyleSelect}
                                                    onChange={(selected) => this._selectItems(selected)}
                                                    isSearchable={true}
                                                    value={""}
                                                    placeholder={isFetchingAvailableItems ? 'Loading...' : 'Select...'}
                                                    components={{
                                                        Option: HtmlOption
                                                    }}
                                                    options={availableItems.map(item => {
                                                        return {
                                                            value: item.id,
                                                            label: item.title
                                                        }
                                                    })}
                                                />
                                                <SelectedLabelRow
                                                    selectedRows={items}
                                                    dangerouslySetInnerHTML
                                                    deleteDisabled={isFetchingAvailableItems}
                                                    deleteRow={row => this._deleteItem(row)}
                                                />
                                                    
                                                </div>
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-6 form-group field-Item-0 select-labels similar-terms">
                                                    <label>Select Labels <span>(up to three labels)</span></label>
                                                    <Select
                                                        styles={this.customStyleSelect}
                                                        onChange={(selected) => this._selectLabels(selected)}
                                                        isSearchable={true}
                                                        value={""}
                                                        options={this.state.availableLabels.map(label => {
                                                            return {
                                                                value: label.id,
                                                                label: label.title
                                                            }
                                                        })}
                                                    />
                                                    <SelectedLabelRow
                                                        selectedRows={labels}
                                                        deleteRow={row => this._deleteLabel(row)}
                                                    />
                                                </div>

                                                <div className="col-md-6 form-group field-Item-1 select-labels similar-terms">
                                                    <label>Add to Term Sets <span>(select or add)</span></label>
                                                    <Select
                                                        styles={this.customStyleSelect}
                                                        onChange={(selected) => this._selectSets(selected)}
                                                        value={null}
                                                        options={this.state.availableSets.map(set => {
                                                            return {
                                                                value: set.id,
                                                                label: set.name
                                                            }
                                                        })}
                                                    />
                                                    <SelectedLabelRow
                                                        selectedRows={sets}
                                                        deleteRow={row => this._deleteSet(row)}
                                                    />
                                                </div>

                                            </div>

                                            <div className="row no-gutters">
                                                <div className="col-md form-group description-wrapper">

                                                <label>Description <span className="input-required"> * </span></label>
                                                <textarea
                                                    styles={this.customStyleSelect}
                                                    onChange={(e) => this.changeValue(e, 'description')}
                                                    name={'description'}
                                                    maxLength={1000}
                                                    value={description}
                                                />
                                                <span className="form-group counter">{this.state.description.length}/1000</span>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="submit-add-term">
                                        <button type="button"
                                                onClick={() => this.publishTerm()}
                                                className={`console-button publish-term-button ${(!title || !description) ? 'button-disabled' : '' }`}
                                                disabled={!title || !description}>
                                            Publish
                                        </button>
                                        {!this.isMinorEdit() && <span onClick={() => this.saveDraft()}
                                              className={title && description ? "save-active" : "save-disabled"}>
                                            Save as Draft
                                        </span>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    allSets: state.managementConsole.sets,
    availableLabels: state.managementConsole.availableLabels,
    singleTerm: state.managementConsole.singleTerm,
    items: state.managementConsole.items
});

const mapDispatchToProps = (dispatch) => ({
    createTerm: (data) => dispatch(createTerm(data)),
    editDraftTerm: (id, data) => dispatch(editDraftTerm(id, data)),
    minorEditTerm: (id, data) => dispatch(minorEditTerm(id, data)),
    getAdminSets: () => dispatch(getAdminSets()),
    getAvailableLabels: () => dispatch(getAvailableLabels()),

    getAdminTerms: (data, loadInBackground = false) => dispatch(getAdminTerms(data, loadInBackground)),
    getSingleTerm: (id) => dispatch(getSingleTerm(id)),
    clearAdminTerms: () => dispatch(clearAdminTerms())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddTerm));
