import React from "react";
import {Modal, Container} from 'react-bootstrap';

const MoalRemoveTemplate = (props) => (
    <Modal
        className="delete-popup-width"
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>

        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Delete Extended Description
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container className="padding-none">
                Warning: You are about to delete extended description. All information from that section will be lost.
                Please Confirm.
            </Container>
        </Modal.Body>
        <Modal.Footer className="delete-extended">
            <span onClick={props.onHide}>Cancel</span>
            <button className="console-button" onClick={() => props.onSave()}>Yes, Delete</button>
        </Modal.Footer>
    </Modal>
);

export default MoalRemoveTemplate;