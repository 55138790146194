import React, {Component} from "react";
import {connect} from "react-redux";
import {
    fetchAuthorTerms
} from "../../store/dashboard/actions";
import Paginator from "../../helpers/Paginator";
import GeneralListRow from "./GeneralListRow";
import CustomFilterTable from "../../helpers/CustomFilterTable";
import SpinnerDownload from "../../helpers/SpinnerDownload";
import {Button} from "reactstrap";

class AuthorsDashboard extends Component {
    state = {
        page: 1,
        per_page: 10,
        searchParams: {
            lang: 'en',
            q: '',
            titles_only: false,
            exclude: false,
            order_by: '',
            order_asc: 'asc',
        },
        selectedTerms: [],
        isFetchingAuthorTerms: true,
    };

    hiddenInputRef = React.createRef();

    _generalListTableColumns = [
        {
            style: {
                width: '3%'
            },
            checkboxesTitle: true,
            className: 'console-select'
        },
        {
            style: {
                width: '28%'
            },
            title: 'Term Title',
            titleSlug: 'content.title',
            className: 'general-table-title'
        },
        {
            style: {
                width: '17%'
            },
            title: 'Templates',
            titleSlug: 'templates',
            className: 'general-table-templates'
        },
        {
            style: {
                width: '16%'
            },
            title: 'Sets',
            titleSlug: 'setName',
            className: 'general-table-set'
        },
        {
            style: {
                width: '10%'
            },
            title: 'Version',
            titleSlug: 'version.number',
            className: 'general-table-version'
        },
        {
            style: {
                width: '14%'
            },
            title: 'Date Created',
            titleSlug: 'created',
            className: 'general-table-created'
        },
        {
            style: {
                width: '14%'
            },
            title: 'Date Modified',
            titleSlug: 'modified',
            className: 'general-table-modified'
        }
    ]

    _searchByName = (e) => {
        const {searchParams} = this.state;
        const value = e.target.value
        if (value === searchParams.q) {
            return false;
        }
        this.setState(state => {
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    q: value
                }
            }
        })

    };

    _paginatorChangedPage(data) {
        const page = data.selected;
        this.setState({
            page
        });
    }

    componentDidMount() {
        this.props.fetchAuthorTerms()
            .then(() => this.setState({ isFetchingAuthorTerms: false }))
            .catch(() => this.setState({ isFetchingAuthorTerms: false }));
    }

    onArrowClick = (order_by, isAsc) => {
        this.setState(({searchParams}) => ({
            searchParams: {
                ...searchParams,
                order_by,
                order_asc: isAsc ? 'asc' : 'desc'
            }
        }))
    }

    resolve(path, obj=self, separator='.') {
        const properties = Array.isArray(path) ? path : path.split(separator)
        const value = properties.reduce((prev, curr) => prev && prev[curr], obj)
        return typeof value === 'string' ? value.replace(/<\/?[^>]+(>|$)/g, "") : value;
    }

    render() {
        const {terms} = this.props;
        const {page, per_page, searchParams, isFetchingAuthorTerms} = this.state;

        const searchTerms = terms.filter((item) => {
            return item.content.title.toLowerCase().indexOf(searchParams.q.toLowerCase()) !== -1 || item.content.acronym.toLowerCase().indexOf(searchParams.q.toLowerCase()) !== -1;
        });
        // if (searchParams.order_by !== '') {
        //     searchTerms.sort((a, b) => {
        //         if (searchParams.order_asc === 'asc') {
        //             return (
        //                 this.resolve(searchParams.order_by, a) > this.resolve(searchParams.order_by, b)
        //             ) ? 1 : ((
        //                 this.resolve(searchParams.order_by, b) > this.resolve(searchParams.order_by, a)
        //             ) ? -1 : 0)
        //         }
        //         else {
        //             return (
        //                 this.resolve(searchParams.order_by, a) > this.resolve(searchParams.order_by, b)
        //             ) ? -1 : ((
        //                 this.resolve(searchParams.order_by, b) > this.resolve(searchParams.order_by, a)
        //             ) ? 1 : 0)
        //         }
        //     })
        // }

        const limit = ((page * per_page) + per_page < searchTerms.length) ? (page * per_page) + per_page : searchTerms.length;
        const _tableFooterColumns = [{
            colspan: 7,
            component: <Paginator
                className={"console-paginator"}
                pageCount={searchTerms.length / per_page}
                pageChanged={(data) => this._paginatorChangedPage(data)}
                forcePage={page}
            />
        }];

        return (
            <>
                <div className="editors-dashboard-page">
                    <div className="editors-dashboard-title">
                        <h1>Author`s dashboard</h1>
                    </div>
                    <div className="editors-dashboard-body">

                        <div className={`general-list-container ${isFetchingAuthorTerms && 'is-loading'}`}>

                            <div className="general-list-title-panel">
                                    <div className="general-list-search_block">
                                        <span className="general-list-brand">
                                            General List
                                        </span>
                                        <div className="search-by-titles-form">
                                            <input type="text" className="form-control search-titles-input"
                                                    placeholder="Search by term name"
                                                    onChange={(e) => this._searchByName(e)}/>
                                        </div>
                                    </div>
                                </div>

                            <div className="general-list-table_block">
                                {searchTerms.length > 0 && <CustomFilterTable
                                    drawFilters={false}
                                    className={"dashboard-genetalList-table"}
                                    columns={this._generalListTableColumns}
                                    items={searchTerms.slice(page * per_page, limit)}
                                    footer={_tableFooterColumns}
                                    onArrowClick={this.onArrowClick}
                                    onChangeSelect={(selected) => {
                                        this.setState({ selectedTerms: selected });
                                    }}
                                >
                                    <GeneralListRow/>
                                </CustomFilterTable>}
                                {searchTerms.length === 0 && !isFetchingAuthorTerms &&
                                    <div className='block-no_found'>No such terms found</div>
                                }
                            </div>

                            {isFetchingAuthorTerms && <div className='spinner-main-wrapper'><SpinnerDownload /></div>}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    terms: state.dashboard.terms,
    user: state.auth.user,
});

const mapActionsToProps = (dispatch) => ({
    fetchAuthorTerms: () => dispatch(fetchAuthorTerms(true))
});

export default connect(mapStateToProps, mapActionsToProps)(AuthorsDashboard);
