import {useState} from "react";
import {Modal} from "react-bootstrap";
import React from "react";


const ModalForgotPassword = ({show, onCancelClick, onSendClick}) => {

    const [email, setEmail] = useState('');

    const cancel = () => {
        setEmail('');
        onCancelClick();
    };

    const send = () => {
        setEmail('');
        onSendClick(email);
    };

    return (
        <Modal show={show} className="modal-forgot-password"
               size="md" centered
               aria-labelledby="contained-modal-title-vcenter"
               onHide={() => cancel()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>Forgot password</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="modal-forgot-password-description">Enter your email and we will send you a link to reset your password</p>
                <input className="modal-forgot-password-email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email address"/>
            </Modal.Body>
            <Modal.Footer>
                <div onClick={() => cancel()} className="cancel-button">Cancel</div>
                <div onClick={() => send()} className="console-button">Send</div>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalForgotPassword;