import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import StyledCheckbox from "../../helpers/StyledCheckbox";
import moment from "moment";
import {withRouter} from "react-router";

class GeneralListRow extends Component {

    render() {
        const props = this.props;

        return (
            <tr>
                <td className={props.props.columns[0].className + '-td'}>
                    <StyledCheckbox
                        checked={props.item.checked}
                        changeSelect={() => props.props.toggleSelect(props.item.id)}
                    />
                </td>
                <td className={props.props.columns[1].className + '-td'}>
                    <Link to={{
                        pathname: `/management-console/terms/${props.item.id}`,
                        state: {
                            termId: props.item.id,
                            prevPath: this.props.location.pathname
                        }
                    }} dangerouslySetInnerHTML={{__html: props.item.content && props.item.content.title}} />
                    {props.item.content && props.item.content.acronym && <span className="acronym">&nbsp;({props.item.content.acronym})</span>}
                </td>
                <td className={props.props.columns[2].className + '-td'}>
                    <span>
                        {typeof props.item.extended_description_type === 'number' && props.item.extended_description_type === 0 ? 'List' : ''}
                        {typeof props.item.extended_description_type === 'number' && props.item.extended_description_type === 1 ? 'Paper' : ''}
                        {typeof props.item.extended_description_type === 'number' && props.item.extended_description_type === 2 ? 'Document' : ''}
                    </span>

                </td>
                <td className={props.props.columns[3].className + '-td'}>
                    {props.item.termSet ? props.item.termSet : 'Set A'}
                </td>
                <td className={props.props.columns[4].className + '-td'}>
                    {props.item.version.number ? props.item.version.number : '1'}
                </td>
                <td className={props.props.columns[5].className + '-td'}>
                    {props.item.created && moment(props.item.created).format('LL')}
                </td>
                <td className={props.props.columns[6].className + '-td'}>
                    {props.item.modified && moment(props.item.modified).format('LL')}
                </td>
            </tr>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralListRow));
