import React, {Component} from 'react';
import TemplateInfoblockRow from "./TemplateInfoblockRow";

const TemplateInfoblockRows = (props) => {
        return (
            <div className={`document-infoblocks-container`}>
                {props.infoblocks && props.infoblocks.length > 0 && props.infoblocks.map((item, index) => (
                    <TemplateInfoblockRow {...props} item={item} index={index} key={index}/>
                ))}
            </div>
        )

};

export default TemplateInfoblockRows