import React, {Component} from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import ModalRestoreVersion from "../../Terms/templates/ModalRestoreVersion";

class Versions extends Component {

    static propTypes = {
        onVersionClick: PropTypes.func,
        onRestoreClick: PropTypes.func,
        current: PropTypes.number
    };

    state = {
        showModalRestoreVersion: false,
        versionToRestore: -1
    };

    onVersionClick = (item) => {
        if (item.number !== this.props.current) {
            this.props.onVersionClick(item)
        }
    };

    onRestoreVersionClick = (version) => {
        this.setState({
            showModalRestoreVersion: true,
            versionToRestore: version
        })
    };

    hideModalRestoreVersion = () => {
        this.setState({
            showModalRestoreVersion: false
        })
    };

    onModalRestoreVersionSubmit = (deleteNewerVersions) => {
        const {versionToRestore} = this.state;
        this.props.onRestoreClick(versionToRestore, deleteNewerVersions);
        this.setState({
            versionToRestore: -1,
            showModalRestoreVersion: false,
        })
    };

    hideModalNewVersionNotes = () => {
        this.setState({
            showModalNewVersionNotes: false
        })
    };

    onSubmitNewVersionNotes = (notes) => {
        this.hideModalNewVersionNotes();
        this.props.onNewVersion(notes);
    };

    render() {
        const {versions} = this.props;
        const {showModalRestoreVersion, showModalNewVersionNotes, versionToRestore} = this.state;

        return (
            <div className="console-term-versions">
                {versions.map((item) => {
                    return (
                        <div key={item.number}
                             className={`console-term-version ${item.is_current ? 'current' : ''}`}>
                            <div className="console-term-version-description">
                                <div className="console-version-first-line">
                                    <div className="console-version-number"
                                         onClick={() => this.onVersionClick(item.number)}>
                                        Version {item.number} {item.is_current ? '(Current)' : ''}
                                    </div>
                                </div>
                                <div className="console-version-modified">
                                    Last modified {moment(item.modified).format('LL')}
                                </div>
                            </div>
                            {!item.is_current &&
                            <div className="console-button" onClick={() => this.onRestoreVersionClick(item.number)}>
                                Restore
                            </div>
                            }
                        </div>
                    )
                })}

                <ModalRestoreVersion
                    version={versionToRestore}
                    show={showModalRestoreVersion}
                    onHide={() => this.hideModalRestoreVersion()}
                    onSave={(deleteNewerVersions) => this.onModalRestoreVersionSubmit(deleteNewerVersions)}
                />
            </div>
        )
    }
}

export default Versions