import React, {Component} from "react";
import Select from 'react-select';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getAdminAvailableTemplates} from "../../../../store/console/actions";

class ExtendedDescriptionRow extends Component {
    state = {
        extendedDescriptionAdding: false,
        availableContentTemplates: {},
        selectedTemplate: undefined
    };

    showExtendedDescription = () => this.setState({extendedDescriptionAdding: true});
    cancelDescriptionType = () => {
        this.setState({extendedDescriptionAdding: false})
        this.props.cancelDescriptionType()
    };
    changeSelectedTemplate = (key, selected) => selected.value !== -1 ? this.setState({selectedTemplate: {key, selected}}) : this.setState({selectedTemplate: undefined});
    applyDescriptionType = () => {
        const {selectedTemplate} = this.state;

        if (selectedTemplate !== undefined) {
            this.props.applyDescriptionType(selectedTemplate.key, selectedTemplate.selected)
        }
    }

    async componentDidMount() {
        this._isMounted = true;

        const response = await this.props.getAdminAvailableTemplates();
        const templates = Object.entries(response.payload.data.data).map(([key, value]) => {
            value.splice(0, 0, {value: 0, label: `Blank ${key} Template`});
            value.splice(0, 0, {value: -1, label: `${key}`});

            return {key, values: value}
        });

        if (this._isMounted) {
            this.setState({availableContentTemplates: templates})
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {editView} = this.props;
        const {extendedDescriptionAdding, availableContentTemplates} = this.state;

        return (
            <div className="extended-description-wrapper">
                {editView && extendedDescriptionAdding === false && (
                    <div>
                        <button
                            type={"button"}
                            className={"console-button"}
                            onClick={this.showExtendedDescription}
                        >Attach template</button>
                    </div>
                )}

                {editView && extendedDescriptionAdding === true && (
                    <>
                        <div className="expanded-description-wrapper">
                            <p>Select Template</p>

                            {availableContentTemplates && availableContentTemplates.map((item) => {
                                return <Select
                                    key={item.key}
                                    className="select-create"
                                    onChange={(selected) => this.changeSelectedTemplate(item.key, selected)}
                                    defaultValue={{value: -1, label: `${item.key}`}}
                                    options={item.values}
                                />
                            })}
                        </div>

                        <div className="extended-description-actions">
                            <button
                                type={"button"}
                                className={"console-button"}
                                onClick={this.applyDescriptionType}
                            >
                                Attach Template
                            </button>
                            <span onClick={this.cancelDescriptionType}>Cancel</span>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    availableContentTemplates: state.managementConsole.availableContentTemplates
});

const mapDispatchToProps = (dispatch) => ({
    getAdminAvailableTemplates: () => dispatch(getAdminAvailableTemplates())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExtendedDescriptionRow));