import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";
import TermLinks from "../Term/partials/TermLinks";

const TermTitle = (props) => {
	const { term, className } = props;

	return (
		<div className={className}>
			<div className='title-box'>
				<Link
					to={`/en/${term.slug}/${
						term.version && term.version.number
							? term.version.number
							: 1
					}`}
					className='starred-title'
					dangerouslySetInnerHTML={{ __html: term.content.title }}
				/>
			</div>
			{term.is_assigned !== undefined && term.is_assigned === true &&
				<div className='link-box'>
					<Link to={`management-console/terms/${term.id}`} onClick={(e) => {e.stopPropagation()}}>edit document</Link>
				</div>
			}
			<TermLinks
				term={term}
				className={"favourite"}
				showCopy
				showFavourite
				showNotifications
				favouriteEnabled
				handleClickedStar={() => {}}
				handleClickedLink={() => {}}
			/>
		</div>
	);
};

TermTitle.propTypes = {
	term: PropTypes.object,
	className: PropTypes.string,
};

export default TermTitle;
