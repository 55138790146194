import React, {Component} from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import StyledCheckbox from "../../../helpers/StyledCheckbox";
import {withRouter} from "react-router";
import ActionsWrapper from "../../../helpers/ActionsWrapper";

class TermRow extends Component {
    state = {
        showDeleteAction: false
    };

    setDeleteAction(showDeleteAction) {
        this.setState({
            showDeleteAction
        })
    }

    deleteTerm(e, item) {
        e.preventDefault();
        e.stopPropagation();

        this.setDeleteAction(false);

        this.props.props.deleteAction(item);
    }

    editTerm = (item) => {
        this.props.history.push(`/management-console/terms/${item.id}/minor-edit`);
    };

    render() {
        const {showDeleteAction} = this.state;
        const props = this.props;

        return (
            <tr className={props.item.status === 'Draft' ? 'blocked-user' : undefined}>
                <td className={props.props.columns[0].className + '-td'}>
                    <StyledCheckbox
                        checked={props.item.checked}
                        changeSelect={() => props.props.toggleSelect(props.item.id)}
                    />
                </td>
                <td className={props.props.columns[1].className + '-td'}>
                    <Link
                        to={{
                            pathname: `/management-console/terms/${props.item.id}${props.item.status === 'Draft' ? '/draft' : ''}`,
                            state: {
                                termId: props.item.id,
                                prevPath: this.props.location.pathname
                            }
                        }}
                        dangerouslySetInnerHTML={
                            {__html: props.item.content.title !== '' ? props.item.content.title : props.item.title}
                        }
                    />
                    {props.item.content && props.item.content.acronym && (
                        <span>
                            &nbsp;({props.item.content.acronym})
                        </span>
                    )}
                </td>
                <td className={props.props.columns[2].className + '-td'}>
                    <span>
                        {typeof props.item.extended_description_type === 'number' && props.item.extended_description_type === 0 ? 'List' : ''}
                        {typeof props.item.extended_description_type === 'number' && props.item.extended_description_type === 1 ? 'Paper' : ''}
                        {typeof props.item.extended_description_type === 'number' && props.item.extended_description_type === 2 ? 'Document' : ''}
                    </span>
                </td>
                <td className={props.props.columns[3].className + '-td'}>
                    <div className="meter">
                    <span className="meter-filled"
                          style={{width: (props.item.available_translations.length * 100 / props.props.languages.length) + '%'}}
                    />
                    <span className="console-terms-languages-count">
                        {props.item.available_translations.length} of {props.props.languages.length}
                    </span>
                    </div>
                </td>
                <td className={props.props.columns[4].className + '-td'}>
                    {props.item.content.co_authors.join(', ')}
                </td>
                <td className={props.props.columns[5].className + '-td'}>
                    {props.item.sets && props.item.sets.length > 0 ? props.item.sets.map((item) => item.name).join(', ') : "-"}
                </td>
                <td className={props.props.columns[6].className + '-td'}>
                    {props.item.created && moment(props.item.created).format('LL')}
                </td>
                <td className={props.props.columns[7].className + '-td'}>
                    {props.item.modified && moment(props.item.modified).format('LL')}
                </td>
                <td className={props.props.columns[8].className + '-td'}>
                    {props.item.content && props.item.content.version}
                </td>
                <td className={props.props.columns[9].className + '-td console-delete-action-td'}>
                    <ActionsWrapper show={showDeleteAction}
                                    onClickOutside={() => this.setDeleteAction(false)}
                                    onClosedClick={() => this.setDeleteAction(true)}>
                        <div className={"delete-item-action"}>
                            <div onClick={(e) => this.deleteTerm(e, props.item)}>
                                Delete
                            </div>
                            <div onClick={(e) => this.editTerm(props.item)}>
                                Edit
                            </div>
                        </div>
                    </ActionsWrapper>
                </td>
            </tr>
        )
    }
}

export default withRouter(TermRow);