import React, {Component} from "react";
import reactStringReplace from 'react-string-replace';
import {Link} from "react-router-dom";

class EmbeddedTerm extends Component {

    componentWillMount() {
        document.addEventListener('click', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, false);
    }

    handleClick = (e) => {
        if (this.node.contains(e.target) || e.which !== 1) {
            return;
        }
        this.props.closeEmbeded();
    };

    nodeSetter = (node) => {
        this.node = node;
    };

    render() {
        const {term, title, description, regExp, withoutRouter} = this.props;
        const htmlDescription = description.replace(regExp, `<span class="inline-topic_embedded">$1</span>`);

        let termTitle = term.singular_title ? term.singular_title : title;
        const termVersion = term.version ? term.version : 1;

        termTitle = term.acronym ? `${termTitle} (${term.acronym})` : termTitle;

        return (
            <React.Fragment>
                <div
                    ref={node => this.nodeSetter(node)}
                    className='embedded-term-wrapper'
                >
                    {withoutRouter ? (
                            <a
                                className='embedded-term-title'
                                href={`/${term.language}/${term.slug}/${termVersion}`}
                            >
                                {termTitle}
                            </a>
                        )
                        : (
                            <Link className='embedded-term-title'
                                  to={`/${term.language}/${term.slug}/${termVersion}`}
                                  dangerouslySetInnerHTML={{__html: termTitle}}
                            />
                        )
                    }
                    <span
                        className='embedded-term-description'
                        dangerouslySetInnerHTML={{__html: htmlDescription}}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default EmbeddedTerm;
