import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {createAdminLabel, editAdminSingleLabel, getAdminSingleLabel} from "../../../store/console/actions";

class AddEditLabel extends Component {
    state = {
        id: false,
        title: '',
        description: '',
    };

    _changeValue(e, field) {
        this.setState({
            [field]: e.target.value
        });
    }

    publishLabel() {
        const {title, description} = this.state;
        const {singleLabel} = this.props;

        if (singleLabel.id !== undefined) {
            this.props.editAdminSingleLabel(singleLabel.id, {
                title,
                description
            }).then(() => this.props.history.push('/management-console/labels'));
        } else {
            this.props.createAdminLabel({
                title,
                description
            }).then(() => this.props.history.push('/management-console/labels'));
        }
    }

    componentDidMount() {
        const {match: {params}} = this.props;

        if (params.labelId !== undefined) {
            this.props.getAdminSingleLabel(params.labelId)
        }
    }

    componentWillReceiveProps(nextProps) {
        const {singleLabel} = nextProps;
        
        if (singleLabel.id !== undefined) {
            this.setState({
                id: singleLabel.id,
                title: singleLabel.title,
                description: singleLabel.description,
            })
        }
    }

    render() {
        const {singleLabel} = this.props;
        const {title, description} = this.state;
        return (
            <>
                <div className="container-fluid">
                    <div className="row console-title">
                        <div className="col-md-12 console-title-cell">
                            <div className="col-xl-12">
                                <Link to={'/management-console/labels'} className="back-to-console">
                                    <FontAwesomeIcon icon={faChevronLeft}/>
                                    <span>Back to Console</span>
                                </Link>
                                <div>{singleLabel.id === undefined ? 'Add' : 'Edit'} Label</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 console-container">
                            <form className="add-term-form">
                                <div className="row">
                                    <div className="col-md-12 box">                                        
                                        <div className="row">
                                            <div className="col-md-12 form-group">                                          
                                                <label>Label Title<span className="input-required">*</span></label>
                                                <input  className="inputTerm"
                                                        type={"text"}
                                                        name={"title"}
                                                        value={title}
                                                        onChange={(e) => this._changeValue(e, 'title')}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md form-group">
                                                <label>Description<span className="input-required">*</span></label>
                                                <textarea   name={"description"}
                                                            value={description || ""}
                                                            onChange={(e) => this._changeValue(e, 'description')}
                                                            maxLength={400}
                                                />
                                            </div>
                                        </div>
                                    </div>                                                                              
                                    <div className="submit-add-term">  
                                        <button type="button"
                                                onClick={() => this.publishLabel()}
                                                className="console-button publish-label-button"
                                                disabled={!title || !description}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    singleLabel: state.managementConsole.singleLabel
});

const mapDispatchToProps = (dispatch) => ({
    createAdminLabel: (data) => dispatch(createAdminLabel(data)),
    getAdminSingleLabel: (id) => dispatch(getAdminSingleLabel(id)),
    editAdminSingleLabel: (id, data) => dispatch(editAdminSingleLabel(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditLabel);
