import React, {Component} from 'react';
import classnames from './utils/classnames';
import DocumentInfoblockRow from "./DocumentInfoblockRow";
import {withRouter} from "react-router-dom";

class NodeRenderer extends Component {

    render() {
        const {
            connectDragPreview,
            connectDragSource,
            canDrag,
            node,
            rowDirection,
            path
        } = this.props;
        const rowDirectionClass = rowDirection === 'rtl' ? 'rst__rtl' : null;

        const style = {};
        if (path.length > 2) {
            style.marginLeft = "-" + ((path.length - 2) * 20) + 'px';
        }

        return (
            <div style={{height: '100%', ...style}}>
                <div className={classnames('rst__rowWrapper', rowDirectionClass)}>
                    {canDrag ? connectDragSource(
                        <div>
                            <DocumentInfoblockRow
                                item={node}
                            />
                        </div>
                    , {dropEffect: 'copy'}) : connectDragPreview(
                        <div>
                            <DocumentInfoblockRow
                                item={node}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default NodeRenderer;