import React, {Component} from "react";
import {FaTrash} from "react-icons/lib/fa";
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/print.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import {defaultTextareaConfig} from "../../../../configs/froalaConfigs";


class PageTemplate extends Component {
    render() {
        const {dataEdit, data, isEdit, removeTemplate, onChange} = this.props;

        return (
            <>
                <div className="wrapper-manage-terms">
                    <div className="template-box">
                        <span>Page Template</span>
                        {isEdit && (<FaTrash onClick={removeTemplate}/>)}
                    </div>
                </div>

                <div className="wrapper-term-body edit-template">
                    <div className="body-box">
                        <div className="terms-title">
                            <span>Add Extended Description</span>
                        </div>
                        <div className="description-body margin-none fr-view">
                            {isEdit ? <FroalaEditor
                                    tag='div'
                                    config={{
                                        htmlUntouched: true,
                                        fullPage: true,
                                        htmlRemoveTags: [],
                                        ...defaultTextareaConfig,
                                        imageUploadParam: 'image_param',
                                        imageUploadURL: '/api/froala_upload_image',
                                        imageUploadMethod: 'POST',
                                        editorClass: "details-textarea max-height",
                                        height: 350
                                    }}
                                    model={dataEdit.extended_description ? dataEdit.extended_description : ''}
                                    onModelChange={(...data) => onChange('extended_description', data[0])}
                                /> : <span dangerouslySetInnerHTML={{__html: data.extended_description}} /> }
                        </div>
                    </div>

                </div>
                <div className="wrapper-term-footer">
                    <div className="footer-box footer-content">
                        <div className="terms-title">
                            <span>Add Footnotes </span>
                        </div>
                        {isEdit ? <FroalaEditor
                                tag='div'
                                config={{
                                    htmlUntouched: true,
                                    fullPage: true,
                                    htmlRemoveTags: [],
                                    ...defaultTextareaConfig,
                                    imageUploadParam: 'image_param',
                                    imageUploadURL: '/api/froala_upload_image',
                                    imageUploadMethod: 'POST',
                                }}
                                model={dataEdit.footnotes ? dataEdit.footnotes : ''}
                                onModelChange={(...data) => onChange('footnotes', data[0])}
                            /> : <span dangerouslySetInnerHTML={{__html: data.footnotes}} />}
                    </div>
                    <div className="footer-box footer-content">
                        <div className="terms-title">
                            <span>Add Acknowledgements</span>
                        </div>
                        {isEdit ? <FroalaEditor
                                tag='div'
                                config={{
                                    htmlUntouched: true,
                                    fullPage: true,
                                    htmlRemoveTags: [],
                                    ...defaultTextareaConfig,
                                    imageUploadParam: 'image_param',
                                    imageUploadURL: '/api/froala_upload_image',
                                    imageUploadMethod: 'POST',
                                }}
                                model={dataEdit.acknowledgements ? dataEdit.acknowledgements : ''}
                                onModelChange={(...data) => onChange('acknowledgements', data[0])}
                            /> : <span dangerouslySetInnerHTML={{__html: data.acknowledgements}} />}
                    </div>
                </div>
            </>
        )
    }
}

export default PageTemplate;