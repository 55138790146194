import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
    setSearchWord,
    setSearchCheckbox
} from "../../store/terms/actions";


class SearchForm extends Component {


    _onChange = (e) => {
        const data = e.target.value
        this.props.setSearchWord(data);
        if(data.langs !== 0 && window.location.pathname !== '/terms/search') this.props.history.push('/terms/search');
    };

    _onChangeFlags = (e) => {
        const data = {
            [e.target.name]: e.target.checked
        }
        this.props.setSearchCheckbox(data);
    };

    _handleSubmit = (e) => {
        e.preventDefault();
        this.props.history.push('/terms/search');

    };

    _onClear = (e) => {
        e.preventDefault();
        this.props.setSearchWord('');
    }

    render() {

        const { searchWord, searchCheckbox } = this.props;

        return (
            <React.Fragment>
                <Container fluid className={'search-container'}>
                    <Row className={'search-form-row'}>
                        <Col>
                            <form onSubmit={(e) => this._handleSubmit(e)}>
                                <div className='search-form-group form-group'>
                                    <div className='form-group--icon'>
                                        <input
                                            type="text"
                                            className='title-input'
                                            placeholder='Type here to search'
                                            value={searchWord}
                                            onChange={(e) => this._onChange(e)}
                                        />
                                        {searchWord.length ? (
                                            <button className={"form-icon"}>
                                                <FontAwesomeIcon
                                                icon={faTimes}
                                                color={"#4bacc6"}
                                                onClick={e => this._onClear(e)}
                                                />
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="conditions search-form-group form-group">
                                    <div className="condition-flag-container">
                                        <input
                                            id={'search-descriptions'}
                                            name={'search_descriptions'}
                                            type="checkbox"
                                            value={'search-descriptions'}
                                            className={'search-form-flag'}
                                            checked={searchCheckbox.search_descriptions}
                                            onChange={(e) => this._onChangeFlags(e)}
                                        />
                                        <label htmlFor="search-descriptions">search descriptions</label>
                                    </div>
                                    {/*<div className="condition-flag-container">
                                        <input
                                            id={'exclude-terms'}
                                            name={'exclude'}
                                            type="checkbox"
                                            value={'exclude'}
                                            className={'search-form-flag'}
                                            checked={searchCheckbox.exclude}
                                            onChange={(e) => this._onChangeFlags(e)}
                                        />
                                        <label htmlFor="exclude-terms">exclude BIMe terms</label>
                                    </div>*/}
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    searchWord: state.terms.searchWord,
    searchCheckbox: state.terms.searchCheckbox,
});

const mapActionsToProps = (dispatch) => ({
    setSearchWord: (data) => dispatch(setSearchWord(data)),
    setSearchCheckbox: (data) => dispatch(setSearchCheckbox(data)),
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(SearchForm));
