import * as Scroll from 'react-scroll';

export default (id, isOffset) => {
    let options = {
        duration: 400,
        smooth: true,
    };

    let offset;
    const screenWidth = window.innerWidth;

    if (screenWidth <= 320) {
        offset = -176;
    } else if (screenWidth > 320 && screenWidth <= 360) {
        offset = -184;
    } else if (screenWidth > 360 && screenWidth <= 375) {
        offset = -187;
    } else if (screenWidth > 375 && screenWidth <= 414) {
        offset = -195;
    } else if (screenWidth > 414 && screenWidth <= 767) {
        offset = -222;
    } else {
        offset = 0;
    }

    if (isOffset) {
        options = {
            ...options,
            offset,
        }
    }

    Scroll.scroller.scrollTo(id, options);
}