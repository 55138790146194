import React, {Component} from "react";
import {connect} from "react-redux";
import {faShareAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SetRow from "./SetRow";
import {Link} from "react-router-dom";
import {bulkDeleteSets, clearAdminSets, deleteSet, getAdminSets} from "../../../store/console/actions";
import Paginator from "../../../helpers/Paginator";
import {managementConsolePaging} from '../../../configs/config';
import CustomTableWithSelecting from "../../../helpers/CustomTableWithSelecting";

class Sets extends Component {
    state = {
        selected: [],
        searchParams: {
            per_page: managementConsolePaging,
            page: 1
        }
    };

    _tableColumns = [{
        style: {
            width: '3%'
        },
        className: 'console-select',
        checkboxesTitle: true
    }, {
        style: {
            width: '11%'
        },
        title: 'Set Name',
        className: 'console-sets-name'
    }, {
        style: {
            width: '36%'
        },
        title: 'Set Description',
        className: 'console-sets-description'
    }, {
        style: {
            width: '16%'
        },
        title: '# of Terms',
        className: 'console-sets-number-of-terms'
    }, {
        style: {
            width: '18%'
        },
        title: 'Shared With',
        className: 'console-sets-shared-users'
    }, {
        style: {
            width: '12%'
        },
        title: 'Created Date',
        className: 'console-sets-created-date'
    }, {
        style: {},
        title: '',
        className: 'console-sets-actions'
    }];

    /* methods */
    deleteSelectedSets() {
        const {selected, searchParams} = this.state;

        this.props.bulkDeleteSets({sets: selected})
            .then(() => this.props.getAdminSets(searchParams))
    }

    _paginatorChangedPage(data) {
        const {searchParams} = this.state;

        searchParams.page = data.selected + 1;
        this.setState({searchParams});

        this.props.getAdminSets(searchParams);
    }

    _deleteAction(set) {
        const {searchParams} = this.state;

        this.props.deleteSet(set.id)
            .then(() => this.props.getAdminSets(searchParams))
    }

    componentDidMount() {
        this.props.clearAdminSets();
    }

    render() {
        const {sets} = this.props;

        const _tableFooterColumns = [{
            colspan: 7,
            component: <Paginator
                pageCount={this.props.totalSets / this.state.searchParams.per_page}
                pageChanged={(data) => this._paginatorChangedPage(data)}
                forcePage={this.state.searchParams.page - 1}
                className={"console-paginator"}
            />
        }];

        return (
            <div className="console-container-full console-sets-container">
                <div className="console-header row no-gutters">
                    <div className="col-xl-10 console-header-sets-lp">
                        <div>
                            <span className="selected-rows">{this.state.selected.length} Selected</span>
                            <span className="share-selected">
                                <FontAwesomeIcon icon={faShareAlt} className={'share-link'}/> share</span>
                            <span className="delete-selected" onClick={() => this.deleteSelectedSets()}>
                                <FontAwesomeIcon icon={faTrashAlt} className={'delete-link'}/> remove
                            </span>
                        </div>
                    </div>
                    <div className="col-xl-2 console-header-sets-rp">
                        <button className="console-button">
                            <Link to={'/management-console/sets/add-set'}>Create New Set</Link>
                        </button>
                    </div>
                </div>

                <div className="console-body">

                    <CustomTableWithSelecting
                        className={"console-table"}
                        columns={this._tableColumns}
                        footer={_tableFooterColumns}
                        items={sets}
                        deleteAction={(item) => this._deleteAction(item)}
                        onChangeSelect={(selected) => {
                            this.setState({
                                selected
                            });
                        }}
                    >
                        <SetRow/>
                    </CustomTableWithSelecting>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sets: state.managementConsole.sets,
    totalSets: state.managementConsole.totalSets,
    hasMoreSets: state.managementConsole.hasMoreSets,
});

const mapDispatchToProps = (dispatch) => ({
    getAdminSets: (data) => dispatch(getAdminSets(data)),
    clearAdminSets: () => dispatch(clearAdminSets()),
    deleteSet: (id) => dispatch(deleteSet(id)),
    bulkDeleteSets: (data) => dispatch(bulkDeleteSets(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sets);