import React, {Component} from "react";
import {connect} from "react-redux";
import {Col, Container, Row} from "reactstrap";
import {fetchLabelList, fetchLanguageList, searchMoreTerms, searchTerms} from "../../store/terms/actions";
import Filter from "../../helpers/Filter";
import TermsFound from "../Term/TermsFound";


class SearchPage extends Component {

    state = {
        q: '',
        searchCheckbox: {},
        chosenParams: [],
        searchParams: {
            lang: '',
            label: '',
        },
        page: 2,
        per_page: 20,
        primaryLanguageFilterWasShown: false
    };

    _filterParams = [{
        name: 'Language:',
        queryName: 'lang',
        _getData: () => this.props.langs,
        _onClick: (param) => this._chooseFilterParams(param)
    }, {
        name: 'Labels:',
        queryName: 'label',
        _getData: () => this.props.labels,
        _onClick: (param) => this._chooseFilterParams(param)
    }];

    toggleChooseAllFilterParams = () => {
        this.setState(state => ({chosenParams: state.chosenParams.length === 0 ?  this._filterParams : []}))
    };

    callSearchAction() {
        const {searchParams, q, searchCheckbox} = this.state;
        const searchData = {
            ...searchCheckbox,
            ...searchParams
        };
        if (q !== '') {
            searchData.q = q;
        }

        this.props.searchTerms(searchData);
    }

    componentDidMount() {
        const {searchParams, chosenParams, q} = this.state;
        const {searchLabel, searchWord, searchCheckbox} = this.props;

        if (searchLabel.id !== undefined) {
            this._chooseFilterParams({
                ...this._filterParams[1],
                defaultValue: {value: searchLabel.id, label: searchLabel.title}
            });

            this.setState({
                searchLabel: searchLabel,
                searchParams: {
                    ...searchParams,
                    label: searchLabel.id
                }
            }, () => this.callSearchAction());
            searchParams.label = searchLabel.id
        }

        if (searchWord !== q) {
            this.setState({
                q: searchWord
            }, () => this.callSearchAction());
        }

        this.setState({searchCheckbox: searchCheckbox}, () => this.callSearchAction());

        this.props.fetchLanguageList();
        this.props.fetchLabelList();
    }

    componentWillUpdate(nextProps) {
        if (this.props.searchWord !== nextProps.searchWord) {
            this.setState({q: nextProps.searchWord}, () => this.callSearchAction());
        }
        if (this.props.searchCheckbox !== nextProps.searchCheckbox) {
            this.setState({searchCheckbox: nextProps.searchCheckbox}, () => this.callSearchAction());
        }
        if (this.props.searchLabel !== nextProps.searchLabel) {
            this._chooseFilterParams({
                ...this._filterParams[1],
                defaultValue: {value: nextProps.searchLabel.id, label: nextProps.searchLabel.title}
            });

            this.setState({
                searchLabel: nextProps.searchLabel,
                searchParams: {
                    ...this.state.searchParams,
                    label: nextProps.searchLabel.id
                }
            }, () => this.callSearchAction());
        }
        // TODO: uncomment when Primary Dictionary Language will come back
        /*const { primaryLanguageFilterWasShown, chosenParams} = this.state;

        const languageParam = chosenParams.find(param => param.name === 'Language:');

        if (nextProps.user.language && !languageParam && !primaryLanguageFilterWasShown) {
            const {code, name} = nextProps.user.language;

            this._chooseFilterParams({
                ...this._filterParams[0],
                defaultValue: {value: code, label: name}
            });

            this.setState({
                primaryLanguageFilterWasShown: true
            });

            this.chooseParamData({value: code, label: name}, "lang")
        }*/
    }

    _chooseFilterParams = (param) => {
        const {chosenParams} = this.state;
        const elem = chosenParams.filter((e) => e.queryName === param.queryName);

        if (elem.length === 0) {
            this.setState({
                chosenParams: [...chosenParams, param]
            });
        } else if (param.defaultValue !== undefined && param.defaultValue !== elem[0].defaultValue) {
            this.setState({
                chosenParams: chosenParams.map((e) =>
                    e.queryName === param.queryName ? {
                        ...e,
                        defaultValue: param.defaultValue
                    } : e)
            });
        }
    };

    _removeParam = (paramIndex, param) => {
        const {chosenParams} = this.state;
        this.setState({
            chosenParams: chosenParams.filter((param, index) => index !== paramIndex),
            searchParams: {
                [param.queryName]: ''
            }
        }, () => this.callSearchAction());
    };

    _removeAllParams = () => {
        this.setState({
            chosenParams: [],
            searchParams: {
                lang: '',
                label: ''
            }
        }, () => this.callSearchAction());
    };

    chooseParamData = (selected, name) => {
        this.setState(state => ({
            ...state,
            searchParams: {
                ...state.searchParams,
                [name]: selected.value,
            }
        }), () => this.callSearchAction());
    };

    findByLabel = (data) => {
        this.props.setSearchLabel(data.item);
    }

    _loadMoreTerms = () => {
        const {hasMoreTerms, searchMoreTerms, searchWord, searchCheckbox} = this.props;
        const q = searchWord ? searchWord : null ;
        if (hasMoreTerms) {
            searchMoreTerms({
                q,
                ...searchCheckbox,
                ...this.state.searchParams,
                per_page: this.state.per_page,
                page: this.state.page
            }).then(() => this.setState(state => ({page: state.page + 1})))
        }
    }

    render() {
        const {langs, hasMoreTerms, termsFound} = this.props;
        const {chosenParams} = this.state;

        return (
            <>
                <Container className='terms-container'>
                    <Row className="filter-row">
                        <Col
                            className="filter-col"
                            xl={{size: 8, offset: 2}}
                        >
                            <Filter
                                toggleChooseAllFilterParams={this.toggleChooseAllFilterParams}
                                langs={this.props.labels}
                                filterParams={this._filterParams}
                                chooseFilterParams={this._chooseFilterParams}
                                removeAllParams={this._removeAllParams}
                                removeParam={this._removeParam}
                                chosenParams={chosenParams}
                                chooseParamData={this.chooseParamData}
                            />
                        </Col>
                    </Row>

                    {termsFound.length !== 0
                    && <Row className="terms-found-row">
                        <TermsFound
                            langs={langs}
                            isFetching={this.props.isFetching}
                            loadMoreTerms={this._loadMoreTerms}
                            hasMoreTerms={hasMoreTerms}
                            terms={termsFound}
                            findByLabel={this.findByLabel}
                        />
                    </Row>}
                </Container>
                {termsFound.length === 0
                && <div className='search-page-wrapper'>
                    No terms found
                </div>}
            </>
        )
    }
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    langs: state.terms.langs,
    labels: state.terms.labels,
    searchWord: state.terms.searchWord,
    searchCheckbox: state.terms.searchCheckbox,
    searchLabel: state.terms.searchLabel,
    termsFound: state.terms.itemsFound,

    hasMoreTerms: state.terms.hasMoreTerms,
    searchFormClear: state.terms.searchFormClear,
    isFetching: state.terms.isFetching
});

const mapActionsToProps = (dispatch) => ({
    searchTerms: (data) => dispatch(searchTerms(data)),
    searchMoreTerms: (data) => dispatch(searchMoreTerms(data)),
    fetchLanguageList: () => dispatch(fetchLanguageList()),
    fetchLabelList: () => dispatch(fetchLabelList())
});

export default connect(mapStateToProps, mapActionsToProps)(SearchPage);
