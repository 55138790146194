import axios from "axios";
import {baseURL} from "../configs/config";
import {watchRequests, createRequestInstance, sendRequest, getRequestInstance} from "redux-saga-requests";
import {createDriver} from "redux-saga-requests-axios";
import {call, put} from 'redux-saga/effects'
import { push } from 'react-router-redux';

function* onErrorSaga(error, action) {
    // const err = errorStatus;
    // const ss = err.split(' ')
    if (error && error.response && error.response.status === 401) {
        // get driver instance, in our case Axios to make a request without Redux
        const requestInstance = yield getRequestInstance();
        if (localStorage.getItem('refresh') !== undefined && localStorage.getItem('refresh') !== null) {
            try {
                const {data} = yield call(
                    requestInstance.post,
                    `${baseURL}token/refresh`,
                    {
                        refresh: localStorage.getItem('refresh')
                    }
                );

                if (data.access !== undefined) {
                    localStorage.setItem('access', data.access);
                } else {
                    throw 'exception';
                }

                const {new_data} = yield call(sendRequest, action, {silent: true});

                if (new_data === undefined) {
                    localStorage.removeItem('access');
                    localStorage.removeItem('refresh');
                    window.location = '/';
                    return false;
                }

                return new_data;

            } catch (e) {
                localStorage.removeItem('access');
                localStorage.removeItem('refresh');
                window.location = '/'
            }
        }
    }
    return {error};
}

export default function* rootSaga() {
    yield createRequestInstance({
        driver: createDriver(axios),
        onError: onErrorSaga,
    });
    yield watchRequests();
}
