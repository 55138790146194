import React, {Component} from 'react';
import TermInsertLine from "../../../../../Term/TermInsertLine";
import TemplateInfoblockRows from "./TemplateInfoblockRows";
import InfoblockEditable from "./InfoblockEditable";
import InfoblockContent from "./InfoblockContent";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class TemplateInfoblockRow extends Component {
    state = {
        hover : false,
    };

    toggleInfoblockHoverAdd = (event) => {
        if (this.props.hideParent !== undefined) {
            this.props.hideParent();
        }

        this.setState({
            hover : true
        })
    };

    showParentFunction = () => this.setState({hover:true});
    hideParentFunction = () => {
        this.setState({hover: false});
        if (this.props.hideParent !== undefined) {
            this.props.hideParent();
        }
    };

    toggleInfoblockHoverOff = (event) => {
        if (this.props.showParent !== undefined) {
            this.props.showParent();
        }

        this.setState({
            hover : false
        })
    };


    render() {
        const {prefix, onDelete, onDuplicate, onHide, onLockUnlock, onEdit, onSave, onInsert, onAdd, item, index, user, term, assingedUsers} = this.props;
        const level = this.props.level !== undefined ? this.props.level : 1;
        const coAuthors = assingedUsers && Array.isArray(assingedUsers) ? assingedUsers : [];
        const isCoAuthor = coAuthors.includes(user.id);
        return (
            <div key={item.id}
                 onMouseEnter={this.toggleInfoblockHoverAdd}
                 onMouseLeave={this.toggleInfoblockHoverOff}
            >
                {item.checked &&
                <div className={`document-infoblock-row`}>
                    {item.isEdit ? <InfoblockEditable
                        prefix={prefix}
                        level={level}
                        item={item}
                        index={index}
                        onDelete={onDelete}
                        onCancelEdit={onEdit}
                        saveBlock={onSave}
                        user={user}

                    /> : <InfoblockContent
                        term={term}
                        isEdit={this.props.isEdit}
                        prefix={prefix}
                        level={level}
                        item={item}
                        index={index}
                        onDelete={onDelete}
                        onDuplicate={onDuplicate}
                        onHide={onHide}
                        onLockUnlock={onLockUnlock}
                        user={user}
                        onEdit={onEdit}
                        infoblockFromLink={this.props.infoblockFromLink}
                        updateEmbeddedTermClick={this.props.updateEmbeddedTermClick}
                    />}

                    {this.state.hover && !item.isEdit && this.props.isEdit && (isCoAuthor || user.role === 'Admin') &&
                    <TermInsertLine
                        item={item}
                        onInsert={onInsert}
                        onAdd={onAdd}
                    />
                    }

                    {item.children && item.children.length !== 0 &&
                    <TemplateInfoblockRows
                        term={term}
                        prefix={`${(prefix !== undefined ? prefix : '') + (index + 1)}.`}
                        infoblocks={item.children}
                        level={level < 2 ? level + 1 : level}
                        isEdit={this.props.isEdit}
                        onInsert={onInsert}
                        onAdd={onAdd}
                        onDelete={onDelete}
                        onDuplicate={onDuplicate}
                        onHide={onHide}
                        onLockUnlock={onLockUnlock}
                        onEdit={onEdit}
                        onSave={onSave}
                        hideParent={this.hideParentFunction}
                        showParent={this.showParentFunction}
                        updateEmbeddedTermClick={this.props.updateEmbeddedTermClick}
                    />
                    }
                </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    infoblockFromLink: state.common.infoblockFromLink,
    assingedUsers: state.managementConsole.singleTerm.assigned_users,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TemplateInfoblockRow));