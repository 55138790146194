import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from 'react-router-dom';
import {clearSearchedTerms, clearSearchForm, setSearchLabel} from "../../store/terms/actions";
import {Card, CardTitle, Row} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EarthLanguage from "./partials/EarthLanguage";
import reactStringReplace from 'react-string-replace';
import * as PropTypes from "prop-types";
import TermLinks from "./partials/TermLinks";
import {FaCog} from "react-icons/lib/fa";
import TermNotifications from "./partials/TermNotifications";


class TermCardView extends Component {

    static propTypes = {
        term: PropTypes.object.isRequired,
        title: PropTypes.any,
        langs: PropTypes.array,
        convertEmbeddedTerms: PropTypes.func,
        clickedEmbeddedTerm: PropTypes.bool,
        onChangeTranslation: PropTypes.func,
        clearSearchForm: PropTypes.func,
        clearSearchedTerms: PropTypes.func,
    };

    state = {
        isClickedStar: false,
        isClickedLink: false,
        isChosenPrimaryLanguage: true,
    };

    createMarkup = (data) => {
        return reactStringReplace(data, /(.*)/, (match, i) => (
            <span className='inline-topic_inner' key={`${i + Math.random()}_inner`}
                  dangerouslySetInnerHTML={{__html: match}}/>));
    };

    setClickedLink = (isClickedLink) => {
        this.setState({
            isClickedLink
        })
    };

    setClickedStar = (isClickedStar) => {
        this.setState({
            isClickedStar
        })
    };

    _checkTermLanguage = (lang) => {
        const {term} = this.props;
        let result = false;
        if (term.available_languages) {
            term.available_languages.forEach(termLang => {
                if (termLang.language === lang.code) {
                    result = true;
                }
            });
        }
        return result
    };

    setDefaultLanguages = () => {
        this.props.history.push('/my-account');
    };

    setChosenLanguage = (language, isPrimaryLanguage) => {
        this.setState({
            isChosenPrimaryLanguage: isPrimaryLanguage
        });
        this.props.onChangeTranslation(language);
    };

    render() {
        const {
            term, langs, onChangeTranslation, convertEmbeddedTerms, clickedEmbeddedTerm, acceptSearchLabel,
            clearSearchedTerms, clearSearchForm, user, title, similarTerms
        } = this.props;

        const termAvailableLanguages = term.available_languages || [];

        return (
            <Card body className={`box-card ${clickedEmbeddedTerm ? 'term-card-shadow_background' : ''}`}>
                <CardTitle className="term-card-title term-card-title_wrapper">
                    {term && term.content &&
                    <span className="term-title-container">
                            <Link
                                to={`/${term.content.language}/${term.slug}/${term.version && term.version.number ? term.version.number : 1}`}
                                className={`${term.content.language === 'ar' ? 'arabic' : ''} term-title ${term.direction !== undefined ? term.direction : ''} ${term.content && term.content.language === 'ar' ? 'arabic' : ''}`}
                                onClick={() => {
                                    clearSearchedTerms();
                                    clearSearchForm(true);
                                }}>
                                {this.createMarkup(title)}
                            </Link>
                        {term.content.acronym && <span className="term-title">&nbsp;({term.content.acronym})</span>}
                        </span>
                    }
                    <TermLinks
                        term={term}
                        showNotifications
                        showCopy
                        showFavourite
                        favouriteEnabled
                        handleClickedStar={() => this.setClickedStar(true)}
                        handleClickedLink={() => this.setClickedLink(true)}

                    />
                </CardTitle>
                <div className="bim-card-text">
                    <div
                        className={`${term.content && term.content.language === 'ar' ? 'arabic' : ''} ${term.direction !== undefined ? term.direction : ''}
                                ${term.content && (term.content.language === 'ar' || term.content.language === 'fa') ? 'rtl' : ''}`}>
                        {this.createMarkup(convertEmbeddedTerms())}
                    </div>
                    <Row className="bim-description-row">
                        <ul className={'bim-card-list'}>
                            {term.similar_terms && (
                                <li>
                                <span className="bim-card-subheader">
                                    Similar Terms:
                                </span>
                                    <span className="bim-card-term">
                                    <span>{this.createMarkup(similarTerms)}</span>
                                </span>
                                </li>
                            )}
                            {term.linked_terms && term.linked_terms.length !== 0 && (
                                <li>
                                    <span className="bim-card-subheader">
                                        Also refer to:
                                    </span>

                                    <span className="bim-card-refer">
                                        {term.linked_terms && term.linked_terms.map((item, index) => (
                                            <span key={index}>
                                                <Link
                                                    to={`/en/${item.slug}/${item.version ? item.version : 1}`}
                                                    dangerouslySetInnerHTML={{__html: item.title}}/>
                                                {index < term.linked_terms.length - 1 && (<span>&nbsp;|&nbsp;</span>)}
                                            </span>
                                        ))}
                                    </span>
                                </li>
                            )}
                        </ul>
                    </Row>
                    <Row
                        // className={`bim-card-footer ${!termAvailableLanguages.length ? 'bim-card-footer-indent' : ''}`}>
                        className={`bim-card-footer ${!termAvailableLanguages.length > 0 && 'bim-card-footer-indent'}`}>
                        <div className={'bim-footer'}>
                            {term.labels && term.labels.length !== 0 &&
                            <>
                                {/*<FontAwesomeIcon icon={'info-circle'} className={'bim-info-circle'}/>*/}

                                <div className="concepts">
                                    {term.labels && term.labels.map((item) => (
                                        <div className="concept" key={item.id}
                                             onClick={() => acceptSearchLabel({item, term})}>
                                            {item.title}
                                        </div>
                                    ))}
                                </div>
                            </>
                            }
                        </div>

                        {user.secondary_language
                            ? <div className="both-languages">
                                <span title="Set Default Languages" onClick={() => this.setDefaultLanguages()}>
                                        <FaCog className="language-cog"/>
                                    </span>
                                <div
                                    className={`primary-language ${this.state.isChosenPrimaryLanguage ? 'active' : ''}`}
                                    onClick={() => {
                                        this.setChosenLanguage(user.language, true);
                                    }}>
                                    English
                                </div>
                                {this._checkTermLanguage(user.secondary_language) && (
                                    <div
                                        className={`secondary-language ${!this.state.isChosenPrimaryLanguage ? 'active' : ''}`}
                                        onClick={() => this.setChosenLanguage(user.secondary_language, false)}
                                        title={user.secondary_language.english}>
                                        {user.secondary_language.name}
                                    </div>
                                )}
                            </div>
                            : <>
                                {termAvailableLanguages.length > 0 && (
                                    <div className="lang">
                                    <span>
                                        <span>
                                            {term.content && langs.map((lang, index) =>
                                                lang.code === term.content.language
                                                    ? <span key={index} title={lang.english}
                                                            className={`${term.content.language === 'ar' ? 'arabic' : ''}`}>{lang.name}</span>
                                                    : false)
                                            }
                                        </span>
                                    </span>
                                        <EarthLanguage
                                            onChangeTranslation={onChangeTranslation}
                                            term={term}
                                        />
                                    </div>
                                )}
                            </>
                        }
                    </Row>
                </div>
            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

const mapActionsToProps = {setSearchLabel, clearSearchForm, clearSearchedTerms};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(TermCardView));
