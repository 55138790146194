import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {
    DropdownItem, DropdownMenu, DropdownToggle, Button, Container,
    Navbar, Nav, NavbarToggler, Collapse, NavItem, UncontrolledDropdown,
    Row, Col
} from "reactstrap";
import logo from '../../assets/logo.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SearchForm from '../components/SearchForm/SearchForm'
import {FaBell} from "react-icons/lib/fa";
import {withRouter} from "react-router";
import GdprBlock from "./GdprBlock";

class Header extends Component {

    state = {
        isOpen: false
    };

    toggleSidebar = () => {
        this.setState(({isOpen}) => ({
            isOpen: !isOpen
        }));
    };

    handleSidebarClick = (event) => {
        if (this.state.isOpen && !this.isClickOnDropdown(event)
            && (this.isClickOnDim(event) || event.target.tagName === 'A')) {
            this.toggleSidebar();
        }
    };

    isClickOnDropdown = (event) => {
        return event.target.parentNode.className.length && event.target.parentNode.className.split(" ").indexOf("dropdown") !== -1;
    };

    isClickOnDim = (event) => {
        return event.target.className.length && event.target.className.indexOf('sidebar-open') !== -1;
    };

    shouldShowBell = () => {
        const {user, location: {pathname}} = this.props;
        return user && user.notifications_count > 0
            && (pathname !== '/editors-dashboard')
            && (pathname !== '/reviewers-dashboard')
            && user.role !== 'User'
    };

    render() {
        const {user, isAuthorized, signOut, location} = this.props;
        return (
            <Container fluid className={'header-container'}>
                <GdprBlock/>
                <div className="header-row">
                    <div
                        className="header-wrapper"
                    >
                        <Navbar color={'faded'} light expand="md">
                            <NavLink className={'navbar-brand'} to={'/'}>
                                {<img src={`${static_url}assets/logo.4c5d90cf.svg`}></img>}&nbsp;
                            </NavLink>

                            <NavbarToggler onClick={this.toggleSidebar}/>

                            <div
                                className={`header-collapse sidebar ${this.state.isOpen ? 'sidebar-open' : 'sidebar-closed'}`}
                                onClick={this.handleSidebarClick}>
                                <div className="sidebar-inner">
                                    <Nav className="ml-auto navbar-buttons sidebar-content" navbar>
                                        <NavItem>
                                            <NavLink
                                                className={'nav-link header-link'}
                                                activeClassName={'active-nav-link'}
                                                to={'/'}
                                            >
                                                Home
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                className={'nav-link header-link'}
                                                activeClassName={'active-nav-link'}
                                                to={'/features'}
                                            >
                                                Features
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                className={'nav-link header-link'}
                                                activeClassName={'active-nav-link'}
                                                to={'/contributors'}
                                            >
                                                Contributors
                                            </NavLink>
                                        </NavItem>

                                        <UncontrolledDropdown className="dropdown-more" nav inNavbar>
                                            <DropdownToggle nav style={{display: 'flex', alignItems: 'center'}}
                                                            className={'header-link'}>
                                                More
                                                <FontAwesomeIcon icon={'chevron-down'} className={'header-arrow-down'}/>
                                            </DropdownToggle>

                                            <DropdownMenu right>
                                                <DropdownItem>
                                                    <NavLink
                                                        className={'nav-link'}
                                                        activeClassName={'active-nav-link'}
                                                        to={'/all-terms'}
                                                    >
                                                        All terms
                                                    </NavLink>
                                                </DropdownItem>

                                                <DropdownItem>
                                                    <NavLink
                                                        className={'nav-link'}
                                                        activeClassName={'active-nav-link'}
                                                        to={'/about'}
                                                    >
                                                        About
                                                    </NavLink>
                                                </DropdownItem>

                                                {user && (user.role === 'Editor' || user.role === 'Reviewer' || user.role === 'Co-Editor' || user.role === 'Admin') &&
                                                <DropdownItem>
                                                    <NavLink
                                                        className={'nav-link'}
                                                        activeClassName={'active-nav-link'}
                                                        to={'/help'}
                                                    >
                                                        Help
                                                    </NavLink>
                                                </DropdownItem>}

                                                <DropdownItem>
                                                    <NavLink
                                                        className={'nav-link'}
                                                        activeClassName={'active-nav-link'}
                                                        to={'/participate'}
                                                    >
                                                        Participate
                                                    </NavLink>
                                                </DropdownItem>

                                                <DropdownItem>
                                                    <NavLink
                                                        className={'nav-link'}
                                                        activeClassName={'active-nav-link'}
                                                        to={'/contact'}
                                                    >
                                                        Contact Us
                                                    </NavLink>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>

                                        <NavItem className="dropdown-user"
                                                 style={{display: 'flex', alignItems: 'center'}}>
                                            {isAuthorized
                                                ? <ul className='user-nav-menu'>
                                                    <UncontrolledDropdown nav inNavbar>
                                                        <DropdownToggle nav style={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }} className={'header-link'}>
                                                            <img src={user.avatar} alt="" className={'avatar-thumb'}/>
                                                            {this.shouldShowBell() &&
                                                            <div className="notification-circle">
                                                                <FaBell/>
                                                            </div>}
                                                            <FontAwesomeIcon icon={'chevron-down'}
                                                                             className={'header-arrow-down'}/>
                                                        </DropdownToggle>

                                                        <DropdownMenu right>
                                                            <DropdownItem>
                                                                <NavLink
                                                                    className={'nav-link'}
                                                                    activeClassName={'active-nav-link'}
                                                                    to={'/starred-terms'}
                                                                >
                                                                    Starred Terms
                                                                </NavLink>
                                                            </DropdownItem>

                                                            <DropdownItem>
                                                                <NavLink
                                                                    className={'nav-link'}
                                                                    activeClassName={'active-nav-link'}
                                                                    to={'/my-account'}
                                                                >
                                                                    My Account
                                                                </NavLink>
                                                            </DropdownItem>

                                                            {(user.role === 'Editor' || user.role === 'Co-Editor') && (
                                                                <DropdownItem>
                                                                    <NavLink
                                                                        className={'nav-link'}
                                                                        activeClassName={'active-nav-link'}
                                                                        to={'/editors-dashboard'}
                                                                    >
                                                                        Editor's Dashboard
                                                                    </NavLink>
                                                                </DropdownItem>
                                                            )}

                                                            {user.is_author && (
                                                                <DropdownItem>
                                                                    <NavLink
                                                                        className={'nav-link'}
                                                                        activeClassName={'active-nav-link'}
                                                                        to={'/authors-dashboard'}
                                                                    >
                                                                        Author's Dashboard
                                                                    </NavLink>
                                                                </DropdownItem>
                                                            )}

                                                            {user.is_logs_viewer && (
                                                                <DropdownItem>
                                                                    <NavLink
                                                                        className={'nav-link'}
                                                                        activeClassName={'active-nav-link'}
                                                                        to={'/logs'}
                                                                    >
                                                                        View Log
                                                                    </NavLink>
                                                                </DropdownItem>
                                                            )}

                                                            {user.role === 'Admin' && (
                                                                <DropdownItem>
                                                                    <NavLink
                                                                        className={'nav-link'}
                                                                        activeClassName={'active-nav-link'}
                                                                        to={'/management-console'}
                                                                    >
                                                                        Management Console
                                                                    </NavLink>
                                                                </DropdownItem>
                                                            )}

                                                            {user.role === 'Reviewer' && (
                                                                <DropdownItem>
                                                                    <NavLink
                                                                        className={'nav-link'}
                                                                        activeClassName={'active-nav-link'}
                                                                        to={'/reviewers-dashboard'}
                                                                    >
                                                                        Reviewer's Dashboard
                                                                    </NavLink>
                                                                </DropdownItem>
                                                            )}

                                                            <div className='dropdown-item'>
                                                                <Button
                                                                    type={'button'}
                                                                    className={'log-out-btn'}
                                                                    color={'link'}
                                                                    onClick={() => signOut()}
                                                                >
                                                                    Log out
                                                                </Button>
                                                            </div>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </ul>
                                                : <NavLink
                                                    activeClassName={'active-nav-link'}
                                                    className={'nav-link header-link'}
                                                    to={'/login'}
                                                >
                                                    Log In
                                                </NavLink>}
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                        </Navbar>
                    </div>
                </div>
                <SearchForm/>
            </Container>
        )
    }

}

export default withRouter(Header);
