import React, {Component} from 'react';
import {Modal, Container, Row, Col} from 'react-bootstrap';

class AttachedTemplateModal extends Component {
    render() {
        return (
            <Modal
                className="wrapper-base-modal insert-infoblock-modal"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        You want to attach template that has already attached to another term. If you attach this template it will be detached from previous term. Are you sure?
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer className="delete-extended plus">
                    <span onClick={this.props.onHide}>Cancel</span>
                    <button className="console-button" onClick={() => this.props.onSave(this.props)}>
                        Detach + Replace
                    </button>
                    <button className="console-button" onClick={() => this.props.onDuplicate(this.props)}>
                        Duplicate
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AttachedTemplateModal