import {Link} from "react-router-dom";
import React from "react";
import * as PropTypes from "prop-types";
import {withRouter} from "react-router";
import {setSearchLabel} from "../../../store/terms/actions";
import {connect} from "react-redux";

const RelatedInfo = ({term, alsoReferTo, setSearchLabel, history}) => {

    const findByLabel = (data) => {
        setSearchLabel(data);
        history.push('/terms/search');
    };

    return (
        <div className="wrapper-term-params">
            {term.similar_terms &&
            <div>
                <span className="label-params similar-term">Similar:</span>
                <span className="similiar-terms-content">{term.similar_terms && term.similar_terms}</span>
            </div>
            }
            {term.linked_terms && term.linked_terms.length !== 0 && (
                <div className="refer-wrapper">
                    <span className="label-params">{alsoReferTo ? `Also refer to:` : `Refer to:`}</span>
                    {term.linked_terms && term.linked_terms.map((item, index) => (
                        <span key={index}>
                            <Link to={`/en/${item.slug}/${item.version ? item.version : 1}`}
                                dangerouslySetInnerHTML={{__html: item.title}}>
                            </Link>
                            {index < term.linked_terms.length - 1 && (<span>&nbsp;|&nbsp;</span>)}
                        </span>
                    ))}
                </div>)
            }
            {term.labels && term.labels.length !== 0 &&
            <div className='label-wrapper'>
                <span className="label-params">Labels:</span>
                {term.labels && term.labels.map((item) => (
                    <span className='labels-term' key={item.id} onClick={() => findByLabel(item)}>
                        {item.title}
                    </span>
                ))}
            </div>
            }
        </div>
    )
};

RelatedInfo.propTypes = {
    term: PropTypes.object.isRequired,
    alsoReferTo: PropTypes.bool
};

const mapStateToProps = () => ({});

const mapActionsToProps = {setSearchLabel};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(RelatedInfo));