import React from 'react'
import {FaCopy} from "react-icons/lib/fa/index";
import {baseURLForLink} from "../../../../../../configs/config";
import moment from "moment";
import TermNotifications from "../../../../../Term/partials/TermNotifications";

class CiteRow extends React.Component {

    state = {
        isClickedCite: false,
        isClickedCiteOnce: false,
    };

    inputCitation = React.createRef();

    onCiteClicked = () => {
        this.setState({
            isClickedCite: true,
            isClickedCiteOnce: true
        });
        this.copyCitation()
    };

    onLinkClose = () => {
        this.setState({
            isClickedCite: false,
            isClickedCiteOnce: false
        });
    };

    copyCitation = () => {
        this.inputCitation.current.select();
        document.execCommand('copy');
    };

    buildCitation = () => {
        const {term} = this.props;
        const content = term.content;
        if (!term.content) {
            return '';
        }
        const citation = `BIM Dictionary (2019), ${content && content.title ? `${content.title},` : ''} version ${term.version ? term.version.number : 1}. `
            + `${baseURLForLink}${content && content.language ? content.language : 'en'}/${term.slug}/${term.version ? term.version.number : 1}. `
            + `last accessed ${moment().format('LL')}`;
        return citation.replace(/<\s*[^>]*>/g, '')
    };

    render() {
        const {term} = this.props;
        const {isClickedCite} = this.state;
        return (
            <div className={`cite-row`}>
                <div className={`cite-title`}>Cite this page as: </div>
                <div className="cite-flex">
                    <span title="Copy citation">
                        <FaCopy className="cite-icon" onClick={() => this.onCiteClicked()} />
                    </span>
                    <p>
                        {this.buildCitation()}
                    </p>
                    <input type="text"
                           ref={this.inputCitation}
                           className="hidden-input"
                           value={this.buildCitation()}
                           readOnly />
                </div>
                <TermNotifications
                    term={term}
                    isClickedLink={isClickedCite}
                    onLinkClose={this.onLinkClose}
                />
            </div>
        )
    }
};

export default CiteRow