import {success, error, abort} from "redux-saga-requests";
import * as types from "./types";

const initialState = {
    isFetching: false,
    isError: false,
    isAuthorized: false,
    commonData: '',
    footerData: '',
    announcement_title: '',
    announcement_body: '',
    announcement_learn_more: '',
    announcementLastModified: '',
    infoblockFromLink: ''
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_COMMON_DATA:
        case types.GET_FOOTER_DATA:
        case types.GET_ANNOUNCEMENT_TITLE:
        case types.GET_ANNOUNCEMENT_BODY:
        case types.GET_ANNOUNCEMENT_LEARN_MORE:
        case types.UPDATE_ANNOUNCEMENT_DATA:
        case types.UPDATE_FOOTER_DATA:
        case types.UPDATE_COMMON_DATA: {
            return {
                ...state,
                isFetching: true,
                isError: false
            };
        }

        case success(types.GET_COMMON_DATA): {
            return {
                ...state,
                isFetching: false,
                isAuthorized: true,
                commonData: action.payload.data.data
            };
        }

        case success(types.GET_FOOTER_DATA): {
            return {
                ...state,
                isFetching: false,
                isAuthorized: true,
                footerData: action.payload.data.data
            };
        }

        case success(types.GET_ANNOUNCEMENT_TITLE): {
            return {
                ...state,
                isFetching: false,
                isAuthorized: true,
                announcement_title: action.payload.data,
                announcementLastModified: Math.max(state.announcementLastModified, action.payload.data.modified)
            };
        }

        case success(types.GET_ANNOUNCEMENT_BODY): {
            return {
                ...state,
                isFetching: false,
                isAuthorized: true,
                announcement_body: action.payload.data,
                announcementLastModified: Math.max(state.announcementLastModified, action.payload.data.modified)
            };
        } 
        
        case success(types.GET_ANNOUNCEMENT_LEARN_MORE): {
            return {
                ...state,
                isFetching: false,
                isAuthorized: true,
                announcement_learn_more: action.payload.data,
                announcementLastModified: Math.max(state.announcementLastModified, action.payload.data.modified)
            };
        }

        case success(types.UPDATE_ANNOUNCEMENT_DATA): {
            const fieldName = action.meta.requestAction.payload.meta.path;
            return {
                ...state,
                isFetching: false,
                [fieldName]: action.payload.data,
                announcementLastModified: Math.max(state.announcementLastModified, action.payload.data.modified)
            };
        }


        case success(types.UPDATE_COMMON_DATA): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                commonData: action.payload.data.data
            };
        }

        case success(types.UPDATE_FOOTER_DATA): {
            return {
                ...state,
                isFetching: false,
                isError: false,
                footerData: action.payload.data.data
            };
        }

        case types.INFOBLOCK_FROM_LINK: {
            return {
                ...state,
                infoblockFromLink: action.payload.data
            }
        }

        case error(types.UPDATE_COMMON_DATA):
        case error(types.GET_FOOTER_DATA):
        case error(types.GET_ANNOUNCEMENT_TITLE):
        case error(types.GET_ANNOUNCEMENT_BODY):
        case error(types.GET_ANNOUNCEMENT_LEARN_MORE):
        case error(types.UPDATE_ANNOUNCEMENT_DATA):
        case error(types.UPDATE_FOOTER_DATA):
        case error(types.GET_COMMON_DATA): {
            return {
                ...state,
                isFetching: false,
                isError: true,
                errMsg: action.payload.response.data,
                errorStatus: action.payload.response.status
            };
        }

        default:
            return state;
    }
};

export default commonReducer;
